import ScrollToError from 'components/common/ScrollToError';
import { Field, Form, Formik } from 'formik';
import { defaults, range } from 'lodash';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { Floor1, Floor2, Floor3, Standard, Premium, Superior } from 'assets/icons/QuickCalculator';
import { ReactComponent as DistrictHeatingStandard } from 'assets/icons/co2-calculator/heating-system/district-heating-standard.svg';
import { ReactComponent as NaturalGas } from 'assets/icons/co2-calculator/heating-system/natural-gas.svg';
import { ReactComponent as Electric } from 'assets/icons/co2-calculator/heating-system/electric.svg';
import { ReactComponent as Coal } from 'assets/icons/co2-calculator/heating-system/coal.svg';
import { ReactComponent as HeatingPump } from 'assets/icons/co2-calculator/heating-system/heating-pump.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as Img } from 'assets/images/build-house/step-1.svg';
import TextInput from 'components/inputs/TextInput';
import NumberInput from 'components/inputs/NumberInput';
import ButtonSelect from 'components/inputs/ButtonSelect';
import Checkbox from 'components/inputs/Checkbox';
import Slider from 'components/inputs/Slider';
import moreInfoEquipmentType from 'constants/wizards/moreInfoEquipmentType';
import SwitchInput from 'components/inputs/Switch';
import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import s from '../../calculators.module.scss';
import validationSchema from '../yupSchemas/buildHouseFirstStepSchema';

export const defaultValues = {
  name: 'Project',
  floors: 1,
  living_space_by_floor_0: null,
  living_space_by_floor_1: null,
  living_space_by_floor_2: null,
  has_property: false,
  living_space: 0,
  terrace_area: null,
  sole_plate_or_basement: 'sole_plate',
  sole_plate_or_basement_area: null,
  living_room_direction: null,
  bathrooms: 1,
  heating_system_type: null,
  car_ports: 0,
  garage_places: 0,
  personal_contribution: 'none',
  solar_power_size_kwp: null,
  equipment_type: 'standard',
  solar_power_equipment_type: null,
  avg_property_price_sq_m: null,
};

function computeTotalLivingSpace(values) {
  const computed_living_space = range(0, values.floors).reduce((space, floorIndex) => {
    const currentFloorLivingSpace = values[`living_space_by_floor_${floorIndex}`];
    const value = Number.isFinite(currentFloorLivingSpace) ? currentFloorLivingSpace : 0;
    return space + value;
  }, 0);
  return computed_living_space || values.living_space;
}

const BuildHouseInfo = ({ onSubmit, lastStep, isEditMode, onSubmitStep, currentStep, nextStep, formValuesRef }) => {
  const { formatMessage: t } = useIntl();

  return (
    <Formik
      initialValues={defaults({}, formValuesRef?.current, defaultValues)}
      validationSchema={validationSchema}
      onSubmit={async (values, formikActions) => {
        onSubmitStep(values);
        await onSubmit({ values, formikActions, currentStep, isEditMode, lastStep, nextStep });
      }}
    >
      {({ values, isSubmitting }) => (
        <Row between="lg" center="md">
          <Col md={8} lg={6}>
            <Form>
              <ScrollToError />
              <div>
                <h2 className="text-left">{t({ id: 'detailed_planning_wizard.house_information_title' })}</h2>
                <Field name="name" component={TextInput} type="text" label={t({ id: 'project_wizard.project_name' })} />
                <Field
                  name="land_area"
                  component={SliderWithTooltip}
                  min={200}
                  step={10}
                  max={3000}
                  units="m²"
                  label={t({ id: 'project_wizard.land_area' })}
                />
                <Field name="has_property" component={SwitchInput} label={t({ id: 'project_wizard.has_property' })} />
                {values.has_property ? (
                  <Field
                    name="estimated_property_price"
                    component={NumberInput}
                    type="number"
                    min={0}
                    max={2000000}
                    label={t({ id: 'project_wizard.estimated_property_price' })}
                  />
                ) : (
                  <Field
                    label={t({ id: 'project_wizard.avg_property_price_sq_m' })}
                    name="avg_property_price_sq_m"
                    units="€"
                    component={SliderWithTooltip}
                    min={5}
                    defaultValue={50}
                    step={10}
                    max={2000}
                  />
                )}
                <Field
                  name="floors"
                  component={ButtonSelect}
                  nullable={false}
                  inOneRow={false}
                  options={[
                    {
                      topIcon: ({ altText }) => <Floor1 className="stroke" title={altText} />,
                      altText: t({ id: 'alt_text.new_building.number_of_floors.one_floor' }),
                      label: t({ id: 'project_wizard.number_of_floors_1' }),
                      value: 1,
                    },
                    {
                      topIcon: ({ altText }) => <Floor2 className="stroke" title={altText} />,
                      altText: t({ id: 'alt_text.new_building.number_of_floors.two_floors' }),
                      label: t({ id: 'project_wizard.number_of_floors_2' }),
                      value: 2,
                    },
                    {
                      topIcon: ({ altText }) => <Floor3 className="stroke" title={altText} />,
                      altText: t({ id: 'alt_text.new_building.number_of_floors.three_floors' }),
                      label: t({ id: 'project_wizard.number_of_floors_3' }),
                      value: 3,
                    },
                  ]}
                  label={t({ id: `detailed_planning_wizard.floors` })}
                />
                {range(0, values.floors).map((floorIndex) => (
                  <Field
                    key={floorIndex}
                    name={`living_space_by_floor_${floorIndex}`}
                    component={SliderWithTooltip}
                    min={20}
                    step={5}
                    max={300}
                    units="m²"
                    label={t({ id: `detailed_planning_wizard.living_space_by_floor.${floorIndex}` })}
                  />
                ))}
                <p className={s.totalValue}>
                  <span>{t({ id: 'detailed_planning_wizard.total_living_space' })}</span>
                  <b>{computeTotalLivingSpace(values)} m²</b>
                </p>
                <Field
                  name="house_type"
                  component={ButtonSelect}
                  label={t({ id: 'project_wizard.house_type_title' })}
                  options={[
                    {
                      label: t({ id: 'project_wizard.prefabricated' }),
                      value: 'prefabricated',
                    },
                    {
                      label: t({ id: 'project_wizard.architect' }),
                      value: 'architect',
                    },
                    {
                      label: t({ id: 'project_wizard.builder' }),
                      value: 'builder',
                    },
                  ]}
                />
                <Field
                  name="equipment_type"
                  component={ButtonSelect}
                  moreDetails={moreInfoEquipmentType}
                  nullable={false}
                  inOneRow={false}
                  options={[
                    {
                      topIcon: ({ altText }) => <Standard className="stroke" title={altText} />,
                      altText: t({ id: 'alt_text.new_building.equipment_house_types.standard' }),
                      label: t({ id: 'equipment_types.standard' }),
                      value: 'standard',
                    },
                    {
                      topIcon: ({ altText }) => <Premium className="stroke" title={altText} />,
                      altText: t({ id: 'alt_text.new_building.equipment_house_types.premium' }),
                      label: t({ id: 'equipment_types.premium' }),
                      value: 'premium',
                    },
                    {
                      topIcon: ({ altText }) => <Superior className="stroke" title={altText} />,
                      altText: t({ id: 'alt_text.new_building.equipment_house_types.superior' }),
                      label: t({ id: 'equipment_types.superior' }),
                      value: 'superior',
                    },
                  ]}
                  label={t({ id: `detailed_planning_wizard.equipment_type` })}
                />
                <Field
                  name="terrace_area"
                  component={SliderWithTooltip}
                  min={5}
                  step={5}
                  max={200}
                  units="m²"
                  label={t({ id: 'detailed_planning_wizard.terrace_area' })}
                />
                <Field
                  name="sole_plate_or_basement"
                  component={ButtonSelect}
                  note={
                    values.sole_plate_or_basement
                      ? t({ id: `detailed_planning_wizard.${values.sole_plate_or_basement}_description` })
                      : ' '
                  }
                  options={[
                    {
                      value: 'sole_plate',
                      label: t({ id: 'detailed_planning_wizard.sole_plate' }),
                    },
                    {
                      value: 'basement',
                      label: t({ id: 'detailed_planning_wizard.basement' }),
                    },
                  ]}
                  label={t({ id: 'detailed_planning_wizard.sole_plate_or_basement' })}
                />
                <Field
                  name="living_room_direction"
                  component={ButtonSelect}
                  label={t({ id: 'detailed_planning_wizard.living_room_direction' })}
                  options={[
                    {
                      value: 'north',
                      label: t({ id: 'detailed_planning_wizard.direction.north' }),
                    },
                    {
                      value: 'south',
                      label: t({ id: 'detailed_planning_wizard.direction.south' }),
                    },
                    {
                      value: 'east',
                      label: t({ id: 'detailed_planning_wizard.direction.east' }),
                    },
                    {
                      value: 'west',
                      label: t({ id: 'detailed_planning_wizard.direction.west' }),
                    },
                  ]}
                />
                <Field
                  name="bathrooms"
                  component={Slider}
                  min={1}
                  max={6}
                  label={t({ id: `detailed_planning_wizard.bathrooms` })}
                />
                <Row>
                  <Col md={6}>
                    <Field label={t({ id: `detailed_planning_wizard.shower` })} name="shower" component={Checkbox} />
                  </Col>
                  <Col md={6}>
                    <Field label={t({ id: `detailed_planning_wizard.bathtub` })} name="bathtub" component={Checkbox} />
                  </Col>
                </Row>
                <Field
                  name="heating_system_type"
                  component={ButtonSelect}
                  inOneRow={false}
                  label={t({ id: 'detailed_planning_wizard.heating_system_type' })}
                  options={[
                    {
                      topIcon: ({ altText }) => <HeatingPump className="fill" title={altText} />,
                      value: 'heat_pump',
                      label: t({ id: 'detailed_planning_wizard.heating_system_types.heat_pump' }),
                    },
                    {
                      topIcon: ({ altText }) => <DistrictHeatingStandard className="fill" title={altText} />,
                      value: 'district_heating',
                      label: t({ id: 'detailed_planning_wizard.heating_system_types.district_heating' }),
                    },
                    {
                      topIcon: ({ altText }) => <Coal className="fill" title={altText} />,
                      value: 'solid_fuel',
                      label: t({ id: 'detailed_planning_wizard.heating_system_types.solid_fuel' }),
                    },
                    {
                      topIcon: ({ altText }) => <Electric className="fill" title={altText} />,
                      value: 'electric',
                      label: t({ id: 'detailed_planning_wizard.heating_system_types.electric' }),
                    },
                    {
                      topIcon: ({ altText }) => <NaturalGas className="fill" title={altText} />,
                      value: 'gas',
                      label: t({ id: 'detailed_planning_wizard.heating_system_types.gas' }),
                    },
                  ]}
                />
              </div>
              <div>
                <h2 className="text-left">{t({ id: 'detailed_planning_wizard.cost_drivers' })}</h2>
                <Field
                  label={t({ id: 'detailed_planning_wizard.hillside_location' })}
                  name="features.hillside_location"
                  component={Checkbox}
                />
                <Field
                  label={t({ id: 'detailed_planning_wizard.demolition_costs' })}
                  name="features.demolition_costs"
                  component={Checkbox}
                />
                {values.features?.demolition_costs ? (
                  <Field
                    label={t({ id: 'detailed_planning_wizard.demolition_area' })}
                    name="demolition_area"
                    units="m²"
                    component={SliderWithTooltip}
                    min={25}
                    defaultValue={50}
                    step={5}
                    max={300}
                  />
                ) : null}

                <h2 className="text-left">{t({ id: 'detailed_planning_wizard.personal_contribution_header' })}</h2>

                <div className="text-left">
                  <small className="muted">
                    {t({ id: `detailed_planning_wizard.personal_contribution_description` })}
                  </small>
                </div>

                <Field
                  name="personal_contribution"
                  component={ButtonSelect}
                  label={t({ id: `detailed_planning_wizard.personal_contribution` })}
                  description={t({ id: 'detailed_planning_wizard.personal_contribution_note', defaultMessage: ' ' })}
                  options={[
                    {
                      value: 'none',
                      label: t({ id: 'detailed_planning_wizard.personal_contribution_options.none' }),
                    },
                    {
                      value: 'low',
                      label: t({ id: 'detailed_planning_wizard.personal_contribution_options.low' }),
                    },
                    {
                      value: 'moderate',
                      label: t({ id: 'detailed_planning_wizard.personal_contribution_options.moderate' }),
                    },
                    {
                      value: 'high',
                      label: t({ id: 'detailed_planning_wizard.personal_contribution_options.high' }),
                    },
                  ]}
                />
              </div>

              <div className={s.buttonsWrapper}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          </Col>

          <Col md={8} lg={6} last="lg" first="xs">
            <div className="sticky-img-box">
              <Img title={t({ id: 'alt_text.project_details_page.new_building.first_step' })} />
            </div>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default BuildHouseInfo;
