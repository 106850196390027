import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { COUNT_INVITATIONS_PER_PAGE } from 'constants/constants';
import { GET_INVITATIONS_QUERY } from './useGetInvitationsByLoanConsultant';

const INVITE_USER_MUTATION = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      success
    }
  }
`;

const useInviteUser = () => {
  const [inviteUserMutation] = useMutation(INVITE_USER_MUTATION);
  const { formatMessage: t } = useIntl();

  const handleInviteUser = useCallback(
    async (variables, { setSubmitting, resetForm }) => {
      try {
        await inviteUserMutation({
          variables: { input: { ...variables } },
          refetchQueries: [
            {
              query: GET_INVITATIONS_QUERY,
              variables: { limit: COUNT_INVITATIONS_PER_PAGE, offset: 0, filter: '' },
            },
          ],
          awaitRefetchQueries: true,
        });
        resetForm();
        toast.success(t({ id: 'invite_user.successfully_sent' }));
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        toast.error(e.message);
      } finally {
        setSubmitting(false);
      }
    },
    [inviteUserMutation, t],
  );
  return handleInviteUser;
};

export default useInviteUser;
