/* eslint-disable jsx-a11y/control-has-associated-label */
import { Header } from 'components/common/heap';
import { get, isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import Loading from 'components/common/Loading';
import useGetCO2Calculations from 'hooks/co2-calculator/useCO2Calculations';
import EnergyReductionItems from './EnergyReductionItems/EnergyReductionItems';
import { EnergyScaleClasses } from './EnergyClassesAndIndicators/EnergyClassesAndIndicators';
import EnergyGhgEquivalents from './EnergyGhgEquivalents';
import EnuPartner from './EnuPartner';

const CO2StatusProjectDetailsStep = () => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const [co2Calculations, loading] = useGetCO2Calculations(projectId);
  const before = get(co2Calculations, 'co2_emissions_before.result', null);
  const after = get(co2Calculations, 'co2_emissions_after.result', null);

  const equivalentValues = useMemo(() => {
    const values = { energyGhgEquivalentPlane: 0, energyGhgEquivalentCar: 0, energyGhgEquivalentTrees: 0 };
    if (before && after) {
      values.energyGhgEquivalentPlane = before.energy_ghg_equivalent_plane - after.energy_ghg_equivalent_plane;
      values.energyGhgEquivalentCar = before.energy_ghg_equivalent_car - after.energy_ghg_equivalent_car;
      values.energyGhgEquivalentTrees = before.energy_ghg_equivalent_trees - after.energy_ghg_equivalent_trees;
    }
    return values;
  }, [before, after]);

  if (loading) return <Loading size={30} />;

  if (!before || !after || isEqual(before, after)) return null;

  return (
    <Row>
      <Col xs={12}>
        <Header>
          <h3>{t({ id: 'renovation_house_wizard.house_energy_balance.before_and_after_header' })}</h3>
        </Header>
      </Col>
      <Col xl={7}>
        <EnergyScaleClasses {...{ co2Calculations, t, costOverviewTab: true }} />
      </Col>
      <Col xl={5}>
        <EnergyReductionItems {...{ t, co2Calculations, type: 'after' }} />
      </Col>
      <Col xs={12}>
        <div className="my-4">
          <EnergyGhgEquivalents {...{ equivalentValues, costOverviewTab: true }} />
        </div>
      </Col>
      <Col xs={12}>
        <EnuPartner />
      </Col>
    </Row>
  );
};

export default CO2StatusProjectDetailsStep;
