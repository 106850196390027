import ButtonLink from 'components/common/ButtonLink';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function Switcher({ isSignUp, setIsSignUp }) {
  return isSignUp ? (
    <p style={{ marginTop: '25px' }}>
      <FormattedMessage id="auth.already_have_account" />{' '}
      <ButtonLink onClick={() => setIsSignUp(false)}>
        <FormattedMessage id="auth.already_have_account_sign_in_link" />
      </ButtonLink>
    </p>
  ) : (
    <p>
      <FormattedMessage id="auth.dont_have_account" />{' '}
      <ButtonLink onClick={() => setIsSignUp(true)}>
        <FormattedMessage id="auth.dont_have_account_sign_up_link" />
      </ButtonLink>
    </p>
  );
}
