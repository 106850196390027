/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { RadioGroup, Radio } from 'react-radio-group';
import get from 'lodash/get';
import styles from './Toggler.module.scss';

const Toggler = ({ form, options, field, label }) => {
  const { errors, touched } = form;
  const { name, value } = field;

  const handleChange = React.useCallback(
    (v) => {
      form.setFieldValue(name, v);
    },
    [name, form],
  );

  return (
    <div className="field-wrapper">
      <label className="label-when-focused-input">{label}</label>
      <RadioGroup className={styles.radioGroup} name={name} selectedValue={value} onChange={handleChange}>
        {options.map(({ label: optLabel, value: optValue }) => {
          return (
            <div key={`${name}.${optLabel}`} className={styles.radioItem}>
              <Radio type="radio" id={`${name}.${optLabel}`} name={name} value={optValue} />
              <label htmlFor={`${name}.${optLabel}`}>{optLabel}</label>
            </div>
          );
        })}
      </RadioGroup>
      {get(errors, name) && get(touched, name) && <div className="error-text">{get(errors, name)}</div>}
    </div>
  );
};

export default Toggler;
