import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Footer from '../layout/Footer';
import AuthNavbar from '../layout/AuthNavbar';
import s from './AuthLayout.module.scss';

export default function AuthLayout({ children }) {
  return (
    <Grid className={s.container}>
      <Row>
        <Col xs={12}>
          <div className="content root-container container-narrow container-fluid" style={{ paddingTop: 0 }}>
            {children}
          </div>
          <AuthNavbar />
          <Footer />
        </Col>
      </Row>
    </Grid>
  );
}
