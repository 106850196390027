import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { cloneDeep, get, pick } from 'lodash';
import { toast } from 'react-toastify';
import { whitelistedPropsCO2Calculator } from 'pages/ProjectDetailsCalculator/RenovationHouseCalculator.data';
import { formatGraphqlErrors } from 'utils/helpers';
import CO2_CALCULATIONS_FRAGMENT from 'graphql/fragments/co2-calculator/co2Calculations';
import { CO2_CALCULATIONS } from './useCO2Calculations';

const UPDATE_CO2_CALCULATOR_VALUES_MUTATION = gql`
  mutation updateCO2CalculatorValues($projectId: ID!, $formData: CO2CalculatorInput!) {
    updateCO2CalculatorValues(projectId: $projectId, formData: $formData) {
      ...co2CalculationsFragment
    }
  }
  ${CO2_CALCULATIONS_FRAGMENT}
`;

export const useUpdateCO2CalculatorValuesHandler = ({ projectId }) => {
  const updateValues = useUpdateCO2CalculatorValues();
  const handleUpdate = useCallback(
    async (values, { setSubmitting }) => {
      try {
        const newValues = cloneDeep(pick(values, whitelistedPropsCO2Calculator));
        const { energy_standard, is_solar_power_system } = newValues;
        if (energy_standard === 'passive_house' || energy_standard === 'low_energy_house') {
          newValues.renovations = [];
        }
        if (!is_solar_power_system) {
          newValues.solar_power_system_size = null;
          newValues.solar_power_system_consuming_percentage = null;
        }
        await updateValues({ projectId, formData: newValues });
        window.scrollTo(0, 0);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [projectId, updateValues],
  );
  return handleUpdate;
};

const useUpdateCO2CalculatorValues = () => {
  const [updateCO2CalculatorValuesMutation, { client }] = useMutation(UPDATE_CO2_CALCULATOR_VALUES_MUTATION);

  const co2Calculator = useCallback(
    async (variables) => {
      try {
        const result = await updateCO2CalculatorValuesMutation({ variables });
        const newCO2Calculations = get(result, 'data.updateCO2CalculatorValues', null);
        client.writeQuery({
          query: CO2_CALCULATIONS,
          variables: { projectId: variables.projectId },
          data: { co2Calculations: { ...newCO2Calculations } },
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [client, updateCO2CalculatorValuesMutation],
  );
  return co2Calculator;
};

export default useUpdateCO2CalculatorValues;
