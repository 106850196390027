import PageTitle from 'components/common/PageTitle';
import { ProjectCard } from 'pages/QuickCalculator/QuickCalculator.components';
import React, { useCallback } from 'react';
import { Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { expressLoanProjectTypes } from './loan-project-types';

const ExpressLoanQuickStart = () => {
  const { formatMessage: t } = useIntl();
  const history = useHistory();

  const handleSelect = useCallback(
    async (type) => {
      history.push({ pathname: routePaths.expressLoan, search: `?type=${type}` });
    },
    [history],
  );

  return (
    <>
      <PageTitle auth className="text-center">
        {t({ id: 'page_titles.express_loan' })}
      </PageTitle>

      <section>
        <Row bottom="xs">
          {expressLoanProjectTypes.map(({ type, ...rest }) => (
            <ProjectCard key={type} {...{ type, handleSelect, t, isSubmitting: false, xlSize: 3, ...rest }} />
          ))}
        </Row>
      </section>
    </>
  );
};

export default ExpressLoanQuickStart;
