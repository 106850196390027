/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { ME_QUERY } from 'hooks/user/useUser';
import first from 'lodash/first';
import RefreshTokenService from 'services/RefreshTokenService';
import { formatGraphqlErrors } from 'utils/helpers';
import { GET_PROJECTS_QUERY } from '../project/useProjects';

const useAuth = (mutation) => {
  const [authMutation, { loading }] = useMutation(mutation, { refetchQueries: [{ query: GET_PROJECTS_QUERY }] });
  const client = useApolloClient();
  const auth = React.useCallback(
    async (variables) => {
      try {
        const res = await authMutation({ variables });
        const resultData = first(Object.values(res.data));
        const { refreshToken, user } = resultData;

        if (refreshToken) {
          RefreshTokenService.setRefreshToken(refreshToken);
        }

        client.writeQuery({ query: ME_QUERY, data: { me: user } });

        return resultData;
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [authMutation, client],
  );

  return [auth, loading];
};

export default useAuth;
