/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'formik';
import TextInput from './TextInput';
import TextArea from './TextArea';
import NumberInput from './NumberInput';
import Select from './Select';
import InlineSelect from './InlineSelect';
import SwitchInput from './Switch';
import Heading from './Heading';
import DatePicker from './DatePicker';
import Slider from './Slider';
import Counter from './Counter';
import ButtonSelect from './ButtonSelect';
import Checkbox from './Checkbox';
import BirthdayInputs from './BirthdayInputs/BirthdayInputs';
import SliderWithTooltip from './SliderWithTooltip';

const MorphField = ({ style, ...field }) => {
  let customComponent;
  let styles = { ...style };
  switch (field.type) {
    case 'text':
      customComponent = TextInput;
      break;
    case 'textarea':
      customComponent = TextArea;
      break;
    case 'number':
      customComponent = NumberInput;
      break;
    case 'money':
      customComponent = NumberInput;
      break;
    case 'slider':
      customComponent = Slider;
      break;
    case 'counter':
      customComponent = Counter;
      break;
    case 'select':
      customComponent = Select;
      break;
    case 'inline_select':
      customComponent = InlineSelect;
      break;
    case 'switch':
      customComponent = SwitchInput;
      break;
    case 'select_modal':
      customComponent = Select;
      break;
    case 'button_select':
      customComponent = ButtonSelect;
      break;
    case 'checkbox':
      customComponent = Checkbox;
      break;
    case 'hidden':
      customComponent = NumberInput;
      styles = { ...style, display: 'none' };
      break;
    case 'slider_with_tooltip':
      customComponent = SliderWithTooltip;
      break;
    case 'birthday_inputs':
      customComponent = BirthdayInputs;
      break;
    case 'heading':
      customComponent = Heading;
      break;
    case 'date':
      customComponent = DatePicker;
      break;
    default:
      return null;
  }
  return <Field name={field.name} style={styles} component={customComponent} {...field} />;
};

export default MorphField;
