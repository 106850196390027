import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';

export const PROJECT_DASHBOARD_QUERY = gql`
  query projectDashboard($id: ID!) {
    project(id: $id) {
      _id
      name
      price
      budget
      financing_rate
      loan_amount
      form_values {
        ... on NewApartmentFormValues {
          cost_apartment
        }
      }
      pdf
      type
      calculations {
        ... on NewApartmentCalculations {
          sprengnetter(projectId: $id) {
            market_value
            error
          }
        }
      }
      calculations {
        ... on BuyingHouseCalculations {
          sprengnetter(projectId: $id) {
            market_value
            error
          }
        }
      }
      progress {
        project_details
        plan_adjusted
        budget_planning
        budget_planning_adjusted
        loan_viewed
        loan_calculator
        loan_request
        step
      }
      loan {
        monthly_rate
        amount
      }
    }
  }
`;

const useProjectDashboardQuery = (projectId = null) => {
  const { loading, data } = useQuery(PROJECT_DASHBOARD_QUERY, {
    variables: { id: projectId },
    fetchPolicy: 'cache-and-network',
  });

  const project = get(data, 'project', null);

  return [project, loading];
};

export default useProjectDashboardQuery;
