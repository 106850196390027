export default {
  yes: 'Ja',
  no: 'Nein',
  cookie_declaration: {
    title: 'Cookie Hinweis',
  },
  quick_calc_result: {
    costs: 'GESCHÄTZTE PROJEKTKOSTEN',
    monthly_rate: 'MONATLICHE KREDITRATE {value}',
    monthly_rate_description: 'Unter Berücksichtigung Ihrer Eigenmittel von {budget}',
    personal_space: 'PERSÖNLICHER BEREICH',
    calculated_price_message:
      'Bezugsfertig (exkl. Aussenanlagen, Terrasse, Einrichtung etc.) und noch ohne Berücksichtigung von Eigenleistungen',
    personal_space_description:
      '<p>Registrieren Sie sich und profitieren Sie von unseren kostenfreien Services:</p><ul><li>Detailplanung von mehr als 30 Detailkategorien!</li><li>Berechnung der leistbaren Rate (Haushaltsüberschuß)</li><li>Kostenloser PDF-Download aller Zusammenfassungen</li><li>Echtzeit-Ermittlung einer voraussichtlichen Zinsindikation (variabler oder fixer Zinssatz)</li><li>Anforderung eines Kreditangebots mit persönlicher Beratung</li></ul>',
    personal_area: 'Persönlichen Bereich',
    advantages: 'gratis und unverbindlich nutzen',
    detailed_planning: 'Detailplanung von mehr als 30 Datenkategorien!',
    calculation_of_budget: 'Berechnung des Haushaltsüberschuß (Leistbare Rate)',
    free_pdf_download: 'Kostenloser PDF-Download aller Zusammenfassungen    ',
    probable_interest_rate_indication:
      'Echtzeit-Ermittlung einer voraussichtlichen Zinsindikation (variabler oder fixer Zinssatz)',
    loan_offer: 'Anforderung eines Kreditangebots mit persönlicher Beratung ',
    google_street_view: 'Umgebungscheck mit Google Street View',
    real_estate_valuation: 'Immobilienbewertung inklusive Marktwertvergleich',
    registration: 'Österreichs innovativster Wohnrechner',
    register_now: 'Jetzt registrieren und persönlichen Bereich gratis und unverbindlich nutzen',
    result: 'Ergebnis',
  },
  express_loan: {
    project_types: {
      loan_renovation: 'Sanierung',
      loan_refinance: 'Umschulden',
      loan_build: 'Bau',
      loan_buying: 'Kauf',
    },
    steps: {
      first_step: 'Basisdaten',
      second_step: 'Übersicht',
      third_step: 'Angebot/Beratung',
    },
    final_modal: {
      header_title: 'Gratulation!',
      title: 'Wohnkredit von Zuhause aus vorbereiten',
      message:
        'Vielen Dank für Ihre Nachricht! \n Ein:e Wohnbauexpert:in wird sich \n demnächst mit Ihnen in Verbindung setzen!',
      backToHomeButton: 'Zurück zur Startseite',
    },
    form: {
      renovation_costs: 'Renovierungskosten',
      has_property: 'Besitzen Sie schon einen Baugrund?',
      estimated_property_price: 'Geschätzter Grundstückswert',
      estimated_purchase_property_price: 'Geschätzter Kaufpreis',
      purchase_price: 'Kaufpreis',
      construction_costs: 'Baukosten',
      estate_agent: 'Makler',
      monthly_net_income_borrowers: 'Monatliches Nettoeinkommen aller Kreditnehmer',
      own_funds: 'Vorhandene Eigenmittel (Sparguthaben, Wertpapiere, etc.)',
      period: 'Kreditlaufzeit in Jahren',
      interest_type: 'Zinskonditionen',
      outstanding_loan_amount: 'Saldo bestehender Kredit',
      penalty_payment_interest: 'Pönalezahlung',
      interest_types: {
        variable: 'Variabel',
        fixed: 'Fix',
      },
      fixed_interest_period: 'Fixzinsperiode',
      errors: {
        calculations_not_found: 'Berechnungsgrundlagen nicht gefunden',
      },
    },
    table: {
      total_project_costs: 'Gesamtkosten',
      property_transfer_tax: 'Grunderwerbsteuer',
      land_register_fee: 'Grundbucheintragungsgebühr',
      broker_commission: 'Maklergebühr',
      notary_fee: 'Vertragserrichtungsgebühr',
      own_funds: 'Eigenmittel',
      estimated_property_price: '*) Zusätzliche Eigenmittel in Form eines vorhandenen Baugrunds in der Höhe von',
      loan_amount: 'Kreditbetrag',
      execution_fee: 'Ausfertigungsgebühr',
      registration_fee: 'Eintragungsgebühr',
      loan_period: 'Gesamtlaufzeit',
      loan_period_years: '{years} Jahre',
      expected_fix_loan_rate: 'Voraussichtlicher Zinssatz {years} Jahre fix',
      expected_variable_loan_rate: 'Voraussichtlicher Zinssatz',
      expected_variable_loan_rate_value: '{interest}',
      total_loan_amount: 'Gesamtbetrag gem. §2 Abs. 10 HikriG',
      monthly_credit_rate: 'Monatliche Kreditrate',
      additional_costs: 'Nebenkosten',
      penalty_old_loan_payment: 'Pönalezahlung aus altem Kreditvertrag',
    },
    advisory_request_button: 'Jetzt anfragen',
    individual_offer_button: 'Noch schneller zum Finanzierungsangebot',
    advisory_request_help_text: 'Ein:e Wohnbauexpert:in setzt sich umgehend mit Ihnen in Verbindung',
    individual_offer_help_text:
      'Mit wenigen Angaben, sind unsere Berater:innen noch schneller in der Erstellung Ihres individuellen Angebots.',
    personal_offer_form: {
      alone_or_partner: 'Nehmen Sie den Kredit alleine auf, oder mit jemanden zweiten gemeinsam auf?',
      existing_loan_rate: 'Wie hoch ist die aktuelle monatliche Rate?',
      alone: 'Alleine',
      partner: 'Zu zweit',
      kids_quantity: 'Wie viele Kinder leben im Haushalt?',
      cars: 'Wie viele Autos haben Sie?',
      loan_exists: 'Haben Sie einen bestehenden Kredit oder Leasingvertrag?',
      full_address: 'Vollständige Adresse (Straße, Hausnummer, Ort)',
      zip: 'Postleitzahl',
      property_type: 'Um welche Immobilie handelt es sich?',
      property_types: {
        apartment: 'Wohnung',
        property: 'Grundstück',
        family_house: 'Ein- Zweifamilienhaus',
      },
      land_area: 'Grundstücksfläche (m²)',
      living_area: 'Wohnnutzfläche (m²)',
      construction_year: 'Baujahr des Hauses?',
      parking: 'Parkmöglichkeit',
      parking_type: {
        outdoor_parking_space: 'Außenstellplatz',
        garage: 'Garage',
        underground_car_park: 'Tiefgarage',
      },
    },
  },
  project_types: {
    new_building: 'Neubau Einfamilienhaus',
    new_apartment: 'Kauf Eigentumswohnung',
    buying_house: 'Kauf Einfamilienhaus (inkl. Sanierung)',
    renovation_house: 'Sanierung Einfamilienhaus (inkl. CO₂-Bilanz)',
    property_valuation: 'Immobilienbewertung',
    lifestyle_calculator: 'Lifestyle Kalkulator - Was kann ich mir leisten?',
  },
  project_details: {
    tabs: {
      house_info: 'Objektdaten',
      apartment_info: 'Apartment info',
      co2_status: 'CO₂ Status',
      common: 'Allgemein',
      energy: 'CO₂-Optimierung',
      indoor: 'Innenbereich',
      outdoor: 'Außenbereich',
      other: 'Sonstiges',
      cost_overview: 'Kostenübersicht',
      sprengnetter_estimation: 'Immobilienbewertung',
    },
  },
  sprengnetter_estimation: {
    sprengnetter: {
      title: 'Immobilienbewertung',
      not_available_for_guest:
        'Die Immobilienpreisbewertung steht nur registrierten Benutzern zur Verfügung. Sie können diese überspringen, oder sich hier gleich unverbindlich registrieren.',
    },
    sprengnetter_land: {
      title: 'Grundstücksbewertung',
      not_available_for_guest:
        'Die Immobilienpreisbewertung steht nur registrierten Benutzern zur Verfügung. Sie können diese überspringen, oder sich hier gleich unverbindlich registrieren.',
    },
    modal_header_text: 'Immobilienbewertung',
    reminder:
      'Möchten Sie wirklich weitermachen ohne Immobilienpreisbewertung? Für die Bewertung klicken Sie bitten den "{buttonTitle}" Knopf.',
    tooltip_label: 'Bewertung Ihrer Immobilie durch unseren Partner Sprengnetter',
    tooltip_description: 'Im Hintergrund führen wir auf Basis Ihrer Daten ein grobe Bewertung der Immobilie durch.',
    estimated_range: 'Der geschätzte Wert dieser Wohnung liegt (ohne Nebenkosten) zwischen',
    ran_out_of_requests: 'Alle {requestsCount} Bewertungsabfragen wurden verwendet',

    reminder_for_guest:
      'Möchten sie wirklich ohne Immobilienbewertung fortfahren - Sie können dieses Service hier {link_label} nutzen!',
    reminder_for_guest_link_label: 'Hier registrieren',
    complete_sprengnetter_form_btn: 'Bitte die Eingaben vervollständigen!',
    estimate_property_btn: 'Neue Bewertung durchführen',
  },
  auth: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    password: 'Passwort',
    temp_password: 'Ihr Startpasswort (bitte ändern)',
    guest_sign_up: 'Als Gast fortfahren',
    new_password: 'Neues Passwort',
    repeat_new_password: 'Passwort wiederholen',
    sign_up_submit: 'Registrieren',
    sign_in_submit: 'Anmelden',
    already_have_account: 'Sie haben bereits ein Konto?',
    already_have_account_sign_in_link: 'Anmelden',
    dont_have_account: 'Sie haben noch keinen Benutzer angelegt?',
    dont_have_account_sign_up_link: 'Registrieren',
    login_by_invitation_page_title: 'Anmelden & Login',
    login_page_title: 'Anmelden',
    login_page_text:
      '<p>Wenn Sie sich bereits registriert haben, können Sie sich hier anmelden und mit dem gespeicherten Letztstand bei folgenden Bausteinen weitermachen: </p><ul><li>Detailplanung von mehr als 30 Detailkategorien!</li><li>Berechnung der leistbaren Rate (Haushaltsüberschuß)</li><li>Kostenloser PDF-Download aller Zusammenfassungen</li><li>Echtzeit-Ermittlung einer voraussichtlichen Zinsindikation (variabler oder fixer Zinssatz)</li><li>Anforderung eines Kreditangebots mit persönlicher Beratung</li></ul>',
    forgot_password: {
      note: 'Haben Sie ihr Passwort vergessen?',
      message:
        'Wenn Sie mit dieser Mailadresse registriert sind, erhalten Sie in Kürze eine Mail mit einer Beschreibung für das Rücksetzen des Passworts.',
      back_to_login: 'Zurück zum Login',
      reset_password: 'Passwort rücksetzen',
      password_changed_successfully: 'Passwort wurde erfolgreich geändert',
    },
    repeat_password: 'Passwort erneut eingeben',
    verification_code_tip:
      'Über die Google Authenticator App erhalten Sie einen 6-stelligen Code, den Sie hier eingeben müssen.',
    terms_of_use: 'Ich akzeptiere die {link}',
    terms_of_use_link_label: 'Nutzungsbedingungen',
    terms_of_use_text: 'Bitte lesen und akzeptieren Sie vorab die Nutzungsbedingungen',
    terms_of_use_required: 'Bitte den Nutzungsbedingungen zustimmen!',
    privacy_policy:
      'Hier finden Sie Informationen zur {data_processing_label} Ihrer personenbezogenen Daten im wohnrechner.at und weitere Informationen zum {data_protection_label} in der HYPO NOE',
    data_processing_label: 'Verarbeitung',
    data_protection_label: 'Datenschutz',
    modal_header: 'Nutzungsbedingungen',
  },
  app: {
    back: 'Zurück',
    save: 'Sichern',
    send: 'Abschicken',
    yes: 'Ja',
    no: 'Nein',
    continue: 'Fortsetzen',
    start: 'Start',
    none: 'Keiner',
    submit: 'Senden',
    cancel: 'Abbrechen',
    proceed: 'Weiter',
    skip: 'Überspringen',
    submit_form_final: 'Berechnen',
    submit_form_next: 'Weiter',
    create_a_project: 'Bitte zunächst ein Projekt anlegen',
    data_successfully_updated: 'Daten wurden erfolgreich gespeichert',
    project_not_found: 'Wählen Sie ein Projekt oder legen Sie ein neues an',
    data_saved_successfully: 'Daten wurden erfolgreich gesichert',
  },
  errors: {
    project_not_found: 'Projekt nicht gefunden',
    full_address: 'Bitte eine der vorgeschlagenen Adressen auswählen',
    fill_field: 'Bitte dieses Feld ausfüllen!',
    choose_one: 'Bitte Auswahl treffen',
    no_server_response:
      'Verbindung zum Server derzeit nicht möglich, bitte Internetverbindung überprüfen bzw. später nochmals versuchen',
    not_found: 'Projekt nicht gefunden!',
    wrong_zip_code: 'Bitte korrekte Postleitzahl eingeben!',
    email_invalid: 'Email-Adresse ist ungültig!',
    email_required: 'Email-Adresse ist erforderlich!',
    password_required: 'Passwort ist erforderlich!',
    password_too_small: 'Passwort muss mindestens 8 Zeichen haben!',
    passwords_must_match: 'Passwörter müssen übereinstimmen',
    first_name_required: 'Bitte den Vornamen eingeben!',
    last_name_required: 'Bitte den Nachnamen eingeben!',
    values_within_range: 'Nur Werte innerhalb des zulässigen Bereichs eingeben: {min} - {max}',
    fill_all_fields: 'Bitte alle Felder ausfüllen',
    min_value: 'Der Minimalwert beträgt - {min}',
    max_value: 'Höchster Wert - {max}',
    invalid_date: 'Ungültiges Datum',
  },

  contact: {
    note: 'Wie können wir ihnen helfen?',
    successfully_sent: 'Ihrer Nachricht wurde übermittelt!',
  },

  profile: {
    first_name: 'Vorname',
    last_name: 'Nachname',
    email: 'Email',
    delete_my_account: {
      title: 'Alle Daten löschen',
      message: 'Sind Sie sicher, dass Sie alle Daten und den Benutzer löschen möchten?',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
  },
  verify_account: {
    title: 'Fast geschafft...',
    message:
      'Gehen Sie bitte in ihre Mailbox und klicken Sie auf den Aktivierungslink, um ihre Mailadresse zu bestätigen!',
    resend_link: 'Bestätigungslink neu versenden',
    logout: 'Abmelden',
    email_sent: 'Email wurde versendet..',
    email_verified: 'Email-Adresse ist bestätigt!',
    email_confirmed: 'Ich habe meine Mail bereits bestätigt',
    message_email_not_verified:
      'Die Email ist noch nicht bestätigt! Bitte überprüfen Sie ihre Mailbox und bestätigen Sie den Aktivierungslink',
    contact_support: 'Falls Sie das Mail nicht empfangen, prüfen Sie bitte in einigen Minuten Ihren Spam-Ordner!',
  },

  page_not_found: {
    title: 'Entschuldigung, die Seite wurde nicht gefunden',
    back_to_dashboard: 'Zurück zur Projektübersicht',
    back_to_quick_calculator: 'Zurück zum Quick-Kalkulator',
  },

  my_projects: {
    name: 'Projekt',
    budget: 'Eigenmittel',
    price: 'Kosten',
    adjust: 'Projekt bearbeiten',
    pdf_download: 'Projekt download',
    new_project: 'Neues Projekt',
    export: 'Export',
    delete_project: {
      title: 'Projekt löschen',
      message: 'Sind Sie sicher, dass das Projekt gelöscht werden soll?',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
    tooltip: 'Das letzte Projekt kann nicht gelöscht werden!',
    tooltip_max_count_of_projects: 'Pro User können nur 10 Projekte angelegt werden!',
    no_projects: 'Aktuell haben Sie keine Projekte. Bitte zunächst ein Projekt anlegen',
    transfer_project: 'Projekt "{projectName}" zu Kunden übertragen',
    transfer_project_button: 'Projekt übertragen',
    successfully_transferred: 'Das Projekt wurde erfolgreich zum Kunden übertragen',
    message_for_consultant:
      'Hier können Sie das Projekt an Ihren Kunden übertragen. Die letzte Haushaltsrechnung die Sie abgespeichert haben, wird gemeinsam mit dem Projekt ebenfalls gemeinsam mit dem Projekt übertragen. Nach dem Transfer zum Kunden haben Sie keinen Zugriff mehr auf die übertragenen Daten, diesen hat nur noch Ihr Kunde. Das übertragene Projekt verschwindet aus Ihrem Account, die Haushaltsrechnung wird kopiert, d.h. der Letztstand verbleibt in Ihrem Account und muss für den nächsten Kunden angepasst werden.',
  },

  property_valuation: {
    planned_project_types: {
      label: 'Was möchten Sie bewerten?',
      new_apartment: 'Wohnung',
      new_apartment_description: 'Hier können Sie den Wert einer Eigentumswohnung ermitteln',
      buying_house: 'Haus',
      buying_house_description: 'Hier können Sie den Wert eines Einfamilienhauses ermitteln',
      new_building: 'Hausbau',
      new_building_description: 'Hier kann der Wert des Grundstücks ermittelt werden',
      land_estimation: 'Baugrund',
      land_estimation_description: 'Hier können Sie den Wert eines als Baugrund gewidmeten Grundstücks ermitteln',
    },
    planning_project_type_label: 'Mit welchem Projekt möchten Sie die Planung beginnen?',
    start_project_planning: 'Starten Sie die Planung auf dieser Seite',
    plan_project_btn: 'Projekt planen',
  },

  renovation_house_wizard: {
    complete_previous_step: 'Sie müssen zunächst das erste Projekt berechnen bevor Sie Zugang zum Dashboard haben!',
    complete_previous_step_btn: 'Projektdetails berrechnen',
    wrong_min_house_area: `Die Fläche des Hauses kann nicht kleiner als die beheizte Wohnfläche sein - {value} m²`,
    number_of_people_aria_labels: {
      one_person: 'Eine Person',
      two_people: 'Zwei Personen',
      three_people: 'Drei Personen',
      four_people: 'Vier Personen',
      five_people: 'Fünf Personen',
      six_people: 'Sechs Personen',
    },
    house_energy_balance: {
      header: 'Aktuelle Energiebilanz Ihres Hauses',
      before_and_after_header: 'Einsparungspotential durch die Sanierungsmaßnahmen',
      label: 'Energiekennzahlen ihres Hauses ohne Sanierungsmaßnahmen',
      before: 'VOR  SANIERUNG',
      after: 'NACH SANIERUNG',
      specific_heating_demand: '{value} kWh/m²a',
      your_house: 'Ihr Haus',
      heating: 'Heizung {value} kg CO₂/a',
      hot_water: 'Warmwasser {value} kg CO₂/a',
      electricity: 'Strom {value} kg CO₂/a',
    },
    heated_living_area_disclaimer:
      'Hinweis: Die beheizte Wohnfläche kann nicht größer als die Wohnfläche sein, diese kann im Schritt 3 erhöht werden.',
    go_to_step_three_btn: ' weiter zu Schritt 3',
    potential_energy_balance: {
      title: 'Ihr CO₂ Einsparungspotential',
      description: 'Ihre Werte vollumfänglicher Sanierung und Einbau einer Photovoltaikanlage (5kwP)',
      redevelopment_house_label: 'Möchten Sie ihr individuelles Energieeinsparungspotential berechnen?',
      ghg_balance: {
        label: 'CO₂ Einsparungspotential',
        value: '{value} kg p.a.',
      },
      total_energy_costs: {
        label: 'Energiekosten Einsparungspotential',
        value: '{value} € p.a.',
      },
      total_energy_balance_per_year: {
        label: 'Energieverbrauch Einsparungspotential',
        value: '{value} kwh/a',
      },
    },
    energy_ghg_equivalents: {
      label: 'Die aktuelle CO₂ Bilanz Ihres Hauses',
      reduction: 'Wie viel kann mit Ihren Renovierungen eingespart werden',
      equivalent_car: '{value} Autokilometer',
      equivalent_plane: '{value} Flüge Wien - New York',
      equivalent_trees: '{value} Bäume',
    },
    enu_partner: {
      description: 'Holen Sie sich die Unterstützung von den Spezialist*innen der Energieberatung NÖ',
      request_btn: 'Beratung anfordern',
    },
    floors: 'Anzahl der Etagen (ohne Keller und Dachboden)',
    update_btn_label: 'Angaben zum Haus ändern',
    should_show_all_calculations: 'Möchten Sie alle Berechnungsdetails sehen?',
    improve_or_renovate_house: 'Möchten Sie ihr Haus sanieren oder verbessern?',
    improve_house_btn_label: 'Haus optimieren',
    show_calculations: 'Berechnungsdetails anzeigen',
    hide_calculations: 'Berechnungsdetails ausblenden',
    edit_co2_calculator_values: 'Werte editieren',
    second_step_title: 'Haus renovieren & klimafit machen',
    heated_living_area: 'Beheizte Wohnfläche',
    kind_of_house: {
      name: 'Um welchen Haustyp handelt es sich?',
      single_family_house: 'Standard Einfamilienhaus',
      bungalow_or_complex_floor_plan: 'Bungalow oder komplexer Grundriss',
      town_house: 'Reihenhaus',
      semi_detached_house: 'Doppelhaushälfte',
    },
    energy_standard: {
      name: 'Energiestandard des Hauses',
      description: 'ab 2006 (Niedrigenergie)',
      before_1960: 'Vor 1960',
      between_1960_and_1975: '1960-1975',
      between_1976_and_1990: '1976-1990',
      after_1990: '1990-2005',
      low_energy_house: 'ab 2006',
      passive_house: 'Passivhaus',
    },
    renovations: {
      name: 'Wurden bereits Renovierungen durchgeführt?',
      new_windows: 'Neue Fenster',
      insulation_top_ceiling: 'Dämmung oberste Geschossdecke',
      insulation_basement_ceiling: 'Dämmung Kellerdecke (m²)',
      insulation_facade: 'Fassadendämmung',
      controlled_living_space_ventilation: 'Kontrollierte Wohnraumlüftung',
    },
    number_of_people: 'Anzahl Personen die im Haus leben',
    tell_about_your_heating: 'Nähere Informationen über Ihre Heizung',
    more_info_about_hot_water: 'Nähere Informationen zum Warmwasser',
    more_info_about_electricity: 'Nähere Informationen zum Stromverbrauch',
    heating_system: {
      name: 'Heizsystem',
      natural_gas: 'Erdgas',
      district_heating_biomass: 'Fernwärme Biomasse',
      district_heating_standard: 'Fernwärme Standard',
      wood_chip: 'Hackschnitzel',
      wood_pellets: 'Holz Pellets',
      firewood: 'Stückholz',
      coal: 'Kohle',
      heating_oil: 'Heizöl',
      electric: 'Stromheizung',
      heat_pump: 'Wärmepumpe',
    },
    is_solar_heating_system: 'Es existiert bereits eine Solaranlage',
    solar_heating_system_type: {
      name: 'Welche Art von Solarthermie haben Sie',
      heating_and_hot_water: 'Für Heizung & Warmwasser',
      only_hot_water: 'Nur Warmwasser',
    },
    age_of_heating_system: {
      name: 'Alter der Heizung',
      description: 'Neue Technologie bedeutet, dass die Heizung jünger als 10 Jahre)',
      less_than_10_years: 'Neue Technologie',
      between_10_and_20_years: 'Zwischen 10 und 20 Jahre',
      more_than_20_years: 'Älter als 20 Jahre',
    },
    temperature: {
      name: 'Wie hoch ist die Raumtemperatur?',
      description: 'jedes Grad mehr erhöht Energiebedarf um 6%',
      less_than_21: 'Weniger als 21 Grad',
      between_21_and_23: 'Zwischen 21 and 23 Grad',
      more_than_23: 'Über 23 Grad',
    },
    hot_water_producer: {
      name: 'Wie wird das Warmwasser produziert?',
      heating_system: 'Mit dem Heizungssystem ',
      electric_boiler: 'Elektrischer Boiler ',
      gas_heater: 'Mit Gas',
      heat_pump: 'Wärmepumpe',
    },
    amount_of_hot_water: {
      name: 'Angaben zum Warmwasserverbrauch',
      description: 'Niedrig (nur duschen) \n Mittel (50l/Tag duschen & baden) \n Hoch (vorwiegend Vollbäder)',
      low: 'Niedrig',
      medium: 'Mittel',
      hight: 'Hoch',
    },
    is_devices_younger_than_10_years:
      'Sind die meisten Ihrer Geräte wie Herd, Kühl- und Gefriergeräte, Waschmaschine und Trockner jünger als 10 Jahre? ',
    power_consumers: {
      name: 'Welche außerordentlichen Stromverbraucher haben Sie?',
      sauna: 'Sauna',
      outdoor_whirlpool: 'Outdoor whirlpool',
      swimming_pool: 'Pool (beheizt)',
      air_condition: 'Klimaanlage',
      waterbed: 'Wasserbett',
    },
    is_solar_power_system: 'Es existiert bereits eine Photovoltaikanlage',
    solar_power_system_size: 'Wie groß ist die Anlage (kWp)?',
    solar_power_system_consuming_percentage: 'Wie hoch ist der Eigenverbrauch ca. in %',
    number_of_economy_class_flights: ' ',
    number_stored_trees: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
    heating_result: {
      table_title: 'Ergebnisse Heizung',
      specific_heating_demand: 'Spezifischer Heizwärmebedarf',
      specific_heating_demand_description:
        'Beschreibt die erforderliche Wärmemenge pro Quadratmeter beheizter Bruttogeschossfläche',
      heating_factor_kea: 'Heizungsfaktor KEA',
      heating_factor_kea_description:
        'Der KEA beinhaltet alle energetischen Aufwendungen für Herstellung, Transport und Verbrauch des Heizmittels.',
      heating_factor_ghg: 'Heizungsfaktor CO₂ (GHG)',
      heating_factor_ghg_description:
        'Gibt an wie viel kg CO₂ je kWh Heizwärme mit dem gewählten Heizmittel produziert werden. ',
      total_heating_demand: 'Heizwärmebedarf',
      total_heating_demand_description:
        'Thermische Energie die notwendig ist, um das Haus auf die gewünschte Temperatur zu heizen',
      primary_energy_for_heating: 'Primärenergiebedarf für das Heizen',
      primary_energy_for_heating_description:
        'Dies ist die Energiemenge in (kWh) die Sie benötigen, um das Haus zu Heizen also den Heizwärmebedarf zu decken. Ein sehr starker Einflussfaktor ist hierbei natürlich auch der Wirkungsgrad des Heizsystems sowie die Menge an Energie die zur Herstellung des Heizmittels benötigt wird.',
      heating_ghg_emission: 'CO₂ Emission Heizung',
      heating_ghg_emission_description: 'Gesamtmenge an CO₂ die durch Ihre Heizung erzeugt wird',
      heating_ghg_equivalent_car: 'Dies entspricht Kilometer mit einem Benzin-PKW',
      heating_ghg_equivalent_car_description: 'Grundlage ist eine CO₂-Menge von 150g/km',
      heating_ghg_equivalent_plane: 'Anzahl an Flugreisen die dieser CO₂-Menge entspricht  ',
      heating_ghg_equivalent_plane_description: 'Wien - New York - Wien Flüge (Economy-Class)',
      heating_ghg_equivalent_trees: 'Anzahl an Bäumen die diese CO₂-Menge speichern',
      heating_ghg_equivalent_trees_description: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
      heating_costs: 'Jährliche Heizkosten',
      heating_costs_description: 'basierend auf Durchschnittspreisen in Österreich',
    },
    hot_water_result: {
      table_title: 'Heisswasser',
      primary_energy_for_hot_water: 'Energiebedarf für Warmwasser',
      primary_energy_for_hot_water_description:
        'Dies ist die Energiemenge in (kWh) die Sie benötigen, um das Warmwasser in Ihrem Haus zu produzieren',
      hot_water_ghg_emission: 'CO₂ Emission Warmwasser',
      hot_water_ghg_emission_description: 'Gesamtmenge an CO₂ das durch die Warmwasserproduktion entsteht',
      hot_water_ghg_equivalent_car: 'Dies entspricht Kilometer mit einem Benzin-PKW',
      hot_water_ghg_equivalent_car_description: 'Grundlage ist eine CO₂-Menge von 150g/km',
      hot_water_ghg_equivalent_plane: 'Anzahl an Flugreisen die dieser CO₂-Menge entspricht  ',
      hot_water_ghg_equivalent_plane_description: 'Wien - New York - Wien Flüge (Economy-Class)',
      hot_water_ghg_equivalent_trees: 'Anzahl an Bäumen die diese CO₂-Menge speichern',
      hot_water_ghg_equivalent_trees_description: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
      hot_water_costs: 'Jährliche Kosten für Warmwasser',
      hot_water_costs_description: 'basierend auf Durchschnittspreisen in Österreich',
    },
    electricity_result: {
      table_title: 'Ergebnisse Stromverbrauch',
      total_power_consumption_per_year: 'Jährlicher Stromverbrauch',
      total_power_consumption_per_year_description: 'Gesamtverbrauch für Ihren Haushalt',
      total_power_production_per_year: 'Jährliche Stromerzeugung durch PV-Anlage',
      total_power_production_per_year_description:
        'Strommenge die durch Ihre PV-Anlage durchschnittlich produziert wird ',
      own_consumption: 'Jährlicher Eigenverbrauch',
      own_consumption_description: 'Geschätzter Eigenverbrauch des PV-Stroms',
      power_consumption_from_energy: 'Menge an Strom der vom Netzbetreiber zugekauft wird',
      power_consumption_from_energy_description:
        'Dieser Wert kann auch negativ sein, wenn mehr PV-Strom produziert, als Strom verbraucht wird',
      electrical_feed_in_to_grid: 'Menge an Strom die ins Netz eingespeist wird',
      electrical_feed_in_to_grid_description: 'Überschussstrom der verkauft werden kann',
      ghg_savings_solar_power_system: 'CO₂-Einsparung durch PV-Anlage',
      ghg_savings_solar_power_system_description:
        'Grundlage ist hier die CO₂ Menge die durchschnittlich bei der Stromerzeugung in Europa entsteht',
      ghg_balance_of_power_consumption: 'CO₂-Bilanz Ihres Stromverbrauchs',
      ghg_balance_of_power_consumption_description:
        'Diese Menge an CO₂ entsteht durch Ihren Stromverbrauch bei der Stromerzeugung in Österreich ',
      electricity_ghg_equivalent_car: 'Dies entspricht Kilometer mit einem Benzin-PKW:',
      electricity_ghg_equivalent_car_description: 'Grundlage ist eine CO₂-Menge von 150g/km',
      electricity_ghg_equivalent_plane: 'Anzahl an Flugreisen die dieser CO₂-Menge entspricht',
      electricity_ghg_equivalent_plane_description: 'Wien - New York - Wien Flüge (Economy-Class)',
      electricity_ghg_equivalent_trees: 'Anzahl an Bäumen die diese CO₂-Menge speichern',
      electricity_ghg_equivalent_trees_description: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
      solar_power_system_helps_to_save: 'Ihre PV-Anlage spart Ihnen jährlich',
      solar_power_system_helps_to_save_description:
        'Basierend auf der Ökostromtarifförderung ist der Fördertarif 2021 7,06 Cent/kWh',
      total_electricity_costs: 'Gesamtkosten für Strom',
      total_electricity_costs_description: 'basierend auf Durchschnittspreisen in Österreich',
    },
    energy_and_co2_balance_result: {
      table_title: 'DIE Energie- & CO₂-Bilanz Ihres Hauses',
      total_energy_balance_per_year: 'Ihr gesamter jährlicher Energiebedarf ',
      total_energy_balance_per_year_description: 'Wie viele kWh benötigen Sie für das Heizen, Warmwasser und den Strom',
      energy_ghg_balance_per_year: 'Ihre jährliche CO₂-Bilanz',
      energy_ghg_balance_per_year_description: 'Menge an CO₂ die dadurch produziert wird ',
      energy_ghg_equivalent_car: 'Dies entspricht Kilometer mit einem Benzin-PKW:',
      energy_ghg_equivalent_car_description: 'Grundlage ist eine CO₂-Menge von 150g/km',
      energy_ghg_equivalent_plane: 'Anzahl an Flugreisen die dieser CO₂-Menge entspricht',
      energy_ghg_equivalent_plane_description: 'Wien - New York - Wien Flüge (Economy-Class)',
      energy_ghg_equivalent_trees: 'Anzahl an Bäumen die diese CO₂-Menge speichern',
      energy_ghg_equivalent_trees_description: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
      total_energy_costs: 'Gesamtkosten für Energie',
      total_energy_costs_description: 'basierend auf Durchschnittspreisen in Österreich',
    },
    new_windows_number: 'Wie viele Fenster werden getauscht?',
    number_of_extra_large_windows: 'Wie viele extra-große Fenster (>3m²) bzw. Balkontüren werden getauscht?',
    number_of_lift_and_slide_doors: 'Wie viele Hebeschiebetüren soll es geben?',
    heating_system_for_renovation: {
      name: 'Welches Heizsystem möchten Sie in Zukunft verwenden?',
      hot_water_with_new_heating_system: 'Soll das Warmwasser mit dem neuen Heizsystem erzeugt werden?',
      hot_water_with_new_heating_system_description: 'Warmwasserproduktion erfolgt durch die neue Heizanlage',
      district: 'Fernwärme',
      gas: 'Gasheizung',
      pellet: 'Pelletsheizung',
      heat_pump: 'Wärmepumpe',
    },
    roof_renewal: {
      name: 'Welche Dachform hat das Haus?',
      gable: 'Satteldach',
      flat: 'Flachdach',
      hipped: 'Walmdach',
      gable_description: 'Kalkuliert wird die Fläche eines durchschnittlich steilen Satteldachs ohne Gaube.',
      flat_description: 'Kalkuliert wird die Fläche eines Flachdachs mit geringem Überstand.',
      hipped_description: 'Kalkuliert wird die Fläche eines durchschnittlich steilen Walmdachs ohne Gaube.',
      area: 'Geschätzte Dachfläche (m²) - Sie können den Wert anpassen.',
    },
    facade_insulation: {
      type: {
        name: 'Welche Anbausituation liegt vor?',
        single_house: 'Freistehend',
        double_house: 'Doppelhaus',
        middle_house: 'Mittelhaus',
        single_house_description: 'Alle Seiten des Hauses müssen gedämmt werden.',
        double_house_description: 'Nur 3 Seiten des Hauses müssen gedämmt werden',
        middle_house_description: 'Nur 2 Seiten des Hauses müssen gedämmt werden',
      },
      area: 'Geschätzte Fassadenfläche (m²) - Sie können den Wert anpassen.',
    },
    house_area: 'Wohnnutzfläche (m²)',
    insulation_top_ceiling_area: 'Fläche der obersten Geschossdecke in m²',
    insulation_basement_ceiling_area: 'Fläche der Kellerdecke in m²',
    number_of_bathrooms_for_renovation: 'Wie viele Badezimmer soll es geben?',
    number_of_toilets_for_renovation: 'Wie viele WCs soll es geben?',
    new_flooring_area: 'Wie viele m² sollen erneuert werden?',
    renovation_walls_and_interior_doors_area: 'Wie viele m² sollen erneuert werden?',
    number_of_interior_doors_for_renovation: 'Wie viele Innentüren sollen ersetzt werden?',
    sanitary_renovation_area: 'Wie viel m² haben die neuen Sanitärbereiche insgesamt?',
  },

  renovation_house_features: {
    solar_heating: 'Solarthermie',
    solar_heating_subitem: 'Solarthermie',
    solar_heating_description: 'Die Solaranlage dient der Warmwasseraufbereitung und speichert dieses in einem Boiler.',
    solar_heating_equipment_type_description:
      'Die <b>Standardvariante</b> dient für Warmwasser, in den teureren Kategorien dient die Anlage auch für Heizunterstützung und verfügt über hochwertigere Komponenten.',
    domestic_ventilation: 'Aktive Wohnraumlüftung',
    domestic_ventilation_subitem: 'Aktive Wohnraumlüftung',
    domestic_ventilation_description:
      'Eine aktive Wohnraumlüftung sorgt durch Luftein- und -auslässe für einen automatischen und energieeffizienten Luftaustausch.',
    domestic_ventilation_equipment_type_description:
      'Unterschiede ergeben sich in der Steuerungsmöglichkeit, den Filtern und der Qualität der Komponenten.',
    insulation_top_ceiling: 'Dämmung der obersten Geschossdecke',
    insulation_top_ceiling_subitem: 'Dämmung der obersten Geschossdecke',
    insulation_top_ceiling_description:
      'Eine kostengünstigere Variante anstelle der Dachstuhlsanierung berücksichtigt lediglich das Anbringen eines Dämmstoffs auf die oberste Geschoßdecke.',
    insulation_top_ceiling_equipment_type_description:
      '<b>Standardausführung</b> sind lose verlegte Dämmplatten mit beschränkter Begehbarkeit. In den höheren Kategorien kommen trittfeste und nachhaltig produzierte Dämmplatten zum Einsatz.',
    insulation_basement_ceiling: 'Dämmung der Kellerdecke',
    insulation_basement_ceiling_subitem: 'Dämmung der Kellerdecke',
    insulation_basement_ceiling_description:
      'Bei einem unbeheizten Keller sollte die Decke gut gedämmt werden um Wärmeverluste zu minimieren.',
    insulation_basement_ceiling_equipment_type_description:
      'Unterschiede bestehen in der Qualität und (nachhaltigen) Ausführung der Dämmung.',
    energy_certificate: 'Energieausweis',
    energy_certificate_description:
      'Ein Energieausweis wird von zertifizierten Stellen ausgestellt und für viele Förderungsansuchen benötigt.',
    reserves_for_unexpected_costs: 'Reserve für ungeplante Zusatzkosten',
    reserves_for_unexpected_costs_description:
      'Insbesondere bei Sanierungen empfehlen wir eine Finanzreserve von mindestens 10% für unvorhergesehene Zusatzaufwände.',
    kitchen: 'Neue Küche',
    kitchen_subitem: 'Neue Küche',
    kitchen_description: ' ',
    kitchen_equipment_type_description:
      'Der Unterschied in den Kategorien ergibt sich durch die Anzahl und Ausführung der Kästen, Oberflächen und Geräte. Bedenken Sie, dass eine Küche im Regelfall hohen Belastungen durch Hitze, Feuchtigkeit, häufiges Öffnen und Schließen ausgesetzt ist, und daher entsprechend hochwertig ausgeführt sein muss, um viele Jahre zu halten. Typische Preistreiber sind Steinplatten, Apothekerschränke, Dampfgarer, Weinschränke etc., die in der <b>Standardvariante</b> nicht enthalten sind.',
    air_condition: 'Einbau einer Klimaanlage für den gesamten Wohnbereich',
    air_condition_subitem: 'Einbau einer Klimaanlage für den gesamten Wohnbereich',
    air_condition_description:
      'Eine festinstallierte Klimaanlage besteht aus einem Außengerät mit entsprechenden Luftauslässen in den Wohnräumen. ',
    air_condition_equipment_type_description:
      'Unterschiede in den Kategorien ergeben sich durch die getrennte Regelung und Programmierung in den unterschiedlichen Räumen (Multisplitanlagen), dem Geräuschpegel und letztlich auch dem Energieverbrauch.',
    sanitary_renovation: 'Erneuerung der Sanitärbereiche',
    sanitary_renovation_subitem: 'Erneuerung der Sanitärbereiche',
    sanitary_renovation_subitem_description: 'Erneuerung der Sanitärbereiche',
    sanitary_renovation_description:
      'Die Erneuerung sämtlicher Wasser- und Abflussleitungen, der komplette Ersatz der alten Sanitärausstattung, sowie eine neue Verfliesung werden kalkuliert. Grundlage der Berechnung sind die m² (Nutzfläche) der Sanitärbereiche, sowie die Anzahl der Bäder bzw. WCs.',
    sanitary_renovation_equipment_type_description:
      'Unterschiede in den Kategorien sind Preistreiber. z.B. großformatige Fliesen, hochwertige Badezimmermöbel, eine Fußbodenheizung, eine bodentiefe Dusche, eine freistehende Badewanne, etc.',
    new_flooring: 'Erneuerung der Böden',
    new_flooring_subitem: 'Erneuerung der Böden',
    new_flooring_description:
      'Alte Böden müssen entfernt, und Neue verlegt werden. Alte aber solide Parkettböden werden abgeschliffen, ausgebessert und neu lackiert.',
    new_flooring_equipment_type_description:
      'In der <b>Standardvariante</b> wird von günstigen Belägen in Baumarktqualität ausgegangen. Bei der <b>gehobenen Variante</b> werden Parkettböden bzw. günstige Fliesen verlegt. Naturstein bzw. hochwertige Naturholzböden finden Sie in der <b>Premiumvariante</b>.',
    renovation_walls_and_interior_doors: 'Sanierung der Wände, Decken und Innentüren',
    renovation_walls_and_interior_doors_subitem: 'Sanierung der Wände, Decken und Innentüren',
    renovation_walls_and_interior_doors_description:
      'Die Quadratmeter beziehen sich auf die Wohnfläche - die grundlegende Sanierung bzw. Erneuerung der Innenwände, Decken und Innentüren wird kalkuliert. ',
    renovation_walls_and_interior_doors_equipment_type_description:
      'Unterschiede ergeben sich vor allem durch Raumhöhen, spezifische Ausführung wie Stukelemente, Tapeten etc. Innentüren verfügen in der <b>Standardausführung</b> oft über eine schlechte Schalldämmung und eine Kunststoffoberfläche.',
    renewal_of_electric_installation: 'Erneuerung der Elektroinstallation',
    renewal_of_electric_installation_subitem: 'Erneuerung der Elektroinstallation',
    renewal_of_electric_installation_description:
      'Demontage und Erneuerung der Verrohrung und Verdrahtung, der Sicherungen, der Steckdosen und der Schalter.',
    renewal_of_electric_installation_equipment_type_description:
      'In der <b>Standardvariante</b> ist in die Erneuerung des Schaltschranks inklusive Sicherungen berücksichtigt. Bei der <b>gehobenen Variante</b> werden zusätzlich Steckdosen und Schalter getauscht. Die <b>Premiumvariante</b> bietet beispielsweise ein BUS-System, mit dem Heizung, Licht und Beschattung gesteuert werden können.',
    renewal_of_front_door: 'Eingangstüre erneuern',
    renewal_of_front_door_subitem: 'Eingangstüre erneuern',
    renewal_of_front_door_description: 'Die Grundlage ist eine moderne Sicherheitstüre inklusive Einbau.',
    renewal_of_front_door_equipment_type_description:
      'Unterschiede ergeben sich in der Größe und Ausführung der Türe (Material und Technik wie Fingerprint-Sensor).',
    new_windows: 'Fenstertausch',
    new_windows_subitem: 'Fenstertausch',
    new_windows_description:
      'Preistreiber sind hier vor allem die Größe der Fenster, die Ausführung (Kunstoff, Holz oder Holz/Alu) sowie Zusatzausstattungen wie z.B. Sonnenschutz, Absturzsicherung, etc.',
    new_windows_equipment_type_description:
      'Die <b>Standardvariante</b> beinhaltet die Entsorgung der alten Fenster und den Einbau günstiger Kunststofffenster. Bei der <b>gehobenen Variante</b> handelt es sich um höherwertige Kunststoff-Aluminiumfenster und teilweisen Sonnenschutz. Die <b>Premiumvariante</b> enthält hochwertige Holz-Alufenster mit durchgängiger Beschattung, welche zusätzlich per App (Smartphone, etc.) gesteuert werden kann.',
    renewal_of_heating_system: 'Heizung erneuern (inklusive Heizkörper)',
    renewal_of_heating_system_subitem: 'Heizung erneuern (inklusive Heizkörper)',
    renewal_of_heating_system_description:
      'Die bestehende Heizung wird demontiert und durch eine neue Heizung ersetzt. Bei einem Umstieg auf ein neues Heizsystem wie z.B. von Öl auf Pelletsheizung können eventuell weitere Zusatzkosten anfallen. In vielen Fällen gibt es dafür Fördermöglichkeiten – informieren Sie sich!',
    renewal_of_heating_system_equipment_type_description:
      'Bei der <b>Standardvariante</b> wird nur die Heizung inklusive aller notwendigen Pumpen getauscht. Die <b>gehobene Variante</b> beinhaltet zusätzlich zur Heizung auch neue Heizkörper und eine moderne Steuerung. Bei der <b>Premiumvariante</b> sind die Kosten für den Umstieg auf eine Fußbodenheizung ebenfalls berücksichtigt, zusätzlich ist die Anlage via App (Smartphone, etc.) steuerbar.',
    roof_renewal: 'Dach erneuern und dämmen',
    roof_renewal_subitem: 'Dach erneuern und dämmen',
    roof_renewal_description:
      'Die Grundlage ist die Entfernung der alten Dacheindeckung und eine neue Dacheindeckung inklusive Dämmung auf Basis des bestehenden Dachstuhls.',
    roof_renewal_equipment_type_description:
      'Die <b>Standardvariante</b> beinhaltet die Erneuerung der Dacheindeckung inklusive einer Dämmung auf Basis des bestehenden Dachstuhls. Bei der <b>gehobenen Variante</b> ist eine neue Dachunterschicht sowie die Erneuerung aller Spenglerarbeiten zusätzlich berücksichtigt. Die <b>Premiumvariante</b> beinhaltet einen kompletten Tausch des bestehenden Dachstuhls inklusive Dämmung und wohnlicher Innenausführung.',
    facade_insulation: 'Fassade dämmen',
    facade_insulation_subitem: 'Fassade dämmen',
    facade_insulation_description:
      'Die Grundlage ist die Dämmung der Außenwände, die Fassade erhält einen neuen Außenputz. Bitte beachten Sie, dass ein allfälliger Tausch der Fenster ebenfalls eingeplant werden sollte.',
    facade_insulation_equipment_type_description:
      'Bei der <b>Standardvariante</b> werden kostengünstige Dämm- und Putzmaterialien sowie einfache Fensterbänke verwendet. Die <b>gehobene Variante</b> inkludiert höherwertige Materialien (Dämmung, Außenputz, Fensterbänke). Bei der <b>Premiumvariante</b> werden ökologische Dämmstoffe (z.B. Hanf) mit hochwertigem Außenputz und Fensterbänken kombiniert. Die Dicke der Dämmung wird wie folgt kalkuliert: Standard mit 10cm/ Gehoben 16cm/ Premium 20cm - Ihr Baumeister/Architekt/Energieberater informiert Sie gerne über die notwendige Dämmstärke.',
    alarm_system: 'Alarmanlage einbauen',
    alarm_system_subitem: 'Alarmanlage einbauen',
    alarm_system_description: ' ',
    alarm_system_equipment_type_description:
      'Unterschiede ergeben sich durch die Art und Anzahl der Sensoren (z.B. Reedkontakte, Bewegungsmelder, etc.), den Steuerungsmöglichkeiten (z.B. App) und der zusätzlichen Installation von Kameras.',
    carport: 'Stellplätze Carport',
    carport_subitem: 'Stellplätze Carport',
    carport_description: ' ',
    carport_equipment_type_description:
      'Die <b>Standardvariante</b> beinhaltet eine einfache Konstruktion. Bei der <b>gehobenen Variante</b> ist eine massive Ausführung inklusive Erweiterungen (z.B. Platz für Mülltonnen, Fahrräder etc.) berücksichtigt. Die <b>Premiumvariante</b> sticht durch ihre Optik hervor (z.B. Beleuchtung, Pflaster etc.).',
    solar_power_system: 'Photovoltaik',
    solar_power_system_subitem: 'Photovoltaik',
    solar_power_system_description:
      'Eine PV-Anlage macht ein Stück weit unabhängig. Oft gibt es dafür auch öffentliche Förderungen - informieren Sie sich! ',
    garage: 'Stellplätze Garage',
    garage_subitem: 'Stellplätze Garage',
    garage_description: ' ',
    garage_equipment_type_description:
      'Die <b>Standardgarage</b> ist ca. 6m lang und je Stellplatz ca. 3m breit und günstig ausgeführt. Die <b>gehobene Variante</b> bietet zusätzliche Bewegungsfreiheit und Platz für Fahrräder. Die <b>Premiumvariante</b> sticht durch ihre Optik hervor (z.B. Beleuchtung, Garagentor, etc.).',
    pool: 'Pool/Naturteich',
    pool_subitem: 'Pool/Naturteich',
    pool_description:
      'Massiver Pool inklusive Erdarbeiten oder ein Naturteich zum Schwimmen in einer Größe von rund 8x4m.',
    pool_equipment_type_description:
      'Unterschiede ergeben sich in der Ausführung und der Wasseraufbereitungssysteme, Heizung und Überdachung (z.B. bei einem Pool).',
    winter_garden: 'Wintergarten',
    winter_garden_subitem: 'Wintergarten',
    winter_garden_description: 'Inkludiert den Anbau eines Wintergartens inklusive Bodenbelag.',
    winter_garden_equipment_type_description:
      'Ein <b>Standardwintergarten</b> ist eine geschlossene Terrasse. Die <b>gehobene Variante</b> bietet zusätzlich eine Belüftung und Beschattung. Bei der <b>Premiumvariante</b> handelt es sich um einen vollwertigen, ganzjährig nutzbaren Wohnraum.',
    wallbox_e_mobility: 'Ladestation für E-Auto - Wallbox',
    wallbox_e_mobility_subitem: 'Ladestation für E-Auto - Wallbox',
    wallbox_e_mobility_description:
      'Um den Neubau zukunftssicher zu gestalten, sollte eine Wallbox immer in der Planung Berücksichtigung finden.',
    wallbox_e_mobility_equipment_type_description:
      'In der Kategorie <>Standard</> wird eine hochwertige Wallbox mit Installation durch einen Fachelektriker geplant. Die Grundlage ist hier die Verwendung der bestehenden Verkabelung, was bedeutet dass oft nur eine Ladeleistung von 3,7kW möglich ist. In den höheren Kategorien wird eine zusätzliche Verkabelung, und eine Wallbox mit Integrationsmöglichkeit in eine PV-Anlage kalkuliert, damit ist eine Ladeleistung von bis zu 11kW möglich.',
  },

  apartment_wizard: {
    title: 'Projektdetails',
    note:
      'Für ein  individuelles Angebot müssen wir Ihre gewünschte Immobilie bewerten, dafür benötigen wir einige Informationen!',
    apartment_information_title: 'Informationen über Ihre Wohnung',

    second_step_apartment_title: 'Erzählen Sie uns mehr über Ihre Wohnung!',
    apartment_type: 'Um welchen Wohnungstyp handelt es sich?',
    cost_apartment: 'Wie hoch ist der Kaufpreis der Wohnung? (€)',
    main_residence: 'Ist diese Wohnung Ihr neuer Hauptwohnsitz?',
    additional_costs: 'Zusatzkosten - Bitte ändern Sie diese, wenn sie für Ihre Wohnung nicht passen:',
    property_transfer_tax: 'Grunderwerbsteuer (3,5%)',
    land_register_fee: 'Grundbucheintragung (1,1%)',
    notary_fee: 'Notargebühr (1,0%)',
    broker_commission: 'Maklerprovision (3,6%)',
    condominium: 'Eigentumswohnung',
    subsidized_condominium: 'Geförderte Eigentumswohnung',
    total_cost_apartment: 'Gesamtkosten des Wohnungskaufs:',

    full_address: 'Vollständige Adresse (Straße, Hausnummer, Ort)',
    top_number: 'Top-Nummer',
    floor_number: 'Stockwerk',
    is_rooftop: 'Ist die Wohnung im Dachgeschoss?',
    apartment_area: 'Wohnnutzfläche (m²)',
    condition_of_house: 'Zustand des Hauses ',
    condition_of_apartment: 'Zustand der Wohnung',
    not_renovated: 'Erstbezug / Nicht saniert',
    fully_renovated: 'Vollsaniert',
    partly_renovated: 'Teilsaniert',
    modernized: 'Modernisiert',
    construction_year: 'Baujahr des Gebäudes',
    apartment_renovation_year: 'Jahr der letzen Renovierung der Wohnung',
    loggia: 'Loggia',
    loggia_area: 'm²',
    terrace: 'Terrasse',
    terrace_area: 'm²',
    garden: 'Garten',
    garden_area: 'm²',
    balcony: 'Balkon',
    elevator: 'Aufzug',
    basement: 'Keller',
    parking: {
      name: 'Parkmöglichkeit',
      underground_car_park: 'Tiefgarage / Garage',
      outdoor_parking_space: 'Außenstellplatz',
      garage: 'Garage',
      none: 'Keine',
    },
    heating_system: {
      name: 'Heizung',
      central_or_district: 'Zentralheizung oder Fernwärme',
      gas: 'Gasetagenheizung',
      electric: 'Elektroheizung',
      wood_coal: 'Holz-Kohleheizung',
    },
    air_conditioning: 'Klimaanlage',
    renovations_or_additional_investments: 'Sind Renovierungen bzw. Zusatzinvestitionen geplant?',
    sanitary_renovation_area: 'Wie viele m² haben die Sanitärbereiche insgesamt?',
    new_flooring_area: 'Wie viele m² sollen erneuert werden?',
    renovation_walls_and_interior_doors_area: 'Wie viele m² sollen erneuert werden?',
    new_windows_number: 'Wie viele Fenster sollen getausch werden',
    other_investments_amount: 'Sonstige Kosten',
    other_investments_comment: 'Erläuterung',

    map: 'Landkarte',
    street_view: 'Ansicht der Straße',
  },
  // description
  apartment_features: {
    cost_apartment: 'Kaufpreis der Wohnung',
    cost_apartment_description: ' ',
    taxes_and_fees: 'Gebühren und Steuern',
    taxes_and_fees_description: ' ',
    property_transfer_tax: 'Grunderwerbsteuer',
    property_transfer_tax_description: ' ',
    land_register_fee: 'Grundbucheintragung',
    land_register_fee_description: ' ',
    notary_fee: 'Notargebühr',
    notary_fee_description: ' ',
    broker_commission: 'Maklerprovision',
    broker_commission_description: ' ',
    renovation_walls_and_interior_doors_title: 'Sanierung der Wände, Decken und Innentüren',
    renovation_walls_and_interior_doors: 'Sanierung der Wände, Decken und Innentüren',
    renovation_walls_and_interior_doors_description:
      'Hier wird die grundlegende Sanierung der Innenwände, Decken und Innentüren kalkuliert.',
    kitchen: 'Neue Küche',
    kitchen_description: ' ',
    reserves_for_unexpected_costs: 'Reserve für ungeplante Zusatzkosten',
    reserves_for_unexpected_costs_description:
      'Insbesondere bei Sanierungen empfehlen wir eine Finanzreserve von mindestens 10% für unvorhergesehene Zusatzaufwände.',
    kitchen_equipment_type_description:
      'Der Unterschied in den Kategorien ergibt sich durch die Anzahl und Ausführung der Kästen, Oberflächen und Geräte. Bedenken Sie, dass eine Küche im Regelfall hohen Belastungen durch Hitze, Feuchtigkeit, häufiges Öffnen und Schließen ausgesetzt ist, und daher entsprechend hochwertig ausgeführt sein muss, um viele Jahre zu halten. Typische Preistreiber sind Steinplatten, Apothekerschränke, Dampfgarer, Weinschränke etc., die in der <b>Standardvariante</b> nicht enthalten sind.',
    sanitary_renovation_title: 'Erneuerung der Sanitärbereiche',
    sanitary_renovation: 'Grundlegende Sanierung von Bad und WC',
    sanitary_renovation_description:
      'Die Erneuerung sämtlicher Wasser- und Abflussleitungen, der komplette Ersatz der alten Sanitärausstattung, sowie eine neue Verfliesung werden kalkuliert.',
    sanitary_renovation_equipment_type_description:
      'Unterschiede in den Kategorien sind Preistreiber. z.B. großformatige Fliesen, hochwertige Badezimmermöbel, eine Fußbodenheizung, eine bodentiefe Dusche, eine freistehende Badewanne, etc.',
    aircondition: 'Einbau einer Klimaanlage für die gesamte Wohnung',
    aircondition_description:
      'Eine festinstallierte Klimaanlage besteht aus einem Außengerät mit entsprechenden Luftauslässen in den Wohnräumen.',
    aircondition_equipment_type_description:
      'Unterschiede in den Kategorien ergeben sich durch die getrennte Regelung und Programmierung in den unterschiedlichen Räumen (Multisplitanlagen), dem Geräuschpegel und letztlich auch dem Energieverbrauch',
    new_flooring_title: 'Erneuerung der Böden',
    new_flooring: 'Parkett, Teppich und/oder Fliesenböden',
    new_flooring_description:
      'Alte Böden müssen entfernt, und Neue verlegt werden. Alte aber solide Parkettböden werden abgeschliffen, ausgebessert und neu lackiert.',
    new_flooring_equipment_type_description:
      'In der <b>Standardvariante</b> wird von günstigen Belägen in Baumarktqualität ausgegangen. Bei der <b>gehobenen Variante</b> werden Parkettböden bzw. günstige Fliesen verlegt. Naturstein bzw. hochwertige Naturholzböden finden Sie in der <b>Premiumvariante</b>.',
    renovation_walls_and_doors: 'Sanierung der Wände, Decken und Innentüren',
    renovation_walls_and_doors_description:
      'Die grundlegende Sanierung bzw. Erneuerung der Innenwände, Decken und Innentüren wird kalkuliert.',
    renovation_walls_and_doors_equipment_type_description:
      'Unterschiede ergeben sich vor allem durch Raumhöhen, spezifische Ausführung wie Stukelemente, Tapeten udgl.',
    renewal_of_electric_installation: 'Erneuerung der Elektroinstallation',
    renewal_of_electric_installation_description:
      'Demontage und Erneuerung der Verrohrung und Verdrahtung, der Sicherungen, der Steckdosen und der Schalter.',
    renewal_of_electric_installation_equipment_type_description:
      'Preistreiber sind hier vor allem Art und Anzahl der Stecker und Schalter bzw. im Bereich <b>Premium</b> ein Bussystem.',
    new_windows_title: 'Fenstertausch',
    new_windows: 'Fenstertausch',
    new_windows_description: ' ',
    new_windows_stove_description: 'Austausch der alten Fenster gegen Neue inklusive der notwendigen Verputzarbeiten.',
    new_windows_equipment_type_description:
      'Preistreiber sind hier vor allem die Größe der Fenster, die Ausführung (Kunstoff, Holz oder Holz/Alu) sowie Zusatzausstattung wie Sonnenschutz.',
    renewal_of_heating_system: 'Heizung erneuern (inklusive Heizkörper)',
    renewal_of_heating_system_description:
      'Die bestehende Heizung wird demontiert und durch eine neue Heizung ersetzt (Gasetagenheizung, Fernwärme oder Hauszentralheizung).',
    renewal_of_heating_system_equipment_type_description:
      'Preistreiber sind hier vor allem Fußbodenheizung, hochwertige Heizkörper und Steuerungsmöglichkeiten via App.',
    other_investments: 'Sonstige Investionen',
    other_investments_description:
      'Sonstige Ausgaben im Zusammenhang mit Ihrem Sanierungsvorhaben können Sie an dieser Stelle individuell erfassen.',
  },
  buying_house_features: {
    solar_heating: 'Solarthermie',
    solar_heating_subitem: 'Solarthermie',
    solar_heating_description: 'Die Solaranlage dient der Warmwasseraufbereitung und speichert dieses in einem Boiler.',
    solar_heating_equipment_type_description:
      'Die <b>Standardvariante</b> dient für Warmwasser, in den teureren Kategorien dient die Anlage auch für Heizunterstützung und verfügt über hochwertigere Komponenten.',
    domestic_ventilation: 'Aktive Wohnraumlüftung',
    domestic_ventilation_subitem: 'Aktive Wohnraumlüftung',
    domestic_ventilation_description:
      'Eine aktive Wohnraumlüftung sorgt durch Luftein- und -auslässe für einen automatischen und energieeffizienten Luftaustausch.',
    domestic_ventilation_equipment_type_description:
      'Unterschiede ergeben sich in der Steuerungsmöglichkeit, den Filtern und der Qualität der Komponenten.',
    insulation_top_ceiling: 'Dämmung der obersten Geschossdecke',
    insulation_top_ceiling_subitem: 'Dämmung der obersten Geschossdecke',
    insulation_top_ceiling_description:
      'Eine kostengünstigere Variante anstelle der Dachstuhlsanierung berücksichtigt lediglich das Anbringen eines Dämmstoffs auf die oberste Geschoßdecke.',
    insulation_top_ceiling_equipment_type_description:
      '<b>Standardausführung</b> sind lose verlegte Dämmplatten mit beschränkter Begehbarkeit. In den höheren Kategorien kommen trittfeste und nachhaltig produzierte Dämmplatten zum Einsatz.',
    insulation_basement_ceiling: 'Dämmung der Kellerdecke',
    insulation_basement_ceiling_subitem: 'Dämmung der Kellerdecke',
    insulation_basement_ceiling_description:
      'Bei einem unbeheizten Keller sollte die Decke gut gedämmt werden um Wärmeverluste zu minimieren.',
    insulation_basement_ceiling_equipment_type_description:
      'Unterschiede bestehen in der Qualität und (nachhaltigen) Ausführung der Dämmung.',
    energy_certificate: 'Energieausweis',
    energy_certificate_description:
      'Ein Energieausweis wird von zertifizierten Stellen ausgestellt und für viele Förderungsansuchen benötigt.',
    reserves_for_unexpected_costs: 'Reserve für ungeplante Zusatzkosten',
    reserves_for_unexpected_costs_description:
      'Insbesondere bei Sanierungen empfehlen wir eine Finanzreserve von mindestens 10% für unvorhergesehene Zusatzaufwände.',
    cost_house: 'Kosten Hauskauf',
    cost_house_description: 'Preis bitte ohne Steuern und Gebühren eingeben.',
    taxes_and_fees: 'Gebühren & Steuern',
    taxes_and_fees_description:
      'Wir berechnen die in Österreich anfallenden Nebenkosten, diese können aber individuell hier angepasst werden.',
    property_transfer_tax: 'Grunderwerbsteuer (3,5%)',
    property_transfer_tax_description: 'Dies ist eine gesetzliche Abgabe die sich am Kaufpreis bemisst.',
    land_register_fee: 'Grundbucheintragung (1,1%)',
    land_register_fee_description: 'Dies ist eine gesetzliche Gebühr die sich am Kaufpreis bemisst.',
    notary_fee: 'Notargebühr (1,0%)',
    notary_fee_description: 'Notargebühr kann durch gute Vorbereitung und Verhandlung auch reduziert werden.',
    broker_commission: 'Maklergebühr (3,6%)',
    broker_commission_description: 'dies ist die maximal Gebühr in Österreich - verhandeln ist hier erlaubt..',
    kitchen: 'Neue Küche',
    kitchen_subitem: 'Neue Küche',
    kitchen_description: ' ',
    kitchen_equipment_type_description:
      'Der Unterschied in den Kategorien ergibt sich durch die Anzahl und Ausführung der Kästen, Oberflächen und Geräte. Bedenken Sie, dass eine Küche im Regelfall hohen Belastungen durch Hitze, Feuchtigkeit, häufiges Öffnen und Schließen ausgesetzt ist, und daher entsprechend hochwertig ausgeführt sein muss, um viele Jahre zu halten. Typische Preistreiber sind Steinplatten, Apothekerschränke, Dampfgarer, Weinschränke etc., die in der <b>Standardvariante</b> nicht enthalten sind.',
    air_condition: 'Einbau einer Klimaanlage für den gesamten Wohnbereich',
    air_condition_subitem: 'Einbau einer Klimaanlage für den gesamten Wohnbereich',
    air_condition_description:
      'Eine festinstallierte Klimaanlage besteht aus einem Außengerät mit entsprechenden Luftauslässen in den Wohnräumen. ',
    air_condition_equipment_type_description:
      'Unterschiede in den Kategorien ergeben sich durch die getrennte Regelung und Programmierung in den unterschiedlichen Räumen (Multisplitanlagen), dem Geräuschpegel und letztlich auch dem Energieverbrauch.',
    sanitary_renovation: 'Erneuerung der Sanitärbereiche',
    sanitary_renovation_subitem: 'Erneuerung der Sanitärbereiche',
    sanitary_renovation_subitem_description: 'Erneuerung der Sanitärbereiche',
    sanitary_renovation_description:
      'Die Erneuerung sämtlicher Wasser- und Abflussleitungen, der komplette Ersatz der alten Sanitärausstattung, sowie eine neue Verfliesung werden kalkuliert. Grundlage der Berechnung sind die m² (Nutzfläche) der Sanitärbereiche, sowie die Anzahl der Bäder bzw. WCs.',
    sanitary_renovation_equipment_type_description:
      'Unterschiede in den Kategorien sind Preistreiber. z.B. großformatige Fliesen, hochwertige Badezimmermöbel, eine Fußbodenheizung, eine bodentiefe Dusche, eine freistehende Badewanne, etc.',
    new_flooring: 'Erneuerung der Böden',
    new_flooring_subitem: 'Erneuerung der Böden',
    new_flooring_description:
      'Alte Böden müssen entfernt, und Neue verlegt werden. Alte aber solide Parkettböden werden abgeschliffen, ausgebessert und neu lackiert.',
    new_flooring_equipment_type_description:
      'In der <b>Standardvariante</b> wird von günstigen Belägen in Baumarktqualität ausgegangen. Bei der <b>gehobenen Variante</b> werden Parkettböden bzw. günstige Fliesen verlegt. Naturstein bzw. hochwertige Naturholzböden finden Sie in der <b>Premiumvariante</b>.',
    renovation_walls_and_interior_doors: 'Sanierung der Wände, Decken und Innentüren',
    renovation_walls_and_interior_doors_subitem: 'Sanierung der Wände, Decken und Innentüren',
    renovation_walls_and_interior_doors_description:
      'Die Quadratmeter beziehen sich auf die Wohnfläche - die grundlegende Sanierung bzw. Erneuerung der Innenwände, Decken und Innentüren wird kalkuliert. ',
    renovation_walls_and_interior_doors_equipment_type_description:
      'Unterschiede ergeben sich vor allem durch Raumhöhen, spezifische Ausführung wie Stukelemente, Tapeten etc. Innentüren verfügen in der <b>Standardausführung</b> oft über eine schlechte Schalldämmung und eine Kunststoffoberfläche.',
    renewal_of_electric_installation: 'Erneuerung der Elektroinstallation',
    renewal_of_electric_installation_subitem: 'Erneuerung der Elektroinstallation',
    renewal_of_electric_installation_description:
      'Demontage und Erneuerung der Verrohrung und Verdrahtung, der Sicherungen, der Steckdosen und der Schalter.',
    renewal_of_electric_installation_equipment_type_description:
      'In der <b>Standardvariante</b> ist in die Erneuerung des Schaltschranks inklusive Sicherungen berücksichtigt. Bei der <b>gehobenen Variante</b> werden zusätzlich Steckdosen und Schalter getauscht. Die <b>Premiumvariante</b> bietet beispielsweise ein BUS-System, mit dem Heizung, Licht und Beschattung gesteuert werden können.',
    renewal_of_front_door: 'Eingangstüre erneuern',
    renewal_of_front_door_subitem: 'Eingangstüre erneuern',
    renewal_of_front_door_description: 'Die Grundlage ist eine moderne Sicherheitstüre inklusive Einbau.',
    renewal_of_front_door_equipment_type_description:
      'Unterschiede ergeben sich in der Größe und Ausführung der Türe (Material und Technik wie Fingerprint-Sensor).',
    new_windows: 'Fenstertausch',
    new_windows_subitem: 'Fenstertausch',
    new_windows_description:
      'Preistreiber sind hier vor allem die Größe der Fenster, die Ausführung (Kunstoff, Holz oder Holz/Alu) sowie Zusatzausstattungen wie z.B. Sonnenschutz, Absturzsicherung, etc.',
    new_windows_equipment_type_description:
      'Die <b>Standardvariante</b> beinhaltet die Entsorgung der alten Fenster und den Einbau günstiger Kunststofffenster. Bei der <b>gehobenen Variante</b> handelt es sich um höherwertige Kunststoff-Aluminiumfenster und teilweisen Sonnenschutz. Die <b>Premiumvariante</b> enthält hochwertige Holz-Alufenster mit durchgängiger Beschattung, welche zusätzlich per App (Smartphone, etc.) gesteuert werden kann.',
    renewal_of_heating_system: 'Heizung erneuern',
    renewal_of_heating_system_subitem: 'Heizung erneuern (inklusive Heizkörper)',
    renewal_of_heating_system_description:
      'Die bestehende Heizung wird demontiert und durch eine neue Heizung ersetzt. Bei einem Umstieg auf ein neues Heizsystem wie z.B. von Öl auf Pelletsheizung können eventuell weitere Zusatzkosten anfallen. In vielen Fällen gibt es dafür Fördermöglichkeiten – informieren Sie sich!',
    renewal_of_heating_system_equipment_type_description:
      'Bei der <b>Standardvariante</b> wird nur die Heizung inklusive aller notwendigen Pumpen getauscht. Die <b>gehobene Variante</b> beinhaltet zusätzlich zur Heizung auch neue Heizkörper und eine moderne Steuerung. Bei der <b>Premiumvariante</b> sind die Kosten für den Umstieg auf eine Fußbodenheizung ebenfalls berücksichtigt, zusätzlich ist die Anlage via App (Smartphone, etc.) steuerbar.',
    roof_renewal: 'Dach erneuern und dämmen',
    roof_renewal_subitem: 'Dach erneuern und dämmen',
    roof_renewal_description:
      'Die Grundlage ist die Entfernung der alten Dacheindeckung und eine neue Dacheindeckung inklusive Dämmung auf Basis des bestehenden Dachstuhls.',
    roof_renewal_equipment_type_description:
      'Die <b>Standardvariante</b> beinhaltet die Erneuerung der Dacheindeckung inklusive einer Dämmung auf Basis des bestehenden Dachstuhls. Bei der <b>gehobenen Variante</b> ist eine neue Dachunterschicht sowie die Erneuerung aller Spenglerarbeiten zusätzlich berücksichtigt. Die <b>Premiumvariante</b> beinhaltet einen kompletten Tausch des bestehenden Dachstuhls inklusive Dämmung und wohnlicher Innenausführung.',
    facade_insulation: 'Fassade dämmen',
    facade_insulation_subitem: 'Fassade dämmen',
    facade_insulation_description:
      'Die Grundlage ist die Dämmung der Außenwände, die Fassade erhält einen neuen Außenputz. Bitte beachten Sie, dass ein allfälliger Tausch der Fenster ebenfalls eingeplant werden sollte.',
    facade_insulation_equipment_type_description:
      'Bei der <b>Standardvariante</b> werden kostengünstige Dämm- und Putzmaterialien sowie einfache Fensterbänke verwendet. Die <b>gehobene Variante</b> inkludiert höherwertige Materialien (Dämmung, Außenputz, Fensterbänke). Bei der <b>Premiumvariante</b> werden ökologische Dämmstoffe (z.B. Hanf) mit hochwertigem Außenputz und Fensterbänken kombiniert.',
    alarm_system: 'Alarmanlage',
    alarm_system_subitem: 'Alarmanlage einbauen',
    alarm_system_description: ' ',
    alarm_system_equipment_type_description:
      'Unterschiede ergeben sich durch die Art und Anzahl der Sensoren (z.B. Reedkontakte, Bewegungsmelder, etc.), den Steuerungsmöglichkeiten (z.B. App) und der zusätzlichen Installation von Kameras.',
    carport: 'Carport',
    carport_subitem: 'Stellplätze Carport',
    carport_description: ' ',
    carport_equipment_type_description:
      'Die <b>Standardvariante</b> beinhaltet eine einfache Konstruktion. Bei der <b>gehobenen Variante</b> ist eine massive Ausführung inklusive Erweiterungen (z.B. Platz für Mülltonnen, Fahrräder etc.) berücksichtigt. Die <b>Premiumvariante</b> sticht durch ihre Optik hervor (z.B. Beleuchtung, Pflaster etc.).',
    solar_power_system: 'Photovoltaik',
    solar_power_system_subitem: 'Photovoltaik',
    solar_power_system_description:
      'Eine PV-Anlage macht ein Stück weit unabhängig. Oft gibt es dafür auch öffentliche Förderungen - informieren Sie sich! ',
    garage: 'Garage',
    garage_subitem: 'Stellplätze Garage',
    garage_description: ' ',
    garage_equipment_type_description:
      'Die <b>Standardgarage</b> ist ca. 6m lang und je Stellplatz ca. 3m breit und günstig ausgeführt. Die <b>gehobene Variante</b> bietet zusätzliche Bewegungsfreiheit und Platz für Fahrräder. Die <b>Premiumvariante</b> sticht durch ihre Optik hervor (z.B. Beleuchtung, Garagentor, etc.).',
    pool: 'Pool/Naturteich',
    pool_subitem: 'Pool/Naturteich',
    pool_description:
      'Massiver Pool inklusive Erdarbeiten oder ein Naturteich zum Schwimmen in einer Größe von rund 8x4m.',
    pool_equipment_type_description:
      'Unterschiede ergeben sich in der Ausführung und der Wasseraufbereitungssysteme, Heizung und Überdachung (z.B. bei einem Pool).',
    winter_garden: 'Wintergarten',
    winter_garden_subitem: 'Wintergarten',
    winter_garden_description: 'Inkludiert den Anbau eines Wintergartens inklusive Bodenbelag.',
    winter_garden_equipment_type_description:
      'Ein <b>Standardwintergarten</b> ist eine geschlossene Terrasse. Die <b>gehobene Variante</b> bietet zusätzlich eine Belüftung und Beschattung. Bei der <b>Premiumvariante</b> handelt es sich um einen vollwertigen, ganzjährig nutzbaren Wohnraum.',
    wallbox_e_mobility: 'Ladestation für E-Auto - Wallbox',
    wallbox_e_mobility_subitem: 'Ladestation für E-Auto - Wallbox',
    wallbox_e_mobility_description:
      'Um den Neubau zukunftssicher zu gestalten, sollte eine Wallbox immer in der Planung Berücksichtigung finden.',
    wallbox_e_mobility_equipment_type_description:
      'In der Kategorie <>Standard</> wird eine hochwertige Wallbox mit Installation durch einen Fachelektriker geplant. Die Grundlage ist hier die Verwendung der bestehenden Verkabelung, was bedeutet dass oft nur eine Ladeleistung von 3,7kW möglich ist. In den höheren Kategorien wird eine zusätzliche Verkabelung, und eine Wallbox mit Integrationsmöglichkeit in eine PV-Anlage kalkuliert, damit ist eine Ladeleistung von bis zu 11kW möglich.',
  },
  buying_house_wizard: {
    addition_information:
      'Für ein  individuelles Angebot müssen wir Ihre gewünschte Immobilie bewerten, dafür benötigen wir einige Informationen!',
    title: 'Projektdetails',
    second_step_house_title: 'Erzählen Sie uns mehr über Ihr neues Haus',
    main_residence: 'Wird es sich dabei um den neuen Hauptwohnsitz handeln?',
    cost_house: 'Wie hoch ist der Kaufpreis',
    total_cost_house: 'Gesamtkosten',
    house_type_label: 'Um welchen Haustyp handelt es sich?',
    single_family_house: 'Einfamilienhaus',
    semi_detached_house: 'Doppelhaushälfte',
    town_house: 'Reihenhaus',
    additional_costs: 'Typische Zusatzkosten beim Hauskauf in Österreich - Sie können diese hier anpassen',
    property_transfer_tax: 'Grunderwerbsteuer (3,5%)',
    land_register_fee: 'Grundbucheintragung (1,1%)',
    notary_fee: 'Notargebühr (1,0%)',
    broker_commission: 'Maklerprovision (3,6%)',
    full_address: 'Vollständige Adresse (Straße, Hausnummer, Ort)',
    land_area: 'Grundstücksfläche (m²)',
    house_area: 'Wohnnutzfläche (m²)',
    floors: 'Anzahl der Etagen (ohne Keller und Dachboden)',
    construction_year: 'Baujahr des Hauses?',
    year_of_outer_wall_renovation: 'Jahr der Außenwand Sanierung',
    roof_renewed: 'Wurde das Dach des Hauses nachträglich saniert?',
    year_of_roof_renewal: 'Jahr der Dach-Sanierung?',
    renovation_outer_wall: {
      name: 'Wurde die Außenwand des Gebäudes nachträglich saniert?',
      no_renovation: 'Keine Renovierung',
      new_plastering_and_painting: 'Neue Fassade bzw. Außenputz',
      thermal_insulation: 'Thermische Isolierung',
    },
    house_type: {
      name: 'Um welche Bauweise handelt es sich?',
      solid_construction: 'Massivhaus',
      prefabricated_house: 'Fertigteilhaus',
      timber_house: 'Holzhaus',
    },
    category: {
      name: 'Um welchen Haustyp handelt es sich?',
      detached_house: 'Einfamilienhaus',
      townhouse: 'Reihenhaus',
      semi_detached_house: 'Doppelhaushaelfte',
    },
    basement: {
      name: 'Ist das Haus unterkellert?',
      full_basement: 'Vollunterkellert',
      partially_basement: 'Teilunterkellert',
      no_basement: 'Kein Keller',
    },
    condition_of_house: {
      name: 'Zustand des Hauses',
      first_occupancy: 'Erstbezug',
      fully_renovated: 'Vollsaniert',
      partly_renovated: 'Teilsaniert',
      renovation_needed: 'Sanierungsbedürftig ',
    },
    number_of_toilets: 'Wie viele WCs hat das Haus?',
    number_of_bathrooms: 'Wie viele Bäder hat das Haus?',
    year_of_renovation: 'Jahr der letzen Renovierung',
    parking: {
      name: 'Parkmöglichkeit',
      garage: 'Garage',
      carport: 'Carport',
      outdoor_parking_space: 'Außenstellplatz',
      none: 'Keine',
    },
    heating_system: {
      name: 'Heizung',
      district: 'Fernwärme',
      gas: 'Gasheizung',
      oil: 'Ölheizung',
      pellet: 'Pelletsheizung',
      heat_pump: 'Wärmepumpe',
      wood_or_coal: 'Stückholz- oder Kohleheizung ',
      electric: 'Stromheizung',
    },
    heating_system_for_renovation: {
      name: 'Welches Heizsystem möchten Sie in Zukunft verwenden?',
      district: 'Fernwärme',
      gas: 'Gasheizung',
      pellet: 'Pelletsheizung',
      heat_pump: 'Wärmepumpe',
    },
    roof_renewal: {
      name: 'Welche Dachform hat das Haus?',
      gable: 'Satteldach',
      flat: 'Flachdach',
      hipped: 'Walmdach',
      gable_description: 'Kalkuliert wird die Fläche eines durchschnittlich steilen Satteldachs ohne Gaube.',
      flat_description: 'Kalkuliert wird die Fläche eines Flachdachs mit geringem Überstand.',
      hipped_description: 'Kalkuliert wird die Fläche eines durchschnittlich steilen Walmdachs ohne Gaube.',
      area: 'Geschätzte Dachfläche (m²) - Sie können den Wert anpassen.',
    },
    facade_insulation: {
      type: {
        name: 'Welche Anbausituation liegt vor?',
        single_house: 'Freistehend',
        double_house: 'Doppelhaus',
        middle_house: 'Mittelhaus',
        single_house_description: 'Alle Seiten des Hauses müssen gedämmt werden.',
        double_house_description: 'Nur 3 Seiten des Hauses müssen gedämmt werden',
        middle_house_description: 'Nur 2 Seiten des Hauses müssen gedämmt werden',
      },
      area: 'Geschätzte Fassadenfläche (m²) - Sie können den Wert anpassen.',
    },
    insulation_top_ceiling_area: 'Fläche der obersten Geschossdecke in m²',
    insulation_basement_ceiling_area: 'Fläche der zu dämmenden Kellerdecke in m²',
    number_of_interior_doors_for_renovation: 'Wie viele Innentüren sollen ersetzt werden?',
    renovations_or_additional_investments: 'Sind Renovierungen bzw. Zusatzinvestitionen geplant?',
    additional_options: 'Zusätzliche Optionen',
    sanitary_renovation_area: 'Wie viel m² haben die neuen Sanitärbereiche insgesamt?',
    number_of_bathrooms_for_renovation: 'Wie viele Badezimmer soll es geben?',
    number_of_toilets_for_renovation: 'Wie viele WCs soll es geben?',
    new_flooring_area: 'Wie viele m² sollen erneuert werden?',
    renovation_walls_and_interior_doors_area: 'Wie viele m² sollen erneuert werden?',
    new_windows_number: 'Wie viele Fenster werden getauscht?',
    number_of_lift_and_slide_doors: 'Wie viele Hebeschiebetüren soll es geben?',
    solar_power_size_kwp: 'Wie groß soll die Anlage sein? (kWp)',
    number_of_extra_large_windows: 'Wie viele extra-große Fenster (>3m²) bzw. Balkontüren werden getauscht?',
  },
  project_wizard: {
    edit_values_btn: 'Werte ändern',
    first_step: {
      title: 'Einfach ausprobieren...',
      property_tools: 'Unsere Services rund um Ihre Immobilie',
      property_tools_description:
        'Der Wohnrechner unterstützt Sie bei der der Ermittlung und Planung der Kosten Ihres individuellen Wohnbauprojektes. Darüber hinaus bieten wir allen Eigentümern eines Einfamilienhauses die Möglichkeit den CO₂-Fußabdruck, das voraussichtliche Einsparungspotential, sowie etwaige Renovierungskosten zu ermitteln.',
      finance_tools: 'Finanzierungstools',
      finance_tools_description: 'Bekommen Sie rasch einen Überblick über Ihre finanziellen Möglichkeiten!',
      alt: {
        renovation_house: 'Grafik Sanierung Einfamilienhaus (Inklusive CO2 - Bilanz)',
        buying_house: 'Grafik Einfamilienhaus (inklusive Sanierung)',
        new_building: 'Grafik Neubau Einfamilienhaus',
        new_apartment: 'Grafik Eigentumswohnung',
      },
    },
    project_name: 'Geben Sie dem Projekt einen Namen',
    project_type: 'Bitte Projekttyp auswählen',
    project_type_hint: 'Aktuell haben wir nur diesen Projekttyp, aber bald wird es zusätzliche geben!',
    new_building: 'Neubau Einfamilienhaus',
    new_apartment: 'Kauf Eigentumswohnung',
    buying_house: 'Kauf Einfamilienhaus (inkl. Sanierung)',
    renovation_house: 'Sanierung Einfamilienhaus (inkl. CO₂-Bilanz)',
    land_estimation: 'Der Verkehrswert des angegebenen Grundstücks (Widmung: Bauland Wohngebiet) beträgt in etwa:',
    land_estimation_per_square_meter: 'bzw. {value} €/m²',
    land_estimation_note: '(dieser Betrag wird zu Ihren Eigenmitteln hinzugerechnet)',
    budget: 'Ungefähre Eigenmittel für das Projekt (€)',
    estimated_property_price: 'Geschätzter Marktwert Ihres Grundstücks (€)',
    total_own_funds: 'Eigenmittel gesamt (€)',
    country: 'Land',
    austria: 'Österreich',
    zip_code: 'Postleitzahl',
    full_address: 'Vollständige Adresse (Straße, Hausnummer, Ort)',
    second_step: 'Schritt 2 (von 4)',
    second_step_title: 'Beschreiben Sie das Grundstück auf dem Sie bauen möchten:',
    land_area: 'Grundstücksfläche in m²',
    additional_information: 'Zusatz Informationen',
    old_building_demolition: 'Abriss eines alten Gebäudes',
    area_of_old_building: 'm² des Abrissgebäudes',
    hillside_location: 'Hanglage',
    limited_access: 'Zufahrt für LKW eingeschränkt',
    special_underground: 'Spezieller Untergrund',
    underground_type: 'Art des Untergrunds',
    groundwater_level_high: 'Grundwasserspiegel hoch',
    rocky: 'felsig',
    marshy: 'sumpfig',
    third_step: 'Schritt 3 (von 4)',
    third_step_title: 'Welches Haus möchten Sie bauen?',
    final_step: 'Letzter Schritt',
    final_step_title: 'Welches Ausstattungs- bzw. Ausführungsniveau soll Ihr Projekt haben?',
    final_step_subtitle:
      'Bitte beachten Sie, dass v.a. die Auswahl (v.a der Option <b>Premium</b>) einen großen Einfluss auf die Projektkosten hat. \nIm Rahmen der Detailplanung kann aber die Auswahl für jeden Teilbereich geändert werden.',
    finish_project_wizard_button: 'Fertigstellen',
    equipment_type: 'Ausstattungskategorie',
    living_space: 'Wohnfläche in m²',
    number_of_floors: 'Anzahl der Geschoße',
    number_of_floors_1: '1 Stockwerk',
    number_of_floors_2: '2 Stockwerke',
    number_of_floors_3: '3 Stockwerke',
    number_of_floors_hint: 'Bitte beachten Sie, dass bei "Anzahl der Geschoße" ein Keller nicht zu berücksichtigen ist',
    number_of_floors_values: {
      '1': '1',
      '2': '2',
      '3': '3',
    },
    options: 'Optionen',
    controlled_domestic_ventilation: 'Kontrollierte Wohnraumlüftung',
    number_of_car_ports: 'Stellplätze Carport',
    number_of_garage_place: 'Stellplätze Garage',
    interior_equipment: 'Inneneinrichtung',
    pool: 'Naturteich/Pool (eingegraben)',
    fence: 'Gartenzaun',
    garden_design: 'Gartengestaltung',
    terrace: 'Terrasse',
    winter_garden: 'Wintergarten',
    more_info: 'Mehr Informationen',
    first_step_summary_title: 'Basisinformationen',
    second_step_summary_title: 'Grundstück',
    third_step_summary_title: 'Gebäude und Optionen',
    final_step_summary_title: 'Ausstattungs- und Ausführungsniveau',
    equipment_type_update_prompt_title: 'Achtung',
    equipment_type_update_prompt_message:
      'Eine Änderung Einfluss auf die Kosten in allen Teilbereichen, wo noch keine Kosten manuell geplant wurden!',
    equipment_type_update_prompt_confirm_button_label: 'Ok',
    equipment_type_update_prompt_cancel_button_label: 'Abbrechen',
    equipment_type_content: {
      standard_title: 'Standard',
      standard_description:
        'Unter Standard verstehen wir Häuser mit einer schlanken, funktionalen Architektur und einer preiswerten Ausführung. Dazu gehören u.a. Aussenwände mit Wärmedämmung, Innenwände mit Rigips, Laminatböden, Innentüren dekorbeschichtet, Kunststofffenster ohne Beschattung etc.',
      premium_title: 'Gehoben',
      premium_description:
        'Gehoben bedeutet eine individuellere Architektur sowie hochwertigere Materialien in allen Bereichen. Dazu gehören u.a. Aussenwände mit 50cm Planziegel, Innenwände massiv, Parkettböden, lackierte Innentüren, Kunststoff/Alufenster mit teilweiser Beschattung und Insektenschutz, Balkon etc.',
      superior_title: 'Premium',
      superior_description:
        'Premium bedeutet eine eindrucksvolle bzw. höchst individuelle Architektur sowie höchste Qualität der Materialien. Dazu gehören u.a. Holz/Alufenster inklusive Hebeschiebetüren mit Beschattung und Insektenschutz, architektonische Details wie Fassadenplatten, große Glasfronten, Innentüren funiert, Böden teilweise aus Natursteinen, moderene Elektronik mit BUS-System etc.',
    },
    has_property: 'Besitzen Sie bereits ein Baugrundstück?',
    prefabricated: 'Fertigteilhausfirma',
    architect: 'Architekt',
    builder: 'Baumeister',
    house_type_title: 'Ihre Hausplanung übernimmt ein/e:',
    city: 'Ort',
    street: 'Straße',
    building: 'Gebäude',
    avg_property_price_sq_m: 'Durchschnittliche Kosten per m² in ihrer Region (€)',
    estimate_final_step_title: 'Welche Optionen sollen inkludiert werden?',
    next_button: 'Nächster Schritt',
    sole_plate_or_basement: 'Bodenplatte oder Keller',
    basement: 'Keller',
    sole_plate: 'Bodenplatte',
    has_additional_features: 'Sind Sanierungen oder zusätzliche Investments geplant?',
  },
  detailed_planning_wizard: {
    title: 'Detailplanung',
    house_information_title: 'Informationen über ihr neues Haus',
    floors: 'Anzahl der Geschosse',
    living_space_by_floor: {
      0: 'Wohnfläche – Erdgeschoss (m²)',
      1: 'Wohnfläche – 1. Stock (m²)',
      2: 'Wohnfläche – 2. Stock (m²)',
    },
    equipment_type: 'Ausstattungskategorie',
    total_living_space: 'Wohnfläche gesamt:',
    terrace_area: 'Terrassenfläche (m²)',
    sole_plate_or_basement: 'Bodenplatte oder Keller',
    basement: 'Keller',
    basement_description:
      'Der Preis für den Keller inkludiert die Baumeisterarbeiten, Estrich, Türen, Fenster und Elektroinstallationen.',
    sole_plate: 'Bodenplatte',
    sole_plate_description: 'Der Preis umfasst eine gedämmte Bodenplatte mit Frostschürze',
    basement_area: 'Keller (m²)',
    sole_plate_area: 'Bodenplatte (m²)',
    direction: {
      north: 'Nord',
      south: 'Süd',
      east: 'Ost',
      west: 'West',
    },
    living_room_direction: 'Ausrichtung Wohnzimmer',
    bathrooms: 'Anzahl der Badezimmer',
    shower: 'Dusche',
    bathtub: 'Badewanne',
    heating_system_type: 'Heizmedium',
    heating_system_types: {
      gas: 'Gas',
      solid_fuel: 'Festbrennstoffe',
      district_heating: 'Fernwärme',
      heat_pump: 'Wärmepumpe',
      electric: 'Stromheizung',
      other: 'Sonstige',
    },
    solar_power_system: 'Photovoltaik',
    solar_power_system_description:
      'Eine PV-Anlage macht ein Stück weit unabhängig. Oft gibt es dafür auch öffentliche Förderungen - informieren Sie sich!',
    solar_power_size_kwp: 'Wie groß soll die Anlage sein? (kWp)',
    solar_power_size_kwp_description:
      'Beachten Sie, dass für eine Nennleistung von 1 kWp ("Kilowatt peak") eine Dachfläche von ca. 7m² benötigt wird.',
    solar_power_equipment_type: {
      name: 'Welche Ausstattungskategorie soll Ihre PV-Anlage haben',
      standard_description:
        'Eine <b>Standard</b> PV-Anlage wird auf das Dach montiert. Der daraus gewonnene Strom wird direkt in das Stromnetz eingespeist.',
      premium_description:
        'Bei einer <b>gehobenen</b> PV-Anlage wird von einem zusätzlichen Batteriepuffer und einem App-basierten Energiemanagementsystem ausgegangen. Eine höhere Eigennutzung der produzierten Energie ist das Ergebnis. Zusätzlich werden die PV-Module direkt in das Dach integriert, wodurch sich diese Variante auch optisch zum <b>Standard</b> abhebt.',
      superior_description:
        'Bei einer <b>Premium</b> PV-Anlage spricht man beispielsweise von Solar-Dachziegeln, die eine besonders ästhetische Optik ermöglichen. Teilweise entstehende Mehrkosten werden hierbei durch geringere Kosten bei der Dacheindeckung kompensiert.',
    },
    cost_drivers: 'Kostentreiber',
    hillside_location: 'Hanglage',
    demolition_costs: 'Abrisskosten',
    demolition_area: 'm² des abzureißenden Gebäudes',
    personal_contribution: 'Meine Eigenleistung ist:',
    personal_contribution_header: 'Meine Eigenleistung',
    personal_contribution_description:
      'Sie sind ein versierter Heimwerker, haben genug Zeit und/oder geschickte Freunde/Verwandte, die mithelfen? Dann können Sie die Projektkosten deutlich reduzieren, sollten jedoch auch bedenken, dass Sie einen Teil Ihrer Freizeit dafür aufwenden müssen.',
    personal_contribution_options: {
      none: 'Keine Mithilfe',
      low: 'Gering - ca. 7%',
      moderate: 'Mittel - ca. 12%',
      high: 'Viel - ca. 20%',
    },
    personal_contribution_note:
      'Hinweis: Ihre Projektkosten werden damit reduziert! \n Gering - ca. 7% (Wände ausmalen, Boden legen etc.) \n Mittel - ca. 12% (Umfassende Mithilfe bei Installationen, Innenausbau, etc.) \n Viel - ca. 20% (intensive Mitarbeit bei den meisten Gewerken inklusive Rohbau)',
    additional_options: 'Zusätzliche Optionen',
    number_of_car_ports: 'Stellplätze Carport',
    number_of_garage_place: 'Stellplätze Garage',
  },
  equipment_types: {
    superior: 'Premium***',
    premium: 'Gehoben**',
    standard: 'Standard*',
    mixed: 'unterschiedliche',
  },
  project_plan: {
    equipment_type: 'Ausstattungskategorie',
    total_costs: 'Gesamtkosten',
    personal_contribution:
      'Einsparungspotential aufgrund der angegebenen Eigenleistung ist in den Gesamtkosten bereits berücksichtigt',
    complete_previous_step: `Sie haben noch keine Planung durchgeführt`,
    complete_previous_step_btn: 'Projekt berechnen',
    btn_to_budget_calculation: 'Weiter zum Lifestyleplaner',
  },
  planning_categories: {
    no_planning_categories: 'Sie haben keine Planungskategorien',
    financing: 'Finanzierung',
    planning_and_construction_management: 'Planung & Bauleitung',
    property_costs: 'Grundstückskosten',
    charges_and_taxes: 'Gebühren & Steuern',
    development_costs: 'Erschließungskosten',
    demolition_costs: 'Abrisskosten',
    tooltip_edit_button: 'Hier können Sie ihren Projektplan individuell anpassen',
    tooltip_download_button: 'Sie können Ihre Projektkostenschätzung hier als PDF herunterladen',
    tooltip_settings_button: 'Sie können hier die nochmals die Projekdetails anpassen',
    sole_plate_or_basement: 'Bodenplatte oder Keller',
    shell_construction: 'Rohbau',
    windows_and_doors: 'Fenster & Türen',
    sanitary: 'Sanitär',
    electrical_installation: 'Elektro',
    heating_system: 'Heizung',
    interior_construction: 'Innenausbau',
    interior_equipment: 'Inneneinrichtung',
    terrace: 'Terrasse',
    winter_garden: 'Wintergarten',
    pool: 'Pool (massiv, eingegraben)',
    garden_landscaping: 'Gartengestaltung',
    fence: 'Gartenzaun/Mauer',
    carport: 'Carport',
    garage: 'Garage',
    hillside_location: 'Hanglage',
    limited_access: 'Eingeschränkte Zufahrt',
    special_underground: 'Schwieriger Untergrund',
    reserves_for_unexpected_costs: 'Reserve für ungeplante Zusatzkosten',
    reserves_for_unexpected_costs_description:
      'Insbesondere bei Sanierungen empfehlen wir eine Finanzreserve von mindestens 10% für unvorhergesehene Zusatzaufwände.',
    planning_and_construction_management_description: 'Architektur-, Planungs- und Bauleitungskosten',
    property_costs_description: 'Eingabe der Grundstückskosten',
    charges_and_taxes_description: 'Anfallende Gebühren und Abgaben',
    development_costs_description: 'Auffschließungskosten',
    demolition_costs_description: 'Abrisskosten für ein bestehendes Gebäude',
    sole_plate_or_basement_description: {
      basement: 'Preis inkludiert den Kellerrohbau, Estrich, Türen und Fenster sowie Elektrik.',
      sole_plate: 'Preis inkludiert auch Rollierung und Wärmedämmung',
    },
    sole_plate_description: 'Planung einer Bodenplatte ersetzt den Keller',
    shell_construction_description:
      'Inkludiert Erdarbeiten, Kanal, Maurerarbeiten, Rauchfang, Dachstuhl, Dach, und Vollwärmeschutzfassade',
    windows_and_doors_description: 'Inkludiert Fenster, Terassentüren, Eingangstüre, Innentüren',
    sanitary_description: 'Inkludiert Sanitärinstallation und Sanitärgeräte',
    electrical_installation_description: 'Inkludiert Leerverrohrung, Verdrahtung und Fertigstellung',
    heating_system_description: 'Inkludiert Heizkessel, Heizungsverrohrung sowie Heizkörper bzw. Fussbodenheizung',
    interior_construction_description:
      'Inkludiert Innenverputz, Estrich, Bodenbeläge, Fliesen, Trockenbau und Malerarbeiten',
    interior_equipment_description:
      'Inkludiert Küche, Möblierung aller Räume, Beleuchtung, Elektrogeräte und Alarmanlage inklusive Montage',
    terrace_description:
      'Inkludiert Kosten für die Terrasse mit Fliesen, je nach Kategorie ohne bzw. mit Sonnenschutz/Überdachung/Zusatzausstattung',
    winter_garden_description:
      'Inkludiert Wintergarten, Bodenbelag, je nach Kategorie beheizt und mit Zusatzausstattung ',
    pool_description:
      'Massiver Pool inklusive Erdarbeiten, je nach Kategorie zusätzlich mit Überdachung/Zusatzausstattung',
    garden_landscaping_description: 'Inkludiert Rasen, Pflanzen und Wege',
    fence_description: 'Inkludiert Sockel, Zaun, Türe und Tor',
    carport_description: 'Inkludiert massive Überdachung',
    garage_description: 'Inkludiert massive Garage mit elektrischem Torantrieb',
    electrical_installation_hint:
      'This calculation is based on the commonly required Energy-Check, the costs of planning your house (Application-Plan and Work-plan), and local construction supervision. You could save a lot of money if you are able to do the local supervision yourself.',
    domestic_ventilation: 'Kontrollierte Wohnraumlüftung',
    domestic_ventilation_description: 'Elektronisch gesteuerte, zentrale Lüftungsanlage für das gesamte Haus.',
    bus_system: 'Bussystem, Smart-Home',
    bus_system_description:
      'Ein Bussystem statt der herkömmlichen Elektroinstallation erlaubt die zentrale Programmierung und Steuerung aller Schalter und Steckdosen',
    solar_heating: 'Solarthermie',
    solar_heating_description: 'Die Solaranlage dient der Warmwasseraufbereitung und speichert dieses in einem Boiler.',
    carport_equipment_type_description:
      'Die <b>Standardvariante</b> beinhaltet eine einfache Konstruktion. Bei der <b>gehobenen Variante</b> ist eine massive Ausführung inklusive Erweiterungen (z.B. Platz für Mülltonnen, Fahrräder etc.) berücksichtigt. Die <b>Premiumvariante</b> sticht durch ihre Optik hervor (z.B. Beleuchtung, Pflaster, etc.).',
    garage_equipment_type_description:
      'Die <b>Standardgarage</b> ist ca. 6m lang und je Stellplatz ca. 3m breit und günstig ausgeführt. Die <b>gehobene Variante</b> bietet zusätzliche Bewegungsfreiheit und Platz für Fahrräder. Die <b>Premiumvariante</b> sticht durch ihre Optik hervor (z.B. Beleuchtung, Garagentor, etc.).',
    solar_power_system_description:
      'Eine PV-Anlage macht ein Stück weit unabhängig. Oft gibt es dafür auch öffentliche Förderungen - informieren Sie sich!',
    tile_stove: 'Kachelofen',
    tile_stove_description: 'Gemauerter Kachelofen',
    solar_power_system: 'Photovoltaik Anlage',
    interior_equipment_type_description: ' ',
    winter_garden_equipment_type_description: ' ',
    pool_equipment_type_description: ' ',
    garden_landscaping_equipment_type_description: ' ',
    fence_landscaping_equipment_type_description: ' ',
    tile_stove_equipment_type_description: ' ',
    domestic_ventilation_equipment_type_description: ' ',
    bus_system_equipment_type_description: ' ',
    solar_heating_equipment_type_description: ' ',
    sole_plate_or_basement_form: {
      basement: 'Keller',
      sole_plate: 'Bodenplatte',
    },
    wallbox_e_mobility: 'Ladestation E-Auto - Wallbox',
    wallbox_e_mobility_description:
      'Um den Neubau zukunftssicher zu gestalten, sollte eine Wallbox immer in der Planung Berücksichtigung finden.',
    wallbox_e_mobility_equipment_type_description:
      'Es wird eine hochwertige Wallbox mit Installation durch einen Fachelektriker geplant. Voraussetzung für eine leistungsfähige Wallbox ist eine Verkabelung mit Querschnitt von 5 x 4mm² damit eine Ladeleistung von 11kW und mehr möglich ist. In den höheren Kategorien wird eine hochleistungsfähige Verkabelung, und eine Wallbox mit Integrationsmöglichkeit in eine PV-Anlage kalkuliert.',
    other_investments: 'Sonstige Investionen',
    other_investments_description:
      'Sonstige Ausgaben im Zusammenhang mit Ihrem Sanierungsvorhaben können Sie an dieser Stelle individuell erfassen.',
  },

  planning: {
    other_investments_amount: 'Sonstige Kosten',
    other_investments_comment: 'Erläuterung',
    other_investments_subitem: ' ',
    override_cost: 'Kosten ändern, €',
    calculated_button: 'Schätzung',
    quoted_button: 'Angebotspreis',
    sole_plate_or_basement: {
      basement: 'Keller',
      sole_plate: 'Bodenplatte',
    },
    sole_plate_or_basement_form: {
      select_label: '',
      basement: 'Keller',
      sole_plate: 'Bodenplatte',
    },
    plan_overview: 'Sichern',
    earthworks: 'Erdarbeiten',
    channel: 'Kanalisierungsarbeiten',
    masonry: 'Maurer-, Beton-, bzw. Stahlbetonarbeiten',
    chimney: 'Kamin',
    carpentry: 'Zimmererarbeiten',
    roofing: 'Dachdecker- und Spenglerarbeiten',
    facade: 'Fassade inklusive Wärmeschutz',
    metal: 'Schlosserarbeiten',
    plumbing: 'Sanitärinstallation',
    sanitary_equipment: 'Sanitärgeräte',
    heating_installation: 'Heizungsverrohrung',
    radiator: 'Heizkörper bzw. Fußbodenheizung',
    boiler: 'Heizkessel bzw. Wärmepumpe',
    electro_cable_ducts: 'Leerverrohrung Elektrik',
    electro_wiring: 'Verdrahtung',
    electro_finishing: 'Fertigstellung (Schalter, Steckdosen etc.)',
    windows_patio_balcony: 'Fenster, Terrassen- und Eingangstüre',
    front_door: 'Eingangstüre',
    interior_plastering: 'Innenverputz',
    floor_construction: 'Estrich, Fußbodenaufbau',
    drywall_construction: 'Trockenbau',
    tiling: 'Fliesenlegerarbeiten',
    interior_doors_doorstays: 'Innentüren komplett',
    interior_doors_door_panels: 'Türblätter und Beschläge',
    flooring: 'Bodenbeläge',
    painting_work: 'Maler- und Tapeziererarbeiten',
    energy_check: 'Energie Check',
    price_of_property: 'Grundstückspreis',
    development_costs: 'Aufschließungskosten',
    charges_and_taxes: 'Gebühren und Steuern',
    demolition_costs: 'Abrisskosten',
    demolition_area: 'm² des abzureißenden Gebäudes',
    interior_equipment: 'Inneneinrichtung',
    controlled_domestic_ventilation: 'Kontrollierte Wohnraumlüftung',
    pool: 'Pool',
    garden_design: 'Gartengestaltung',
    fence: 'Gartenzaun',
    carport: 'Carport',
    number_of_car_ports: 'Stellplätze Carport',
    number_of_garage_place: 'Stellplätze Garage',
    garage: 'Garage',
    terrace: 'Terrasse',
    winter_garden: 'Wintergarten',
    hillside_location: 'Hanglage',
    limited_access: 'Eingeschränkte Zufahrt',
    special_underground: 'Schwieriger Untergrund',
    special_underground_form: {
      underground_type: 'Art des Untergrunds',
      underground_type_options: {
        groundwater_level_high: 'Grundwasserspiegel hoch',
        rocky: 'felsig',
        marshy: 'sumpfig',
      },
    },
    cost_estimation: 'Entwurfsplanung & Kostenschätzung',
    permit_planning: 'Einreichplan erstellen',
    execution_planning: 'Ausführungsplanung ',
    bill_of_quantity: 'Leistungsverzeichnis & Angebote',
    construction_management: 'Bauleitung',
    bus_system: 'Bussystem, Smart-Home',
    tile_stove: 'Kachelofen',
    solar_power_system: 'Photovolatikanlage',
    solar_heating: 'Solarthermie',
    taxes_and_notary_expenses: 'Gebühren & Steuern',
  },

  feature_groups: {
    options: 'Optionen',
    main: 'Standard',
    additional_costs: 'Zusätzliche Kosten',
    cost_drivers: 'Kostentreiber',
  },

  page_titles: {
    express_loan: 'Wohnkredit von Zuhause aus vorbereiten',
    my_projects: 'Alle Projekte',
    dashboard: 'Mein aktuelles Projekt',
    project_info: 'Projektdetails',
    options: 'Optionen',
    budget: 'Haushaltsplan',
    contact: 'Kontakt',
    logout: 'Abmelden',
    profile: 'Benutzerprofil',
    my_budget: 'Mein Haushaltsplan',
    plan: 'Projektkosten planen',
    forgot_password: 'Haben Sie ihr Passwort vergessen',
    reset_password: 'Passwort rücksetzen',
    loan: 'Finanzierung',
    edit_budget: 'Monatliche Ausgaben anpassen',
    sign_up: 'Österreichs innovativster Wohnrechner',
    login: 'Anmelden',
    invite_user: 'Kunden einladen',
    invitations: 'Einladungen',
    property_valuation: 'Immobilienbewertung',
    debug_mode: 'Debug mode',
  },

  invite_user: {
    loan_consultant: 'Kreditberater',
    add_user_btn: 'Kunden einladen',
    successfully_sent: 'Eine Mail mit Einladung wurde versendet',
    message: 'Hier können Sie noch eine ergänzende, individuelle Nachricht für den Kunden schreiben (optional)',
    transfer_project_with_budget: 'Möchten Sie auch die Haushaltsrechnung transferieren?',
    transfer_budget_description:
      'Wenn Sie diese Checkbox auswählen, wird eine allenfalls bereits vorhandene Haushaltsrechnung bei Ihrem Kunden überschrieben!',
  },
  invitations: {
    download_btn: 'Download Excel',
    refresh_data_btn: 'Daten aktualisieren',
    no_invitations: 'Noch keine Einladungen',
    no_projects: 'Es gibt noch keine Projekte',
    email: 'Email',
    name: 'Name',
    date_of_invitation: 'Datum der Einladung',
    date_of_last_login: 'Datum letztes Login',
    temp_password_changed: 'Password geändert',
    number_of_projects: 'Gesamtanzahl der Projekte',
    loan_consultant_email: 'Berater Mail',
    loan_consultant_name: 'Berater Name',
    inviter_email: 'Mailadresse Einlader (email)',
    inviter_name: 'Eingeladen  (name)',
    resend_temp_password: 'Initial-Passwort nochmals versenden',
    delete_user_account: 'Diesen User löschen',
    search_label: 'Suche nach Vorname, Nachname bzw. Mailadresse',
    projects: 'Projekte',
    budget: 'Haushaltsplan PDF',
    refreshed_data: 'Daten aktualisieren',
    user_successfully_deleted: 'User wurde gelöscht',
    successfully_sent: 'Initial-Passwort wurde neu versendet',
    delete_account: {
      title: 'User löschen',
      message: 'Sind Sie sicher, dass Sie der User von {clientInfo} gelöscht werden soll?',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
    budget_pdf: 'Haushaltsplan PDF',
    project: {
      name: 'Projektname',
      type: 'Typ',
      pdf: 'PDF',
      project_details: 'Projektdetails geplant',
      plan_adjusted: 'Detailplanung durchgeführt',
      budget_planning: 'Haushaltsplan fertiggestellt',
      loan: 'Finanzierung geplant',
      loan_offer: 'Angebot eingeholt',
      transferred_by_name: 'Transferiert von (name)',
      transferred_by_email: 'Transferiert von (email)',
    },
  },

  user: {
    first_name: 'Vorname',
    last_name: 'Nachname',
    email: 'Email',
  },

  loan: {
    overview: {
      title: 'Kostenübersicht',
      budget: 'Eigenmittel:',
      total: 'Gesamt:',
      shortage: 'Finanzierungsbedarf:',
    },
    project_price_zero:
      'Die geschätzten Kosten Ihres Projekts sind 0€ - Sie können daher hier nicht weitermachen, bitte beginnen Sie zunächst mit der Planung',
    start_planning: 'Planung starten',
    interest_type: {
      variable: 'Variabel',
      fixed: 'Fix',
    },
    personal_financing_request: {
      title: 'Beratung anfordern',
      prefer_contact_by: 'Wie möchte ich gerne kontaktiert werden',
      prefer_contact_by_email: 'per Mail',
      prefer_contact_by_phone: 'telefonisch',
      phone_number: 'Meine Telefonnummer',
      reachability_on_phone: 'Telefonische Erreichbarkeit',
      message: 'Möchten Sie uns noch etwas mitteilen, das für das Finanzierungsangebot hilfreich sein könnte?',
      description: '(z.B. zusätzliche Eigenmittel, zukünftige Einkommensveränderungen, Link zum Immobilieninserat)',
      before: 'Vor',
      after: 'oder nach',
      oclock: 'Uhr ',
      day: 'Tag',
      month: 'Monat',
      year: 'Jahr',
      desired_branch: {
        label: 'Meine gewünschte Filiale',
        vienna_label: 'Wien',
        lower_austria_label: 'Niederösterreich',
      },
    },
    own_funds_ratio_not_successful:
      'Ihr Eigenmittelanteil ({own_funds_ratio}) könnte eine Finanzierung schwierig machen. Gerne beraten wir Sie jedoch und suchen eine Lösung für Ihren Wohntraum!',
    max_loan_rate_ratio_not_successful:
      'Die erforderliche Rate übersteigt {max_loan_rate_ratio} Ihres monatlichen Einkommens. Ein Wohnberater sieht sich Ihren Fall jedoch gerne an und prüft Ihre Eingaben. Fordern Sie ein Angebot an!',
    estimated_property_price: 'Geschätzter Grundstückswert',
    already_requested:
      'Sie haben bereits eine Anfrage abgeschickt, Ihre Anfrage wird vom Team der Hypo NOE beantwortet!',
    request_sent: 'Ihre Anfrage wurde erfolgreich gesendet!',
    tooltip_settings_button: 'Sie können hier die Finanzierungsdetails anpassen',
    total_project_costs: 'Gesamtprojektkosten',
    loan_costs: 'Einmalige Kreditnebenkosten',
    affordable_loan: 'Leistbarer Kreditbetrag',
    budget_surplus: 'Berechneter Haushaltsüberschuß',
    financing_gap: 'Finanzierungslücke',
    financing_reserve: 'Finanzierungsreserve',
    monthly_deficit: 'Monatlicher Fehlbetrag',
    monthly_reserve: 'Monatliche Sicherheitsreserve',
    financing_deficit_message:
      '<p>Die Gesamtprojektkosten übersteigen derzeit Ihren finanziellen Rahmen.</p> <p>Gerne beraten wir Sie bei der Finanzplanung Ihres Wohnprojekts.</p>',
    financing_deficit_button: 'Persönliche Beratung anfordern',
    financing_reserve_message:
      '<p>Herzlichen Glückwunsch!</p> <p>Ihr geplantes Projekt ist mit Ihren finanziellen Möglichkeiten wahrscheinlich umsetzbar! (Ihr Eigenmittelanteil beträgt {own_funds_ratio} und die monatliche Rate beträgt {loan_rate_ratio} Ihres Einkommen)</p> <p>Fordern Sie jetzt Ihr persönliches Angebot an!</p>',
    financing_reserve_button: 'Persönliches Angebot anfordern',
    loan_input: 'Angaben zum Kredit',
    loan_details: 'Kreditdetails',
    title: 'Kredit für das Projekt "{value}"',
    price: 'Gesamtprojektkosten (€)',
    project_link_description: 'Entsprechend ihrem Projekt:',
    budget: 'Eigenmittel',
    period: 'Kreditlaufzeit in Jahren',
    interest_terms: 'Zinskonditionen',
    fixed_interest_period: 'Fixzinsperiode',
    date: 'Bis wann möchten Sie den Kredit',
    amount: 'Gewünschter Kreditbetrag',
    own_funds_cash: 'Eigenmittel',
    own_funds_value_of_property: 'Eigenmittel des vorhandenen Grundstücks',
    total_own_funds: 'Eigenmittel gesamt',
    required_loan: 'Benötigter Kreditbetrag',
    loan_end_date: 'Ich möchte meinen Kredit bis zum',
    loan_term: 'Ich möchte eine Laufzeit von',
    term_in_years: '{period} Jahren',
    amount_with_loan_costs: 'Kreditbetrag inkl. Nebenkosten',
    interest_rate: 'Zu erwartende Zinsbandbreite (abhängig von Bonität und Besicherung)',
    monthly_rate: 'Ihre erste Rate beträgt',
    fixed_interest_rate: 'Ich möchte eine Fixzinsperiode von:',
    variable_interest_rate: 'Ich möchte eine variable Rate basierend auf dem 6-Monats EURIBOR',
    no_need: 'Wie es aussieht haben Sie für ihr Projekt genügend Eigenmittel und brauchen keinen Kredit - Glückwunsch!',
    edit: 'Bearbeiten',
    complete_previous_step:
      'Sie müssen zunächst die Haushaltsplanung abschließen, bevor Sie den Schritt "Finanzierung" beginnen können!',
    complete_previous_step_btn: 'Haushaltsbudget berrechnen',
    rate_title: 'Wie berechnet sich meine Rate?',
    rate_btn_label: 'Wie wird meine Rate berechnet?',
    how_rate_calculated_first_part:
      '<b>Wie wird die Kreditrate Ihrer Finanzierung berechnet?</b> \n Die Berechnung erfolgt auf <b>Basis Ihrer Eingaben</b> und dient als Orientierungshilfe.\n Die <b>Finanzierungsform</b> ist ein hypothekarisch besicherter Kredit.\n Es kann aus 3 <b>Finanzierungsvarianten</b> gewählt werden: <ul><li>Variable</li><li>Fix</li><li>Fix- und Variabel kombiniert</li></ul> Die <b>Kondition</b> aus der <b>variablen Verzinsung</b> ergibt sich aus der Laufzeit, dem Aufschlag - der bonitäts- und besicherungsabhängig ist - und dem aktuellen 6 Monats-Euribor.\n Anpassung jeweils am 01.01. und 01.07. jeden Jahres. \n Die Kondition der fixen Verzinsung ist ebenfalls laufzeit-, bonitäts- und besicherungsabhängig. \n',
    how_rate_calculated_second_part:
      '<b>Fixzinslaufzeiten</b> können ihren Bedürfnissen angepasst werden: 5, 10, 15 oder 20 Jahre stehen zur Auswahl. \n Die <b>Gesamtlaufzeit</b> der Finanzierung beträgt max. 35 Jahre. \n Das <b>Höchstalter</b> bei Kreditende beträgt 80 Jahre. \n <b>Einmalige Gebühren: \n </b> <ul><li><b>Ausfertigungsgebühr (KSV-Spesen, Grundbuch-Archivierungsgebühr, Grundbuch-Eingabegebühr, Grundbuchsauszug, Beglaubigung Pfandurkunde, Schätzkosten je Liegenschaft, EDV Kosten)</b></li><li><b>Grundbuchseintragungsgebühr</b> (1,2% der im Grundbuch eingetragenen Höchstbetragshypothek)</li></ul> Die an Sie ausbezahlte <b>Kreditsumme</b> wird um die Summe der genannten Gebühren vermindert. \n Zusätzlich zu den einmaligen Gebühren fällt dann noch die <b>Kreditkontoführungsgebühr</b> an, diese wird über die gesamte Kreditlaufzeit quartalsweise verrechnet.',
  },

  budget: {
    edit: {
      complete_budget_step:
        'Sie müssen zuerst die Haushaltsbudgetplanung abschliessen, bevor Sie die Werte anpassen können!',
      complete_budget_step_btn: 'Haushaltsbudget berechnen',
    },
    titles: {
      main: 'Haushaltsplanübersicht',
      personal_situation: 'Meine Lebenssituation',
      monthly_net_income: 'Monatliche Netto-Einkünfte',
      monthly_expenses: 'Monatliche Ausgaben',
      affordable_loan: 'Leistbarer Kredit',
      total_monthly_income: 'Monatliche Gesamteinkünfte (12x pro Jahr)',
      total_monthly_costs: 'Monatliche Gesamtausgaben',
      total_loan_amount: 'Der maximal leistbare Kreditbetrag, basierend auf einer Laufzeit von 30 Jahren, beträgt',
      monthly_repayments: 'Die leistbare, monatliche Rückzahlungsrate beträgt',
    },
    btn_to_financing: 'Weiter zur Finanzierung',
    tooltip_download_button: 'Sie können hier Ihre Haushaltsplanung als PDF herunterladen',
    tooltip_edit_button: 'Hier können Sie die Haushaltsplanung individuell anpassen',
    tooltip_settings_button: 'Hier können Sie die Eingaben für die Haushaltsplanung nochmals ändern',
    monthly_net_salary: 'Mein Einkommen',
    monthly_net_income: 'Mein monatliches Nettoeinkommen beträgt inklusive aliquotem 13./14. Gehalt',
    monthly_net_income_partner: 'Das Nettoeinkommen meines Partners beträgt inklusive aliquotem 13./14. Gehalt',
    monthly_net_salary_partner: 'Das Einkommen meines Partners',
    other_regular_income: 'Sonstige Einkünfte',
    monthly_alimony: 'Unterhaltszahlungen',
    monthly_repayments_loans: 'Rückzahlungen von bestehenden Krediten oder Leasingraten',
    monthly_expenses: 'Basisausgaben für mich',
    monthly_expenses_description: 'Hier sind alle Kosten der täglichen Grundbedürfnisse zusammengefasst',
    monthly_expenses_partner: 'Basisausgaben für meinen Partner',
    monthly_expenses_partner_description: 'Hier sind alle Kosten der täglichen Grundbedürfnisse zusammengefasst',
    monthly_expenses_children: 'Basisausgaben für die Kinder',
    monthly_expenses_children_description: 'Hier sind alle Kosten der täglichen Grundbedürfnisse zusammengefasst',
    operational_costs_home: 'Betriebskosten und Reparaturrücklage',
    operational_costs_home_description:
      'Heizung, Strom, Wasser, Kanal, Müll, Steuern, Versicherung, Reparaturrücklage etc.',
    technology: 'Smartphone, Notebook, TV und Internet',
    technology_description: 'Smartphone, Notebook, Internet, Streamingdienste, etc.',
    luxuries: 'Bekleidung, Beauty und Shopping',
    luxuries_description: 'Moderne Kleidung, Kosmetik, Friseurbesuche, Shopping, etc.',
    socializing: 'Kulinarisches, Gäste, Feste und Geschenke',
    socializing_description: 'Essen gehen, Partys feiern, Freunde einladen, etc.',
    hobbies: 'Hobbys',
    hobbies_description: 'Z.B. Fitnesscenter, Mitgliedsbeiträge, Kurse etc.',
    taking_a_break: 'Urlaube und Reisen',
    taking_a_break_description: 'Flugreisen, Ausflüge, Thermenurlaube, etc.',
    cars: 'Auto(s): {number}',
    cars_description:
      'Hier werden die Gesamtkosten jedes Autos (Wertverlust, Versicherung, Tanken etc.) berücksichtigt. ',
    private_pension_and_insurances: 'Zusatzversicherungen, Pensionsvorsorge, etc.',
    private_pension_and_insurances_description:
      'Zusatzversicherung für Krankheit und Unfall, Rechtsschutz, Altersvorsorge.',
    select_values: {
      not_important: 'Nicht wichtig',
      important: 'Wichtig',
      very_important: 'Sehr wichtig',
    },
    new_car: 'Autos',
    socializing_select_description: 'Essen gehen, Partys feiern, Freunde einladen, etc.',
    luxuries_select_description:
      'Es geht hier um Einkäufe, Dienstleistungen die über das Notwendige hinausgehen, aber vielfach zu einem unbeschwerten Leben beitragen',
    new_car_select_description: 'Ein großes Auto, umfangreiche Zusatzausstattung, etc.',
    taking_a_break_select_description: 'Flugreisen, Ausflüge, Thermenurlaube, etc.',
    hobbies_select_description: 'Fitnesscenter, Kulturveranstaltungen, Mitgliedsbeiträge, etc.',
    technology_select_description: 'Smartphone, Notebook, Internet, Streamingdienste, etc.',
    private_pension_and_insurances_select_description: 'Zusatzversicherungen, Pensionsvorsorge, etc.',
    lifestyle: 'Lebensstil',
    edit_budget_button: 'Haushaltsplan ändern',
  },

  budget_calculator: {
    first_step: {
      show_more_text: 'Mehr erfahren',
      show_less_text: 'Einklappen',
      alone_or_partner: 'Sie leben alleine oder gemeinsam mit einem Partner?',
      partner_option_label: 'Partner',
      alone_option_label: 'Allein',
      kids_quantity: 'Wie viele Kinder leben im Haushalt?',
      kids_quantity_description: ' ',
      age: 'Alter',
      age_description: 'Ihr Alter wird für die Berechnung der Kreditkonditionen benötigt (z.B. Kreditlaufzeit, etc.)',
      slider_unit_years: 'Jahre',
      own_funds: 'Ihre Eigenmittel für das Projekt sind:',
      own_funds_description:
        'Wie hoch sind Ihre Eigenmittel? Hier handelt es sich um z.B. Ersparnisse, Zuwendungen der Familie, geplante Erlöse vom Verkauf einer Immobilie, etc.',
      square_meters: 'Wie groß ist die Wohnfläche des geplanten Hauses / der Wohnung?',
      square_meters_description:
        'Diese Information wird für die Berechnung der durchschnittlichen Betriebskosten benötigt.',
      project_type_finance: {
        label: 'Welche Projektart möchten Sie finanzieren?',
        buying: 'Kauf',
        renovation: 'Sanierung',
        building: 'Neubau',
      },
      agents_help_when_buying: {
        label: 'Kaufen Sie über einen Makler?',
        buy_with_agent: 'Kauf mit Makler',
        buy_without_agent: 'Kauf ohne Makler',
      },
      budget_calculator_description:
        'Unser innovativer Haushaltsrechner unterstützt Sie bei der Erstellung Ihres Haushaltsbudgets. Der Vorteil liegt in der individuellen Festlegung der Relevanz von Kategorien (z.B. wie wichtig ist mir ein Auto, Urlaube und Reisen, etc.). Sobald Ihre Präferenzen festgelegt wurden, finden Sie direkt entsprechende Werte, sollten diese nicht mit Ihrer individuellen Situation übereinstimmen, können Sie diese einfach abändern.',
    },
    second_step: {
      other_regular_revenues: 'Sonstige regelmäßige Einkünfte?',
      other_regular_revenues_description: 'z.B. Familienbeihilfe, Unterhaltszahlungen, Mieteinnahmen etc.',
      monthly_net_salary: 'Wie hoch ist Ihr monatliches Nettoeinkommen?',
      monthly_net_salary_description:
        'Monatliches Nettoeinkommen exkl. Diäten und Kilometergeld, sollten Sie Einkünfte aus selbstständigen Tätigkeiten beziehen, erfassen Sie bitte das Jahresnettoeinkommen dividiert durch 14.',
      monthly_net_salary_partner: 'Wie hoch ist das monatliche Nettoeinkommen ihres Partners?',
    },
    fifth_step: {
      monthly_expenses_subtitle: 'Monatliche Ausgaben',
    },
    sixth_step: {
      affordable_loan_repayments: 'Leistbare Rate',
      monthly_income: 'Monatliche Einkünfte',
      monthly_income_partner: 'Monatliche Einkünfte Partner',
      other_revenues: 'Sontige Einkünfte',
      total_monthly_income: 'Monatliche Gesamteinkünfte (12x pro Jahr)',
      monthly_expenses: 'Summe monatlicher Basisausgaben',
      amount_of_alimony: 'Monatliche Alimente',
      existing_loan: 'Bestehender Kredit',
    },
    final_step: {
      affordable_loan: 'Leistbarer Kredit',
      own_funds: 'Eigenmittel ({ownFundsPercentage}%)',
      available_budget: 'Verfügbares Budget',
      possible_project_costs: 'Projektbudget',
      additional_costs: 'Nebenkosten',
      property_transfer_tax: '3,5% Grunderwerbsteuer',
      land_register_fee: '1,1% Eintragungsgebühr Grundbuch',
      notary_fee: '1,0% Notargebühr',
      broker_commission: '3,6% Maklergebühr',
      request_loan_consulting: 'Kreditberatung anfordern',
      gratitude_title: 'Danke für ihr Vertrauen!',
      gratitude_subtitle: 'Ihre Anfrage wurde an unsere Finanzierungsberater übermittelt!',
      back_to_lifestyle_step: 'Zurück zum Lifestylerechner',
      calculate_project_costs: 'Projektkosten berechnen',
      message_insufficient_own_funds:
        'Achtung: Wir empfehlen einen Eigenmittelanteil von mindestens 20%, der in Ihrem Fall aktuell unterschritten wird. \n \n Unsere Wohnbauexpert:innen stehen Ihnen für ein Beratungsgespräch jederzeit zur Verfügung und besprechen mit Ihnen wie es Ihnen doch noch gelingen könnte diese Anforderung zu erfüllen!',
    },
    first_step_title: 'Ihre Wohnsituation',
    second_step_title: 'Ihr Einkommen',
    third_step_title: 'Kosten',
    fourth_step_title: 'Lebensstil',
    fifth_step_title: 'Ausgabenübersicht',
    sixth_step_title: 'Leistbare monatliche Kreditrate',
    final_step_title: 'Übersicht',
    next_step_button: 'Fortsetzen',
    finish_step_button: 'Berechnen',
    not_important_option_label: 'Nicht so wichtig',
    important_option_label: 'Wichtig',
    very_important_option_label: 'Sehr wichtig',
    how_many_cars: 'Wie viele Autos gibt es in ihrem Haushalt?',
    amount_of_alimony: 'Unterhaltszahlungen',
    monthly_loan:
      'Bestehende Kredite oder Leasingverträge - bitte geben Sie die Summe der monatlichen Rückzahlungen an (€)',
    socializing: 'Kulinarisches, Gäste & Feste ',
    socializing_descriptions: 'Essen gehen, Partys feiern, Freunde einladen, etc.',
    luxuries: 'Bekleidung, Beauty und Shopping',
    luxuries_description: 'Moderne Kleidung, Kosmetik, Friseurbesuche, Shopping, etc.',
    new_car: 'Autos',
    new_car_description: 'Ein großes Auto, umfangreiche Zusatzausstattung, etc.',
    holidays_and_traveling: 'Ausgaben für Urlaube und Reisen',
    hobbies: 'Hobbys',
    hobbies_description: 'Fitnesscenter, Kulturveranstaltungen, Mitgliedsbeiträge, etc.',
    technology: 'Technologie',
    technology_description: 'Smartphone, Notebook, Internet, Streamingdienste, etc.',
    pension_and_insurances: 'Ausgaben für Altersvorsorge und Zusatzversicherungen',
    monthly_expenses: 'Basisausgaben für mich',
    monthly_expenses_result_description: 'Hier sind alle Kosten der täglichen Grundbedürfnisse zusammengefasst',
    monthly_expenses_partner_result_description: 'Hier sind alle Kosten der täglichen Grundbedürfnisse zusammengefasst',
    monthly_expenses_kids_result_description: 'Hier sind alle Kosten der täglichen Grundbedürfnisse zusammengefasst',
    costs_for_repair_result_description:
      'Heizung, Strom, Wasser, Kanal, Müll, Steuern, Versicherung, Reparaturrücklage etc.',
    technology_result_description:
      'Dies betrifft die regelmäßige Neuanschaffung von Geräten wie z.B. alle 3 Jahre ein neues Smartphone, aber auch Ausgaben für Streaming Dienste.',
    luxuries_result_description:
      'Es geht hier großteils um Einkäufe, Dienstleistungen die über das Notwendige hinausgehen, aber vielfach zu einem unbeschwerten Leben beitragen',
    socializing_result_description:
      'Denken Sie hier auch an die Kosten für Mitbringsel & Geschenke, das Essen gehen während der Arbeit etc.',
    taking_a_break_result_description: 'Flugreisen, Ausflüge, Thermenurlaube, etc.',
    monthly_expenses_partner: 'Basisausgaben für meinen Partner',
    monthly_expenses_kids: 'Basisausgaben für die Kinder',
    costs_for_repair: 'Betriebskosten und Reparaturrücklage für das Haus',
    cars: 'Auto(s)',
    cars_description: 'Gesamtkosten für jedes Auto sind zu berücksichtigen',
    personal_financial_situation: 'Ihre finanzielle Situation',
    monthly_costs: 'Ihre monatlichen Ausgaben',
    monthly_costs_description:
      'Unser Alogrithmus liefert hier Vorschlagswerte die Sie aber überschreiben können - wir rechnen hier nicht mit statischen Werten, sondern berücksichtigen dabei das Einkommen und den Lebensstil!',
    monthly_rate_loan: 'Darauf basierend können Sie sich diese monatliche Kreditrate leisten:',
    amount_of_loan: 'Welchen Kreditbetrag kann ich mir dabei ohne großes Risiko leisten:',
    funds_and_budget: 'Dies ergibt zusammen mit Deinen Eigenmitteln ein Projektbudget von:',
    taking_a_break: 'Urlaube und Reisen',
    taking_a_break_description: 'Flugreisen, Ausflüge, Thermenurlaube, etc.',
    loan_impossible_description:
      'Ihre Ausgaben übersteigen die Einnahmen. Bitte überprüfen Sie diese nochmals auf Richtigkeit und/oder kontaktieren Sie uns falls Sie die Beratung eines Wohnbauexperten in Anspruch nehmen möchten.',
    missing_monthly_value: 'Es fehlen auf Basis Ihrer Eingaben mindestens {value} pro Monat!',
  },

  dashboard: {
    title: 'Projektübersicht',
    complete_project_details_step: 'Sie müssen zunächst die Projektplanung abschließen!',
    complete_project_details_step_btn: 'Projektdetails',
    can_not_complete_step: `Currently, you can't complete this step for the new calculator for CO₂, energy costs, and renovation costs. Development in progress`,
    budget_shortage_chart: {
      shortage_label: 'Finanzierungsbedarf',
      budget_label: 'Eigenmittel',
    },
    downloads: {
      title: 'Dokumente zum Download',
      project_pdf: 'Kostenschätzung für Ihr Projekt',
      budget_not_available_tooltip: 'Sie müssen zuerst Ihre Haushaltsplanung durchführen',
      budget_pdf: 'Haushaltsplan',
    },
    overview: {
      project_price: 'Meine aktuellen <b>Projektkosten</b>',
      budget: 'Meine <b>Eigenmittel</b>',
      monthly_affordable_repayments: 'Mein <b>Haushaltsüberschuß</b>',
      loan_amount: 'Meine <b>kreditsumme</b>',
      monthly_rate: 'Meine <b>monatliche rate</b>',
      budget_monthly_rate_loan: 'Sie müssen zunächst den {link} fertigstellen, um diesen Wert zu berechnen',
      budget_monthly_rate_loan_link_label: 'Haushaltsplan',
    },
    getting_started: {
      step_cta_button: 'Bearbeiten',
      project_details: {
        title: 'Wohntraum planen',
        description:
          'Wählen Sie aus zahlreichen Parametern und ermitteln Sie damit Ihre vorraussichtlichen Projektkosten. Als registrierte/r Nutzer/in profitieren Sie zusätzlich von der Marktwertermittlung Ihrer Immobilie.',
      },
      adjust_plan: {
        title: 'Kosten anpassen',
        description:
          'Falls Sie bereits Angebotspreise haben oder manche Tätigkeiten in Eigenleistung erbringen, können Sie die ermittelten Preise überschreiben, um die Projektkosten individuell an Ihre Situation anzupassen.',
      },
      budget_planning: {
        title: 'Haushaltsbudget planen',
        description:
          'Erfassen Sie spielerisch Ihre finanziellen Moglichkeiten basierend auf Ihrem personlichen Lebensstil.',
      },
      adjust_budget_plan: {
        title: 'Haushaltsbudget anpassen',
        description: 'Korrigieren Sie die zuvor erfasste Gewichtung und Werte an Ihren personlichen Lebensstil',
      },
      loan_calculator: {
        title: 'Finanzierung wählen',
        description:
          'Bestimmen Sie die Kreditlaufzeit und die Zinsbindung und erhalten Sie unmittelbar darauf eine unverbindliche Kreditzusage inklusive Zusatzinformationen (monatliche Rate, voraussichtlicher Zinssatz, etc.)',
        disabled_proceed_btn_tooltip:
          'Sie müssen zunächst die Haushaltsplanung abschließen, bevor Sie den Schritt "Finanzierung" beginnen können!',
      },
      loan: {
        title: 'Finanzierung erhalten',
        description: 'Fast geschafft! Sie erhalten unsere Einschatzung uber die Darstellbarkeit Ihrer Finanzierung!',
      },
      request_personal_offer: {
        title: 'Persönliches Angebot anfordern',
        description:
          'Haben Sie noch Fragen? Übermitteln Sie Ihre Daten mit einem Klick an eine Wohnbauexpertin oder einen Wohnbauexperten der HYPO NOE. Diese/r wird sich umgehend mit Ihnen in Verbindung setzen und Sie bei individuellen Fragen umfassend beraten!',
      },
    },
  },
  disclaimers: {
    guest_user:
      'Aktuell planen Sie als "Gast" - um die Daten zu sichern und alle Funktionen zu nutzen, können Sie sich hier registrieren',
    registration:
      '<b>HAFTUNGSAUSCHLUSS:</b>\n\n Die angegebenen Werte der Projektkostenermittlung sind unverbindliche Schätzwerte, die aufgrund marktüblicher Durchschnittswerte in Österreich berrechnet wurden, und ausschließlich zu Ihrer Orientierungshilfe dienen.\n Bedenken Sie bitte, dass diese Werte je nach Marktlage, Verhandlungssituation und Ausführung  mehr oder weniger von den tatsächlichen Werten abweichen können.\n Die HYPO NOE Landesbank für Niederösterreich und Wien AG (kurz: HYPO NOE) übernimmt keine Haftung für die Richtigkeit dieser Werte, ebenso wenig wie für allfällige Fehlfunktionen der Plattform. ',
    budget:
      '<b>HAFTUNGSAUSCHLUSS:</b>\n\n Die HYPO NOE empfiehlt Ihnen keinen bestimmten Kredit. Aufgrund Ihrer Antworten auf einige der Fragen erhalten Sie von der HYPO NOE jedoch Informationen über Finanzierungen, damit Sie Ihre eigene Entscheidung treffen können. \n Die vorliegende Aufstellung stellt kein Angebot im Rechtssinn dar, sondern dient ausschließlich der internen Bearbeitung Ihres Kreditwunsches.',
    loan_calculator:
      '<b>HAFTUNGSAUSCHLUSS:</b>\n\n Dieses Ergebnis wurde aufgrund Ihrer Eingaben berechnet,  dient ausschließlich als Orientierungshilfe und stellt kein verbindliches Angebot dar.\n Die HYPO NOE ist bereit Ihnen im Falle eines konkreten Interesses ein persönliches, unverbindliches Angebot gemäß Hypothekar- und Immobilienkreditgesetz (HIKrG) zu erstellen.\n Bitte nutzen Sie dafür die Option "Persönliches Angebot anfordern" und einer unserer Wohnbauexperten wird sich diesbezüglich mit Ihnen in Verbindung setzen.',
    sprengnetter_not_available: 'Für die eingegebene Adresse steht leider keine Immobilienbewertung zur Verfügung!',
    sprengnetter_estimation:
      'Hinweis:\n\n Jedem Anwender stehen nur maximal 10 Immobilienbewertungen zur Verfügung. Von Ihnen bereits durchgeführte Bewertungen: {requestsCount}',
    land_sprengnetter_not_available:
      'Für die eingegebene Adresse steht leider keine Immobilienbewertung zur Verfügung!',
    land_sprengnetter_estimation:
      'Hinweis:\n\n Es stehen maximal 10 Grundstücksbewertungen pro User zur Verfügung. Bisher bereits durchgeführte: {requestsCount}',
    land_sprengnetter_estimation_loan_consultant:
      'Bisher bereits durchgeführte: {requestsCount} Grundstücksbewertungen',
  },
  legend: {
    totalLabel: 'Ihr Preis {value}',
    lower: 'Schätzpreis Untergrenze',
    avg: 'Schätzpreis',
    upper: 'Schätzwert Obergrenze',
  },
  debug_mode: {
    co2_values: {
      columns: {
        option_name: 'Option name',
        actual_scenario_input: 'Actual-Scenario Input Parameter',
        actual_scenario_output: 'Actual-Scenario Output Parameter',
        potential_scenario_input: 'Potential-Scenario Input Parameter',
        potential_scenario_output: 'Potential-Scenario Output Parameter',
        planned_scenario_input: 'Planned-Scenario Input Parameter',
        planned_scenario_output: 'Planned-Scenario Output Parameter',
      },
      form_values: {
        amount_of_hot_water: 'Amount of hot water',
        energy_standard: 'Energy standard',
        heated_living_area: 'Heated living area',
        heating_system: 'Heating system',
        hot_water_producer: 'Hot water producer',
        age_of_heating_system: 'How old is heating system',
        is_devices_younger_than_10_years: 'Is devices younger than 10 years',
        is_solar_heating_system: 'Is solar heating system',
        is_solar_power_system: 'Is solar power system',
        kind_of_house: 'Kind of house',
        number_of_people: 'Number of people',
        power_consumers: 'Power consumers',
        renovations: 'Renovations',
        solar_heating_system_type: 'Solar heating system type',
        solar_power_system_consuming_percentage: 'Solar power system consuming percentage',
        solar_power_system_size: 'Solar power system size',
        temperature: 'Temperature',
      },
      result: {
        specific_heating_demand: 'Specific heating demand',
        heating_factor_kea: 'Heating factor kea',
        heating_factor_ghg: 'Heating factor ghg',
        total_heating_demand: 'Total heating demand',
        primary_energy_for_heating: 'Primary energy for heating',
        heating_ghg_emission: 'Heating ghg emission',
        heating_ghg_equivalent_car: 'Heating ghg equivalent_car',
        heating_ghg_equivalent_plane: 'Heating ghg equivalent plane',
        heating_ghg_equivalent_trees: 'Heating ghg equivalent trees',
        total_heating_costs: 'Total heating costs',
        primary_energy_for_hot_water: 'Primary energy for hot water',
        hot_water_ghg_emission: 'Hot water ghg emission',
        hot_water_ghg_equivalent_car: 'Hot water ghg equivalent car',
        hot_water_ghg_equivalent_plane: 'Hot water ghg equivalent plane',
        hot_water_ghg_equivalent_trees: 'Hot water ghg equivalent trees',
        total_hot_water_costs: 'Total hot water costs',
        total_power_consumption_per_year: 'Total power consumption per year',
        total_power_production_per_year: 'Total power production per year',
        own_consumption: 'Yourself consumption',
        power_consumption_from_energy: 'Power_consumption_from_energy',
        electrical_feed_in_to_grid: 'Electrical feed in to grid',
        ghg_savings_solar_power_system: 'Ghg savings solar power system',
        ghg_balance_of_power_consumption: 'Ghg balance of power consumption',
        electricity_ghg_equivalent_car: 'Electricity ghg equivalent car',
        electricity_ghg_equivalent_plane: 'Electricity ghg equivalent plane',
        electricity_ghg_equivalent_trees: 'Electricity ghg equivalent trees',
        solar_power_system_helps_to_save: 'Solar power system helps to save',
        total_electricity_costs: 'Total electricity costs',
        total_energy_balance_per_year: 'Total energy balance per year',
        energy_ghg_balance_per_year: 'Energy ghg balance per year',
        energy_ghg_equivalent_car: 'Energy ghg equivalent car',
        energy_ghg_equivalent_plane: 'Energy ghg equivalent plane',
        energy_ghg_equivalent_trees: 'Energy ghg equivalent trees',
        total_energy_costs: 'Total energy costs',
      },
    },
  },
  alt_text: {
    btn_select_with_icon: 'Grafik {btnLabel}',
    quick_calculator: {
      renovation_house: 'Grafik Sanierung Einfamilienhaus (inklusive Co2 - Bilanz)',
      buying_house: 'Grafik Einfamilienhaus (inklusive Sanierung)',
      new_building: 'Grafik Neubau Einfamilienhaus',
      new_apartment: 'Grafik Eigentumswohnung',
      lifestyle_calculator: 'Grafik Lifestyle Kalkulator',
    },
    project_details_page: {
      new_apartment: {
        first_step: 'Grafik erster Schritt Projekttyp Wohnungskauf',
        third_step: 'Grafik dritter Schritt Projekttyp Wohnungskauf',
        fourth_step: 'Grafik vierter Schritt Projekttyp Wohnungskauf',
        fifth_step: 'Grafik fünfter Schritt Projekttyp Wohnungskauf',
      },
      new_building: {
        first_step: 'Grafik erster Schritt Projekttyp Neubau Einfamilienhaus',
        third_step: 'Grafik dritter Schritt Projekttyp Neubau Einfamilienhaus',
        fourth_step: 'Grafik vierter Schritt Projekttyp Neubau Einfamilienhaus',
        fifth_step: 'Grafik fünfter Schritt Projekttyp Neubau Einfamilienhaus',
        sixth_step: 'Grafik sechster Schritt Projekttyp Neubau Einfamilienhaus',
      },
      buying_house: {
        first_step: 'Grafik erster Schritt Projekttyp Hauskauf',
        third_step: 'Grafik dritter Schritt Projekttyp Hauskauf',
        fourth_step: 'Grafik vierter Schritt Projekttyp Hauskauf',
        fifth_step: 'Grafik fünfter Schritt Projekttyp Hauskauf',
        sixth_step: 'Grafik sechster Schritt Projekttyp Hauskauf',
      },
      renovation_house: {
        first_step: 'Grafik Objektdaten - Haussanierung',
        third_step: 'Grafik Wohnungen – Allgemeine Angaben',
        fourth_step: 'Grafik CO2-Optimierung – Windräder, Solaranalge',
        fifth_step: 'Grafik Innenbereich – Frau sitzt bei Computer',
        sixth_step: 'Grafik Außenbereich – Haus mit Solarpanels',
        seventh_step: 'Grafik Wohnungen – Allgemeine Angaben',
      },
    },
    auth_pages: {
      login: 'Grafik Login Seite',
      sign_up: 'Grafik Registrierungsseite',
      forgot_password: 'Grafik Passwort vergessen',
      reset_password: 'Grafik Passwort zurücksetzen',
    },
    project_plan_page: {
      img: 'Grafik Projektkosten planen - Rechner',
    },
    my_projects: {
      delete_project: 'Icon Projekt löschen',
    },
    renovation_house: {
      energy_scale_house: 'Grafik Energiebilanzanzeige für Ihr Haus',
      co2_icon: 'Icon Co2 Einsparungspotential',
      euro_icon: 'Icon Energiekosten Einsparungspotential',
      lightning_icon: 'Icon Energieverbrauch Einsparungspotential',
      plane_icon: 'Icon Flugreisen',
      car_icon: 'Icon Autokilometer',
      tree_icon: 'Icon Bäume',
      renovation_house_img: 'Grafik Haussanierung – altes wird neu',
      number_of_people: {
        one_person: 'Icon eine Person',
        two_people: 'Icon zwei Personen',
        three_people: 'Icon drei Personen',
        four_people: 'Icon vier Personen',
        five_people: 'Icon fünf Personen',
        six_people: 'Icon sechs Personen',
      },
    },
    new_building: {
      number_of_floors: {
        one_floor: 'Icon Bungalow',
        two_floors: 'Icon Haus mit 2 Ebenen ',
        three_floors: 'Icon Haus mit 3 Ebenen',
      },
      equipment_house_types: {
        standard: 'Icon Ausstattungstyp Standard',
        premium: 'Icon Ausstattungstyp Gehoben',
        superior: 'Icon Ausstattungstyp Exklusiv',
      },
    },
    invite_user_page_img: 'Grafik User einladen',
    contact_us_page_img: 'Grafik Kontakt',
    budget_overview_img: 'Grafik Budgetübersicht',
    budget_edit_monthly_expenses_img: 'Icon Monatliche Ausgaben editieren',
    budget_calculator: {
      first_step: 'Grafik Wohnsituation – Paar mit Kinderwagen',
      second_step: 'Grafik Einkommen – Mann mit Euro Münze',
      third_step: 'Grafik Kosten – Frau mit Geldbörse',
      fourth_step: 'Grafik Sparen & Vorsorge – Frau mit Münze und Sparschwein',

      cars: {
        one_car: 'Icon ein Auto',
        two_cars: 'Icon zwei Autos',
        three_cars: 'Icon drei Autos',
        four_cars: 'Icon vier oder mehr Autos',
      },
      kids_quantity: {
        one_child: 'Icon ein Kind',
        two_children: 'Icon zwei Kinder',
        three_children: 'Icon drei Kinder',
        four_children: 'Icon vier oder mehr Kinder',
      },
    },
    icons: {
      info: 'Icon Info',
      plus: 'Icon plus',
      minus: 'Icon minus',
      lightning: 'Icon Blitz',
      has_none: 'Icon keine',
      close: 'Icon schliessen',
      check_circle: 'Icon Kreis',

      euro: 'Icon Euro',
      wallet: 'Icon Wallet',
      document: 'Icon Dokument',
      dashboard: 'Icon Dashboard',
      message: 'Icon Nachricht',
      subject: 'Icon Betreff',
      local_offer: 'Icon Angebot',
      supervisor_account: 'Icon Supervisor Account',
      wrench: 'Icon Schraubenschlüssel',
      arrow_right: 'Icon Pfeil rechts',
    },
    navbar: {
      logo: 'Logo',
      dropdown_user_icon: 'Icon user',
    },
  },
};

export const backendErrors = {
  access_denied: 'Zugriff nicht möglich, bitte etwas später nochmals versuchen',
  something_went_wrong: 'Sorry, etwas lief falsch - bitte etwas später nochmals versuchen',
  form_data_is_missing: 'Eingaben im Formular fehlen',
  create_project_first: 'Login ist erst nach Registrierung und Anlage eines Projekts möglich',
  auth: {
    login_failed: 'Login fehlgeschlagen - bitte nochmals versuchen',
    email_exists: 'Es existiert bereits ein User mit dieser Email-Adresse',
    verify_account: 'Email-Adresse muss noch bestätigt werden',
    password_is_required: 'Passwort eingeben',
    email_is_required: 'Email ist erforderlich',
    not_authorized: 'Nicht berechtigt',
    temporary_password_is_required: 'Für Ihr Erstlogin benötigen Sie das zugesendete Start-Passwort',
    wrong_password: 'Falsches Passwort',
    account_has_no_password: 'Ihr Account hat kein Passwort da Sie sich mit Google oder Facebook registriert haben',
    link_has_expired: 'Link ist abgelaufen',
  },
  user: {
    user_not_found: 'User nicht gefunden',
    cannot_remove_yourself: 'Sie können sich nicht selbst löschen',
  },
  sprengnetter_estimation: {
    no_permissions: 'Keine Berechtigung für Sprengnetter Bewertung',
    requests_limit: 'Sie haben bereits die maximale Anzahl an Bewertungen erreicht',
  },
  project: {
    limit_of_projects: 'Mehr als 10 Projekte sind nicht möglich',
    last_project: 'Das letzte Projekt kann nicht gelöscht werden',
    not_found: 'Projekt nicht gefunden',
    cannot_remove_project_payments_recorded:
      'Dieses Projekt kann nicht gelöscht werden, da dafür bereits Ausgaben erfasst wurden',
    has_already_owner: 'Projekt wurde bereits einem User zugeordnet',
  },
  budget: {
    no_budget_calculation: 'Sie haben noch keine Haushaltsrechnung',
    need_to_transfer_project_with_budget:
      'Da der Finanzierung Schritt im Projekt abgeschlossen ist, müssen Sie auch die Budgetberechnungen klonen',
  },
  financing: {
    request_sent: 'Anfrage wurde bereits versendet',
  },
  invitation: {
    email_exists: 'Mit dieser Email-Adresse gibt es bereits einen User',
    cannot_resend_temp_password: `Sie können kein neues Startpasswort versenden, da der User/Kunde es bereits geändert hat`,
    cannot_delete_invited_user: `Sie können den Kunden-Account nicht löschen, da der Kunde bereits das Startpasswort geändert`,
  },
  image_uploading: {
    fetch_fail: 'Lesen der Datei fehlgeschlagen',
    fetch_owner_fail: 'Failed to fetch file uploader',
    wrong_owner: 'Keine Berechtigung diese Datei zu löschen',
  },
  payments: {
    limit_of_payers: 'Mehr als 5 Zahler sind nicht möglich',
    failed_to_remove_payer:
      'Der Zahler kann nicht entfernt werden - bitte vorher alle Zahlungen einem anderen Zahler zuordnen.',
  },
  admin: {
    disable_2_fa: 'Disable 2FA before initiating it again.',
    otp_is_invalid: 'One-time password is not valid. Please try again',
    last_admin: 'Sie sind der letzte Admin',
  },
  pdf: {
    export_error: 'Etwas ist beim Export des PDF schiefgegangen - bitte nochmals versuchen...',
  },
  co2Calculations: {
    co2_calculations: '@Unfortunately no CO₂ calculations were found, please try again later',
  },
  express_loan: {
    calculations_not_found: 'Berechnungsgrundlagen nicht gefunden, bitte nochmals versuchen...',
  },
};
