export const NEW_BUILDING = 'new_building';
export const NEW_APARTMENT = 'new_apartment';
export const BUYING_HOUSE = 'buying_house';
export const RENOVATION_HOUSE = 'renovation_house';
export const LAND_ESTIMATION = 'land_estimation';
export const GUEST = 'guest';
export const DEVELOPER = 'developer';
export const LOAN_CONSULTANT = 'loan_consultant';
export const LIFESTYLE_CALCULATOR = 'lifestyle_calculator';
export const COUNT_INVITATIONS_PER_PAGE = 20;
export const PROPERTY_VALUATION = 'property_valuation';

export const LOAN_RENOVATION = 'renovation';
export const LOAN_REFINANCE = 'refinance';
export const LOAN_BUILD = 'build';
export const LOAN_BUYING = 'buying';

export const applicationTypes = {
  expressLoan: 'EXPRESS_LOAN',
};

export const viennaStores = ['Wipplingerstraße', 'Alsergrund', 'Hütteldorf', 'Floridsdorf'];
export const lowerAustriaStores = [
  'Amstetten',
  'Baden',
  'Groß Enzersdorf',
  'Horn',
  'Klosterneuburg',
  'Korneuburg',
  'Krems',
  'Melk',
  'Mistelbach',
  'Mödling',
  'Schwechat',
  'St. Pölten',
  'Stockerau',
  'Tulln',
  'Wr. Neustadt',
  'Zwettl',
];
