import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { toastErrorMessage } from 'utils/helpers';

const REQUEST_PERSONAL_OFFER_MUTATION = gql`
  mutation requestExpressLoanPersonalOffer($id: ID!, $formData: ExpressLoanPersonalOfferInput!) {
    requestExpressLoanPersonalOffer(id: $id, formData: $formData)
  }
`;

const useRequestExpressLoanPersonalOffer = () => {
  const [requestPersonalOfferMutation] = useMutation(REQUEST_PERSONAL_OFFER_MUTATION, { onError: toastErrorMessage });

  const handleRequestPersonalOffer = useCallback(
    async (id, formData) => {
      await requestPersonalOfferMutation({ variables: { id, formData } });
    },
    [requestPersonalOfferMutation],
  );
  return handleRequestPersonalOffer;
};

export default useRequestExpressLoanPersonalOffer;
