/* eslint-disable react/no-danger */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { formatCurrency } from 'utils/helpers';
import s from './Overview.module.scss';

const MY_BUDGET_PDF_QUERY = gql`
  query myBudgetPdf {
    myBudget {
      _id
      calculation {
        monthly_rate_loan {
          calculated_value
        }
      }
    }
  }
`;

export default function Overview({ project }) {
  const { formatMessage: t } = useIntl();
  const { name, price, budget } = project;
  const { data: myBudgetQueryData } = useQuery(MY_BUDGET_PDF_QUERY);
  const monthlyRate = get(project, 'loan.monthly_rate', project.financing_rate);
  const loanAmount = get(project, 'loan.amount', project.loan_amount);
  const budgetMonthlyRateLoan = get(myBudgetQueryData, 'myBudget.calculation.monthly_rate_loan.calculated_value', null);

  return (
    <div className={s.overviewCol}>
      <h2 className={s.projectName}>{name}</h2>
      <div className={s.grayItem}>
        <h3 dangerouslySetInnerHTML={{ __html: t({ id: 'dashboard.overview.project_price' }) }} />
        <b>{formatCurrency(price)}</b>
      </div>
      <div className={s.grayItem}>
        <h3 dangerouslySetInnerHTML={{ __html: t({ id: 'dashboard.overview.budget' }) }} />
        <b>{formatCurrency(budget)}</b>
      </div>
      <div className={s.grayItem}>
        <h3 dangerouslySetInnerHTML={{ __html: t({ id: 'dashboard.overview.monthly_affordable_repayments' }) }} />
        {budgetMonthlyRateLoan ? (
          <b>{formatCurrency(budgetMonthlyRateLoan)}</b>
        ) : (
          <p className={s.notAvailableValue}>
            {t(
              { id: 'dashboard.overview.budget_monthly_rate_loan' },
              {
                link: (
                  <Link to={routePaths.lifestyleOverview} key={1}>
                    {t({ id: 'dashboard.overview.budget_monthly_rate_loan_link_label' })}
                  </Link>
                ),
              },
            )}
          </p>
        )}
      </div>
      <div className={s.primaryItem}>
        <h3 dangerouslySetInnerHTML={{ __html: t({ id: 'dashboard.overview.loan_amount' }) }} />
        <b>{formatCurrency(loanAmount)}</b>
      </div>
      <div className={s.primaryItem}>
        <h3 dangerouslySetInnerHTML={{ __html: t({ id: 'dashboard.overview.monthly_rate' }) }} />
        <b>{formatCurrency(monthlyRate)}</b>
      </div>
    </div>
  );
}
