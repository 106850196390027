import React from 'react';
import { FormattedMessage } from 'react-intl';
import s from './FormattedHTMLMessage.module.scss';

const FormattedHTMLMessage = ({ id, defaultMessage = ' ', values }) => {
  return (
    <FormattedMessage
      id={id}
      defaultMessage={defaultMessage}
      values={{
        p: (...chunks) => <p key={chunks}>{chunks}</p>,
        b: (...chunks) => <b key={chunks}>{chunks}</b>,
        ul: (...chunks) => (
          <ul key={chunks} className={s.list}>
            {chunks}
          </ul>
        ),
        li: (chunks) => (
          <li key={chunks} className={s.listItem}>
            {chunks}
          </li>
        ),
        ...values,
      }}
    />
  );
};

export default FormattedHTMLMessage;
