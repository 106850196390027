import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonLink from 'components/common/ButtonLink';
import styles from './ProfileInfo.module.scss';

const ProfileInfo = ({ deleteMyAccount, firstName, lastName, email }) => (
  <div className={styles.container}>
    <table className={styles.table}>
      <tbody>
        <tr>
          <td>
            <b>
              <FormattedMessage id="profile.first_name" />
            </b>
          </td>
          <td>{firstName}</td>
        </tr>
        <tr>
          <td>
            <b>
              <FormattedMessage id="profile.last_name" />
            </b>
          </td>
          <td>{lastName}</td>
        </tr>
        <tr>
          <td>
            <b>
              <FormattedMessage id="profile.email" />
            </b>
          </td>
          <td>{email}</td>
        </tr>
      </tbody>
    </table>
    <ButtonLink className={styles.btnDeleteAccount} onClick={deleteMyAccount}>
      <FormattedMessage id="profile.delete_my_account.title" />
    </ButtonLink>
  </div>
);

export default ProfileInfo;
