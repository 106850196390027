import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Row } from 'react-flexbox-grid';
import { useHistory } from 'react-router-dom';
import useRemoveProject from 'hooks/project/useRemoveProject';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/react-hooks';
import useLastProjectId, { LAST_PROJECT_ID_QUERY } from 'hooks/project/useLastProjectId';
import { routePatterns } from 'router/route-paths';
import { useModal } from 'components/common/Modal';
import CustomerInfoModal from './CustomerInfoModal';
import ProjectCard from './ProjectCard';

const useRemoveProjectHandler = () => {
  const removeProject = useRemoveProject();
  const { formatMessage: __ } = useIntl();

  const handleRemove = useCallback(
    async (projectId) => {
      try {
        confirmAlert({
          title: __({ id: 'my_projects.delete_project.title' }),
          message: __({ id: 'my_projects.delete_project.message' }),
          buttons: [
            {
              label: __({ id: 'my_projects.delete_project.cancel' }),
            },
            {
              label: __({ id: 'my_projects.delete_project.confirm' }),
              onClick: async () => {
                await removeProject(projectId);
              },
            },
          ],
        });
      } catch (error) {
        toast.error(error.message);
      }
    },
    [__, removeProject],
  );
  return handleRemove;
};

const ProjectList = ({ projects }) => {
  const removeProject = useRemoveProjectHandler();
  const { lastProjectId } = useLastProjectId();
  const history = useHistory();
  const { showModal, hideModal, modalProps, isVisible } = useModal();
  const client = useApolloClient();

  const changeLastProjectId = useCallback(
    (_id) => {
      client.writeQuery({
        query: LAST_PROJECT_ID_QUERY,
        data: { lastProject: { _id, __typename: 'Project' } },
      });
    },
    [client],
  );

  const onTransferClick = useCallback(
    (projectId, name) => {
      showModal({ projectId, name });
    },
    [showModal],
  );

  const onCardClick = useCallback(
    (e, _id) => {
      e.stopPropagation();
      changeLastProjectId(_id);
      history.push(routePatterns.dashboard.stringify({ id: _id }));
    },
    [changeLastProjectId, history],
  );

  return (
    <Row>
      {projects.map((project) => (
        <ProjectCard
          key={project._id}
          {...{
            isLastProject: projects.length <= 1,
            removeProject,
            onCardClick,
            onAdjustProject: changeLastProjectId,
            isActive: project._id === lastProjectId,
            onTransferClick,
            ...project,
          }}
        />
      ))}
      <CustomerInfoModal isVisible={isVisible} hideModal={hideModal} {...modalProps} />
    </Row>
  );
};

export default ProjectList;
