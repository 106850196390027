import React from 'react';
import { ExclamationTriangle, ArrowForward } from 'assets/icons';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import s from './WarningMessage.module.scss';

const WarningMessage = ({
  title,
  message,
  btnLabel,
  btnLink,
  disabledBtn = false,
  onBtnClick,
  btnEndIcon = <ArrowForward />,
  btnStartIcon = null,
}) => (
  <Grid className={s.warningMessage}>
    <Row center="xs">
      <Col md={12}>
        <PageTitle className={s.title}>{title}</PageTitle>
      </Col>
    </Row>
    <Row center="xs">
      <Col md={12} className={s.wrapper}>
        <ExclamationTriangle width="40px" height="40px" />
        <p className={s.message}>{message}</p>
        {btnLink ? (
          <Link to={btnLink}>
            <Button loading={disabledBtn} disabled={disabledBtn} endIcon={btnEndIcon} startIcon={btnStartIcon}>
              {btnLabel}
            </Button>
          </Link>
        ) : null}
        {typeof onBtnClick === 'function' ? (
          <Button
            loading={disabledBtn}
            disabled={disabledBtn}
            onClick={onBtnClick}
            endIcon={btnEndIcon}
            startIcon={btnStartIcon}
          >
            {btnLabel}
          </Button>
        ) : null}
      </Col>
    </Row>
  </Grid>
);

export default WarningMessage;
