import Nav from 'components/calculators/Nav';
import ExpressLoanFirstStep from 'components/express-loan/ExpressLoanFirstStep';
import ExpressLoanSecondStep from 'components/express-loan/ExpressLoanSecondStep';
import ExpressLoanThirdStep from 'components/express-loan/ExpressLoanThirdStep';
import StepWizard from 'react-step-wizard';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { Col, Row } from 'react-flexbox-grid';
import useSearchParams from 'hooks/useSearchParams';
import { expressLoanProjectTypes } from './loan-project-types';

const tabs = [
  { label: 'express_loan.steps.first_step', component: ExpressLoanFirstStep },
  { label: 'express_loan.steps.second_step', component: ExpressLoanSecondStep },
  { label: 'express_loan.steps.third_step', component: ExpressLoanThirdStep },
];

const ExpressLoan = ({ type }) => {
  const [step, setStep] = useState(1);

  const onSubmitStep = useCallback(() => {
    window.scrollTo(0, 0);
    setStep(step + 1);
  }, [step]);

  const onBack = useCallback(
    (callback) => {
      setStep(step - 1);
      if (typeof callback === 'function') callback();
    },
    [step],
  );

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col lg={10} xl={12}>
        <StepWizard initialStep={step} transitions={{}} isLazyMount nav={<Nav {...{ tabs, step }} />}>
          {tabs.map(({ label, component: C }) => (
            <C key={label} {...{ type, onSubmitStep, onBack }} />
          ))}
        </StepWizard>
      </Col>
    </Row>
  );
};

const ExpressLoanContainer = () => {
  const history = useHistory();
  const search = useSearchParams();
  const type = search.get('type');

  useEffect(() => {
    const selectedType = expressLoanProjectTypes.find((p) => p.type === type);
    if (!selectedType) history.push(routePaths.expressLoanQuickStart);
  }, [history, type]);

  return <ExpressLoan {...{ type }} />;
};

export default ExpressLoanContainer;
