import React, { useMemo } from 'react';
import useProjects from 'hooks/project/useProjects';
import ProjectList from 'components/project-list/ProjectList';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { Link } from 'react-router-dom';
import { Plus } from 'assets/icons';
import { useIntl } from 'react-intl';
import routePaths from 'router/route-paths';
import Button from 'components/common/Button';
import PageTitle from 'components/common/PageTitle';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Tooltip from 'components/common/Tooltip';
import Alert from 'components/common/Alert';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { LOAN_CONSULTANT } from 'constants/constants';
import { Visible, Hidden } from 'components/common/Grid';
import IconButton from 'components/common/IconButton';
import s from './MyProjects.module.scss';

const AddProjectButton = ({ isMaxCountOfProjects, t }) => (
  <Link to={routePaths.newProject} style={{ pointerEvents: isMaxCountOfProjects ? 'none' : 'initial' }}>
    <Hidden xs sm>
      <Button disabled={isMaxCountOfProjects} endIcon={<Plus />}>
        {t({ id: 'my_projects.new_project' })}
      </Button>
    </Hidden>
    <Visible xs sm>
      <IconButton>
        <Plus />
      </IconButton>
    </Visible>
  </Link>
);

export default function MyProjects() {
  const [projects, loading] = useProjects();
  const isMaxCountOfProjects = useMemo(() => projects.length >= 10, [projects.length]);
  const { formatMessage: t } = useIntl();
  const isLoanConsultant = useHasRequiredRole(LOAN_CONSULTANT);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <Grid>
      <Row className={s.row}>
        <Col xs={6} className={s.firstCol}>
          <PageTitle>{t({ id: 'page_titles.my_projects' })}</PageTitle>
        </Col>
        <Col xs={6} className={s.secondCol}>
          <Tooltip
            overlay={<p>{t({ id: 'my_projects.tooltip_max_count_of_projects' })}</p>}
            placement="top"
            hide={!isMaxCountOfProjects}
          >
            <span>
              <AddProjectButton isMaxCountOfProjects={isMaxCountOfProjects} t={t} />
            </span>
          </Tooltip>
        </Col>
      </Row>
      {isLoanConsultant ? (
        <Alert color="primary" id="show_message_for_consultant" isClosable>
          <div style={{ lineHeight: '25px' }}>{t({ id: 'my_projects.message_for_consultant' })}</div>
        </Alert>
      ) : null}
      {projects.length === 0 ? (
        <Alert>{t({ id: 'my_projects.no_projects' })}</Alert>
      ) : (
        <ProjectList {...{ projects }} />
      )}
    </Grid>
  );
}
