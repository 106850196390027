import Button from 'components/common/Button';
import React, { useCallback } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import useGetInvitationsByLoanConsultant from 'hooks/loan-consultant/useGetInvitationsByLoanConsultant';
import Loading from 'components/common/Loading';
import PageTitle from 'components/common/PageTitle';
import { ReactComponent as UpdateIcon } from 'assets/icons/update.svg';
import { Download } from 'assets/icons';
import InvitationsTable from 'components/invitations/IntitationsTable';
import FilterInvitationsInput from 'components/invitations/FilterInvitationsInput';
import { toast } from 'react-toastify';
import { Hidden, Visible } from 'components/common/Grid';
import IconButton from 'components/common/IconButton';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';
import s from './Invitations.module.scss';

const Invitations = () => {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'invitations' });
  const { filter, debouncedFilterValue, handleChange } = useFilterValue({ changePage });

  const { invitations, total, xlsxUrl, loading, refetch } = useGetInvitationsByLoanConsultant({
    currentPage,
    filter: debouncedFilterValue,
  });

  const onRefetch = useCallback(async () => {
    await refetch();
    toast.success(t({ id: 'invitations.refreshed_data' }));
  }, [refetch, t]);

  return (
    <Grid>
      <Row className={s.row}>
        <Col xs={7}>
          <PageTitle>{t({ id: 'page_titles.invitations' })}</PageTitle>
        </Col>
        <Col xs={5}>
          <div className={s.buttonsWrapper}>
            <a href={xlsxUrl} target="_blank" rel="noopener noreferrer">
              <Hidden xs sm md>
                <Button endIcon={<Download />}>{t({ id: 'invitations.download_btn' })}</Button>
              </Hidden>
              <Visible xs sm md>
                <IconButton>
                  <Download />
                </IconButton>
              </Visible>
            </a>
            <Hidden xs sm md>
              <Button onClick={onRefetch} endIcon={<UpdateIcon />}>
                {t({ id: 'invitations.refresh_data_btn' })}
              </Button>
            </Hidden>
            <Visible xs sm md>
              <IconButton onClick={onRefetch}>
                <Download />
              </IconButton>
            </Visible>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <FilterInvitationsInput value={filter} onChange={handleChange} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {!loading ? <InvitationsTable {...{ invitations, currentPage, total, changePage }} /> : <Loading size={40} />}
        </Col>
      </Row>
    </Grid>
  );
};

export default Invitations;
