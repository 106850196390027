/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { FormattedMessage } from 'react-intl';
import useUpdateProjectNewBuildingMutation from 'hooks/project-details/useUpdateProjectNewBuildingMutation';
import { toast } from 'react-toastify';
import featuresSchema from 'constants/wizards/build-house-features-schema';
import HouseDetails from 'components/calculators/BuildHouseForm';
import { isEmpty } from 'lodash';

const defaultFeatures = [
  'planning_and_construction_management',
  'sole_plate_or_basement',
  'property_costs',
  'shell_construction',
  'windows_and_doors',
  'sanitary',
  'electrical_installation',
  'heating_system',
  'interior_construction',
];

const handleSpecificCategories = (_formData) => {
  const formData = cloneDeep(_formData);

  const keysLivingSpaceByFloor = Object.keys(formData)
    .filter((key) => key.includes(`living_space_by_floor`))
    .reduce((array, key) => {
      array.push(formData[key]);
      delete formData[key];
      return array;
    }, []);
  formData.living_space_by_floor = compact(keysLivingSpaceByFloor);

  if (!formData.features.includes('other_investments')) {
    formData.other_investments_amount = null;
    formData.other_investments_comment = '';
  }

  if (!formData.features.includes('carport')) formData.car_ports = 0;
  if (!formData.features.includes('garage')) formData.garage_places = 0;

  return formData;
};

const useSubmitHouseDetailsCalculator = (projectId) => {
  const updateProjectNewBuildingMutation = useUpdateProjectNewBuildingMutation();

  const onSubmit = React.useCallback(
    async ({ values, formikActions, isEditMode, changeStep = true, lastStep, nextStep, currentStep }) => {
      const { setSubmitting } = formikActions;
      // eslint-disable-next-line no-param-reassign
      const features = Object.entries(values.features).reduce((arr, [feature, isEnabled]) => {
        if (isEnabled) arr.push(feature);
        return arr;
      }, []);

      const formData = cloneDeep(values);
      formData.features = uniq([...defaultFeatures, ...features]);

      const step = isEditMode ? null : changeStep ? currentStep + 1 : currentStep;
      formData.step = step;

      if (!values.features.demolition_costs) {
        formData.demolition_area = 0;
      }

      const _formData = handleSpecificCategories(formData);
      setSubmitting(true);
      try {
        await updateProjectNewBuildingMutation(projectId, _formData);
        if (isEditMode && typeof lastStep === 'function') {
          lastStep();
        } else if (typeof nextStep === 'function') {
          nextStep();
        }
        if (changeStep) window.scrollTo(0, 0);
      } catch (err) {
        const error = err.raw?.networkError ? <FormattedMessage id="errors.no_server_response" /> : err.message;
        toast.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [projectId, updateProjectNewBuildingMutation],
  );

  return onSubmit;
};

const whitelistedProps = [
  'name',
  'equipment_type',
  'zip',
  'estimated_property_price',
  'form_values.full_address',
  'form_values.has_property',
  'form_values.coordinates.lat',
  'form_values.coordinates.lng',
  'form_values.floors',
  'form_values.living_space',
  'form_values.terrace_area',
  'form_values.house_type',
  'form_values.bathtub',
  'form_values.shower',
  'form_values.sole_plate_or_basement',
  'form_values.sole_plate_or_basement_area',
  'form_values.living_room_direction',
  'form_values.bathrooms',
  'form_values.heating_system_type',
  'form_values.demolition_area',
  'form_values.personal_contribution',
  'form_values.car_ports',
  'form_values.garage_places',
  'form_values.solar_power_size_kwp',
  'form_values.solar_power_equipment_type',
  'form_values.land_area',
  'form_values.avg_property_price_sq_m',
  ...featuresSchema.flatMap((feature) =>
    Array.isArray(feature.form) ? feature.form.map(({ name }) => `form_values.${name}`) : [],
  ),
];

const BuildHouseDetailsCalculator = ({ project }) => {
  const onSubmit = useSubmitHouseDetailsCalculator(project._id);

  const initialValues = useMemo(() => {
    const living_space_by_floor = {};
    const livingSpaceByFloor = project.form_values.living_space_by_floor;

    const floors = !isEmpty(livingSpaceByFloor) ? livingSpaceByFloor : [null, null, null];
    floors.forEach((value, index) => {
      living_space_by_floor[`living_space_by_floor_${index}`] = value;
    });

    // category of other investments is default
    const restFeatures = {};
    if (project.progress.step !== null) restFeatures.reserves_for_unexpected_costs = true;
    restFeatures.other_investments = !!project.form_values?.other_investments_amount;

    const { form_values, ...whitelisted } = pick(project, whitelistedProps);
    const features = { ...project.features.reduce((acc, name) => ({ ...acc, [name]: true }), {}), ...restFeatures };

    return { ...whitelisted, ...form_values, ...living_space_by_floor, features };
  }, [project]);

  return (
    <HouseDetails
      {...{
        onSubmit,
        initialValues,
        projectType: project.type,
        isEditMode: project.progress.step === null,
        step: project.progress.step,
      }}
    />
  );
};

export default BuildHouseDetailsCalculator;
