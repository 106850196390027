import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import routePaths from 'router/route-paths';
import useUser from 'hooks/user/useUser';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Button from 'components/common/Button';
import { ArrowBack } from 'assets/icons';

export default function NotFound() {
  const [me] = useUser();
  return (
    <Grid fluid style={{ marginTop: '10%' }}>
      <Row center="xs">
        <Col xs={12}>
          <h1>
            <FormattedMessage id="page_not_found.title" />
          </h1>
          {me ? (
            <Link to={routePaths.myProjects} className="link">
              <Button startIcon={<ArrowBack />}>
                <FormattedMessage id="app.back" />
              </Button>
            </Link>
          ) : (
            <Link to={routePaths.quickCalculator} className="link">
              <Button>
                <FormattedMessage id="page_not_found.back_to_quick_calculator" />
              </Button>
            </Link>
          )}
        </Col>
      </Row>
    </Grid>
  );
}
