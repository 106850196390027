import gql from 'graphql-tag';

export default gql`
  fragment propertyValuationFormValues on PropertyValuationFormValues {
    construction_year
    apartment_area
    full_address
    coordinates {
      lat
      lng
    }
    country
    zip
    floor_number
    terrace
    terrace_area
    garden
    garden_area
    loggia
    loggia_area
    balcony
    elevator
    basement
    is_rooftop
    parking
    condition_of_apartment
    heating_system

    land_area
    house_area
    house_category
    basement_type
  }
`;
