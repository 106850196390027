import React from 'react';
import { throttle } from 'lodash';
import { useHistory } from 'react-router-dom';
import Button from 'components/common/Button';
import get from 'lodash/get';
import useSendVerificationEmail from 'hooks/auth/useSendVerificationEmail';
import { useIntl } from 'react-intl';
import useLogout from 'hooks/auth/useLogout';
import ButtonLink from 'components/common/ButtonLink';
import useUser from 'hooks/user/useUser';
import useLastProjectId from 'hooks/project/useLastProjectId';
import { toast } from 'react-toastify';
import PageTitle from 'components/common/PageTitle';
import { routePatterns } from 'router/route-paths';
import { ArrowForward } from 'assets/icons';
import Modal, { useModal } from 'components/common/Modal';
import ContactForm from 'components/contact-form/ContactForm';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useContactHandler } from 'pages/Contact';
import styles from './ActivateAccount.module.scss';

const useRefreshMe = () => {
  const [, , , refetch] = useUser();
  const { lastProjectId } = useLastProjectId();
  const { formatMessage: t } = useIntl();
  const history = useHistory();

  const handleRefresh = React.useCallback(async () => {
    try {
      const refreshedData = await refetch();
      const emailVerified = get(refreshedData, 'data.me.emails[0].verified', null);
      if (emailVerified) {
        history.push(routePatterns.dashboard.stringify({ id: lastProjectId }));
      } else {
        toast.error(t({ id: 'verify_account.message_email_not_verified' }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, [history, lastProjectId, refetch, t]);
  return handleRefresh;
};

const ActivateAccount = () => {
  const [handleSendVerificationEmail, loading] = useSendVerificationEmail();
  const { showModal, hideModal, isVisible } = useModal();
  const { formatMessage: __ } = useIntl();
  const logout = useLogout();
  const refreshMe = useRefreshMe();
  const onSubmitContact = useContactHandler(hideModal);

  const onLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  return (
    <div className={styles.container}>
      <PageTitle className={styles.title} auth>
        {__({ id: 'verify_account.title' })}
      </PageTitle>
      <div className={styles.message}>{__({ id: 'verify_account.message' })}</div>
      <div className={styles.btnContainer}>
        <Button loading={loading} onClick={throttle(handleSendVerificationEmail, 5000)}>
          {__({ id: 'verify_account.resend_link' })}
        </Button>
        <Button onClick={onLogout} endIcon={<ArrowForward />}>
          {__({ id: 'verify_account.logout' })}
        </Button>
      </div>
      <ButtonLink className={styles.emailConfirmedMessage} onClick={refreshMe}>
        {__({ id: 'verify_account.email_confirmed' })}
      </ButtonLink>
      <ButtonLink onClick={showModal}>{__({ id: 'verify_account.contact_support' })}</ButtonLink>

      <Modal isVisible={isVisible} close={hideModal} headerText={__({ id: 'page_titles.contact' })}>
        <Grid>
          <Row>
            <Col md={12}>
              <ContactForm onSubmit={onSubmitContact} />
            </Col>
          </Row>
        </Grid>
      </Modal>
    </div>
  );
};

export default ActivateAccount;
