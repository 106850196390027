import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import Button from 'components/common/Button';
import { GUEST } from 'constants/constants';
import Alert from 'components/common/Alert';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ArrowForward } from 'assets/icons';
import AppFooter from '../layout/AppFooter';
import AppNavbar from '../layout/AppNavbar';
import Sidebar from '../layout/Sidebar';
import SidebarAwareContainer from '../layout/SidebarAwareContainer';
import s from './AppLayout.module.scss';

const AlertForGuest = ({ t }) => (
  <Alert color="warning" className={s.disclaimer} withContainer>
    <div className={s.disclaimerContent}>
      <div className={s.info}>
        <InfoIcon className={s.infoIcon} title={t({ id: 'alt_text.icons.info' })} />
        <p>{t({ id: 'disclaimers.guest_user' })}</p>
      </div>
      <div className={s.btnWrapper}>
        <Link to={routePaths.guestSignUp}>
          <Button color="secondary" endIcon={<ArrowForward />}>
            {t({ id: 'auth.sign_up_submit' })}
          </Button>
        </Link>
        <Link to={routePaths.guestLogin}>
          <Button color="secondary" endIcon={<ArrowForward />}>
            {t({ id: 'auth.sign_in_submit' })}
          </Button>
        </Link>
      </div>
    </div>
  </Alert>
);

export default function AppLayout({ children, noCardWrapper, fluid }) {
  const { formatMessage: t } = useIntl();
  const isGuest = useHasRequiredRole(GUEST);

  return (
    <>
      {/* <div className="primary-bg" /> */}
      <Sidebar />
      <SidebarAwareContainer>
        <div
          className={cn('content', 'root-container', !fluid && 'container-narrow', fluid && 'container-fluid')}
          styles={isGuest ? { marginTop: '35px' } : ''}
        >
          {isGuest ? <AlertForGuest t={t} /> : null}
          {noCardWrapper ? children : <div>{children}</div>}
        </div>
      </SidebarAwareContainer>
      <AppNavbar />
      <AppFooter />
    </>
  );
}
