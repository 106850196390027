/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

const CDatePicker = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  max,
  label,
  className,
  dateFormat,
  outerClassName,
  ...props
}) => {
  const { name } = field;
  const [value, setValue] = React.useState(field.value);
  const handleOnChange = React.useCallback(
    (date) => {
      setValue(date);
      setFieldValue(name, date);
    },
    [setFieldValue, name],
  );
  const getClassNameForInput =
    touched[field.name] && errors[field.name] ? 'error-input default-input' : 'default-input';
  return (
    <div className={cn('field-wrapper', outerClassName)}>
      <label className="label-when-focused-input">{label}</label>
      <DatePicker
        {...field}
        {...props}
        selected={value}
        onChange={handleOnChange}
        autoComplete="none"
        className={cn(getClassNameForInput, className)}
        dateFormat={dateFormat || 'dd.MM.yyyy'}
      />
      {touched[name] && errors[name] && <div className="error-text">{errors[name]}</div>}
    </div>
  );
};

export default CDatePicker;
