import React from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import { Row, Col } from 'react-flexbox-grid';
import { Hidden } from 'components/common/Grid';
import theme from 'config/theme';
import s from './Overview.module.scss';

export default function Overview({ project }) {
  const { formatMessage: t } = useIntl();
  const shortage = Math.max(0, project.price - project.budget);

  const metrics = [
    {
      title: t({ id: 'loan.overview.budget' }),
      displayValue: formatCurrency(project.budget),
      colorValue: theme.yellowColor,
    },
    {
      title: t({ id: 'loan.overview.shortage' }),
      displayValue: formatCurrency(shortage),
      colorValue: theme.primaryColor,
    },
    {
      title: t({ id: 'loan.overview.total' }),
      displayValue: formatCurrency(project.price),
      colorValue: 'black',
    },
  ];

  return (
    <Row className={s.overviewContainer}>
      {metrics.map(({ title, displayValue, colorValue: color }) => (
        <Col key={title} lg={6} xl={4} className={s.metric}>
          <div>
            <p className={s.title}>{title}</p>

            <b className={s.value} style={{ color }}>
              {displayValue}
            </b>
          </div>
          <Hidden xs sm>
            <span className={s.divider} />
          </Hidden>
        </Col>
      ))}
    </Row>
  );
}
