import gql from 'graphql-tag';

export default gql`
  fragment budgetFormData on BudgetEstimation {
    formData {
      alone_or_partner
      kids_quantity
      monthly_net_salary
      monthly_income
      monthly_income_partner
      monthly_net_salary_partner
      other_revenues
      cars
      buying_with_agent
      amount_of_alimony
      monthly_loan
      own_funds
      project_type
      square_meters
      socializing
      luxuries
      new_car
      taking_a_break
      hobbies
      technology
      pension_and_insurances
      age

      broker_commission
      property_transfer_tax
      land_register_fee
      notary_fee
    }
  }
`;
