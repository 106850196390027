import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { formatGraphqlErrors } from 'utils/helpers';
import { PROJECT_PLAN_FRAGMENT, PROJECT_PLAN_QUERY } from './useProjectPlan';

const UPDATE_PLAN_ITEM_MUTATION = gql`
  mutation updatePlanItem($projectId: ID!, $categoryName: String!, $planItemName: String!, $formData: PlanItemInput!) {
    updatePlanItem(
      projectId: $projectId
      categoryName: $categoryName
      planItemName: $planItemName
      formData: $formData
    ) {
      ...projectPlan
    }
  }
  ${PROJECT_PLAN_FRAGMENT}
`;

const useUpdatePlanItem = () => {
  const [updatePlanItemMutation] = useMutation(UPDATE_PLAN_ITEM_MUTATION);

  const updatePlanItem = useCallback(
    async ({ projectId, categoryName, planItemName, formData }) => {
      try {
        await updatePlanItemMutation({
          variables: { projectId, categoryName, planItemName, formData },
          refetchQueries: [{ query: PROJECT_PLAN_QUERY, variables: { projectId } }],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [updatePlanItemMutation],
  );
  return updatePlanItem;
};

export default useUpdatePlanItem;
