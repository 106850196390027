import { ArrowBack, ArrowForward } from 'assets/icons';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import useExpressLoan from 'hooks/financing/useExpressLoan';
import useSearchParams from 'hooks/useSearchParams';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency, grabErrorMessage } from 'utils/helpers';
import cn from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import { useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import Collapsible from 'react-collapsible';
import { ReactComponent as HypoPeopleImg } from 'assets/images/hypo-people.svg';
import { Hidden, Visible } from 'components/common/Grid';
import { sum } from 'lodash';
import s from './ExpressLoanSteps.module.scss';
import TaxesAndFeesTable from './TaxesAndFeesTable';

const PrimaryBox = ({ label, value, highlighted }) => {
  return (
    <div className={cn(s.primaryBox, highlighted ? s.highlightedPrimaryBox : '')}>
      <p>{label}</p>
      <b>{value}</b>
    </div>
  );
};

const PrimaryBoxes = ({ expressLoan }) => {
  const { formatMessage: t } = useIntl();
  const { form_values, total_loan_amount, monthly_credit_rate, expected_loan_rate } = expressLoan;

  return (
    <div className={s.boxesWrapper}>
      <PrimaryBox
        label={t({ id: 'express_loan.table.loan_period' })}
        value={t({ id: 'express_loan.table.loan_period_years' }, { years: form_values.period })}
      />
      {form_values.interest_type === 'fixed' ? (
        <PrimaryBox
          label={t({ id: 'express_loan.table.expected_fix_loan_rate' }, { years: form_values.fixed_interest_period })}
          value={`${parseFloat((expected_loan_rate * 100).toFixed(3))}%`}
          highlighted
        />
      ) : (
        <PrimaryBox
          label={t({ id: 'express_loan.table.expected_variable_loan_rate' })}
          value={t(
            { id: 'express_loan.table.expected_variable_loan_rate_value' },
            { interest: `${parseFloat((expected_loan_rate * 100).toFixed(3))}%` },
          )}
          highlighted
        />
      )}
      <PrimaryBox label={t({ id: 'express_loan.table.total_loan_amount' })} value={formatCurrency(total_loan_amount)} />
      <PrimaryBox
        label={t({ id: 'express_loan.table.monthly_credit_rate' })}
        value={formatCurrency(monthly_credit_rate)}
        highlighted
      />
    </div>
  );
};

const ExpressLoanSecondStep = ({ onBack, previousStep, expressLoan, nextStep }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage: t } = useIntl();
  const history = useHistory();
  const {
    form_values,
    total_project_costs,
    broker_commission,
    notary_fee,
    property_transfer_tax,
    land_register_fee,
    loan_amount,
    execution_fee,
    penalty_old_loan_payment,
    registration_fee,
  } = expressLoan;

  const onSubmitStep = useCallback(
    (formType) => {
      nextStep();
      history.replace({
        pathname: routePaths.expressLoan,
        search: `?type=${expressLoan.type}&id=${expressLoan._id}&formType=${formType}`,
      });
    },
    [expressLoan._id, expressLoan.type, history, nextStep],
  );
  const taxesAndFeesTotal = useMemo(
    () =>
      sum([
        property_transfer_tax,
        land_register_fee,
        broker_commission,
        notary_fee,
        penalty_old_loan_payment,
        execution_fee,
        registration_fee,
      ]),
    [
      execution_fee,
      land_register_fee,
      notary_fee,
      penalty_old_loan_payment,
      property_transfer_tax,
      broker_commission,
      registration_fee,
    ],
  );

  return (
    <>
      <PrimaryBoxes {...{ expressLoan }} />
      <Row className={s.container}>
        <Col xl={8}>
          <div className={s.tableWrapper}>
            <table className={cn('table last-col-text-right', s.secondStepTable)}>
              <tbody>
                <tr>
                  <td>{t({ id: 'express_loan.table.total_project_costs' })}</td>
                  <td>{formatCurrency(total_project_costs)}</td>
                </tr>
                {taxesAndFeesTotal ? (
                  <tr>
                    <td colSpan={2} className={s.additionalCostsTd}>
                      <Collapsible
                        open={isOpen}
                        handleTriggerClick={() => setIsOpen(!isOpen)}
                        transitionTime={800}
                        trigger={
                          <div className={s.trigger}>
                            <div>
                              {t({ id: 'express_loan.table.additional_costs' })}
                              <ArrowForward className={isOpen ? s.activeChevronSvg : s.chevronSvg} />
                            </div>
                            {!isOpen ? <span>{formatCurrency(taxesAndFeesTotal)}</span> : null}
                          </div>
                        }
                      >
                        <TaxesAndFeesTable {...{ expressLoan }} />
                      </Collapsible>
                    </td>
                  </tr>
                ) : null}

                <tr>
                  <td className={s.minus}>{`${t({ id: 'express_loan.table.own_funds' })} ${
                    form_values.has_property ? '*' : ''
                  }`}</td>
                  <td>{formatCurrency(form_values.own_funds)}</td>
                </tr>
                <tr>
                  <td className={s.isEqualTo}>
                    <b>{t({ id: 'express_loan.table.loan_amount' })}</b>
                  </td>
                  <td>
                    <b>{formatCurrency(loan_amount)}</b>
                  </td>
                </tr>
              </tbody>
            </table>

            {form_values.has_property ? (
              <div className={s.additionalOwnFunds}>
                <p>{t({ id: 'express_loan.table.estimated_property_price' })}</p>
                <p>{formatCurrency(form_values.estimated_property_price)}</p>
              </div>
            ) : null}

            <div className={s.fgContainer} style={{ marginTop: '16px' }}>
              <Button color="warning" endIcon={<ArrowForward />} onClick={() => onSubmitStep('advisory')}>
                {t({ id: 'express_loan.advisory_request_button' })}
              </Button>
              <Hidden xs sm>
                <Button color="warning" endIcon={<ArrowForward />} onClick={() => onSubmitStep('offer')}>
                  {t({ id: 'express_loan.individual_offer_button' })}
                </Button>
              </Hidden>
            </div>
            <div className={s.fgContainer}>
              <Alert color="secondary" className="p-3 mt-4">
                {t({ id: 'express_loan.advisory_request_help_text' })}
              </Alert>
              <Visible xs sm>
                <Button color="warning" endIcon={<ArrowForward />} onClick={() => onSubmitStep('offer')}>
                  {t({ id: 'express_loan.individual_offer_button' })}
                </Button>
              </Visible>
              <Alert color="secondary" className="p-3 mt-4">
                {t({ id: 'express_loan.individual_offer_help_text' })}
              </Alert>
            </div>

            {/* <Row className={s.ctaButtonsRow}>
              <Col xs={12} md={6}>
                <Button color="warning" endIcon={<ArrowForward />} onClick={() => onSubmitStep('advisory')}>
                  {t({ id: 'express_loan.advisory_request_button' })}
                </Button>
                <Alert color="secondary" className="p-3 mt-4">
                  {t({ id: 'express_loan.advisory_request_help_text' })}
                </Alert>
              </Col>
              <Col xs={12} md={6}>
                <Button color="warning" endIcon={<ArrowForward />} onClick={() => onSubmitStep('offer')}>
                  {t({ id: 'express_loan.individual_offer_button' })}
                </Button>

                <Alert color="secondary" className="p-3 mt-4">
                  {t({ id: 'express_loan.individual_offer_help_text' })}
                </Alert>
              </Col>
            </Row> */}
          </div>
        </Col>
        <Visible xl xxl>
          <Col xl={4}>
            <HypoPeopleImg />
          </Col>
        </Visible>
      </Row>

      <div className={s.buttonsWrapper}>
        <Button startIcon={<ArrowBack />} color="outline" onClick={() => onBack(previousStep)}>
          {t({ id: 'app.back' })}
        </Button>
      </div>
    </>
  );
};

export default (props) => {
  const { formatMessage: t } = useIntl();
  const searchParams = useSearchParams();
  const expressLoanId = searchParams.get('id');
  const { loading, error, expressLoan } = useExpressLoan({ id: expressLoanId });

  if (loading) return <Loading size={50} />;

  if (error) {
    return <Alert color="danger">{grabErrorMessage(error)}</Alert>;
  }

  if (!expressLoan) {
    return <Alert color="danger">{t({ id: 'express_loan.form.errors.calculations_not_found' })}</Alert>;
  }

  return <ExpressLoanSecondStep {...props} {...{ expressLoan }} />;
};
