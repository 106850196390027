import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LoadingOverlay from 'components/common/LoadingOverlay';
import settings from 'config/settings';
import { applicationTypes } from 'constants/constants';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';
import useUser from '../hooks/user/useUser';

const RootRouter = ({ children }) => {
  const [user, loading] = useUser();

  if (loading && !user) {
    return <LoadingOverlay />;
  }

  if (settings.applicationType === applicationTypes.expressLoan) {
    <Router basename={process.env.PUBLIC_URL}>
      <AuthRouter />
    </Router>;
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {children} {user ? <AppRouter /> : <AuthRouter />}
    </Router>
  );
};

export default RootRouter;
