import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useVerifyEmail from 'hooks/auth/useVerifyEmail';
import LoadingOverlay from 'components/common/LoadingOverlay';

const VerifyEmail = () => {
  const { token } = useParams();

  const handleConfirmEmail = useVerifyEmail(token);

  useEffect(() => {
    handleConfirmEmail(token);
  }, [handleConfirmEmail, token]);

  return <LoadingOverlay />;
};

export default VerifyEmail;
