import gql from 'graphql-tag';

export default gql`
  fragment newApartmentFormValues on NewApartmentFormValues {
    cost_apartment
    property_transfer_tax
    land_register_fee
    notary_fee
    broker_commission

    full_address
    coordinates {
      lat
      lng
    }
    floor_number
    is_rooftop
    apartment_area
    condition_of_apartment
    construction_year
    loggia
    loggia_area
    terrace
    terrace_area
    garden
    garden_area
    balcony
    elevator
    basement
    parking
    heating_system

    sanitary_renovation_area
    new_flooring_area
    renovation_walls_and_interior_doors_area
    new_windows_number

    other_investments_amount
    other_investments_comment

    kitchen_equipment_type
    aircondition_equipment_type
    sanitary_renovation_equipment_type
    new_flooring_equipment_type
    new_windows_equipment_type
    renewal_of_electric_installation_equipment_type
    renewal_of_heating_system_equipment_type
    renovation_walls_and_interior_doors_equipment_type
  }
`;
