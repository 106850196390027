import React, { useState, useEffect, useCallback, memo } from 'react';
import { StreetView as StreetViewIcon, Place } from 'assets/icons';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import settings from 'config/settings';
import ToggleButtons from 'components/common/ToggleButtons';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import Streetview from './StreetView';
import GoogleMap from './GoogleMap';
import s from './MapContainer.module.scss';

const MapContainer = ({ position }) => {
  const [isEnableStreetView, setIsEnableStreetView] = useState(false);
  const [isMap, setIsMap] = useState(false);

  const f = useCallback(async () => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/streetview/metadata?key=${settings.googleApiKey}&location=${position.lat},${position.lng}`,
    );
    if (response.data.status === 'OK') {
      setIsEnableStreetView(true);
      setIsMap(false);
    } else {
      setIsEnableStreetView(false);
      setIsMap(true);
    }
  }, [position.lat, position.lng]);

  useEffect(() => {
    f();
  }, [f, position.lat, position.lng]);

  return (
    <div className={s.mapContainer}>
      {isMap ? <GoogleMap position={position} /> : null}
      {isEnableStreetView && !isMap ? <Streetview position={position} /> : null}
      {isEnableStreetView ? (
        <div className={s.toggleButtons}>
          <ToggleButtons
            isSelect={isMap}
            onSelect={() => setIsMap(true)}
            onDeselect={() => setIsMap(false)}
            selectComponentIcon={<Place />}
            deselectComponentIcon={<StreetViewIcon />}
            selectComponent={
              <div className={cn(s.buttonLabel, isMap ? s.activeButton : null)}>
                <div className={s.buttonText}>
                  <FormattedMessage id="apartment_wizard.map" />
                </div>
              </div>
            }
            deselectComponent={
              <div className={cn(s.buttonLabel, !isMap ? s.activeButton : null)}>
                <div className={s.buttonText}>
                  <FormattedMessage id="apartment_wizard.street_view" />
                </div>
              </div>
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default memo(MapContainer, isEqual);
