import { useEffect } from 'react';
import { connect } from 'formik';
import { animateScroll } from 'react-scroll';

const ScrollToError = ({ formik, inModal }) => {
  useEffect(() => {
    const { isSubmitting, isValidating, errors } = formik;
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[id="${keys[0]}"], [name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        if (inModal) {
          errorElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        } else {
          const coordinates = errorElement.getBoundingClientRect();
          animateScroll.scrollTo(coordinates.top + window.pageYOffset - 300);
        }
      }
    }
  }, [formik, inModal]);
  return null;
};

export default connect(ScrollToError);
