/* eslint-disable no-unused-expressions */
import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { Close } from 'assets/icons';
import { Col, Grid, Row } from 'react-flexbox-grid';
import s from './Alert.module.scss';

const colorToClassNameMap = {
  primary: s.primary,
  danger: s.danger,
  warning: s.warning,
  secondary: s.secondary,
};

const Alert = ({
  children,
  color = 'primary',
  icon,
  className,
  id,
  isClosable = false,
  withContainer = false,
  ...props
}) => {
  const visible = JSON.parse(window?.localStorage?.getItem(id));
  const [isVisible, setIsVisible] = useState(typeof visible === 'boolean' ? visible : true);

  const handleOnCloseBtnClick = useCallback(() => {
    setIsVisible(false);
    window?.localStorage?.setItem(id, false);
  }, [id]);

  const AlertContent = () =>
    isVisible ? (
      <div className={classnames(s.alert, colorToClassNameMap[color], className)} {...props}>
        {children}
        {isClosable ? <Close className={s.closeBtn} onClick={handleOnCloseBtnClick} /> : null}
        {icon ? <div className={s.iconContainer}>{icon}</div> : null}
      </div>
    ) : null;

  const AlertWithContainer = () => (
    <Grid>
      <Row>
        <Col xs={12}>
          <AlertContent />
        </Col>
      </Row>
    </Grid>
  );

  return withContainer ? <AlertWithContainer /> : <AlertContent />;
};

export default Alert;
