import React, { memo } from 'react';
import settings from 'config/settings';
import Streetview from 'react-google-streetview';
import { isEqual } from 'lodash';

const StreetView = ({ position }) => (
  <div style={{ width: '100%', height: '370px', position: 'static' }}>
    <Streetview
      apiKey={settings.googleApiKey}
      streetViewPanoramaOptions={{
        position,
      }}
    />
  </div>
);

export default memo(StreetView, isEqual);
