import React, { useCallback, useState } from 'react';
import ReactOtpInput from 'react-otp-input';
import get from 'lodash/get';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { useIntl } from 'react-intl';
import ErrorMessage from 'components/common/ErrorMessage';
import s from './OtpModalField.module.scss';

const OtpModalField = ({
  field,
  form,
  label,
  numInputs = 6,
  separator = '-',
  isVisible,
  hideModal,
  onSubmitAdminLogin,
}) => {
  const { name, value } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;
  const { formatMessage: t } = useIntl();
  const [isSubmitting, setSubmitting] = useState(false);

  const getClassNameForInput = get(touched, name) && get(errors, name) ? 'form-control is-invalid' : 'form-control';

  const handleChange = useCallback(
    (v) => {
      setFieldValue(name, v);
    },
    [name, setFieldValue],
  );

  const handleOnBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  return (
    <Modal headerText={t({ id: 'auth.login_page_title' })} isVisible={isVisible} close={hideModal} size="sm">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <label htmlFor={name} className="number-label" id={`label-${name}`}>
          {label}
        </label>
        <ReactOtpInput
          {...field}
          onBlur={handleOnBlur}
          numInputs={numInputs}
          separator={<span>{separator}</span>}
          name={name}
          className={getClassNameForInput}
          onChange={handleChange}
          containerStyle={s.container}
          inputStyle={s.input}
          focusStyle={s.focusInput}
          isInputNum
          shouldAutoFocus
        />
        {get(touched, name) && get(errors, name) && <div className="error-text">{get(errors, name)}</div>}

        {errors.otpInvalid ? <ErrorMessage message={errors.otpInvalid} /> : null}

        <Button
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={async () => {
            setSubmitting(true);
            await onSubmitAdminLogin({ otp: value });
            setSubmitting(false);
          }}
          className={s.btn}
        >
          {t({ id: 'auth.sign_in_submit' })}
        </Button>
      </div>
    </Modal>
  );
};

export default OtpModalField;
