/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useCallback, memo } from 'react';
import Switch from 'react-switch';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import { areEqualFields } from 'utils/form';
import theme from 'config/theme';
import styles from './Switch.module.scss';

const SwitchInput = ({ field, form: { touched, errors, setFieldValue }, onChange, ...props }) => {
  const { label, style } = props;
  const { name, value } = field;

  const handleChange = useCallback(
    (v) => {
      setFieldValue(name, v);
    },
    [name, setFieldValue],
  );

  const hasError = touched[name] && errors[name];

  useEffect(() => {
    if (isNil(value)) setFieldValue(name, false);
  }, [name, setFieldValue, value]);

  return (
    <div className={cn('field-wrapper', styles.switchWrapper)} style={style}>
      <Switch
        value={value || false}
        name={name}
        onChange={onChange || handleChange}
        checked={value || false}
        offColor={theme.whiteSmokeColor}
        onColor={theme.primaryColor}
        offHandleColor="#808080"
        height={20}
        width={40}
        uncheckedIcon={false}
        checkedIcon={false}
        className={cn({ 'error-input': hasError }, styles.switch)}
      />
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      {hasError && <div className="error-text">{errors[name]}</div>}
    </div>
  );
};

export default memo(SwitchInput, areEqualFields);
