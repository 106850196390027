import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { GUEST } from 'constants/constants';
import { Grid } from 'react-flexbox-grid';
import React, { useCallback } from 'react';
import StepWizard from 'react-step-wizard';
import useFormValuesRef from 'hooks/useFormValuesRef';
import {
  BuildHouseFirstStep,
  BuildHouseSecondStep,
  BuildHouseThirdStep,
  BuildHouseFourthStep,
  BuildHouseFifthStep,
  BuildHouseSixthStep,
} from './tabs';
import Nav from '../Nav';
import CostOverview from '../CostOverviewTab';

const formValueMock = {};

const tabs = [
  { label: 'project_details.tabs.house_info', component: BuildHouseFirstStep },
  { label: 'project_details.tabs.sprengnetter_estimation', component: BuildHouseSecondStep },
  { label: 'project_details.tabs.energy', component: BuildHouseThirdStep },
  { label: 'project_details.tabs.indoor', component: BuildHouseFourthStep },
  { label: 'project_details.tabs.outdoor', component: BuildHouseFifthStep },
  { label: 'project_details.tabs.other', component: BuildHouseSixthStep },
  { label: 'project_details.tabs.cost_overview', component: CostOverview },
];

export default function HouseDetails({ initialValues, onSubmit, isEditMode, projectType, step }) {
  const isGuest = useHasRequiredRole(GUEST);
  const formValuesRef = useFormValuesRef([], { formValueMock, initialValues });

  const onSubmitStep = useCallback(
    (values) => {
      formValuesRef.current = { ...formValuesRef.current, ...values };
    },
    [formValuesRef],
  );

  const onBack = useCallback(
    (values, callback) => {
      onSubmitStep(values);
      callback();
    },
    [onSubmitStep],
  );

  return (
    <Grid>
      <StepWizard initialStep={step} transitions={{}} isLazyMount nav={<Nav tabs={tabs} isEditMode={isEditMode} />}>
        {tabs.map(({ label, component: C }) => (
          <C
            key={label}
            {...{
              isGuest,
              onSubmitStep,
              formValuesRef,
              initialValues,
              onSubmit,
              isEditMode,
              projectType,
              onBack,
            }}
          />
        ))}
      </StepWizard>
    </Grid>
  );
}
