import React, { useMemo } from 'react';
import LoadingOverlay from 'components/common/LoadingOverlay';
import LifestyleCalculatorForm from 'components/budget/form';
import useMyBudget from 'hooks/budget/useMyBudget';
import { isNull, map, pick, round } from 'lodash';

export const lifestyleCalculatorInitialValues = {
  own_funds: null,
  project_type: null,
  buying_with_agent: null,
  square_meters: null,
  alone_or_partner: null,
  monthly_net_salary: null,
  socializing: 'not_important',
  luxuries: 'not_important',
  taking_a_break: 'not_important',
  hobbies: 'not_important',
  technology: 'not_important',
  pension_and_insurances: 'not_important',
  new_car: 'not_important',
  cars: null,
  kids_quantity: null,
  day: null,
  month: null,
  year: null,
  monthly_net_salary_partner: null,
  other_revenues: null,
  amount_of_alimony: null,
  monthly_loan: null,
  broker_commission: null,
  property_transfer_tax: null,
  land_register_fee: null,
  notary_fee: null,
};

const monthlyExpensesKeys = [
  'socializing',
  'luxuries',
  'taking_a_break',
  'hobbies',
  'technology',
  'pension_and_insurances',
  'new_car',
];

function getMonthlyExpensesInitialValues(lifestyleCalculations) {
  if (!lifestyleCalculations) return {};
  const allowedValues = pick(lifestyleCalculations.calculation, monthlyExpensesKeys);

  map(allowedValues, (item, key) => {
    if (item.calculated_value === 0 && !Number.isFinite(item.user_input)) {
      delete allowedValues[key];
    } else if (Number.isFinite(item.calculated_value)) {
      const value = Number.isFinite(item.user_input) ? item.user_input : item.calculated_value;
      allowedValues[`${key}_number_value`] = round(value);
    }
  });
  return allowedValues;
}

export function getInitialLifestyleCalculatorValues(myBudget) {
  return { ...lifestyleCalculatorInitialValues, ...getMonthlyExpensesInitialValues(myBudget), ...myBudget?.formData };
}

const LifestyleCalculator = () => {
  const [myBudget, loading] = useMyBudget();
  const initialValues = useMemo(() => getInitialLifestyleCalculatorValues(myBudget), [myBudget]);

  const calculatorCompleted = useMemo(() => (!myBudget ? false : isNull(myBudget.step)), [myBudget]);
  const initialStep = useMemo(() => (!myBudget || isNull(myBudget.step) ? 1 : myBudget.step), [myBudget]);

  if (loading) return <LoadingOverlay />;

  return <LifestyleCalculatorForm {...{ initialValues, myBudget, calculatorCompleted, initialStep }} />;
};

export default LifestyleCalculator;
