import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import useUser from 'hooks/user/useUser';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import routePaths from 'router/route-paths';
import useDeleteMyAccount from 'hooks/user/useDeleteMyAccount';
import ProfileInfo from 'components/profile-info/ProfileInfo';
import 'components/common/AlertOverlay.scss';

export const useHandleDeleteMyAccount = () => {
  const deleteMyAccount = useDeleteMyAccount();
  const history = useHistory();
  const { formatMessage: __ } = useIntl();

  const handleSubmit = useCallback(async () => {
    confirmAlert({
      title: __({ id: 'profile.delete_my_account.title' }),
      message: __({ id: 'profile.delete_my_account.message' }),
      buttons: [
        {
          label: __({ id: 'profile.delete_my_account.cancel' }),
        },
        {
          label: __({ id: 'profile.delete_my_account.confirm' }),
          onClick: async () => {
            await deleteMyAccount();
            history.push(routePaths.quickCalculator);
          },
        },
      ],
    });
  }, [__, deleteMyAccount, history]);

  return handleSubmit;
};

const Profile = () => {
  const [me] = useUser();
  const {
    profile: { firstName, lastName },
    emails,
  } = me;
  const email = emails[0].address;
  const deleteMyAccount = useHandleDeleteMyAccount();

  return (
    <div>
      <h1>
        <FormattedMessage id="page_titles.profile" />
      </h1>
      <ProfileInfo deleteMyAccount={deleteMyAccount} firstName={firstName} lastName={lastName} email={email} />
    </div>
  );
};

export default Profile;
