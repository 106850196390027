import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { formatGraphqlErrors } from 'utils/helpers';
import { GET_PROJECT_TYPES_QUERY } from './useGetProjectTypes';

const CHANGE_PROJECT_TYPE_MUTATION = gql`
  mutation changeProjectType($projectId: ID!, $type: ProjectType!) {
    changeProjectType(projectId: $projectId, type: $type) {
      success
    }
  }
`;

const useChangeProjectType = () => {
  const [changeProjectTypeMutation] = useMutation(CHANGE_PROJECT_TYPE_MUTATION);

  const mutate = useCallback(
    async ({ projectId, type }) => {
      try {
        await changeProjectTypeMutation({
          variables: { projectId, type },
          refetchQueries: [{ query: GET_PROJECT_TYPES_QUERY, variables: { projectId } }],
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [changeProjectTypeMutation],
  );

  return mutate;
};

export default useChangeProjectType;
