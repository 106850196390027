import { __ } from 'utils/form';
import { formatCurrency } from 'utils/helpers';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  cost_apartment: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field'))
    .min(20000, __('errors.max_value', { max: formatCurrency(20000) }))
    .max(3000000, __('errors.max_value', { max: formatCurrency(3000000) })),
  property_transfer_tax: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  land_register_fee: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  notary_fee: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  broker_commission: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  apartment_area: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field'))
    .min(25, __('errors.values_within_range', { min: 25, max: 300 }))
    .max(300, __('errors.values_within_range', { min: 25, max: 300 })),
});

export default validationSchema;
