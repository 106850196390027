import LoadingOverlay from 'components/common/LoadingOverlay';
import { DEVELOPER, RENOVATION_HOUSE } from 'constants/constants';
import useGetProjectTypes from 'hooks/project/useGetProjectTypes';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import Logout from 'pages/Logout';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';
import CO2Values from './CO2Values';

export default () => {
  const isDeveloper = useHasRequiredRole(DEVELOPER);
  const { id: projectId } = useParams();
  const { type, loading } = useGetProjectTypes(projectId);

  if (!isDeveloper) return <Logout />;
  if (loading) return <LoadingOverlay />;

  return (
    <Grid>
      <Row>
        <Col xs={12}>{type === RENOVATION_HOUSE ? <CO2Values /> : null}</Col>
      </Row>
    </Grid>
  );
};
