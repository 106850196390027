import { __ } from 'utils/form';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  zip: yup
    .string()
    .matches(/^[1-9]{1}[0-9]{3}$/, { message: __('errors.wrong_zip_code') })
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  house_area: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field'))
    .min(25, __('errors.values_within_range', { min: 25, max: 300 }))
    .max(300, __('errors.values_within_range', { min: 25, max: 300 })),
  number_of_floors: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

export default validationSchema;
