import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';
import { PROJECT_PLAN_QUERY } from '../project-plan/useProjectPlan';

const UPDATE_PROJECT_NEW_APARTMENT_MUTATION = gql`
  mutation updateProjectNewApartment($projectId: ID!, $formData: UpdateProjectNewApartmentInput!) {
    updateProjectNewApartment(projectId: $projectId, formData: $formData) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useUpdateProjectNewApartmentMutation = () => {
  const [updateProjectNewApartmentMutation] = useMutation(UPDATE_PROJECT_NEW_APARTMENT_MUTATION);

  const mutate = useCallback(
    async (projectId, formData) => {
      try {
        const {
          data: { updateProjectNewApartment: result },
        } = await updateProjectNewApartmentMutation({
          variables: { projectId, formData },
          refetchQueries: [{ query: PROJECT_PLAN_QUERY, variables: { projectId } }],
          awaitRefetchQueries: true,
        });

        return result;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        throw e;
      }
    },
    [updateProjectNewApartmentMutation],
  );
  return mutate;
};

export default useUpdateProjectNewApartmentMutation;
