import gql from 'graphql-tag';
import CO2_CALCULATOR_FORM_VALUES from './co2CalculatorFormValuesFragment';
import CO2_CALCULATOR_RESULT from './co2CalculatorResultFragment';

export default gql`
  fragment co2CalculationsFragment on CO2Calculations {
    _id
    co2_emissions_before {
      form_values {
        ...co2CalculatorFormValues
      }
      result {
        ...co2CalculatorResult
      }
    }
    co2_emissions_potential {
      form_values {
        ...co2CalculatorFormValues
      }
      result {
        ...co2CalculatorResult
      }
    }
    co2_emissions_after {
      form_values {
        ...co2CalculatorFormValues
      }
      result {
        ...co2CalculatorResult
      }
    }
  }
  ${CO2_CALCULATOR_RESULT}
  ${CO2_CALCULATOR_FORM_VALUES}
`;
