export const primaryLoader = {
  height: 170,
  width: 700,
  speed: 1,
  viewBox: '0 0 320 70',
  backgroundColor: '#ededed',
  foregroundColor: '#e9f3fb',
  rects: [
    {
      id: 1,
      x: '10',
      y: '4',
      rx: '6',
      ry: '6',
      width: '150',
      height: '12',
    },
    {
      id: 2,
      x: '10',
      y: '22',
      rx: '6',
      ry: '8',
      width: '120',
      height: '14',
    },
    {
      id: 3,
      x: '10',
      y: '40',
      rx: '6',
      ry: '8',
      width: '70',
      height: '15',
    },
    {
      id: 4,
      x: '10',
      y: '62',
      rx: '0',
      ry: '0',
      width: '303',
      height: '1',
    },
  ],
  circles: [
    {
      id: 1,
      cx: '302',
      cy: '52',
      r: '7',
    },
  ],
};

export const smallLoader = {
  height: 135,
  width: 320,
  speed: 1,
  viewBox: '0 0 320 93',
  backgroundColor: '#ededed',
  foregroundColor: '#e9f3fb',
  rects: [
    {
      id: 1,
      x: '10',
      y: '1',
      rx: '9',
      ry: '9',
      width: '290',
      height: '23',
    },
    {
      id: 2,
      x: '10',
      y: '33',
      rx: '9',
      ry: '9',
      width: '200',
      height: '23',
    },
    {
      id: 3,
      x: '10',
      y: '63',
      rx: '11',
      ry: '15',
      width: '130',
      height: '27',
    },
  ],
  circles: [],
};
