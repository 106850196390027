import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import RefreshTokenService from 'services/RefreshTokenService';
import { GET_PROJECTS_QUERY } from './project/useProjects';
import useUser, { CURRENT_USER_FRAGMENT, ME_QUERY } from './user/useUser';

const QUICK_CALCULATOR_MUTATION = gql`
  mutation quickCalculator($formData: QuickCalculatorInput!) {
    quickCalculator(formData: $formData) {
      projectId
      refreshToken
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useQuickCalculatorMutation = () => {
  const client = useApolloClient();
  const [me] = useUser();

  const quickCalculator = useCallback(
    async (variables) => {
      try {
        const res = await client.mutate({
          mutation: QUICK_CALCULATOR_MUTATION,
          variables: { formData: { ...variables } },
          refetchQueries: me ? [{ query: GET_PROJECTS_QUERY }] : [],
        });

        const result = res.data[Object.keys(res.data)[0]];
        if (result.refreshToken) {
          RefreshTokenService.setRefreshToken(result.refreshToken);
        }
        if (!me) client.writeQuery({ query: ME_QUERY, data: { me: result.user } });

        return result;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        throw e;
      }
    },
    [client, me],
  );
  return quickCalculator;
};

export default useQuickCalculatorMutation;
