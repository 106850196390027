/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { Grid } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import pick from 'lodash/pick';
import useFormValuesRef from 'hooks/useFormValuesRef';
import useBudgetCalculator from 'hooks/budget/useBudgetCalculator';
import PageTitle from 'components/common/PageTitle';
import client from 'graphql/apollo';
import StepWizard from 'react-step-wizard';
import Nav from 'components/calculators/Nav';
import { MY_BUDGET_QUERY } from 'hooks/budget/useMyBudget';
import { get, isNull, isUndefined } from 'lodash';
import FirstStep from './LifestyleCalculatorFirstStep';
import SecondStep from './LifestyleCalculatorSecondStep';
import ThirdStep from './LifestyleCalculatorThirdStep';
import FourthStep from './LifestyleCalculatorFourthStep';
import FifthStep from './LifestyleCalculatorFifthStep';
import SixthStep from './LifestyleCalculatorSixthStep';
import FinalStep from './LifestyleCalculatorFinalStep';

const formValueMock = {
  alone_or_partner: 'partner',
  amount_of_alimony: 0,
  cars: 1,
  kids_quantity: 2,
  monthly_net_salary: 6000,
  monthly_net_salary_partner: 2000,
  monthly_loan: 100,
  other_revenues: 1000,
  own_funds: 200000,
  square_meters: 160,
  project_type: 'buying',
  buying_with_agent: true,
  new_car: 'important',
  luxuries: 'important',
  hobbies: 'not_important',
  socializing: 'not_important',
  pension_and_insurances: 'not_important',
  technology: 'very_important',
  taking_a_break: 'very_important',
  age: 25,
};

const formDataAllowedKeys = [
  'alone_or_partner',
  'kids_quantity',
  'monthly_net_salary',
  'monthly_net_salary_partner',
  'other_revenues',
  'cars',
  'buying_with_agent',
  'own_funds',
  'amount_of_alimony',
  'monthly_loan',
  'project_type',
  'square_meters',
  'broker_commission',
  'property_transfer_tax',
  'land_register_fee',
  'notary_fee',
  'age',
];

const monthlyExpensesKeys = [
  'socializing',
  'luxuries',
  'new_car',
  'taking_a_break',
  'hobbies',
  'technology',
  'pension_and_insurances',
];

const useSubmitBudgetCalculator = (formValuesRef, calculatorCompleted, budgetStep) => {
  const budgetCalculatorSubmit = useBudgetCalculator();

  const onSubmit = useCallback(
    async (values, { setSubmitting, setErrors }, { currentStep, cacheStep }) => {
      formValuesRef.current = { ...formValuesRef.current, ...values };
      setSubmitting(true);

      try {
        const allowedFormValues = pick(formValuesRef.current, formDataAllowedKeys);
        const monthlyExpensesFields = pick(formValuesRef.current, monthlyExpensesKeys);
        const { project_type, buying_with_agent } = formValuesRef.current;

        const additionalExpenses = ['broker_commission', 'property_transfer_tax'];
        Object.keys(pick(allowedFormValues, additionalExpenses)).forEach((valueKey) => {
          if (valueKey === 'broker_commission' && (project_type !== 'buying' || !buying_with_agent)) {
            allowedFormValues.broker_commission = null;
          }
          if (valueKey === 'property_transfer_tax' && ['renovation', 'building'].includes(project_type)) {
            allowedFormValues.property_transfer_tax = null;
          }
        });

        const monthlyExpensesCategories = Object.keys(monthlyExpensesFields).reduce((acc, categoryName) => {
          const numberValue = get(formValuesRef.current, `${categoryName}_number_value`, null);
          const value = { importanceValue: monthlyExpensesFields[categoryName], numberValue };
          return { ...acc, [categoryName]: value };
        }, {});

        let _step = currentStep < budgetStep ? budgetStep : currentStep;
        if (calculatorCompleted || isNull(currentStep)) _step = null;

        const budget = await budgetCalculatorSubmit({ ...allowedFormValues, ...monthlyExpensesCategories }, _step);

        if (!cacheStep) window.scrollTo(0, 0);

        const step = !isUndefined(cacheStep) ? cacheStep : _step;
        client.writeQuery({
          query: MY_BUDGET_QUERY,
          data: { myBudget: { ...budget.budgetEstimation, step: cacheStep || step } },
        });
      } catch (error) {
        setErrors({ form: error.message });
      } finally {
        setSubmitting(false);
      }
    },
    [formValuesRef, budgetCalculatorSubmit, calculatorCompleted, budgetStep],
  );
  return onSubmit;
};

const tabs = [
  { label: 'budget_calculator.first_step_title', component: FirstStep },
  { label: 'budget_calculator.second_step_title', component: SecondStep },
  { label: 'budget_calculator.third_step_title', component: ThirdStep },
  { label: 'budget_calculator.fourth_step_title', component: FourthStep },
  { label: 'budget_calculator.fifth_step_title', component: FifthStep },
  { label: 'budget_calculator.sixth_step_title', component: SixthStep },
  { label: 'budget_calculator.final_step_title', component: FinalStep },
];

const LifestyleCalculatorForm = ({ initialValues, initialStep, myBudget, calculatorCompleted }) => {
  const { formatMessage: t } = useIntl();
  const formValuesRef = useFormValuesRef([], { formValueMock, initialValues });
  const onSubmit = useSubmitBudgetCalculator(formValuesRef, calculatorCompleted, myBudget?.step);

  const onSubmitStep = useCallback(
    (values) => {
      window.scrollTo(0, 0);
      formValuesRef.current = { ...formValuesRef.current, ...values };
    },
    [formValuesRef],
  );

  const onBack = useCallback(
    (values, callback) => {
      onSubmitStep(values);
      callback();
    },
    [onSubmitStep],
  );

  return (
    <Grid>
      <PageTitle>{t({ id: 'page_titles.budget' })}</PageTitle>

      <StepWizard
        initialStep={initialStep}
        transitions={{}}
        isLazyMount
        nav={<Nav {...{ tabs, isEditMode: calculatorCompleted, clickableTabStep: 6, step: myBudget?.step }} />}
      >
        {tabs.map(({ label, component: C }) => (
          <C
            key={label}
            {...{
              initialValues,
              clickableTabStep: 6,
              myBudget,
              formValuesRef,
              onSubmitStep,
              onSubmit,
              onBack,
              calculatorCompleted,
            }}
          />
        ))}
      </StepWizard>
    </Grid>
  );
};

export default LifestyleCalculatorForm;
