import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from 'components/common/Button';
import routePaths from 'router/route-paths';
import settings from 'config/settings';
import { applicationTypes } from 'constants/constants';
import Navbar from './Navbar';
import s from './Navbar.module.scss';

const AuthNavbar = () => {
  // const history = useHistory();
  return (
    <Navbar hideHamburger fluid auth>
      {settings.applicationType !== applicationTypes.expressLoan ? (
        <Link to={routePaths.login} className="link">
          <Button color="outline" className={s.loginBtn}>
            <FormattedMessage id="auth.sign_in_submit" />
          </Button>
        </Link>
      ) : null}
    </Navbar>
  );
};

export default AuthNavbar;
