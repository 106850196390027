import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const CONTACT_SUPPORT = gql`
  mutation contactSupport($message: String!) {
    contactSupport(message: $message)
  }
`;

const useContactSupport = () => {
  const [contactSupportMutation] = useMutation(CONTACT_SUPPORT);

  const contactSupport = useCallback(
    async (variables) => {
      try {
        await contactSupportMutation({
          variables: { ...variables },
        });
        return;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [contactSupportMutation],
  );

  return contactSupport;
};

export default useContactSupport;
