/* eslint-disable */
function createEquipmentTypeSelect(options) {
  return {
    label: 'project_wizard.equipment_type',
    type: 'button_select',
    name: options.name,
    options: [
      {
        value: 'standard',
        label: 'equipment_types.standard',
      },
      {
        value: 'premium',
        label: 'equipment_types.premium',
      },
      {
        value: 'superior',
        label: 'equipment_types.superior',
      },
    ],
    required: true,
    nullable: false,
    ...options,
  };
}
export default [
  {
    id: 'b5dda054-d18a-4a4f-ab40-bcdf19493f5e',
    name: 'solar_power_system',
    label: 'planning_categories.solar_power_system',
    description: 'planning_categories.solar_power_system_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'detailed_planning_wizard.solar_power_size_kwp',
        type: 'counter',
        name: 'solar_power_size_kwp',
        min: 3,
        max: 15,
        description: 'detailed_planning_wizard.solar_power_size_kwp_description',
        required: true,
      },
      {
        type: 'inline_select',
        name: 'solar_power_equipment_type',
        label: 'detailed_planning_wizard.solar_power_equipment_type.name',
        options: [
          {
            imageSrc: require('assets/images/pv_standard.png'),
            label: 'equipment_types.standard',
            description: 'detailed_planning_wizard.solar_power_equipment_type.standard_description',
            value: 'standard',
          },
          {
            imageSrc: require('assets/images/pv_premium.png'),
            label: 'equipment_types.premium',
            description: 'detailed_planning_wizard.solar_power_equipment_type.premium_description',
            value: 'premium',
          },
          {
            imageSrc: require('assets/images/pv_superior.png'),
            label: 'equipment_types.superior',
            description: 'detailed_planning_wizard.solar_power_equipment_type.superior_description',
            value: 'superior',
          },
        ],
        required: true,
      },
    ],
  },
  {
    id: '8d4aed8b-a5cf-481a-b635-6ae7703925b6',
    name: 'carport',
    label: 'planning_categories.carport',
    description: 'planning_categories.carport_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      {
        label: 'detailed_planning_wizard.number_of_car_ports',
        type: 'slider',
        name: 'car_ports',
        min: 1,
        max: 5,
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'carport_equipment_type',
        description: 'planning_categories.carport_equipment_type_description',
      }),
    ],
  },
  {
    id: 'a7afaf61-8159-4fee-babd-1842d00a1a61',
    name: 'garage',
    label: 'planning_categories.garage',
    description: 'planning_categories.garage_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      {
        label: 'detailed_planning_wizard.number_of_garage_place',
        type: 'slider',
        name: 'garage_places',
        min: 1,
        max: 5,
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'garage_equipment_type',
        description: 'planning_categories.garage_equipment_type_description',
      }),
    ],
  },
  {
    id: 'f5226dae-1fb5-4d1b-9ae5-343f8ebb93c6',
    name: 'wallbox_e_mobility',
    label: 'planning_categories.wallbox_e_mobility',
    description: 'planning_categories.wallbox_e_mobility_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [],
  },
  {
    _id: '5e6d5330-1f3a-4eb9-9850-ba58b6345730',
    name: 'interior_equipment',
    label: 'planning_categories.interior_equipment',
    description: 'planning_categories.interior_equipment_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'interior_equipment_type',
        description: 'planning_categories.interior_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'a02b8d07-d00c-4a9d-b89c-8559d547d2be',
    name: 'winter_garden',
    label: 'planning_categories.winter_garden',
    description: 'planning_categories.winter_garden_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'winter_garden_equipment_type',
        description: 'planning_categories.winter_garden_equipment_type_description',
      }),
    ],
  },
  {
    _id: '6d6a1982-bf39-402c-96f5-2e00cf61c0c9',
    name: 'pool',
    label: 'planning_categories.pool',
    description: 'planning_categories.pool_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'pool_equipment_type',
        description: 'planning_categories.pool_equipment_type_description',
      }),
    ],
  },
  {
    _id: '15b78a14-caad-4552-be29-1854abb29f49',
    name: 'garden_landscaping',
    label: 'planning_categories.garden_landscaping',
    description: 'planning_categories.garden_landscaping_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'garden_landscaping_equipment_type',
        description: 'planning_categories.garden_landscaping_equipment_type_description',
      }),
    ],
  },
  {
    _id: '337d822c-3da2-4960-85b4-1b6c2b61e514',
    name: 'fence',
    label: 'planning_categories.fence',
    description: 'planning_categories.fence_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'fence_equipment_type',
        description: 'planning_categories.fence_landscaping_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'ebff0ccd-db83-4d33-8257-466c770306ec',
    name: 'tile_stove',
    label: 'planning_categories.tile_stove',
    description: 'planning_categories.tile_stove_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'tile_stove_equipment_type',
        description: 'planning_categories.tile_stove_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'c278b961-5444-424b-a89d-352e1ed4f75c',
    name: 'domestic_ventilation',
    label: 'planning_categories.domestic_ventilation',
    description: 'planning_categories.domestic_ventilation_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'domestic_ventilation_equipment_type',
        description: 'planning_categories.domestic_ventilation_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'e21edc6d-e8f4-4840-aa0e-640fb43ae29f',
    name: 'bus_system',
    label: 'planning_categories.bus_system',
    description: 'planning_categories.bus_system_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'bus_system_equipment_type',
        description: 'planning_categories.bus_system_equipment_type_description',
      }),
    ],
  },
  {
    _id: '6a8099fd-c63a-471b-9744-d86bb675e679',
    name: 'solar_heating',
    label: 'planning_categories.solar_heating',
    description: 'planning_categories.solar_heating_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [],
  },
  {
    id: 'a92230e3-867b-4b07-a80f-8a05d4259a19',
    name: 'reserves_for_unexpected_costs',
    label: 'planning_categories.reserves_for_unexpected_costs',
    description: 'planning_categories.reserves_for_unexpected_costs_description',
    autoselect: false,
    group: 'other',
    costs_type: 'building',
    form: [],
  },
  {
    _id: '6a1592c8-10e3-4955-bf72-581262c0efa0',
    name: 'other_investments',
    label: 'planning_categories.other_investments',
    description: 'planning_categories.other_investments_description',
    autoselect: false,
    group: 'other',
    costs_type: 'building',
    required_value: 'other_investments_amount',
    form: [
      {
        label: 'planning.other_investments_amount',
        type: 'number',
        name: 'other_investments_amount',
        required: true,
      },
      {
        label: 'planning.other_investments_comment',
        type: 'textarea',
        name: 'other_investments_comment',
        required: true,
        minRows: 8,
      },
    ],
  },
];
