import gql from 'graphql-tag';
import { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const GET_PROJECTS_QUERY = gql`
  query projects {
    projects {
      _id
      name
      pdf
      type
      price
      last_updated_at
    }
  }
`;

const useProjects = () => {
  const { data, loading } = useQuery(GET_PROJECTS_QUERY);

  const projects = useMemo(() => get(data, 'projects', []), [data]);

  return [projects, loading];
};

export default useProjects;
