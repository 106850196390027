import PageTitle from 'components/common/PageTitle';
import ScrollToError from 'components/common/ScrollToError';
import { Field, Form, Formik } from 'formik';
import { defaults, pick } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import ErrorMessage from 'components/common/ErrorMessage';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, SaveIcon } from 'assets/icons';
import { __ } from 'utils/form';
import { formatCurrency } from 'utils/helpers';
import useUpdateBudget from 'hooks/budget/useUpdateBudget';
import NumberInput from 'components/inputs/NumberInput';
import { toast } from 'react-toastify';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { useApolloClient } from '@apollo/react-hooks';
import { MY_BUDGET_QUERY } from 'hooks/budget/useMyBudget';
import { BudgetStep5 } from 'assets/images/lifestyle-calculator';
import useChangeBudgetStep from 'hooks/budget/useChangeBudgetStep';
import IconButton from 'components/common/IconButton';
import s from './LifestyleCalculatorFifthStep.module.scss';

const fillFieldError = yup
  .number()
  .required(__('errors.fill_field'))
  .typeError(__('errors.fill_field'));

const validationSchema = yup.object().shape({
  socializing: fillFieldError,
  luxuries: fillFieldError,
  new_car: fillFieldError,
  taking_a_break: fillFieldError,
  hobbies: fillFieldError,
  technology: fillFieldError,
  pension_and_insurances: fillFieldError,
  monthly_expenses: fillFieldError,
  costs_for_repair: fillFieldError,
});

const useUpdateBudgetHandler = (calculatorCompleted, budgetStep) => {
  const updateBudget = useUpdateBudget();
  const [changeBudgetStep] = useChangeBudgetStep();
  const client = useApolloClient();
  const { locale } = useIntl();

  const handleSubmit = React.useCallback(
    async (variables, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const result = await updateBudget(variables);
        const budgetData = client.readQuery({ query: MY_BUDGET_QUERY, variables: { locale } });
        client.writeQuery({ query: MY_BUDGET_QUERY, data: { myBudget: defaults(result, budgetData.myBudget) } });
        if (!calculatorCompleted && budgetStep < 6) await changeBudgetStep(6);
        window.scrollTo(0, 0);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [updateBudget, client, locale, calculatorCompleted, budgetStep, changeBudgetStep],
  );
  return handleSubmit;
};

const MonthlyExpenses = ({ myBudget, t }) => {
  const calculatedValues = useMemo(
    () =>
      Object.entries(myBudget.calculation).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.user_input ? value.user_input : value.calculated_value };
      }, {}),
    [myBudget.calculation],
  );

  return (
    <>
      <h2>{t({ id: 'budget_calculator.fifth_step.monthly_expenses_subtitle' })}</h2>
      <ul className={s.monthlyExpensesUl}>
        <ol>
          <p>{t({ id: 'budget_calculator.socializing' })}</p>
          <p>{formatCurrency(calculatedValues.socializing)}</p>
        </ol>
        <ol>
          <p>{t({ id: 'budget_calculator.luxuries' })}</p>
          <p>{formatCurrency(calculatedValues.luxuries)}</p>
        </ol>
        {calculatedValues.new_car ? (
          <ol>
            <p>{t({ id: 'budget_calculator.cars' }, { number: myBudget.formData.cars })}</p>
            <p>{formatCurrency(calculatedValues.new_car)}</p>
          </ol>
        ) : null}
        <ol>
          <p>{t({ id: 'budget_calculator.taking_a_break' })}</p>
          <p>{formatCurrency(calculatedValues.taking_a_break)}</p>
        </ol>
        <ol>
          <p>{t({ id: 'budget_calculator.hobbies' })}</p>
          <p>{formatCurrency(calculatedValues.hobbies)}</p>
        </ol>
        <ol>
          <p>{t({ id: 'budget_calculator.technology' })}</p>
          <p>{formatCurrency(calculatedValues.technology)}</p>
        </ol>
        <ol>
          <p>{t({ id: 'budget_calculator.pension_and_insurances' })}</p>
          <p>{formatCurrency(calculatedValues.pension_and_insurances)}</p>
        </ol>
        <ol>
          <p>{t({ id: 'budget_calculator.monthly_expenses' })}</p>
          <p>{formatCurrency(calculatedValues.monthly_expenses)}</p>
        </ol>
        {myBudget.formData.alone_or_partner === 'partner' ? (
          <ol>
            <p>{t({ id: 'budget_calculator.monthly_expenses_partner' })}</p>
            <p>{formatCurrency(calculatedValues.monthly_expenses_partner)}</p>
          </ol>
        ) : null}
        {myBudget.formData.kids_quantity ? (
          <ol>
            <p>{t({ id: 'budget_calculator.monthly_expenses_kids' })}</p>
            <p>{formatCurrency(calculatedValues.monthly_expenses_kids)}</p>
          </ol>
        ) : null}
        <ol>
          <p>{t({ id: 'budget_calculator.costs_for_repair' })}</p>
          <p>{formatCurrency(calculatedValues.costs_for_repair)}</p>
        </ol>
        {myBudget.formData.amount_of_alimony ? (
          <ol>
            <p>{t({ id: 'budget_calculator.amount_of_alimony' })}</p>
            <p>{formatCurrency(myBudget.formData.amount_of_alimony)}</p>
          </ol>
        ) : null}
        {myBudget.formData.monthly_loan ? (
          <ol>
            <p>{t({ id: 'budget_calculator.monthly_loan' })}</p>
            <p>{formatCurrency(myBudget.formData.monthly_loan)}</p>
          </ol>
        ) : null}
      </ul>
    </>
  );
};

const monthlyExpensesKeys = [
  'calculation.socializing',
  'calculation.luxuries',
  'calculation.new_car',
  'calculation.taking_a_break',
  'calculation.hobbies',
  'calculation.technology',
  'calculation.pension_and_insurances',
  'calculation.monthly_expenses',
  'calculation.monthly_expenses_partner',
  'calculation.monthly_expenses_kids',
  'calculation.costs_for_repair',
  'formData.amount_of_alimony',
  'formData.monthly_loan',
];

function getInitialValues(lifestyleCalculations) {
  if (!lifestyleCalculations) return {};
  const { calculation, formData } = pick(lifestyleCalculations, monthlyExpensesKeys);
  const values = { ...calculation, ...formData };
  return Object.entries(values).reduce((acc, [fieldKey, fieldValue]) => {
    if (Number.isFinite(fieldValue) && fieldValue !== 0) return { ...acc, [fieldKey]: fieldValue };
    const value = Number.isFinite(fieldValue.user_input) ? fieldValue.user_input : fieldValue.calculated_value;
    return { ...acc, [fieldKey]: value };
  }, {});
}

const MonthlyExpensesForm = ({
  myBudget,
  currentStep,
  calculatorCompleted,
  nextStep,
  t,
  previousStep,
  onBack,
  isEditMode,
}) => {
  const initialValues = useMemo(() => getInitialValues(myBudget), [myBudget]);
  const onSubmit = useUpdateBudgetHandler(calculatorCompleted, myBudget.step);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, { currentStep: currentStep + 1 });
        nextStep();
      }}
    >
      {({ isSubmitting, values, errors, isValid }) => (
        <Form>
          <ScrollToError />
          <Field
            name="socializing"
            component={NumberInput}
            max={5000}
            label={t({ id: 'budget_calculator.socializing' })}
          />
          <Field name="luxuries" component={NumberInput} max={5000} label={t({ id: 'budget_calculator.luxuries' })} />

          {myBudget.formData.cars ? (
            <Field
              name="new_car"
              component={NumberInput}
              max={5000}
              label={t({ id: 'budget_calculator.cars' }, { number: myBudget.formData.cars })}
              validate={(value) => (!Number.isFinite(value) ? t({ id: 'errors.fill_field' }) : '')}
            />
          ) : null}
          <Field
            name="taking_a_break"
            component={NumberInput}
            max={5000}
            label={t({ id: 'budget_calculator.taking_a_break' })}
          />
          <Field name="hobbies" component={NumberInput} max={5000} label={t({ id: 'budget_calculator.hobbies' })} />
          <Field
            name="technology"
            component={NumberInput}
            max={5000}
            label={t({ id: 'budget_calculator.technology' })}
          />
          <Field
            name="pension_and_insurances"
            component={NumberInput}
            max={5000}
            label={t({ id: 'budget_calculator.pension_and_insurances' })}
          />

          <Field
            name="monthly_expenses"
            component={NumberInput}
            max={5000}
            label={t({ id: `budget_calculator.monthly_expenses` })}
          />
          {myBudget.formData.alone_or_partner === 'partner' ? (
            <Field
              name="monthly_expenses_partner"
              component={NumberInput}
              max={5000}
              label={t({ id: `budget_calculator.monthly_expenses_partner` })}
              validate={(value) => (!Number.isFinite(value) ? t({ id: 'errors.fill_field' }) : '')}
            />
          ) : null}
          {myBudget.formData.kids_quantity ? (
            <Field
              name="monthly_expenses_kids"
              component={NumberInput}
              max={5000}
              label={t({ id: `budget_calculator.monthly_expenses_kids` })}
              validate={(value) => (!Number.isFinite(value) ? t({ id: 'errors.fill_field' }) : '')}
            />
          ) : null}
          <Field
            name="costs_for_repair"
            component={NumberInput}
            max={5000}
            label={t({ id: `budget_calculator.costs_for_repair` })}
          />
          {myBudget.formData.amount_of_alimony ? (
            <Field
              name="amount_of_alimony"
              component={NumberInput}
              max={5000}
              label={t({ id: `budget_calculator.amount_of_alimony` })}
              validate={(value) => (!Number.isFinite(value) ? t({ id: 'errors.fill_field' }) : '')}
            />
          ) : null}
          {myBudget.formData.monthly_loan ? (
            <Field
              name="monthly_loan"
              component={NumberInput}
              largeLabel
              max={2000}
              label={t({ id: `budget_calculator.monthly_loan` })}
              validate={(value) => (!Number.isFinite(value) ? t({ id: 'errors.fill_field' }) : '')}
            />
          ) : null}

          {errors.form ? <ErrorMessage message={errors.form} /> : null}

          <div className={s.buttonsWrapper}>
            <Button
              disabled={isSubmitting || !isValid}
              onClick={() => onBack(values, previousStep)}
              startIcon={<ArrowBack />}
              color="outline"
            >
              {t({ id: 'app.back' })}
            </Button>
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
              className="ml-auto"
              color="secondary"
            >
              {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const LifestyleCalculatorFifthStep = ({
  nextStep,
  currentStep,
  previousStep,
  calculatorCompleted,
  clickableTabStep,
  myBudget,
  onBack,
}) => {
  const { formatMessage: t } = useIntl();
  const [showForm, setForm] = useState(false);
  const [changeStep, isSubmitting] = useChangeBudgetStep();
  const isEditMode = useMemo(() => calculatorCompleted || myBudget?.step >= clickableTabStep, [
    myBudget,
    clickableTabStep,
    calculatorCompleted,
  ]);

  const onNextStep = useCallback(() => {
    if (!isEditMode) {
      changeStep(currentStep + 1);
    }
    nextStep();
  }, [changeStep, currentStep, isEditMode, nextStep]);

  return (
    <Row between="xl" center="md">
      <Col md={10} lg={8} xl={6} className="text-left">
        <div className="d-flex-center-between">
          <PageTitle>{t({ id: 'budget_calculator.fifth_step_title' })}</PageTitle>
          <IconButton onClick={() => setForm(true)}>
            <PencilIcon />
          </IconButton>
        </div>
        {showForm ? (
          <MonthlyExpensesForm
            {...{ myBudget, calculatorCompleted, currentStep, nextStep, t, previousStep, onBack, isEditMode }}
          />
        ) : (
          <>
            <MonthlyExpenses {...{ myBudget, t }} />
            <div>
              <div className={s.buttonsWrapper}>
                <Button disabled={isSubmitting} onClick={previousStep} startIcon={<ArrowBack />} color="outline">
                  {t({ id: 'app.back' })}
                </Button>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={onNextStep}
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                  color="secondary"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </div>
          </>
        )}
      </Col>
      <Col md={10} lg={8} xl={6} first="xs" last="xl">
        <div className="sticky-img-box">
          <BudgetStep5 title={t({ id: 'alt_text.budget_calculator.fourth_step' })} width="100%" height="100%" />
        </div>
      </Col>
    </Row>
  );
};

export default LifestyleCalculatorFifthStep;
