import { isMobile } from 'react-device-detect';

export default {
  xl: {
    content: {
      ...(isMobile
        ? {
            width: '100%',
          }
        : {
            width: '60%',
            minWidth: '750px',
            maxWidth: '850px',
          }),
    },
  },
  lg: {
    content: {
      ...(isMobile
        ? {
            width: '100%',
          }
        : {
            width: '45%',
            minWidth: '550px',
            maxWidth: '600px',
          }),
    },
  },
  md: {
    content: {
      ...(isMobile
        ? {
            width: '100%',
          }
        : {
            width: '40%',
            minWidth: '450px',
            maxWidth: '500px',
          }),
    },
  },
  sm: {
    content: {
      ...(isMobile
        ? {
            width: '100%',
          }
        : {
            width: '30%',
            minWidth: '380px',
          }),
    },
  },
};
