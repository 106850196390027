import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import CO2_CALCULATIONS_FRAGMENT from 'graphql/fragments/co2-calculator/co2Calculations';

export const CO2_CALCULATIONS = gql`
  query co2Calculations($projectId: ID!) {
    co2Calculations(projectId: $projectId) {
      ...co2CalculationsFragment
    }
  }
  ${CO2_CALCULATIONS_FRAGMENT}
`;

const useGetCO2Calculations = (projectId) => {
  const { data, loading } = useQuery(CO2_CALCULATIONS, { variables: { projectId } });
  return [data?.co2Calculations, loading];
};

export default useGetCO2Calculations;
