import React, { useMemo } from 'react';
import { ReactComponent as CO2Icon } from 'assets/icons/co2-calculator/co2.svg';
import { ReactComponent as LightningIcon } from 'assets/icons/lightning-2.svg';
import { ReactComponent as EuroIcon } from 'assets/icons/sidebar/euro.svg';
import { ArrowRight } from 'assets/icons';
import { clamp, get, round } from 'lodash';
import { formatValue } from 'utils/helpers';
import theme from 'config/theme';
import s from './EnergyReductionItems.module.scss';

function getEnergyItems(calculations, type) {
  const prop = {
    potential: 'co2_emissions_potential.result',
    after: 'co2_emissions_after.result',
  };
  const potentialValue = get(calculations, prop[type], {});
  const {
    co2_emissions_before: { result: co2BeforeResult },
  } = calculations;

  return [
    {
      icon: CO2Icon,
      altText: 'alt_text.renovation_house.co2_icon',
      color: theme.successColor,
      label: 'renovation_house_wizard.potential_energy_balance.ghg_balance.label',
      valueLabel: 'renovation_house_wizard.potential_energy_balance.ghg_balance.value',
      actualValue: co2BeforeResult.energy_ghg_balance_per_year,
      potentialValue: potentialValue.energy_ghg_balance_per_year,
    },
    {
      icon: EuroIcon,
      altText: 'alt_text.renovation_house.euro_icon',
      color: theme.primaryColor,
      label: 'renovation_house_wizard.potential_energy_balance.total_energy_costs.label',
      valueLabel: 'renovation_house_wizard.potential_energy_balance.total_energy_costs.value',
      actualValue: co2BeforeResult.total_energy_costs,
      potentialValue: potentialValue.total_energy_costs,
    },
    {
      icon: LightningIcon,
      altText: 'alt_text.renovation_house.lightning_icon',
      color: theme.pastelGreenColor,
      label: 'renovation_house_wizard.potential_energy_balance.total_energy_balance_per_year.label',
      valueLabel: 'renovation_house_wizard.potential_energy_balance.total_energy_balance_per_year.value',
      actualValue: co2BeforeResult.total_energy_balance_per_year,
      potentialValue: potentialValue.total_energy_balance_per_year,
    },
  ];
}

const EnergyReductionItem = ({
  t,
  label,
  icon: Icon,
  altText,
  color,
  actualValue,
  potentialValue,
  valueLabel,
  percentageDiff,
}) => {
  const lineWidth = clamp(100 - percentageDiff, 0, 100);
  const valuesDiff = round(actualValue - potentialValue);
  const formattedDiff = valuesDiff < 0 ? `+${formatValue(Math.abs(valuesDiff))}` : formatValue(100 - valuesDiff);
  const percentageValue = percentageDiff > 100 ? `+${percentageDiff - 100}` : `-${100 - percentageDiff}`;

  return (
    <div className={s.potentialItem}>
      <div className={s.circle} style={{ backgroundColor: color }}>
        <Icon title={t({ id: altText })} width="100%" height="100%" />
      </div>

      <div className={s.info}>
        <p className={s.label}>{t({ id: label })}</p>
        <div className={s.differenceValue}>
          <p className={s.value} style={{ color }}>
            {t({ id: valueLabel }, { value: formattedDiff })}
          </p>
          <div className={s.beforeAndAfterValues}>
            <span style={{ color }}>{formatValue(round(actualValue))}</span>
            <span>
              <ArrowRight />
            </span>
            <span className="text-gray">{formatValue(round(potentialValue))}</span> <span>({percentageValue}%)</span>
          </div>
        </div>
        <div className={s.barChart}>
          <div style={{ backgroundColor: color, width: `${lineWidth}%` }} className={s.potentialValue} />
        </div>
      </div>
    </div>
  );
};

export default function EnergyReductionItems({ co2Calculations, t, type = 'potential' }) {
  const potentialItems = useMemo(() => getEnergyItems(co2Calculations, type), [co2Calculations, type]);
  return (
    <div className={s.potentialValues}>
      {potentialItems.map(({ actualValue, potentialValue, ...rest }) => {
        const percentageDiff = round((potentialValue / actualValue) * 100);
        return (
          <EnergyReductionItem key={rest.label} {...{ percentageDiff, potentialValue, actualValue, t, ...rest }} />
        );
      })}
    </div>
  );
}
