import renovationHouseFeaturesSchema from 'constants/wizards/renovation-house-features';

export const whitelistedPropsCO2Calculator = [
  'step',
  'heated_living_area',
  'kind_of_house',
  'energy_standard',
  'renovations',
  'is_solar_heating_system',
  'solar_heating_system_type',
  'number_of_people',
  'heating_system',
  'age_of_heating_system',
  'temperature',
  'hot_water_producer',
  'amount_of_hot_water',
  'is_devices_younger_than_10_years',
  'power_consumers',
  'is_solar_power_system',
  'solar_power_system_size',
  'solar_power_system_consuming_percentage',
];

export const whitelistedProps = [
  'name',
  'zip',
  'country',
  'form_values.house_area',
  'form_values.number_of_floors',
  ...whitelistedPropsCO2Calculator.map((key) => `calculations.co2_emissions_before.form_values.${key}`),
  ...renovationHouseFeaturesSchema.flatMap((feature) =>
    Array.isArray(feature.form) ? feature.form.map(({ name }) => `form_values.${name}`) : [],
  ),
];

export const initialValuesCO2Calculator = {
  heated_living_area: null,
  kind_of_house: null,
  energy_standard: null,
  renovations: [],
  is_solar_heating_system: false,
  solar_heating_system_type: null,
  number_of_people: null,
  heating_system: null,
  age_of_heating_system: null,
  temperature: null,
  hot_water_producer: null,
  amount_of_hot_water: null,
  is_devices_younger_than_10_years: false,
  power_consumers: [],
  is_solar_power_system: false,
  solar_power_system_size: null,
  solar_power_system_consuming_percentage: null,
};
