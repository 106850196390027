import React from 'react';
import Dropdown from './Dropdown';
import Navbar from './Navbar';

const AppNavbar = () => (
  <Navbar fluid>
    <Dropdown />
  </Navbar>
);
export default AppNavbar;
