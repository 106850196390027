/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import classnames from 'classnames';
import { changeLanguage } from 'utils/helpers';
import styles from './Footer.module.scss';

export default function Footer({ fluid }) {
  return (
    <footer onClick={changeLanguage} className={styles.footer}>
      <div className={classnames({ 'container-fluid': fluid, 'root-container': !fluid })}>
        Copyright © {new Date().getFullYear()} HYPO NOE Landesbank für Niederösterreich und Wien AG
      </div>
    </footer>
  );
}
