import React from 'react';
import { Formik, Field, Form } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import ErrorMessage from 'components/common/ErrorMessage';
import routePaths from 'router/route-paths';
import OtpModalField from 'components/inputs/OtpModalField';
import { ArrowForward } from 'assets/icons';
import ScrollToError from 'components/common/ScrollToError';
import settings from 'config/settings';
import TextInput from 'components/inputs/TextInput';
import Checkbox from 'components/inputs/Checkbox';
import Button from 'components/common/Button';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { GUEST } from 'constants/constants';
import PasswordInput from '../inputs/PasswordInput';
import Switcher from './Switcher';
import s from './LoginForm.module.scss';

const loginShape = {
  email: yup
    .string()
    .required(<FormattedMessage id="errors.email_required" />)
    .email(<FormattedMessage id="errors.email_invalid" />),
  plainPassword: yup
    .string()
    .required(<FormattedMessage id="errors.password_required" />)
    .min(8, <FormattedMessage id="errors.password_too_small" />),
};
const signUpShape = {
  firstName: yup.string().required(<FormattedMessage id="errors.first_name_required" />),
  lastName: yup.string().required(<FormattedMessage id="errors.last_name_required" />),
  terms_of_use_consent: yup.bool().oneOf([true], <FormattedMessage id="auth.terms_of_use_required" />),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('plainPassword'), null], <FormattedMessage id="errors.passwords_must_match" />),
  ...loginShape,
};
const loginParamsSchema = yup.object().shape(loginShape);
const signUpParamsSchema = yup.object().shape(signUpShape);

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  plainPassword: '',
  passwordRepeat: '',
  consent: false,
};

export default function LoginForm({
  onSubmit,
  initialValues,
  signUpEnabled = true,
  isAdmin,
  isSignUp,
  setIsSignUp,
  hideModal,
  isVisible,
  onSubmitAdminLogin,
}) {
  const { formatMessage: t } = useIntl();
  const schema = React.useMemo(() => (isSignUp ? signUpParamsSchema : loginParamsSchema), [isSignUp]);
  const isGuest = useHasRequiredRole(GUEST);
  return (
    <Formik initialValues={initialValues || defaultInitialValues} validationSchema={schema} onSubmit={onSubmit}>
      {({ errors, isSubmitting }) => (
        <Form>
          <ScrollToError />
          {isSignUp ? (
            <div>
              <Field name="firstName" component={TextInput} label={t({ id: 'auth.firstName' })} />
              <Field name="lastName" component={TextInput} label={t({ id: 'auth.lastName' })} />
            </div>
          ) : null}
          <Field name="email" lowerCase component={TextInput} label={t({ id: 'auth.email' })} type="email" />

          <Field name="plainPassword" component={PasswordInput} label={t({ id: 'auth.password' })} type="password" />
          <Field
            name="otp"
            component={OtpModalField}
            label={t({ id: 'auth.verification_code_tip' })}
            hideModal={hideModal}
            isVisible={isVisible}
            {...{ hideModal, isVisible, onSubmitAdminLogin }}
          />
          {isSignUp ? (
            <>
              <Field
                name="passwordRepeat"
                component={PasswordInput}
                label={t({ id: 'auth.repeat_password' })}
                type="password"
              />
              <Field
                name="terms_of_use_consent"
                component={Checkbox}
                label={
                  <FormattedMessage
                    id="auth.terms_of_use"
                    values={{
                      link: (
                        <a
                          href={settings.termsOfUseUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                        >
                          {t({ id: 'auth.terms_of_use_link_label' })}
                        </a>
                      ),
                    }}
                  />
                }
              />
              <p className="text-left">
                <FormattedMessage
                  id="auth.privacy_policy"
                  values={{
                    data_processing_label: (
                      <a
                        href={settings.dataProcessingUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                      >
                        {t({ id: 'auth.data_processing_label' })}
                      </a>
                    ),
                    data_protection_label: (
                      <a
                        href={settings.dataProtectionUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                      >
                        {t({ id: 'auth.data_protection_label' })}
                      </a>
                    ),
                  }}
                />
              </p>
            </>
          ) : null}
          {!isSignUp && !isAdmin ? (
            <p className="text-left">
              <Link to={isGuest ? routePaths.guestForgotPassword : routePaths.forgotPassword} className="link">
                {t({ id: 'auth.forgot_password.note' })}
              </Link>
            </p>
          ) : null}

          {signUpEnabled && !isGuest ? <Switcher {...{ setIsSignUp, isSignUp }} /> : null}

          {errors.form && <ErrorMessage message={errors.form} />}

          <div className={s.btnWrapper}>
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting} endIcon={<ArrowForward />}>
              {t({ id: isSignUp ? 'auth.sign_up_submit' : 'auth.sign_in_submit' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
