import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const EXPRESS_LOAN_FORM_VALUES_FRAGMENT = gql`
  fragment expressLoanPersonalOfferFragment on ExpressLoanPersonalOfferFormValues {
    alone_or_partner
    kids_quantity
    cars
    loan_exists
    full_address
    zip
    property_type
    land_area
    living_area
    construction_year
    parking
  }
`;

const PERSONA_OFFER_FORM_VALUES_QUERY = gql`
  query personalOfferFormValues($id: ID!) {
    expressLoan(id: $id) {
      _id
      personal_offer_form_values {
        ...expressLoanPersonalOfferFragment
      }
    }
  }
  ${EXPRESS_LOAN_FORM_VALUES_FRAGMENT}
`;

const useExpressLoanPersonalOfferFormValues = ({ id }) => {
  const { loading, error, data } = useQuery(PERSONA_OFFER_FORM_VALUES_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });
  return { loading, error, formValues: data?.expressLoan?.personal_offer_form_values };
};

export default useExpressLoanPersonalOfferFormValues;
