import React from 'react';
import useLastProjectId from 'hooks/project/useLastProjectId';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { useParams, Link } from 'react-router-dom';
import { Visible } from 'components/common/Grid';
import Alert from 'components/common/Alert';
import { SectionWrapper } from 'components/common/heap';
import { routePatterns } from 'router/route-paths';
import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import NegativeMonthlyRateBudgetStep from './NegativeMonthlyRateBudgetStep';
import AffordableLoanInfo from './AffordableLoanInfo';
import MonthlyNetIncomeInfo from './MonthlyNetIncomeInfo';
import MonthlyExpensesInfo from './MonthlyExpensesInfo';
import s from './BudgetSummary.module.scss';

export function MonthlyRateMessage({ myBudget: { calculation } }) {
  const monthlyRate = get(calculation, 'monthly_rate_loan.calculated_value', null);
  const potentialAmountOfLoan = get(calculation, 'potential_amount_of_loan.calculated_value');
  const potentialMonthlyRateLoan = get(calculation, 'potential_monthly_rate_loan.calculated_value');
  const monthlyRateLoan = get(calculation, 'monthly_rate_loan.calculated_value');
  const amountOfLoan = get(calculation, 'amount_of_loan.calculated_value');

  const myBudget = { potentialAmountOfLoan, potentialMonthlyRateLoan, monthlyRateLoan, amountOfLoan };

  if (monthlyRate < 0) {
    return (
      <Alert color="danger">
        <NegativeMonthlyRateBudgetStep negativeMonthlyRateLoan={Math.abs(monthlyRate)} />
      </Alert>
    );
  }
  return <AffordableLoanInfo {...{ myBudget }} />;
}

const BudgetSummary = ({ myBudget }) => {
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();
  const { lastProjectId } = useLastProjectId();

  return (
    <div className={s.budgetSummary}>
      <Visible xl xxl>
        <MonthlyRateMessage {...{ myBudget }} />
      </Visible>

      <SectionWrapper>
        <MonthlyNetIncomeInfo {...{ myBudget }} />
      </SectionWrapper>
      <div className={s.divider} />
      <SectionWrapper>
        <MonthlyExpensesInfo {...{ myBudget }} />
      </SectionWrapper>

      <div className={s.btnWrapper}>
        {projectId || lastProjectId ? (
          <Link to={routePatterns.loan.stringify({ id: projectId || lastProjectId })}>
            <Button color="secondary" endIcon={<ArrowForward />}>
              {t({ id: 'budget.btn_to_financing' })}
            </Button>
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default BudgetSummary;
