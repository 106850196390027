import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const PROJECT_CALCULATIONS = gql`
  query project_calculations($id: ID!) {
    project(id: $id) {
      _id
      calculations {
        ... on NewBuildingCalculations {
          sprengnetter_land(projectId: $id) {
            market_value
            request_completed
            form_values {
              coordinates {
                lat
                lng
              }
              land_area
            }
            error
          }
        }
        ... on BuyingHouseCalculations {
          sprengnetter(projectId: $id) {
            market_value
            error
            request_completed
            form_values {
              construction_year
              cost_house
              coordinates {
                lat
                lng
              }
              land_area
              house_area
              house_category
              house_type
              number_of_floors
              parking
              basement_type
            }
          }
          sprengnetter_land(projectId: $id) {
            market_value
            request_completed
            form_values {
              land_area
              coordinates {
                lat
                lng
              }
            }
            error
          }
        }
        ... on NewApartmentCalculations {
          sprengnetter(projectId: $id) {
            market_value
            error
            request_completed
            form_values {
              cost_apartment
              coordinates {
                lat
                lng
              }
              apartment_area
              construction_year
              condition_of_apartment
              parking
              floor_number
              elevator
            }
          }
        }
      }
    }
    me {
      _id
      sprengnetterRequestsCount
    }
  }
`;

const useGetProjectCalculations = (projectId) => {
  const { data, loading } = useQuery(PROJECT_CALCULATIONS, {
    variables: { id: projectId },
    fetchPolicy: 'cache-and-network',
  });

  const calculations = get(data, 'project.calculations', null);
  const sprengnetterRequestsCount = get(data, 'me.sprengnetterRequestsCount', null);

  return { calculations, sprengnetterRequestsCount, loading };
};

export default useGetProjectCalculations;
