import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FINANCING_QUERY } from './useFinancing';

const LOAN_MUTATION = gql`
  mutation submitLoan($projectId: ID!, $formData: LoanInput!) {
    submitLoan(projectId: $projectId, formData: $formData)
  }
`;

const useSubmitLoan = () => {
  const [submitLoanMutation] = useMutation(LOAN_MUTATION);

  const submitLoan = useCallback(
    async ({ projectId, formData }) => {
      try {
        await submitLoanMutation({
          variables: { projectId, formData },
          refetchQueries: [{ query: FINANCING_QUERY, variables: { id: projectId } }],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        throw e;
      }
    },
    [submitLoanMutation],
  );
  return submitLoan;
};

export default useSubmitLoan;
