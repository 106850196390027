import gql from 'graphql-tag';

export default gql`
  fragment buyingHouseFormValues on BuyingHouseFormValues {
    cost_house
    property_transfer_tax
    land_register_fee
    notary_fee
    broker_commission

    full_address
    coordinates {
      lat
      lng
    }
    land_area
    house_area
    house_type
    house_category
    number_of_floors
    basement_type
    construction_year
    number_of_toilets
    number_of_bathrooms
    parking
    heating_system
    number_of_car_ports
    number_of_garage_places
    solar_power_size_kwp
    new_windows_number
    number_of_lift_and_slide_doors
    number_of_extra_large_windows
    renovation_walls_and_interior_doors_area
    new_flooring_area
    sanitary_renovation_area
    number_of_bathrooms_for_renovation
    number_of_toilets_for_renovation
    facade_insulation_type
    facade_insulation_area
    renewal_of_front_door
    roof_renewal_type
    roof_renewal_area
    heating_system_for_renovation
    insulation_top_ceiling_area
    insulation_basement_ceiling_area
    number_of_interior_doors_for_renovation

    other_investments_amount
    other_investments_comment

    domestic_ventilation_equipment_type
    carport_equipment_type
    garage_equipment_type
    alarm_system_equipment_type
    facade_insulation_equipment_type
    roof_renewal_equipment_type
    solar_power_equipment_type
    kitchen_equipment_type
    air_condition_equipment_type
    sanitary_renovation_equipment_type
    new_flooring_equipment_type
    renovation_walls_and_interior_doors_equipment_type
    renewal_of_electric_installation_equipment_type
    renewal_of_front_door_equipment_type
    new_windows_equipment_type
    renewal_of_heating_system_equipment_type
    pool_equipment_type
    winter_garden_equipment_type
  }
`;
