export default {
  type: 'screen_link',
  // label: '',
  title: 'project_wizard.equipment_type',
  content: [
    {
      key: 1,
      image: {
        src: require('assets/images/equipment_standard.jpg'),
      },
      description: [
        {
          type: 'title',
          text: 'project_wizard.equipment_type_content.standard_title',
        },
        {
          type: 'paragraph',
          text: 'project_wizard.equipment_type_content.standard_description',
        },
      ],
    },
    {
      key: 2,
      image: {
        src: require('assets/images/equipment_premium.jpg'),
      },
      description: [
        {
          type: 'title',
          text: 'project_wizard.equipment_type_content.premium_title',
        },
        {
          type: 'paragraph',
          text: 'project_wizard.equipment_type_content.premium_description',
        },
      ],
    },
    {
      key: 3,
      image: {
        src: require('assets/images/equipment_superior.jpg'),
      },
      description: [
        {
          type: 'title',
          text: 'project_wizard.equipment_type_content.superior_title',
        },
        {
          type: 'paragraph',
          text: 'project_wizard.equipment_type_content.superior_description',
        },
      ],
    },
  ],
};
