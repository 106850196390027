import gql from 'graphql-tag';
import useAuth from './useAuth';
import { CURRENT_USER_FRAGMENT } from '../user/useUser';

const LOGIN_MUTATION = gql`
  mutation loginWithPassword(
    $email: String
    $plainPassword: String
    $tempPassword: String
    $projectId: ID
    $otp: String
  ) {
    loginWithPassword(
      email: $email
      plainPassword: $plainPassword
      tempPassword: $tempPassword
      projectId: $projectId
      otp: $otp
    ) {
      id
      refreshToken
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useLogin = () => {
  const [login] = useAuth(LOGIN_MUTATION);
  return login;
};

export default useLogin;
