import React, { useMemo } from 'react';
import { PieChart, Pie, Legend, ResponsiveContainer, Label, Text } from 'recharts';
import { useIntl } from 'react-intl';
import theme from 'config/theme';
import { Hidden } from './Grid';
import s from './DonutChart.module.scss';

export default function DonutChart({
  height = 310,
  thickness = 50,
  legendHeight = 30,
  project,
  labelInsideChart,
  hideLegendOnMobile = true,
}) {
  const { formatMessage: t } = useIntl();
  const labelPadding = 0;
  const radius = height / 2 - legendHeight - labelPadding * 2;
  const isLargeScreen = window.innerWidth >= 768;
  const labelFontSize = window.innerWidth <= 992 ? '0.7rem' : '1.15rem';

  const data = useMemo(
    () => [
      {
        name: t({ id: 'dashboard.budget_shortage_chart.shortage_label' }),
        value: Math.max(0, project.price - project.budget),
        fill: theme.primaryColor,
      },
      {
        name: t({ id: 'dashboard.budget_shortage_chart.budget_label' }),
        value: project.budget,
        fill: theme.yellowColor,
      },
    ],
    [project.budget, project.price, t],
  );

  const ChartLegend = (
    <Legend align="center" verticalAlign="bottom" iconType="circle" iconSize={24} height={legendHeight} />
  );

  return (
    <ResponsiveContainer width="100%" height={height} className={s.donutChart}>
      <PieChart>
        <Pie dataKey="value" data={data} outerRadius={radius} innerRadius={radius - thickness}>
          {labelInsideChart ? (
            <Label
              width={110}
              content={(props) => {
                const {
                  viewBox: { cx, cy },
                  width,
                } = props;
                const positioningProps = {
                  x: cx,
                  y: cy + 5,
                  width,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                  fill: 'black',
                  fontSize: labelFontSize,
                  fontWeight: 'bold',
                };

                // const contentPositioningProps = {
                //   x: cx - (isLargeScreen ? 25 : 15),
                //   y: cy - (isLargeScreen ? 65 : 40),
                //   textAnchor: 'middle',
                //   verticalAnchor: 'middle',
                // };

                return <Text {...positioningProps}>{labelInsideChart}</Text>;
              }}
            />
          ) : null}
        </Pie>

        {!isLargeScreen && hideLegendOnMobile ? (
          <Hidden xs sm>
            {ChartLegend}
          </Hidden>
        ) : (
          ChartLegend
        )}
      </PieChart>
    </ResponsiveContainer>
  );
}
