import gql from 'graphql-tag';
import { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import { COUNT_INVITATIONS_PER_PAGE } from 'constants/constants';

export const GET_INVITATIONS_QUERY = gql`
  query invitationsByLoanConsultant($limit: Int!, $offset: Int!, $filter: String) {
    invitationsByLoanConsultant(limit: $limit, offset: $offset, filter: $filter) {
      total
      xlsxUrl
      invitations {
        client {
          _id
          profile {
            email
            firstName
            lastName
          }
          createdAt
          lastLoginAt
          numberOfProjects
          temp_password_changed
          budgetPdf
          loanConsultant {
            email
            firstName
            lastName
          }
          invitedBy {
            email
            firstName
            lastName
          }
        }
        projects {
          _id
          name
          type
          pdf
          transferredBy {
            email
            firstName
            lastName
          }
          progress {
            project_details
            plan_adjusted
            budget_planning
            loan_calculator
            loan_request
          }
        }
      }
    }
  }
`;

const useGetInvitationsByLoanConsultant = ({ currentPage, filter }) => {
  const { data, loading, refetch } = useQuery(GET_INVITATIONS_QUERY, {
    variables: { offset: COUNT_INVITATIONS_PER_PAGE * (currentPage - 1), limit: COUNT_INVITATIONS_PER_PAGE, filter },
  });

  const invitations = useMemo(() => get(data, 'invitationsByLoanConsultant.invitations', []), [data]);
  const total = useMemo(() => get(data, 'invitationsByLoanConsultant.total', 0), [data]);
  const xlsxUrl = useMemo(() => get(data, 'invitationsByLoanConsultant.xlsxUrl', ''), [data]);

  return { invitations, total, xlsxUrl, loading, refetch };
};

export default useGetInvitationsByLoanConsultant;
