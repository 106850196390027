import { useApolloClient } from '@apollo/react-hooks';
import useUpdateCO2CalculatedValues from 'hooks/co2-calculator/useUpdateCO2CalculatedValues';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import React, { useState, useCallback } from 'react';
import { SectionWrapper } from 'components/common/heap';
import { useIntl } from 'react-intl';
import { CO2_CALCULATIONS } from 'hooks/co2-calculator/useCO2Calculations';
import HeatingResult from './HeatingResult';
import HotWaterResult from './HotWaterResult';
import ElectricityResult from './ElectricityResult';
import s from '../CO2CalculatorResult.module.scss';

const AllCalculations = ({ co2Calculations }) => {
  const client = useApolloClient();
  const { formatMessage: t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { id: projectId } = useParams();
  const updateCalculatedValue = useUpdateCO2CalculatedValues();

  const onUpdateCalculatedValueButtonClick = useCallback(
    async ({ callback, fieldName, fieldValue }) => {
      const data = client.readQuery({ query: CO2_CALCULATIONS, variables: { projectId } });
      const newData = cloneDeep(data);
      newData.co2Calculations.co2_emissions_before.result[fieldName].user_input = fieldValue;
      client.writeQuery({
        query: CO2_CALCULATIONS,
        variables: { projectId },
        data: { ...newData },
      });
      callback();
      await updateCalculatedValue(projectId, { [fieldName]: fieldValue });
    },
    [client, projectId, updateCalculatedValue],
  );

  return (
    <Collapsible
      open={isOpen}
      handleTriggerClick={() => setIsOpen(!isOpen)}
      transitionTime={800}
      trigger={
        <div style={{ margin: '25px 0' }}>
          <span>{t({ id: 'renovation_house_wizard.should_show_all_calculations' })}</span>
          <div className={s.showAllCalculations}>
            {t({ id: `renovation_house_wizard.${isOpen ? 'hide_calculations' : 'show_calculations'}` })}
          </div>
        </div>
      }
    >
      <SectionWrapper>
        <SectionWrapper>
          <HeatingResult {...{ co2Calculations, onSaveButtonClick: onUpdateCalculatedValueButtonClick }} />
        </SectionWrapper>
        <SectionWrapper>
          <HotWaterResult {...{ co2Calculations, onSaveButtonClick: onUpdateCalculatedValueButtonClick }} />
        </SectionWrapper>
        <SectionWrapper>
          <ElectricityResult {...{ co2Calculations, onSaveButtonClick: onUpdateCalculatedValueButtonClick }} />
        </SectionWrapper>
      </SectionWrapper>
    </Collapsible>
  );
};

export default AllCalculations;
