/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useMemo, useState, useCallback } from 'react';
import LoanCalculatorForm from 'components/loan/LoanCalculatorForm';
import PageTitle from 'components/common/PageTitle';
import { Row, Col } from 'react-flexbox-grid';
import DonutChart from 'components/common/DonutChart';
import { formatCurrency } from 'utils/helpers';
import Downloads from 'components/dashboard/Downloads';
import Overview from 'components/loan/Overview';
import { debounce } from 'lodash';
import { Hidden, Visible } from 'components/common/Grid';

export default function LoanCalculator({ project, t, initialValues, onSubmit }) {
  const [budget, setBudget] = useState(project?.budget - project?.estimated_property_price);
  const [estimatedPropertyPrice, setEstimatedPropertyPrice] = useState(project?.estimated_property_price);
  const ownFunds = useMemo(() => budget + estimatedPropertyPrice, [budget, estimatedPropertyPrice]);

  const monthlyRate = project?.loan?.monthly_rate || project?.financing_rate;
  const labelInsideChart = useMemo(
    () => t({ id: 'quick_calc_result.monthly_rate' }, { value: formatCurrency(monthlyRate) }),
    [monthlyRate, t],
  );

  const onChangeBudget = useCallback(
    debounce((v) => setBudget(v), 1000),
    [],
  );

  const onChangeEstimatedPropertyPrice = useCallback(
    debounce(({ value }) => setEstimatedPropertyPrice(value), 1000),
    [],
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <PageTitle>{t({ id: 'page_titles.loan' })}</PageTitle>
        </Col>
      </Row>
      <Row between="xs">
        <Col lg={7}>
          <Hidden xs sm md>
            <Overview project={{ budget: ownFunds, price: project?.price }} />
          </Hidden>

          {/* mobile start */}
          <Visible xs sm md>
            <Row>
              <Col xs={7}>
                <DonutChart
                  project={{ budget: ownFunds, price: project?.price }}
                  labelInsideChart={labelInsideChart}
                  height={200}
                  thickness={25}
                />
              </Col>
              <Col xs={5}>
                <Overview project={{ budget: ownFunds, price: project?.price }} />
              </Col>
              <Col xs={12}>
                <Downloads {...{ project }} />
              </Col>
            </Row>
          </Visible>
          {/* mobile end */}

          <LoanCalculatorForm
            initialValues={initialValues}
            projectName={project.name}
            projectId={project._id}
            onSubmit={onSubmit}
            onChangeBudget={onChangeBudget}
            onChangeEstimatedPropertyPrice={onChangeEstimatedPropertyPrice}
          />
        </Col>

        {/* desktop start */}
        <Hidden xs sm md>
          <Col lg={5} xl={4}>
            <Hidden lg>
              <DonutChart project={{ budget: ownFunds, price: project?.price }} labelInsideChart={labelInsideChart} />
            </Hidden>
            <Visible lg>
              <DonutChart
                project={{ budget: ownFunds, price: project?.price }}
                labelInsideChart={labelInsideChart}
                height={300}
                thickness={30}
              />
            </Visible>
            <Downloads {...{ project }} />
          </Col>
        </Hidden>
        {/* desktop end */}
      </Row>
    </>
  );
}
