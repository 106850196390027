export default {
  yes: 'Yes',
  no: 'No',
  cookie_declaration: {
    title: 'Cookie Declaration',
  },
  quick_calc_result: {
    costs: 'ESTIMATED PROJECT COSTS',
    monthly_rate: 'MONTHLY CREDIT RATE {value}',
    monthly_rate_description: 'Taking into account your own funds of {budget}',
    personal_space: 'PERSONAL SPACE',
    calculated_price_message:
      "The calculated price is for a 'ready to move into' house but without extras like furniture, terrace etc.",
    personal_space_description:
      '<p>Register and benefit from our free services:</p><ul><li>Detailed planning of more than 30 detail categories!</li><li>Calculation of budget surplus (affordable rate)</li><li>Free PDF download of all summaries</li><li>Real-time determination of an expected interest rate indication (variable or fixed interest rate)</li><li>Request a loan offer with personal advice</li></ul>',
    personal_area: 'Personal area',
    advantages: 'use it free of charge and without obligation',
    detailed_planning: 'Detailed planning of more than 30 data categories!',
    calculation_of_budget: 'Calculation of the budget surplus (affordable rate)',
    free_pdf_download: 'Free PDF download of all summaries',
    probable_interest_rate_indication:
      'Real-time determination of a probable Interest rate indication (variable or fixed interest rate)',
    loan_offer: 'Request a loan offer with personal advice',
    google_street_view: 'Area check with Google Street View',
    real_estate_valuation: 'Real estate valuation inc. Market value comparison',
    registration: 'Registration',
    register_now: 'Register now and use your personal area free of charge and without obligation',
    result: 'Ergebnis',
  },
  express_loan: {
    project_types: {
      loan_renovation: 'Renovation',
      loan_refinance: 'Refinance',
      loan_build: 'Build',
      loan_buying: 'Purchase',
    },
    steps: {
      first_step: 'Basic data',
      second_step: 'Overview',
      third_step: 'Offer/advisory',
    },
    final_modal: {
      header_title: 'Congratulations!',
      title: 'Wohnkredit von Zuhause aus vorbereiten',
      message: 'Thank you for your message! \n A housing expert will be in touch \n with you shortly!',
      backToHomeButton: 'Back to Home',
    },
    form: {
      renovation_costs: 'Renovation costs',
      has_property: 'Do you already own a building plot?',
      estimated_property_price: 'Estimated property price',
      estimated_purchase_property_price: 'Estimated purchase price',
      construction_costs: 'Construction costs',
      purchase_price: 'Purchase price',
      estate_agent: 'Estate agent',
      construction_cost: 'Construction cost',
      monthly_net_income_borrowers: 'Monthly net income of all borrowers',
      own_funds: 'Existing own funds (savings, securities, etc.)',
      period: 'Loan term in years',
      interest_type: 'Interest conditions',
      outstanding_loan_amount: 'Outstanding credit amount',
      penalty_payment_interest: 'Penalty payments',
      interest_types: {
        variable: 'Variable',
        fixed: 'Fixed',
      },
      fixed_interest_period: 'Fixed rate period',
      errors: {
        calculations_not_found: 'Calculations not found',
      },
    },
    table: {
      total_project_costs: 'Total project costs',
      property_transfer_tax: 'Property transfer tax',
      land_register_fee: 'Land registration fee',
      broker_commission: 'Estate agent',
      notary_fee: 'Notary fee',
      own_funds: 'Own funds',
      estimated_property_price: '*) Additional own funds in the form of an existing building plot in the amount of',
      loan_amount: 'Loan amount',
      execution_fee: 'Execution fee',
      registration_fee: 'Registration fee',
      loan_period: 'Loan period',
      loan_period_years: '{years} years',
      expected_fix_loan_rate: 'Expected interest rate {years} years fix',
      expected_variable_loan_rate: 'Interest rate',
      expected_variable_loan_rate_value: '{interest}',
      total_loan_amount: 'Total amount according to §2 paragraph 10 HikriG',
      monthly_credit_rate: 'Monthly credit rate',
      additional_costs: 'Additional costs',
      penalty_old_loan_payment: 'Penalty payment from old loan agreement',
    },
    advisory_request_button: 'Advisory request',
    individual_offer_button: '2 minutes for an individual offer',
    advisory_request_help_text: '@Ein:e Wohnbauexpert:in setzt sich umgehend mit Ihnen in Verbindung',
    individual_offer_help_text:
      'Mit wenigen Angaben, sind unsere Berater:innen noch schneller in der Erstellung Ihres individuellen Angebots.',
    personal_offer_form: {
      alone_or_partner: 'Do you take out the loan alone or together with someone else?',
      existing_loan_rate: 'How much is the monthly rate for it?',
      alone: 'Alone',
      partner: 'For two',
      kids_quantity: 'How many children live in the household?',
      cars: 'How many cars do you have?',
      loan_exists: 'Do you have an existing loan or lease agreement?',
      full_address: 'Complete address (street, house number, city)',
      zip: 'Postal code',
      property_type: 'Which property is it?',
      property_types: {
        apartment: 'Apartment',
        property: 'Property',
        family_house: 'One-two family house',
      },
      land_area: 'Land area (m²)',
      living_area: 'Living area (m²)',
      construction_year: 'Year of construction of the house?',
      parking: 'Parking space',
      parking_type: {
        outdoor_parking_space: 'Outdoor parking space',
        garage: 'Garage',
        underground_car_park: 'Underground car park',
      },
    },
  },
  project_types: {
    new_building: 'New single-family house',
    new_apartment: 'Buying apartment',
    buying_house: 'Buying family-house',
    renovation_house: 'Renovation house',
    property_valuation: 'Property valuation',
    lifestyle_calculator: 'Lifestyle calculator',
  },
  project_details: {
    tabs: {
      house_info: 'House info',
      apartment_info: 'Apartment info',
      co2_status: 'CO₂ Status',
      common: 'Common',
      energy: 'Energy',
      indoor: 'Indoor',
      outdoor: 'Outdoor',
      other: 'Other investments',
      cost_overview: 'Cost overview',
      sprengnetter_estimation: 'Sprengnetter estimation',
    },
  },
  sprengnetter_estimation: {
    sprengnetter: {
      title: 'Apartment cost estimation',
      not_available_for_guest:
        'Estimation is not available for the guest. In order to be able to do it, please register',
    },
    sprengnetter_land: {
      title: 'Land estimation',
      not_available_for_guest:
        'Estimation is not available for the guest. In order to be able to do it, please register',
    },
    modal_header_text: 'Sprengnetter estimation',
    reminder:
      'Do you really want to proceed without trying Sprengnetter property estimation? To get the estimation please click the "{buttonTitle}" button.',
    reminder_for_guest: 'Do you really want to proceed without estimation - you can {link_label} to use this feature!',
    reminder_for_guest_link_label: 'sign up here',
    complete_sprengnetter_form_btn: 'Complete sprengnetter form',
    tooltip_label: 'Sprengnetter',
    tooltip_description: '@Tooltip placeholder',
    estimated_range: 'Estimated sales range',
    ran_out_of_requests: '@All {requestsCount} evaluation requests were used',
    estimate_property_btn: 'Valuate with Sprengnetter',
  },
  auth: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    guest_sign_up: 'Continue as guest',
    temp_password: 'Your temporary password',
    password: 'Password',
    new_password: 'New password',
    repeat_new_password: 'Repeat new password',
    sign_up_submit: 'Sign up',
    sign_in_submit: 'Sign in',
    already_have_account: 'Already have an account?',
    already_have_account_sign_in_link: 'Sign in',
    dont_have_account: "Don't have an account?",
    dont_have_account_sign_up_link: 'Sign up',
    login_by_invitation_page_title: 'Login',
    login_page_title: 'Login',
    login_page_text:
      '<p> If you have already registered, you can log in here and continue with the saved status for the following modules: </p><ul> <li> Detailed planning of more than 30 detail categories! </li> <li> Calculation of the budget surplus (affordable rate) </li> <li> Free PDF download of all summaries </li> <li> Real-time determination of an expected interest rate indication (variable or fixed interest rate) </li> <li> Request a loan offer with a personal Advice </li> </ul>',
    forgot_password: {
      note: 'Forgot password?',
      message:
        'You will receive an email with instructions to reset your password if there is an account associated with this email',
      back_to_login: 'Back to login',
      reset_password: 'Reset password',
      password_changed_successfully: 'Password changed successfully',
    },
    repeat_password: 'Repeat password',
    passwords_must_match: 'Passwords must match',
    verification_code_tip: 'Get a 6-digit code from your Google Authenticator app and enter the code',
    terms_of_use: 'I accept the {link}',
    terms_of_use_text: 'Please accept our terms of use to continue',
    terms_of_use_link_label: 'Terms of use',
    terms_of_use_required: 'You must agree with our Terms of Use',
    privacy_policy:
      'Hier finden Sie Informationen zur {data_processing_label} Ihrer personenbezogenen Daten im wohnrechner und weitere Informationen zum {data_protection_label} in der HYPO NOE',
    data_processing_label: 'Verarbeitung',
    data_protection_label: 'Datenschutz',
    modal_header: 'Terms of use',
  },
  app: {
    back: 'Back',
    save: 'Save',
    send: 'Send',
    yes: 'Yes',
    start: 'Start',
    no: 'No',
    none: 'None',
    cancel: 'Cancel',
    proceed: 'Proceed',
    continue: 'Continue',
    skip: 'Skip',
    submit: 'Submit',
    submit_form_final: 'Finish',
    submit_form_next: 'Next',
    create_a_project: 'Create a project to access this menu item',
    data_successfully_updated: 'Data successfully updated',
    project_not_found: 'Select a project or create a new one',
    data_saved_successfully: 'Data saved successfully',
  },
  errors: {
    full_address: 'Please select a suggested address',
    fill_field: 'Please fill out this field',
    choose_one: 'Please choose one',
    project_not_found: 'Project not found',
    no_server_response: 'Cannot connect to the server. Please check your internet connection and try again',
    wrong_zip_code: 'Wrong zip-code',
    email_invalid: 'Email is invalid',
    email_required: 'Email is required',
    password_required: 'Password is required',
    password_too_small: 'Password should be at least 8 characters',
    passwords_must_match: 'Passwords must match',
    first_name_required: 'First Name is required',
    last_name_required: 'Last Name is required',
    values_within_range: 'Enter values within this range {min} - {max}',
    fill_all_fields: 'Fill all fields in the form',
    min_value: 'Minimum value - {min}',
    max_value: 'Maximum value - {max}',
    invalid_date: 'Invalid date',
  },

  contact: {
    note: 'How can we help you?',
    successfully_sent: 'Your message has been sent successfully',
  },

  profile: {
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    delete_my_account: {
      title: 'Delete my account',
      message: 'Are you sure you want to delete your account?',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
  },
  verify_account: {
    title: 'ALMOST THERE...',
    message: 'Go to your inbox and click the activation link to verify your email.',
    resend_link: 'Resend link',
    logout: 'Log out',
    email_sent: 'Email sent',
    email_verified: 'Email verified',
    email_confirmed: 'I have confirmed my email',
    message_email_not_verified:
      'The email is not verified, please check your inbox and click on the verification link in the email message',
    contact_support: 'If you have issues receiving emails, please contact us',
  },

  page_not_found: {
    title: `Unfortunately, the page isn't found`,
    back_to_dashboard: 'Back to dashboard',
    back_to_quick_calculator: 'Back to the quick calculator',
  },

  my_projects: {
    name: 'Project name',
    budget: 'Budget',
    price: 'Price',
    pdf_download: 'PDF Download',
    adjust: 'Adjust',
    new_project: 'New project',
    export: 'Export',
    delete_project: {
      title: 'Delete project',
      message: 'Are you sure you want to delete this project?',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    tooltip: "You can't remove the last project",
    tooltip_max_count_of_projects: "You can't create more than 10 projects",
    no_projects: "You don't have any projects yet. Try to create the first project",
    transfer_project: 'Transfer project "{projectName}"',
    transfer_project_button: 'Transfer project',
    successfully_transferred: 'The project was successfully transferred',
    message_for_consultant:
      'Here you can transfer the project to the client. Budget calculations will be cloned with the project. The project will be completely transferred and you will not have access to it. The budget calculation is the one for all projects, to provide up-to-date information on all projects budget calculations will be cloned to the client and will remain in your account',
  },

  property_valuation: {
    planned_project_types: {
      label: 'What would you like to value?',
      new_apartment: 'Apartment',
      new_apartment_description: 'Apartment description',
      buying_house: 'Buying House',
      buying_house_description: 'Buying House description',
      new_building: 'Building house',
      new_building_description: 'Building house description',
      land_estimation: 'Land',
      land_estimation_description: 'Land description',
    },
    planning_project_type_label: 'What type of project would you like to start planning?',
    start_project_planning: 'Start planning your project to access this page',
    plan_project_btn: 'Plan Project',
  },

  renovation_house_wizard: {
    complete_previous_step: 'You must complete the Project details step first to have access to Dashboard',
    complete_previous_step_btn: 'Calculate project details',
    wrong_min_house_area: `The area of the house can't be less than the heated living area - {value} m²`,
    number_of_people_aria_labels: {
      one_person: 'One person',
      two_people: 'Two people',
      three_people: 'Three people',
      four_people: 'Four people',
      five_people: 'Five people',
      six_people: 'Six people',
    },
    house_energy_balance: {
      header: 'Current energy balance of a house',
      before_and_after_header: 'Savings potential through the renovation measures',
      before: 'Before',
      after: 'After',
      label: 'Energy figures for your house without renovation measures',
      specific_heating_demand: '{value} kWh/m²a',
      your_house: 'Your house',
      heating: 'Heating {value} kg CO₂/a',
      hot_water: 'Hot water {value} kg CO₂/a',
      electricity: 'Electricity {value} kg CO₂/a',
    },
    heated_living_area_disclaimer:
      'if you want to increase the limit heated living area, you need to change the living area in step 3',
    go_to_step_three_btn: ' go to step 3',
    potential_energy_balance: {
      title: 'Your CO₂ saving potential',
      description: 'Your values of full renovation and installation of a photovoltaic system (5kwP)',
      redevelopment_house_label: 'Would you like to calculate your individual energy saving potential?',
      ghg_balance: {
        label: 'CO₂ Saving',
        value: '{value} kg p.a.',
      },
      total_energy_costs: {
        label: 'Energy costs',
        value: '{value} € p.a.',
      },
      total_energy_balance_per_year: {
        label: 'Power consumption',
        value: '{value} kwh/a',
      },
    },
    energy_ghg_equivalents: {
      label: 'The actual CO₂ status of your house',
      reduction: 'How much are you saving with your renovations',
      equivalent_car: '{value} Car kilometers',
      equivalent_plane: '{value} Flights Vienna - New York',
      equivalent_trees: '{value} Trees',
    },
    enu_partner: {
      description: 'Get support from the specialists at the Lower Austria energy and environmental organization',
      request_btn: 'Request',
    },
    floors: 'Number of floors (without basement)',
    update_btn_label: 'Update values',
    should_show_all_calculations: 'Do you want to see all CO₂ calculations?',
    improve_or_renovate_house: 'Would you like to improve or renovate your house?',
    improve_house_btn_label: 'Improve house',
    show_calculations: 'Show calculations',
    hide_calculations: 'Hide calculations',
    edit_co2_calculator_values: 'Edit values',
    second_step_title: 'Renovation house title',
    heated_living_area: 'Heated living area',
    kind_of_house: {
      name: 'Which kind of house do you have?',
      single_family_house: 'Single family house',
      bungalow_or_complex_floor_plan: 'Bungalow or non cubic-layout',
      town_house: 'Town house',
      semi_detached_house: 'Semi detached house',
    },
    energy_standard: {
      name: 'Energy standard of the house',
      description: '@Description for energy standard',
      before_1960: 'Before 1960',
      between_1960_and_1975: '1960-1975',
      between_1976_and_1990: '1976-1990',
      after_1990: 'After 1990',
      low_energy_house: 'Low energy',
      passive_house: 'Passive',
    },
    renovations: {
      name: 'Has there been renovations?',
      new_windows: 'New windows',
      insulation_top_ceiling: 'Insulation roof/top ceiling',
      insulation_basement_ceiling: 'Insulation basement ceiling (m²)',
      insulation_facade: 'Insulation facade',
      controlled_living_space_ventilation: 'Controlled living space ventilation',
    },
    number_of_people: 'How many persons are living in the house?',
    tell_about_your_heating: 'Tell us about your heating',
    more_info_about_hot_water: 'More information about your hot water',
    more_info_about_electricity: 'More information about your electricity',
    heating_system: {
      name: 'Heating system',
      natural_gas: 'Natural gas',
      district_heating_biomass: 'District heating biomass',
      district_heating_standard: 'District heating standard',
      wood_chip: 'Wood chip',
      wood_pellets: 'Wood pellets',
      firewood: 'Firewood',
      coal: 'Coal',
      heating_oil: 'Heating oil',
      electric: 'Electric',
      heat_pump: 'Heating pump',
    },
    is_solar_heating_system: 'Is there a solar heat system?',
    solar_heating_system_type: {
      name: '@Label for the solar heating system type',
      heating_and_hot_water: 'For heating and hot water',
      only_hot_water: 'Only hot water',
    },
    age_of_heating_system: {
      name: 'How old is your heating-system',
      description: '@description for the heating system',
      less_than_10_years: 'New technology',
      between_10_and_20_years: 'Between 10 and 20  years',
      more_than_20_years: 'Older than  20 years',
    },
    temperature: {
      name: 'Which temperature do you have?',
      description: 'Each degree increases the energy demand  about 6%',
      less_than_21: 'Less than 21',
      between_21_and_23: 'Between 21 and 23',
      more_than_23: 'More than 23',
    },
    hot_water_producer: {
      name: 'How are you producing hot water?',
      heating_system: 'With the heating-system ',
      electric_boiler: 'Electric boiler ',
      gas_heater: 'Gas heater',
      heat_pump: 'Heat pump',
    },
    amount_of_hot_water: {
      name: 'How much hot water do you need',
      description: 'Low (just showering) \n Medium (50l/day showering and bathing) \n High (only bathing)',
      low: 'Low',
      medium: 'Medium',
      hight: 'High',
    },
    is_devices_younger_than_10_years:
      'Are most of your devices like cooling- and washing-equipment, cooker, oven younger than 10 years?',
    power_consumers: {
      name: 'Which extraordinary power consumer do you have?',
      sauna: 'Sauna',
      outdoor_whirlpool: 'Outdoor whirlpool',
      swimming_pool: 'Swimming pool (heated)',
      air_condition: 'Air condition',
      waterbed: 'Waterbed',
    },
    is_solar_power_system: 'Do you have a PV system?',
    solar_power_system_size: 'What size does it have?',
    solar_power_system_consuming_percentage: 'How many % are you consuming yourself?',
    number_of_economy_class_flights: 'Number of economy-class flights Vienna-New York (round trip)',
    number_stored_trees: 'Number trees which stores this CO₂',
    heating_result: {
      table_title: 'Heating result',
      specific_heating_demand: 'Specific heating demand',
      specific_heating_demand_description: 'Specific heating demand description',
      heating_factor_kea: 'Heating Factor KEA',
      heating_factor_kea_description: 'Heating Factor KEA description',
      heating_factor_ghg: 'Heating Factor GHG',
      heating_factor_ghg_description: 'Heating Factor GHG description',
      total_heating_demand: 'Total heating demand',
      total_heating_demand_description: 'Total heating demand description',
      primary_energy_for_heating: 'Primary energy for heating',
      primary_energy_for_heating_description:
        'this is the energy you need to buy - you can change it if it doesn´t fit',
      heating_ghg_emission: 'GHG emission',
      heating_ghg_emission_description: 'GHG emission description',
      heating_ghg_equivalent_car: 'GHG equivalent car-km',
      heating_ghg_equivalent_car_description: 'GHG equivalent car-km description',
      heating_ghg_equivalent_plane: 'GHG equivalent plane',
      heating_ghg_equivalent_plane_description: 'GHG equivalent plane description',
      heating_ghg_equivalent_trees: 'GHG equivalent trees',
      heating_ghg_equivalent_trees_description: 'GHG equivalent trees description',
      heating_costs: 'Heating-costs',
      heating_costs_description: 'based avg. Prices for your heating-system in Austria',
    },
    hot_water_result: {
      table_title: 'Hot water results',
      primary_energy_for_hot_water: 'Primary energy for hot water',
      primary_energy_for_hot_water_description:
        'this is the energy you need to buy - you can change it if it doesn´t fit',
      hot_water_ghg_emission: 'GHG emission',
      hot_water_ghg_emission_description: 'GHG emission description',
      hot_water_ghg_equivalent_car: 'GHG equivalent car-km',
      hot_water_ghg_equivalent_car_description: 'GHG equivalent car-km description',
      hot_water_ghg_equivalent_plane: 'GHG equivalent plane',
      hot_water_ghg_equivalent_plane_description: 'GHG equivalent plane description',
      hot_water_ghg_equivalent_trees: 'GHG equivalent trees',
      hot_water_ghg_equivalent_trees_description: 'GHG equivalent trees description',
      hot_water_costs: 'HotWater costs',
      hot_water_costs_description: 'based avg. Prices for your heating-system in Austria',
    },
    electricity_result: {
      table_title: 'Electricity results',
      total_power_consumption_per_year: 'Your total power consumption per year',
      total_power_consumption_per_year_description: 'Your total power consumption per year description',
      total_power_production_per_year: 'Your total power production per year',
      total_power_production_per_year_description: 'Your total power production per year description',
      own_consumption: 'Your self consumption',
      own_consumption_description: 'Your self consumption description',
      power_consumption_from_energy: 'Power consumption from energy supply company',
      power_consumption_from_energy_description: 'Power consumption from energy supply company description',
      electrical_feed_in_to_grid: 'Electrical feed-in to grid',
      electrical_feed_in_to_grid_description: 'Electrical feed-in to grid description',
      ghg_savings_solar_power_system: 'GHG savings PV system',
      ghg_savings_solar_power_system_description: 'GHG savings PV system description',
      ghg_balance_of_power_consumption: 'GHG balance of your power consumption',
      ghg_balance_of_power_consumption_description: 'GHG balance of your power consumption description',
      electricity_ghg_equivalent_car: 'GHG equivalent car-km',
      electricity_ghg_equivalent_car_description: 'GHG equivalent car-km description',
      electricity_ghg_equivalent_plane: 'GHG equivalent plane',
      electricity_ghg_equivalent_plane_description: 'GHG equivalent plane description',
      electricity_ghg_equivalent_trees: 'GHG equivalent trees',
      electricity_ghg_equivalent_trees_description: 'GHG equivalent trees description',
      solar_power_system_helps_to_save: 'Your PV system helps to save',
      solar_power_system_helps_to_save_description: 'Your PV system helps to save description',
      total_electricity_costs: 'Total Electricity costs',
      total_electricity_costs_description: 'based avg. Prices for your heating-system in Austria',
    },
    energy_and_co2_balance_result: {
      table_title: 'The energy & CO₂  balance of your house',
      total_energy_balance_per_year: 'Your total energy balance per year',
      total_energy_balance_per_year_description: 'Your total energy balance per year description',
      energy_ghg_balance_per_year: 'Your GHG balance per year',
      energy_ghg_balance_per_year_description: 'Your GHG balance per year description',
      energy_ghg_equivalent_car: 'GHG equivalent car-km',
      energy_ghg_equivalent_car_description: 'GHG equivalent car-km description',
      energy_ghg_equivalent_plane: 'GHG equivalent plane',
      energy_ghg_equivalent_plane_description: 'GHG equivalent plane description',
      energy_ghg_equivalent_trees: 'GHG equivalent trees',
      energy_ghg_equivalent_trees_description: 'GHG equivalent trees description',
      total_energy_costs: 'Total energy costs',
      total_energy_costs_description: 'based on avg. prices in Austria',
    },
    new_windows_number: 'How many windows should be changed',
    number_of_extra_large_windows: 'How many extra large windows (balcony-doors) do you have?',
    number_of_lift_and_slide_doors: 'Number of list & slide doors?',
    heating_system_for_renovation: {
      name: 'Which heating system?',
      hot_water_with_new_heating_system: 'Should hot-water be produced with new heating-system?',
      hot_water_with_new_heating_system_description: 'Hot water be produced with new heating system description',
      district: 'District Heating',
      gas: 'Gas heating',
      pellet: 'Pellet heating',
      heat_pump: 'Heat pump',
    },
    roof_renewal: {
      description: ' ',
      name: 'Which roof-type does the house have?',
      gable: 'Gable Roof',
      flat: 'Flat Roof',
      hipped: 'Hipped Roof',
      gable_description: '@Gable description',
      flat_description: '@Flat description',
      hipped_description: '@Hipped description',
      area: 'Estimated roof area (m²)',
    },
    facade_insulation: {
      name: 'Facade insulation',
      description: ' ',
      type: {
        name: 'Has your house contact to others?',
        single_house: 'Single House',
        double_house: 'Double House',
        middle_house: 'Middle House',
        single_house_description: 'Single house description',
        double_house_description: 'Double house description',
        middle_house_description: 'Middle house description',
      },
      area: 'Estimated facade area (m²)',
    },
    house_area: 'How many m² has the house',
    insulation_top_ceiling_area: 'Insulation top ceiling area',
    insulation_basement_ceiling_area: 'Insulation basement ceiling area',
    sanitary_renovation_area: 'How many m² have the sanitary facilities',
    number_of_bathrooms_for_renovation: 'How many bathrooms should the house have?',
    number_of_toilets_for_renovation: 'How many toilets should the house have?',
    new_flooring_area: 'How many m² should be renewed',
    renovation_walls_and_interior_doors_area: 'How many m² should be renewed',
    number_of_interior_doors_for_renovation: 'Number of interior doors for renovation',
  },

  renovation_house_features: {
    solar_heating: 'Solar heating',
    solar_heating_subitem: 'Solar heating',
    solar_heating_description: 'Solar heating description',
    solar_heating_equipment_type_description: 'Solar heating equipment type description',
    domestic_ventilation: 'Domestic ventilation',
    domestic_ventilation_subitem: 'Domestic ventilation',
    domestic_ventilation_description: 'Domestic ventilation description',
    domestic_ventilation_equipment_type_description: 'Domestic ventilation equipment type description',
    insulation_top_ceiling: 'Insulation top ceiling',
    insulation_top_ceiling_subitem: 'Insulation top ceiling',
    insulation_top_ceiling_description: 'Insulation top ceiling description',
    insulation_top_ceiling_equipment_type_description: 'Insulation top ceiling equipment type description',
    insulation_basement_ceiling: 'Insulation basement ceiling',
    insulation_basement_ceiling_subitem: 'Insulation basement ceiling',
    insulation_basement_ceiling_description: 'Insulation basement ceiling description',
    insulation_basement_ceiling_equipment_type_description: 'Insulation basement ceiling equipment type description',
    energy_certificate: 'Energy certificate',
    energy_certificate_description: 'Energy certificate description',
    reserves_for_unexpected_costs: 'Reserver for unexpected costs',
    reserves_for_unexpected_costs_description: 'Reserver for unexpected costs description',
    kitchen: 'New Kitchen',
    kitchen_subitem: 'New Kitchen',
    kitchen_description: ' ',
    kitchen_equipment_type_description: ' ',
    air_condition: 'Installation of an aircondition for the whole apartment',
    air_condition_subitem: 'Installation of an aircondition for the whole apartment',
    air_condition_description: '',
    air_condition_equipment_type_description: '',
    sanitary_renovation: 'New bathrooms and toilet',
    sanitary_renovation_subitem: 'New bathrooms and toilet',
    sanitary_renovation_description: ' ',
    sanitary_renovation_equipment_type_description: ' ',
    new_flooring: 'New flooring',
    new_flooring_subitem: 'New flooring',
    new_flooring_description: ' ',
    new_flooring_equipment_type_description: ' ',
    renovation_walls_and_doors: 'Renovation of walls an interior doors',
    renovation_walls_and_doors_subitem: 'Renovation of walls an interior doors',
    renovation_walls_and_doors_description: ' ',
    renovation_walls_and_doors_equipment_type_description: ' ',
    renewal_of_electric_installation: 'Renewal of electric installation',
    renewal_of_electric_installation_subitem: 'Renewal of electric installation',
    renewal_of_electric_installation_description: ' ',
    renewal_of_electric_installation_equipment_type_description: ' ',
    renewal_of_front_door: 'Renewal of front door',
    renewal_of_front_door_subitem: 'Renewal of front door',
    renewal_of_front_door_description: ' ',
    renewal_of_front_door_equipment_type_description: ' ',
    new_windows: 'New windows',
    new_windows_subitem: 'New windows',
    new_windows_description: ' ',
    new_windows_equipment_type_description: ' ',
    carport: 'Carport',
    carport_subitem: 'Carport',
    carport_description: 'Carport description',
    carport_equipment_type_description: 'Carport equipment type description',
    garage: 'Garage',
    garage_subitem: 'Garage',
    garage_description: 'Garage description',
    garage_equipment_type_description: 'Garage equipment type description',
    renovation_walls_and_interior_doors: 'Renovation of walls and interior doors',
    renovation_walls_and_interior_doors_subitem: 'Renovation of walls and interior doors',
    renovation_walls_and_interior_doors_description: 'Renovation of walls and interior doors description',
    renewal_of_heating_system: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_subitem: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_description: ' ',
    renewal_of_heating_system_equipment_type_description: ' ',
    roof_renewal: 'Roof renewal',
    roof_renewal_subitem: 'Roof renewal',
    roof_renewal_description: ' ',
    roof_renewal_equipment_type_description: ' ',
    facade_insulation: 'Facade insulation',
    facade_insulation_subitem: 'Facade insulation',
    facade_insulation_description: ' ',
    facade_insulation_equipment_type_description: 'Facade insulation equipment type description',
    alarm_system: 'Alarm system',
    alarm_system_subitem: 'Alarm system',
    alarm_system_description: ' ',
    alarm_system_equipment_type_description: ' ',
    solar_power_system: 'Solar power system',
    solar_power_system_subitem: 'Solar power system',
    solar_power_system_description: ' ',
    pool: 'Pool',
    pool_subitem: 'Pool',
    pool_description: ' ',
    pool_equipment_type_description: ' ',
    winter_garden: 'Winter garden',
    winter_garden_subitem: 'Winter garden',
    winter_garden_description: ' ',
    winter_garden_equipment_type_description: ' ',
    wallbox_e_mobility: 'Wallbox E-Mobility',
    wallbox_e_mobility_subitem: 'Wallbox E-Mobility',
    wallbox_e_mobility_description:
      'To be ready for the future you should consider to install a wallbox for charging electric cars. We estimate the price of a fix mounted wallbox which is installed by a professional',
    wallbox_e_mobility_equipment_type_description:
      'Standard includes a wallbox which is installed based on the existing building wiring which means that it normaly has only 4.6kw. In the higher categories new wiring and a wallbox with energy-management is calculated - this allows you loading up to 11kW and an integration into a photovoltaic system',
  },

  apartment_wizard: {
    title: 'Detailed Planning',
    note: 'For your individual loan-offer we´ll do a valuation of your apartment. Therefore we need some information!',
    apartment_information_title: 'Information about your apartment',

    second_step_apartment_title: 'Tell us more about your apartment',
    apartment_type: 'What type of apartment is it?',
    cost_apartment: 'How much does it cost?',
    main_residence: 'Will it be your main residence?',
    additional_costs: "Additional costs are - change it if we haven't calc. them right:",
    property_transfer_tax: 'Property transfer tax (3.5%)',
    land_register_fee: 'Land register fee (1.1%)',
    notary_fee: "Notary's fee (1%)",
    broker_commission: 'Broker commission (3.6%)',
    total_cost_apartment: 'Total cost apartment:',
    condominium: 'Condominium',
    subsidized_condominium: 'Subsidized condominium',

    full_address: 'Full address (city, street, building)',
    top_number: 'Top number',
    floor_number: 'Floor number',
    is_rooftop: 'Is it in the rooftop?',
    apartment_area: 'How many m² does the apartment have',
    condition_of_house: 'Condition of the house',
    not_renovated: 'First occupancy / not renovated',
    fully_renovated: 'Fully renovated',
    partly_renovated: 'Partly refurbished',
    modernized: 'Modernized',
    condition_of_apartment: 'Condition of the apartment',
    construction_year: 'Construction-year of the building',
    apartment_renovation_year: 'Year of renovation of the apartment',
    loggia: 'Loggia',
    loggia_area: 'm²',
    terrace: 'Terrace',
    terrace_area: 'm²',
    garden: 'Garden',
    garden_area: 'm²',
    balcony: 'Balcony',
    elevator: 'Elevator',
    basement: 'Basement',
    parking: {
      name: 'Parking',
      underground_car_park: 'Underground car park',
      outdoor_parking_space: 'Outdoor parking space',
      garage: 'Garage',
      none: 'None',
    },
    heating_system: {
      name: 'Heating system',
      central_or_district: 'Central heating or district heating',
      gas: 'Gas heating',
      electric: 'Electric heating',
      wood_coal: 'Wood-coal heating',
    },
    air_conditioning: 'Air conditioning',
    renovations_or_additional_investments: 'Renovations or additional investments',
    sanitary_renovation_area: 'How many m² have the sanitary facilities',
    new_flooring_area: 'How many m² should be renewed',
    renovation_walls_and_interior_doors_area: 'How many m² should be renewed',
    new_windows_number: 'How many windows should be changed',
    other_investments_amount: 'Amount',
    other_investments_comment: 'Comment',

    map: 'Map',
    street_view: 'Street View',
  },

  apartment_features: {
    cost_apartment: 'Apartment costs',
    cost_apartment_description: ' ',
    taxes_and_fees: 'Taxes and fees',
    taxes_and_fees_description: ' ',
    property_transfer_tax: 'Property transfer tax',
    property_transfer_tax_description: ' ',
    land_register_fee: 'Land register fee',
    land_register_fee_description: ' ',
    notary_fee: 'Notary´s fee',
    notary_fee_description: ' ',
    broker_commission: 'Broker commission',
    broker_commission_description: ' ',
    reserves_for_unexpected_costs: 'Reserver for unexpected costs',
    reserves_for_unexpected_costs_description: 'Reserver for unexpected costs description',
    renovation_walls_and_interior_doors_title: 'Renovation of walls an interior doors',
    renovation_walls_and_interior_doors: 'Renovation of walls an interior doors',
    renovation_walls_and_interior_doors_description: '@Placeholder for the description',
    kitchen: 'New kitchen',
    kitchen_description: '@Placeholder for the description',
    kitchen_equipment_type_description: ' ',
    sanitary_renovation_title: 'New bathrooms and toilet',
    sanitary_renovation: 'New bathrooms and toilet',
    sanitary_renovation_description: '@Placeholder for the description',
    sanitary_renovation_equipment_type_description: ' ',
    aircondition: 'Installation of an aircondition for the whole apartment',
    aircondition_description: '@Placeholder for the description',
    aircondition_equipment_type_description: ' ',
    new_flooring_title: 'New flooring',
    new_flooring: 'New flooring',
    new_flooring_description: '@Placeholder for the description',
    new_flooring_equipment_type_description: ' ',
    renovation_walls_and_doors: 'Renovation of walls an interior doors',
    renovation_walls_and_doors_description: '@Placeholder for the description',
    renovation_walls_and_doors_equipment_type_description: ' ',
    renewal_of_electric_installation: 'Renewal of electric installation',
    renewal_of_electric_installation_description: '@Placeholder for the description',
    renewal_of_electric_installation_equipment_type_description: ' ',
    new_windows_title: 'New windows',
    new_windows: 'New windows',
    new_windows_description: ' ',
    new_windows_stove_description: '@Placeholder for the description',
    new_windows_equipment_type_description: ' ',
    renewal_of_heating_system: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_description: '@Placeholder for the description',
    renewal_of_heating_system_equipment_type_description: ' ',
    other_investments: 'Other investments',
    other_investments_description: '@Placeholder for the description',
  },
  buying_house_features: {
    solar_heating: 'Solar heating',
    solar_heating_subitem: 'Solar heating',
    solar_heating_description: 'Solar heating description',
    solar_heating_equipment_type_description: 'Solar heating equipment type description',
    domestic_ventilation: 'Domestic ventilation',
    domestic_ventilation_subitem: 'Domestic ventilation',
    domestic_ventilation_description: 'Domestic ventilation description',
    domestic_ventilation_equipment_type_description: 'Domestic ventilation equipment type description',
    insulation_top_ceiling: 'Insulation top ceiling',
    insulation_top_ceiling_subitem: 'Insulation top ceiling',
    insulation_top_ceiling_description: 'Insulation top ceiling description',
    insulation_top_ceiling_equipment_type_description: 'Insulation top ceiling equipment type description',
    insulation_basement_ceiling: 'Insulation basement ceiling',
    insulation_basement_ceiling_subitem: 'Insulation basement ceiling',
    insulation_basement_ceiling_description: 'Insulation basement ceiling description',
    insulation_basement_ceiling_equipment_type_description: 'Insulation basement ceiling equipment type description',
    energy_certificate: 'Energy certificate',
    energy_certificate_description: 'Energy certificate description',
    reserves_for_unexpected_costs: 'Reserver for unexpected costs',
    reserves_for_unexpected_costs_description: 'Reserver for unexpected costs description',
    cost_house: 'House costs',
    cost_house_description: 'House costs description',
    taxes_and_fees: 'Taxes and fees',
    taxes_and_fees_description: ' ',
    property_transfer_tax: 'Property transfer tax',
    property_transfer_tax_description: ' ',
    land_register_fee: 'Land register fee',
    land_register_fee_description: ' ',
    notary_fee: 'Notary´s fee',
    notary_fee_description: ' ',
    broker_commission: 'Broker commission',
    broker_commission_description: ' ',
    kitchen: 'New Kitchen',
    kitchen_subitem: 'New Kitchen',
    kitchen_description: ' ',
    kitchen_equipment_type_description: ' ',
    air_condition: 'Installation of an aircondition for the whole apartment',
    air_condition_subitem: 'Installation of an aircondition for the whole apartment',
    air_condition_description: '',
    air_condition_equipment_type_description: '',
    sanitary_renovation: 'New bathrooms and toilet',
    sanitary_renovation_subitem: 'New bathrooms and toilet',
    sanitary_renovation_description: ' ',
    sanitary_renovation_equipment_type_description: ' ',
    new_flooring: 'New flooring',
    new_flooring_subitem: 'New flooring',
    new_flooring_description: ' ',
    new_flooring_equipment_type_description: ' ',
    renovation_walls_and_doors: 'Renovation of walls an interior doors',
    renovation_walls_and_doors_subitem: 'Renovation of walls an interior doors',
    renovation_walls_and_doors_description: ' ',
    renovation_walls_and_doors_equipment_type_description: ' ',
    renewal_of_electric_installation: 'Renewal of electric installation',
    renewal_of_electric_installation_subitem: 'Renewal of electric installation',
    renewal_of_electric_installation_description: ' ',
    renewal_of_electric_installation_equipment_type_description: ' ',
    renewal_of_front_door: 'Renewal of front door',
    renewal_of_front_door_subitem: 'Renewal of front door',
    renewal_of_front_door_description: ' ',
    renewal_of_front_door_equipment_type_description: ' ',
    new_windows: 'New windows',
    new_windows_subitem: 'New windows',
    new_windows_description: ' ',
    new_windows_equipment_type_description: ' ',
    carport: 'Carport',
    carport_subitem: 'Carport',
    carport_description: 'Carport description',
    carport_equipment_type_description: 'Carport equipment type description',
    garage: 'Garage',
    garage_subitem: 'Garage',
    garage_description: 'Garage description',
    garage_equipment_type_description: 'Garage equipment type description',
    renovation_walls_and_interior_doors: 'Renovation of walls and interior doors',
    renovation_walls_and_interior_doors_subitem: 'Renovation of walls and interior doors',
    renovation_walls_and_interior_doors_description: 'Renovation of walls and interior doors description',
    renewal_of_heating_system: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_subitem: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_description: ' ',
    renewal_of_heating_system_equipment_type_description: ' ',
    roof_renewal: 'Roof renewal',
    roof_renewal_subitem: 'Roof renewal',
    roof_renewal_description: ' ',
    facade_insulation: 'Facade insulation',
    facade_insulation_subitem: 'Facade insulation',
    facade_insulation_description: ' ',
    facade_insulation_equipment_type_description: 'Facade insulation equipment type description',
    alarm_system: 'Alarm system',
    alarm_system_subitem: 'Alarm system',
    alarm_system_description: ' ',
    alarm_system_equipment_type_description: ' ',
    solar_power_system: 'Solar power system',
    solar_power_system_subitem: 'Solar power system',
    solar_power_system_description: ' ',
    pool: 'Pool',
    pool_subitem: 'Pool',
    pool_description: ' ',
    pool_equipment_type_description: ' ',
    winter_garden: 'Winter garden',
    winter_garden_subitem: 'Winter garden',
    winter_garden_description: ' ',
    winter_garden_equipment_type_description: ' ',
    wallbox_e_mobility: 'Wallbox E-Mobility',
    wallbox_e_mobility_subitem: 'Wallbox E-Mobility',
    wallbox_e_mobility_description:
      'To be ready for the future you should consider to install a wallbox for charging electric cars. We estimate the price of a fix mounted wallbox which is installed by a professional',
    wallbox_e_mobility_equipment_type_description:
      'Standard includes a wallbox which is installed based on the existing building wiring which means that it normaly has only 4.6kw. In the higher categories new wiring and a wallbox with energy-management is calculated - this allows you loading up to 11kW and an integration into a photovoltaic system',
  },
  buying_house_wizard: {
    addition_information:
      'For your individual loan-offer we´ll do a valution of your apartment. Therfore we need some information!',
    title: 'Detailed Planning',
    main_residence: 'Will it be your main residence?',
    second_step_house_title: 'Tell us more about your house',
    cost_house: 'How much does it cost?',
    total_cost_house: 'Total cost house:',
    single_family_house: 'Single family house',
    semi_detached_house: 'Semi-detached house',
    town_house: 'Town house',
    additional_costs: "Additional costs are - change it if we haven't calc. them right:",
    property_transfer_tax: 'Properly transfer tax (3.5%)',
    land_register_fee: 'Land register fee (1.1%)',
    notary_fee: "Notary's fee (1%)",
    broker_commission: 'Broker commission (3.6%)',
    full_address: 'Full address (city, street, building)',
    land_area: 'How many m² has the land',
    house_area: 'How many m² has the house',
    floors: 'Number of floors (without basement)',
    construction_year: 'Construction year of the house?',
    year_of_outer_wall_renovation: 'Year of outer wall renovation',
    roof_renewed: 'Has the roof been renewed meanwhile?',
    year_of_roof_renewal: 'Year of roof renewal?',
    renovation_outer_wall: {
      name: 'Has the outer wall of the building been renovated?',
      no_renovation: 'No renovation',
      new_plastering_and_painting: 'New plastering & painting',
      thermal_insulation: 'Thermal insulation',
    },
    house_type: {
      name: 'How is the house build?',
      solid_construction: 'Solid construction',
      prefabricated_house: 'Prefabricated house',
      timber_house: 'Timber house',
    },
    category: {
      name: 'Which category of house do you want to buy?',
      detached_house: 'Detached house',
      townhouse: 'Townhouse',
      semi_detached_house: 'Semi detached house',
    },
    basement: {
      name: 'Has the house a basement?',
      full_basement: 'Full basement',
      partially_basement: 'Partially basement',
      no_basement: 'No basement',
    },
    condition_of_house: {
      name: 'Condition of the house',
      first_occupancy: 'First occupancy',
      fully_renovated: 'Fully renovated',
      partly_renovated: 'Partly renovated',
      renovation_needed: 'Renovation needed',
    },
    number_of_toilets: 'How many toilets has the house?',
    number_of_bathrooms: 'How many bathrooms has the house?',
    year_of_renovation: 'Year of renovation of the apartment',
    parking: {
      name: 'Parking',
      garage: 'Garage',
      carport: 'Carport',
      outdoor_parking_space: 'Outdoor parking space',
      none: 'None',
    },
    heating_system: {
      name: 'Heating system',
      district: 'District Heating',
      gas: 'Gas heating',
      oil: 'Oil heating',
      pellet: 'Pellet heating',
      heat_pump: 'Heat pump',
      wood_or_coal: 'Wood or Coal heating',
      electric: 'Electric heating',
    },
    heating_system_for_renovation: {
      name: 'Which heating system?',
      district: '@District Heating',
      gas: '@Gas heating',
      pellet: '@Pellet heating',
      heat_pump: '@Heat pump',
    },
    roof_renewal: {
      description: ' ',
      name: 'Which roof-type does the house have?',
      gable: 'Gable Roof',
      flat: 'Flat Roof',
      hipped: 'Hipped Roof',
      gable_description: '@Gable description',
      flat_description: '@Flat description',
      hipped_description: '@Hipped description',
      area: 'Estimated roof area (m²)',
    },
    facade_insulation: {
      name: 'Facade insulation',
      description: ' ',
      type: {
        name: 'Has your house contact to others?',
        single_house: 'Single House',
        double_house: 'Double House',
        middle_house: 'Middle House',
        single_house_description: 'Single house description',
        double_house_description: 'Double house description',
        middle_house_description: 'Middle house description',
      },
      area: 'Estimated facade area (m²)',
    },
    insulation_top_ceiling_area: 'Insulation top ceiling area',
    insulation_basement_ceiling_area: 'Insulation basement ceiling area',
    number_of_interior_doors_for_renovation: 'Number of interior doors for renovation',
    renovations_or_additional_investments: 'Are you planning renovations or additional investments?',
    additional_options: 'Additional options',
    number_of_garage_place: 'Number of Garage places',
    number_of_garage_place_description: 'für wieviele Autos',
    sanitary_renovation_area: 'How many m² have the sanitary facilities',
    number_of_bathrooms_for_renovation: 'How many bathrooms should the house have?',
    number_of_toilets_for_renovation: 'How many toilets should the house have?',
    new_flooring_area: 'How many m² should be renewed',
    renovation_walls_and_interior_doors_area: 'How many m² should be renewed',
    new_windows_number: 'How many windows should be changed',
    number_of_lift_and_slide_doors: 'Number of list & slide doors?',
    number_of_extra_large_windows: 'How many extra large windows (balcony-doors) do you have?',
  },
  project_wizard: {
    edit_values_btn: 'Edit values',
    first_step: {
      title: 'Tell us basic info about your new project',
      property_tools: 'Property tools',
      property_tools_description:
        'Wohnrechner will help you calculate the cost of building, buying or even renovating a house or apartment',
      finance_tools: 'Finance tools',
      finance_tools_description: 'We also have tools to help you manage and plan your capital and finances',
      alt: {
        renovation_house: '@Grafik Sanierung Einfamilienhaus (Inklusive CO₂ - Bilanz)',
        buying_house: '@Grafik Einfamilienhaus (inklusive Sanierung)',
        new_building: '@Grafik Neubau Einfamilienhaus',
        new_apartment: '@Grafik Eigentumswohnung',
      },
    },
    project_name: 'Project name',
    project_type: 'Project type',
    project_type_hint: 'Currently we only have this project type, but we work hard to offer you more',
    new_building: 'New single-family house',
    new_apartment: 'Buying apartment',
    buying_house: 'Buying family-house',
    renovation_house: 'Renovation house',
    land_estimation: 'The market value of this land is around:',
    land_estimation_per_square_meter: 'or {value} €/m²',
    land_estimation_note: '(this amount will be added to your own-funds)',
    budget: 'Own funds (€)',
    estimated_property_price: 'Estimated market value of your property (€)',
    total_own_funds: 'Total own funds',
    country: 'Country',
    austria: 'Austria',
    zip_code: 'ZIP code',
    full_address: 'Full address (city, street, building)',
    second_step: 'Second step',
    second_step_title: 'Now, describe your building location',
    land_area: 'Land area m²',
    additional_information: 'Additional information',
    old_building_demolition: 'Old building demolition',
    area_of_old_building: 'm² of old building',
    hillside_location: 'Hillside location',
    limited_access: 'Limited access (no truck)',
    special_underground: 'Special underground',
    underground_type: 'Underground type',
    groundwater_level_high: 'Groundwater level high',
    rocky: 'Rocky',
    marshy: 'Marshy',
    third_step: 'Third step',
    third_step_title: 'What house do you want to build?',
    finish_project_wizard_button: 'Finish',
    equipment_type: 'Equipment type',
    living_space: 'Living space m²',
    number_of_floors: 'Number of floors',
    number_of_floors_1: '1 floor',
    number_of_floors_2: '2 floors',
    number_of_floors_3: '3 floors',
    number_of_floors_hint: "Note that 'Number of floors' does not include a basement",
    number_of_floors_values: {
      '1': '1',
      '2': '2',
      '3': '3',
    },
    options: 'Options',
    controlled_domestic_ventilation: 'Controlled domestic ventilation',
    number_of_car_ports: 'Number of Car ports',
    number_of_garage_place: 'Number of Garage places',
    interior_equipment: 'Interior equipment',
    pool: 'Pool',
    fence: 'Fence',
    garden_design: 'Garden design (lawn, plants)',
    terrace: 'Terrace',
    winter_garden: 'Winter garden',
    more_info: 'More info',
    superior: 'Superior',
    premium: 'Premium',
    standard: 'Standard',
    first_step_summary_title: 'Basic info',
    second_step_summary_title: 'Location',
    third_step_summary_title: 'House type',
    final_step_summary_title: 'Equipment type',
    equipment_type_update_prompt_title: 'Warning',
    equipment_type_update_prompt_message:
      'This action will override all the equipment types in categories. It will not affect on the prices that were set manually',
    equipment_type_update_prompt_confirm_button_label: 'Ok',
    equipment_type_update_prompt_cancel_button_label: 'Cancel',
    equipment_type_content: {
      standard_title: 'Standard',
      standard_description:
        'Standard means a lean functional architecture and standard equipment in all other categories (electricity, sanitary, windows etc.) without any additional options, ut everything fulfils the local standards and norms.',
      premium_title: 'Premium',
      premium_description:
        'Premium means an individual architecture, high quality materials and modern equipment in all categories.',
      superior_title: 'Superior',
      superior_description:
        'Superior means an impressive architecture and highest quality in all categories and latest technology.',
    },
    has_property: 'Do you already have a property there?',
    prefabricated: 'Prefabricated house',
    architect: 'Architect´s house',
    builder: 'Builder´s house',
    house_type_title: 'Your house will be a:',
    city: 'City',
    street: 'Street',
    building: 'Building',
    avg_property_price_sq_m: 'Average costs per m² in you region (€)',
    estimate_final_step_title: 'Which features do you want to include in your project?',
    next_button: 'Next Step',
    sole_plate_or_basement: 'Sole plate or Basement',
    basement: 'Basement',
    sole_plate: 'Sole plate',
    has_additional_features: 'Are you planning a renovation or additional investment?',
  },
  detailed_planning_wizard: {
    title: 'Detailed Planning',
    house_information_title: 'Information about your house',
    floors: 'Number of floors',
    living_space_by_floor: {
      0: 'Living space – ground floor (m²)',
      1: 'Living space – first floor (m²)',
      2: 'Living space – second floor (m²)',
    },
    equipment_type: 'Equipment type',
    total_living_space: 'Total living space:',
    terrace_area: 'Terrace and balcony (m²)',
    sole_plate_or_basement: 'Sole plate or Basement',
    basement: 'Basement',
    basement_description: 'Price for basement includes brickwork, floors, windows and doors and electrical work',
    sole_plate: 'Sole plate',
    sole_plate_description: 'Price includes a soleplate with insulating',
    basement_area: 'Basement (m²)',
    sole_plate_area: 'Sole plate (m²)',
    number_of_car_ports: 'Carport (für wieviele Autos)',
    number_of_garage_place: 'Garage (für wieviele Autos)',
    direction: {
      north: 'North',
      south: 'South',
      east: 'East',
      west: 'West',
    },
    living_room_direction: 'Direction of the living room',
    bathrooms: 'Number of bathrooms',
    shower: 'Shower',
    bathtub: 'Bathtub',
    heating_system_type: 'Type of heating system',
    heating_system_types: {
      gas: 'Gas',
      solid_fuel: 'Solid fuel',
      district_heating: 'District heating',
      heat_pump: 'Heat pump',
      electric: 'Electric',
      other: 'Other',
    },
    solar_power_system: 'Solar Power System',
    solar_power_system_description:
      'A solar power system in our times is cost-efficient and helps to prevent climate-change. In many regions you also get public sponsorship.',
    solar_power_size_kwp: 'How big should the system be? (kWp)',
    solar_power_size_kwp_description: 'Please note that for 1 kWp ("Kilowatt peak") you need 7m² on your rooftop',
    cost_drivers: 'Cost drivers',
    hillside_location: 'Hillside location',
    demolition_costs: 'Demolition costs',
    demolition_area: 'Demolition area (m²)',
    personal_contribution: 'My contribution is:',
    personal_contribution_header: 'Personal contribution',
    personal_contribution_description:
      'You have bricklayer skills, you have enough time and maybe also some skilled friends? Then you can reduce the costs of your project, but please don´t overestimate yourself when you think about your personal contribution of the project!',
    personal_contribution_options: {
      none: 'none',
      low: 'low (7%)',
      moderate: 'moderate (12%)',
      high: 'high (20%)',
    },
    solar_power_equipment_type: {
      name: 'The type of equipments of the Solar Power System',
      standard_description:
        'A Standard Solar Power System is mounted on your rooftop. The system is directly connected to your grid.',
      premium_description:
        'A premium system has additionally a solar battery and an App-based energy management system. Furthermore the panels a integrated into the rooftop which looks more aesthetic.',
      superior_description:
        'For a Superior system we assume that it has solar roof tiles for a perfect aesthetic look. Partially you can save some of the extra costs because you need less traditionally roof-tiles.',
    },
    personal_contribution_note: 'Note: it reduces your costs',
    additional_options: 'Additional options',
  },
  equipment_types: {
    superior: 'Superior',
    premium: 'Premium',
    standard: 'Standard',
    mixed: 'mixed',
  },

  feature_groups: {
    options: 'Options',
    main: 'Default',
    additional_costs: 'Additional Costs',
    cost_drivers: "Cost drivers'",
  },

  page_titles: {
    express_loan: 'Prepare a home loan from home',
    my_projects: 'My projects',
    dashboard: 'Dashboard',
    project_info: 'Project info',
    options: 'Options',
    budget: 'Budget',
    contact: 'Contact us',
    logout: 'Logout',
    profile: 'Profile',
    plan: 'Plan',
    my_budget: 'My Budget',
    forgot_password: 'Forgot your password',
    reset_password: 'Reset your password',
    loan: 'Financing',
    edit_budget: 'Adjust monthly expenses',
    sign_up: 'Sign up',
    login: 'Sign in',
    invite_user: 'Invite user',
    invitations: 'Invitations',
    property_valuation: 'Property valuation page title',
    debug_mode: 'Debug mode',
  },

  invite_user: {
    loan_consultant: 'Loan consultant',
    add_user_btn: 'Invite user',
    successfully_sent: 'An invitation message has been sent',
    message: 'Write a message for the client here (optional)',
    transfer_project_with_budget: 'Do you also want to transfer a budget calculation?',
    transfer_budget_description:
      'If you choose this checkbox and if the client has completed budget calculation step, it will be overwritten by your budget calculation',
  },

  invitations: {
    email: 'Email',
    name: 'Name',
    download_btn: 'Download excel',
    refresh_data_btn: 'Refresh data',
    no_invitations: 'Has no invitations yet',
    no_projects: 'Has no projects yet',
    date_of_invitation: 'Date of invitation',
    date_of_last_login: 'Date of the last login',
    temp_password_changed: 'Password changed',
    number_of_projects: 'Total number of user projects',
    loan_consultant_email: 'Loan Consultant Email',
    loan_consultant_name: 'Loan Consultant Name',
    inviter_email: 'Invited by (email)',
    inviter_name: 'Invited by (name)',
    resend_temp_password: 'Resend temporary password',
    delete_user_account: 'Delete user',
    search_label: "Search for the user's first and last name by email",
    projects: 'Projects',
    budget: 'Budget pdf',
    refreshed_data: 'Refreshed data',
    budget_pdf: 'Budget pdf',
    user_successfully_deleted: 'User account successfully deleted',
    successfully_sent: 'Temporary password successfully sent',
    delete_account: {
      title: 'Delete user account',
      message: 'Are you sure you want to delete account of {clientInfo}?',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    project: {
      name: 'Name',
      type: 'Type',
      pdf: 'Pdf',
      project_details: 'Project details completed',
      plan_adjusted: 'Adjust project plan completed',
      budget_planning: 'Budget planning completed',
      loan: 'Financing completed',
      loan_offer: 'Loan offer completed',
      transferred_by_name: 'Transferred by (name)',
      transferred_by_email: 'Transferred by (email)',
    },
  },

  user: {
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
  },

  project_plan: {
    equipment_type: 'Equipment type',
    total_costs: 'Total costs',
    personal_contribution: 'Your personal contribution fee',
    complete_previous_step: `You don't have planning categories, try to plan`,
    complete_previous_step_btn: 'Calculate project details',
    btn_to_budget_calculation: 'Budget Calculation',
  },

  planning_categories: {
    no_planning_categories: `You don't have planning categories`,
    financing: 'Financing',
    planning_and_construction_management: 'Planning & Construction Management',
    property_costs: 'Property costs',
    charges_and_taxes: 'Charges and taxes',
    development_costs: 'Development costs',
    demolition_costs: 'Demolition costs',
    tooltip_edit_button: 'You can update your project plan calculated values here',
    tooltip_download_button: 'You can download project estimation in pdf here',
    tooltip_settings_button: 'You can update your project details here',
    reserves_for_unexpected_costs: 'Reserver for unexpected costs',
    reserves_for_unexpected_costs_description: 'Reserver for unexpected costs description',
    shell_construction: 'Shell construction',
    windows_and_doors: 'Windows & Doors',
    sanitary: 'Sanitary',
    electrical_installation: 'Electrical installation',
    heating_system: 'Heating system',
    interior_construction: 'Interior construction',
    interior_equipment: 'Interior equipment',
    terrace: 'Terrace',
    winter_garden: 'Winter garden',
    pool: 'Pool',
    fence: 'Fence',
    carport: 'Carport',
    garage: 'Garage',
    garden_landscaping: 'Garden landscaping',
    hillside_location: 'Hillside location',
    limited_access: 'Limited access',
    special_underground: 'Special underground',
    planning_and_construction_management_description: 'Costs for Architecture, Planning and Construction Management',
    property_costs_description: 'Set how much your property costs',
    charges_and_taxes_description: 'Charges for different services',
    development_costs_description: 'Charges for development',
    demolition_costs_description: 'Charges for old building demolition',
    sole_plate_or_basement_description: {
      basement: 'Price for basement includes brickwork, floors, windows and doors and electrical work',
      sole_plate: 'Price includes a soleplate with insulating',
    },
    sole_plate: 'Here are only the costs for the sole-plate - earth works are not included',
    shell_construction_description:
      'Includes earth works, channel work, masonry, concrete works, chimney, carpentry, roofing, facade and metal worker.',
    windows_and_doors_description: 'Charges on windows and their installation',
    sanitary_description: 'Includes plumbing and sanitary equipment',
    electrical_installation_description: 'Includes cable ducts, wiring, and electro-furniture',
    heating_system_description: 'Includes boiler, heating piping as well as radiators or underfloor heating',
    interior_construction_description: 'Includes interior plaster, screed, flooring, tiles, drywall and painting',
    interior_equipment_description:
      'Includes kitchen, furniture of all rooms, lighting, electrical devices and alarm system including installation',
    terrace_description:
      'Includes costs for the terrace with tiles, depending on the category without or with sun protection / roofing / additional equipment',
    winter_garden_description:
      'Includes conservatory, flooring, heated depending on the category and with additional equipment',
    pool_description:
      'Massive pool including earthworks, depending on the category with additional roofing / additional equipment',
    garden_landscaping_description: 'Includes lawns, plants and paths',
    fence_description: 'Includes base, fence, door / gate',
    carport_description: 'Includes massive canopy',
    garage_description: 'Includes massive garage with electric gate drive',
    electrical_installation_hint_description:
      'This calculation is based on the commonly required Energy-Check, the costs of planning your house (Application-Plan and Work-plan), and local construction supervision. You could save a lot of money if you are able to do the local supervision yourself.',
    domestic_ventilation: 'Domestic ventilation',
    domestic_ventilation_description: 'Electronically controlled, central ventilation system for the entire house',
    bus_system: 'Bus system, smart home',
    bus_system_description: '@This is a placeholder for bus system description',
    solar_heating: 'Solar heating',
    solar_heating_description: 'Solar system for hot water preparation or heating support',
    tile_stove: 'Tile Stove',
    tile_stove_description: 'Solid, tiled stove that is firmly connected to the house',
    solar_power_system: 'Solar power system',
    solar_power_system_description: '',
    sole_plate_or_basement: 'Sole plate or Basement',
    interior_equipment_type_description: ' ',
    winter_garden_equipment_type_description: ' ',
    pool_equipment_type_description: ' ',
    garden_landscaping_equipment_type_description: ' ',
    fence_landscaping_equipment_type_description: ' ',
    tile_stove_equipment_type_description: ' ',
    domestic_ventilation_equipment_type_description: ' ',
    bus_system_equipment_type_description: ' ',
    solar_heating_equipment_type_description: ' ',
    sole_plate_or_basement_form: {
      basement: 'Basement',
      sole_plate: 'Sole plate',
    },
    wallbox_e_mobility: 'Wallbox E-Mobility',
    wallbox_e_mobility_description:
      'To be ready for the future you should consider to install a wallbox for charging electric cars. We estimate the price of a fix mounted wallbox which is installed by a professional',
    wallbox_e_mobility_equipment_type_description:
      'Standard includes a wallbox which is installed based on the existing building wiring which means that it normally has only 4.6kw. In the higher categories new wiring and a wallbox with energy-management is calculated - this allows you loading up to 11kW and an integration into a photovoltaic system',
    other_investments: 'Other investments',
    other_investments_description: '@Placeholder for the description',
  },

  planning: {
    other_investments_amount: 'Amount',
    other_investments_comment: 'Comment',
    other_investments_subitem: ' ',
    override_cost: 'Override cost, €',
    calculated_button: 'Calculated',
    quoted_button: 'Quoted',
    sole_plate_or_basement_form: {
      select_label: '',
      basement: 'Basement',
      sole_plate: 'Sole plate',
    },
    sole_plate_or_basement: {
      basement: 'Basement',
      sole_plate: 'Sole plate',
    },
    plan_overview: 'Plan Overview',
    earthworks: 'Earthworks',
    channel: 'Channel work',
    masonry: 'Masonry, concrete and reinforced concrete works',
    chimney: 'Chimney',
    carpentry: 'Carpentry',
    roofing: 'Roofing and plumbing work (plumber)',
    facade: 'Facade - full thermal insulation',
    metal: 'Metal worker',
    plumbing: 'Plumbing',
    sanitary_equipment: 'Sanitary equipment',
    heating_installation: 'Heating installation total',
    radiator: 'Radiator or underfloor heating assembly',
    boiler: 'Boiler',
    electro_cable_ducts: 'Electro - cable ducts',
    electro_wiring: 'Electro - wiring',
    electro_finishing: 'Electro - finishing',
    windows_patio_balcony: 'Windows, patio and front door',
    front_door: 'Front door',
    interior_plastering: 'Interior plastering',
    floor_construction: 'Floor construction - screed',
    drywall_construction: 'Drywall construction',
    tiling: 'Tiling',
    interior_doors_doorstays: 'Interior doors - doorstays',
    interior_doors_door_panels: 'Interior doors - door panels and completion',
    flooring: 'Flooring',
    painting_work: 'Painting work (house painter, upholsterer)',
    energy_check: 'Energy Check',
    price_of_property: 'Price of property',
    development_costs: 'Development costs',
    charges_and_taxes: 'Charges and taxes',
    demolition_costs: 'Demolition costs',
    demolition_area: 'Demolition area (m²)',
    interior_equipment: 'Interior equipment',
    controlled_domestic_ventilation: 'Controlled domestic ventilation',
    pool: 'Pool',
    garden_design: 'Garden design',
    fence: 'Fence',
    carport: 'Carport',
    number_of_car_ports: 'Number of Car ports',
    number_of_garage_place: 'Number of Garage places',
    garage: 'Garage',
    terrace: 'Terrace',
    winter_garden: 'Winter garden',
    hillside_location: 'Hillside location',
    limited_access: 'Limited access',
    special_underground: 'Special underground',
    special_underground_form: {
      underground_type: 'Underground type',
      underground_type_options: {
        groundwater_level_high: 'Groundwater level high',
        rocky: 'Rocky',
        marshy: 'Marshy',
      },
    },
    cost_estimation: 'Preliminary Planning & cost estimation',
    permit_planning: 'Permit planning',
    execution_planning: 'Execution planning',
    bill_of_quantity: 'Bill of quantity & Offers',
    construction_management: 'Construction Management',
    bus_system: 'Bus system, smart home',
    tile_stove: 'Tile Stove',
    solar_power_system: 'Solar power system',
    solar_heating: 'Solar heating',
    taxes_and_notary_expenses: 'Taxes and notary expenses',
  },
  loan: {
    overview: {
      title: 'Overview',
      budget: 'Budget:',
      total: 'Total:',
      shortage: 'Shortage:',
    },
    project_price_zero: `The estimated cost of your project is zero so you can't complete this step. You can start planning now`,
    start_planning: 'Start planning',
    interest_type: {
      variable: 'Variable',
      fixed: 'Fixed',
    },
    personal_financing_request: {
      title: 'Personal financing request',
      prefer_contact_by: 'I prefer making contact by',
      prefer_contact_by_email: 'Mail',
      prefer_contact_by_phone: 'Phone',
      phone_number: 'My phone number',
      reachability_on_phone: 'Reachability on phone',
      message: 'Would you like to tell us something which could be useful for the financing offer?',
      description: 'e.g additional funds, expected increase of your loan, Link to your desired real-estate',
      before: 'Before',
      after: 'or after',
      oclock: "o'clock ",
      day: 'Day',
      month: 'Month',
      year: 'Year',
      desired_branch: {
        label: 'My desired branch',
        vienna_label: 'Vienna',
        lower_austria_label: 'Lower Austria',
      },
    },
    own_funds_ratio_not_successful:
      "Your own funds ratio {own_funds_ratio} could make the financing difficult. But let's talk maybe we find a solution!",
    max_loan_rate_ratio_not_successful:
      "The calculated loan rate exceeds {max_loan_rate_ratio} of your total income, this makes financing difficult but we'll look at it and help you to find a solution!",
    estimated_property_price: 'Estimated property price',
    already_requested: 'You have already requested a loan',
    request_sent: 'The request was successfully sent',
    tooltip_settings_button: 'You can update your financing details here',
    total_project_costs: 'Total project costs',
    loan_costs: 'Loan costs',
    affordable_loan: 'Affordable loan',
    budget_surplus: 'Calculated budget surplus',
    financing_gap: 'Financing gap',
    financing_reserve: 'Financing reserve',
    monthly_deficit: 'Monthly deficit',
    monthly_reserve: 'Monthly reserve',
    financing_deficit_message:
      '<p>Your total project costs are currently above your finacial capabilities. You can try to adjust the following parameters:</p><ul><li>Lower your project costs</li><li>Increase your own funds</li><li>Try to earn more money</li><li>Lower your monthly expenses</li><li>Increase the loan term</li></ul><p>We would be glad to support you with your financial planning!</p>',
    financing_deficit_button: 'Request Personal consulting',
    financing_reserve_message:
      '<p>Congratulations!</p> <p>It seems that your project can be financed! (your own funds ratio is {own_funds_ratio} and your loan rate ratio is {loan_rate_ratio})</p> <p>Please request your personal offer!</p>',
    financing_reserve_button: 'Request your personal offer',
    loan_input: 'Loan input',
    loan_details: 'Loan details',
    title: 'Loan on Project "{value}"',
    price: 'Total project costs (€)',
    project_link_description: 'According to your project:',
    budget: 'Own funds',
    period: 'Loan period in years',
    interest_terms: 'Interest terms',
    fixed_interest_period: 'Fixed interests in years',
    date: 'Loan date:',
    amount: 'Requested loan amount',
    own_funds_cash: 'Own funds cash',
    own_funds_value_of_property: 'Own funds - value of property',
    total_own_funds: 'Total own funds',
    required_loan: 'Required loan',
    loan_end_date: 'I would need the loan till the',
    loan_term: 'I would prefer a loan term of',
    term_in_years: '{period} years',
    amount_with_loan_costs: 'Your loan amount including loan-costs would be',
    interest_rate: 'Expected interest bandwith (depending on your credit assment) between',
    monthly_rate: 'This would be first monthly a rate of',
    fixed_interest_rate: 'I would like a fixed-interest-period of:',
    variable_interest_rate: 'I would like to have a variable rate based at the 6-month EURIBOR',
    no_need: "It looks like you have enough budget for your project and don't need a loan",
    edit: 'Edit',
    complete_previous_step: 'You must complete the Budget step first to have access to Financing',
    complete_previous_step_btn: 'Calculate Budget',
    rate_title: 'How is my rate calculated?',
    rate_btn_label: 'How is my rate calculated?',
    how_rate_calculated_first_part:
      'The individual calculation is based on your inputs and is for your first information. \n We are calculating a mortgage loan where you can select 3 different variants: <ul><li>Variable interest</li><li>Fix interests</li><li>Combine fix- and variabel</li></ul>',
    how_rate_calculated_second_part:
      'We also charge loan-costs which we include in your loan-amount. \n The final condition is based on your own funds and your credit-score.',
  },

  budget: {
    edit: {
      complete_budget_step:
        'You must first complete the budget planning before you can start the "Budget adjust" step!',
      complete_budget_step__btn: 'Calculate budget',
    },
    titles: {
      main: 'Budget Report',
      personal_situation: 'Personal situation',
      monthly_net_income: 'Monthly net income',
      monthly_expenses: 'Monthly expenses',
      affordable_loan: 'Affordable loan',
      total_monthly_income: 'Total monthly income (12 times a year)',
      total_monthly_costs: 'Total monthly costs',
      total_loan_amount: 'You can afford a total loan amount of',
      monthly_repayments: 'You can afford monthly repayments of',
    },
    btn_to_financing: 'Financing',
    tooltip_download_button: 'You can download budget estimation in pdf here',
    tooltip_edit_button: 'You can update your budget calculated values here',
    tooltip_settings_button: 'You can update your budget here',
    monthly_net_salary: 'My salary',
    monthly_net_income: 'My monthly net income is',
    monthly_net_income_partner: 'The net income of my partner is',
    monthly_net_salary_partner: 'Monthly net salary of partner',
    other_regular_income: 'Other regular income',
    monthly_alimony: 'Monthly alimony to pay',
    monthly_repayments_loans: 'Monthly repayments for existing loans',
    monthly_expenses: 'Monthly expenses for me',
    monthly_expenses_description: 'This includes the essential expenses for your basic needs',
    monthly_expenses_partner: 'Monthly expenses for my partner',
    monthly_expenses_partner_description: 'This includes the essential expenses for your basic needs',
    monthly_expenses_children: 'Monthly expenses for my children',
    monthly_expenses_children_description: 'This includes the essential expenses for your basic needs',
    operational_costs_home: 'Operational costs for our home',
    operational_costs_home_description: 'Such as repair and maintenance costs.',
    technology: 'Technology',
    technology_description: 'Such as smartphones, computers, TV, and Internet.',
    luxuries: 'Luxuries',
    luxuries_description: 'Such as fashion, beauty or retail therapy.',
    socializing: 'Socializing',
    socializing_description: 'Such as going out for drinks or a meal, or entertaining at your home.',
    hobbies: 'Hobbies',
    hobbies_description:
      'This includes costs for equipment/instruments and equipment, membership fees for clubs, courses, etc.',
    taking_a_break: 'Taking a break',
    taking_a_break_description: 'Such as holidays and travel.',
    cars: 'Cars: {number}',
    cars_description: '@This is a placeholder for cars',
    private_pension_and_insurances: 'Private pension and insurances',
    private_pension_and_insurances_description:
      'Supplementary insurance for illness and accident, legal protection, old-age provision',
    select_values: {
      not_important: 'Not important',
      important: 'Important',
      very_important: 'Very important',
    },
    new_car: 'That new car smell',
    socializing_select_description:
      'How important is it to you to go out for drinks or a meal, or entertain at your home?',
    luxuries_select_description:
      'How important is it for you to spend on non-necessities such as fashion, beauty or retail therapy.',
    new_car_select_description: 'How important is it for you to have a new, fresh or powerful car?',
    taking_a_break_select_description: 'How important is it for you to take holidays or to travel?',
    hobbies_select_description:
      'How important is it for you to have money to spend on potentially costly hobbies? E.g. Golf, Skiing, Hunting, Motorsports etc.',
    technology_select_description:
      'How important is it for you to have the latest technology? E.g. smartphones, tablets, wearables, TVs, or digital subscriptions.',
    private_pension_and_insurances_select_description: 'How much do you spend on any privates pensions and insurances?',
    lifestyle: 'Lifestyle',
  },
  budget_calculator: {
    first_step: {
      show_more_text: 'Read more',
      show_less_text: 'Show less',
      alone_or_partner: 'Do you live alone or with partner?',
      partner_option_label: 'Partner',
      alone_option_label: 'Alone',
      kids_quantity: 'How many kids do you have?',
      kids_quantity_description: "Please also enter kid 'in planning' because a loan is a long-lasting commitment",
      age: 'Age',
      age_description: ' ',
      slider_unit_years: 'years',
      own_funds: 'Savings (€)',
      own_funds_description: 'How much money do you have available, before your loan, to dedicate to the project?',
      square_meters: 'How many square meters does your house or flat have?',
      square_meters_description: "We'll use this to calculate the running costs.",
      project_type_finance: {
        label: 'What kind of project are you undertaking?',
        buying: 'Buying',
        renovation: 'Renovation',
        building: 'Building',
      },
      agents_help_when_buying: {
        label: 'Do you buy with an agent?',
        buy_with_agent: 'Buy with agent',
        buy_without_agent: 'Buy without agent',
      },
      budget_calculator_description:
        "Most budget calculators use average spending figures to calculate a budget - but honestly, who is really average? Our budget should be based on what we like to do - if you love to read books, go out for walks and like to cook at home then your budget should be different from someone who is a skiing fanatic, never eats at home and has an inclination for fast cars. Our calculator uses these factors to help you find out what you can afford and still enjoy your life. Maybe it's better to have a slightly small home and still have the money to spend on the things you love to do. MeinBau's goal is to help people find that balance and be happier for it :)",
    },
    second_step: {
      other_regular_revenues: 'Other regular revenues? (€)',
      other_regular_revenues_description: 'e.g. revenues of renting a flat',
      monthly_net_salary: 'How much is your monthly salary? (€)',
      monthly_net_salary_description:
        'Please enter your monthly net salary excluding diets and mileage allowance. If you receive income from self-employment, please enter your last annual net salary divided by 14 here',
      monthly_net_salary_partner: 'How much is the monthly net income of your partner? (€)',
    },
    fifth_step: {
      monthly_expenses_subtitle: 'Monthly expenses',
    },
    sixth_step: {
      affordable_loan_repayments: 'Affordable loan repayments',
      monthly_income: 'Monthly income',
      monthly_income_partner: 'Monthly income partner',
      other_revenues: 'Other revenues',
      total_monthly_income: 'Total monthly income (12 times a year)',
      monthly_expenses: 'Monthly expenses',
      amount_of_alimony: 'Amount of alimony',
      existing_loan: 'Existing loan',
    },
    final_step: {
      affordable_loan: 'Affordable loan',
      own_funds: 'Own-funds ({ownFundsPercentage}%)',
      available_budget: 'Available budget',
      possible_project_costs: 'Possible project costs',
      additional_costs: 'Additional costs',
      property_transfer_tax: 'Property transfer tax (3.5%)',
      land_register_fee: 'Land register fee (1.1%)',
      notary_fee: "Notary's fee (1%)",
      broker_commission: 'Broker commission (3.6%)',
      request_loan_consulting: 'Request loan-consulting',
      gratitude_title: 'Thanks for your trust!',
      gratitude_subtitle: 'Your request was sent to our retail experts!',
      back_to_lifestyle_step: 'Back to lifestyle step',
      calculate_project_costs: 'Calculate project costs',
      message_insufficient_own_funds:
        'The minimum percentage of your funds must be at least 20 % of the total loan amount. Current percentage {ownFundsPercentage}%',
    },
    first_step_title: 'You and Your Family',
    second_step_title: 'Your Income',
    third_step_title: 'Expenses',
    fourth_step_title: 'Lifestyle',
    fifth_step_title: 'Cost overview',
    sixth_step_title: 'Affordable monthly repayments',
    final_step_title: 'Result',
    next_step_button: 'Continue',
    finish_step_button: 'Calculate',
    not_important_option_label: 'Not important',
    important_option_label: 'Important',
    very_important_option_label: 'Very important',
    how_many_cars: 'How many cars do you have?',
    amount_of_alimony: 'How much alimony do you have to pay? (€)',
    monthly_loan: 'Monthly repayments for any loans (€)',
    socializing: 'Socializing',
    socializing_descriptions: 'How important is it for you to go out for drinks or a meal, or entertain at your home?',
    luxuries: 'Luxuries',
    luxuries_description:
      'How important is it for you to spend on non-necessities such as fashion, beauty or retail therapy.',
    new_car: 'That new car smell',
    new_car_description: 'How important is it for you to have a new, fresh or powerful car?',
    holidays_and_traveling: 'Holidays and traveling',
    hobbies: 'Hobbies',
    hobbies_description:
      'How important is it for you to have money to spend on potentially costly hobbies? E.g. Golf, Skiing, Hunting, Motorsports etc.',
    technology: 'Technology',
    technology_description:
      'How important is it for you to have the latest technology? E.g. smartphones, tablets, wearables, TVs, or digital subscriptions.',
    pension_and_insurances: 'Private pensions and insurances',
    monthly_expenses: 'Monthly expenses for me',
    monthly_expenses_result_description: 'This includes the essential expenses for your basic needs',
    monthly_expenses_partner_result_description: 'This includes the essential expenses for their basic needs',
    monthly_expenses_kids_result_description: 'This includes the essential expenses for their basic needs',
    costs_for_repair_result_description: 'Such as repair and maintenance costs.',
    technology_result_description: 'Such as smartphones, computers, TV, and Internet.',
    luxuries_result_description: 'Such as fashion, beauty or retail therapy.',
    socializing_result_description: 'Such as going out for drinks or a meal, or entertaining at your home.',
    taking_a_break_result_description: 'Such as holidays and travel.',
    monthly_expenses_partner: 'Monthly expenses for my partner',
    monthly_expenses_kids: 'Monthly expenses for my children',
    costs_for_repair: 'Operational costs for our home.',
    cars: 'Car(s)',
    cars_description: '@This is a placeholder for cars',
    personal_financial_situation: 'Your financial situation',
    monthly_costs: 'Your monthly costs',
    monthly_costs_description:
      'Our algorithm compares your monthly income against calculated estimates of your outgoings based on the lifestyle information you input. You can override our calculations if you know more accurate figure.',
    monthly_rate_loan: 'You can afford monthly repayments of:',
    amount_of_loan: 'You can afford a total loan amount of:',
    funds_and_budget: 'Including your own funds, you have a total project budget of:',
    taking_a_break: 'Taking a break',
    taking_a_break_description: 'How important is it for you to take holidays or to travel?',
    loan_impossible_description:
      'Oops, it seems that your income doesn’t fit your lifestyle - please adjust income or expenses!',
    missing_monthly_value: "You're missing at least {value} per month",
  },

  dashboard: {
    title: 'Dashboard',
    complete_project_details_step: 'You must complete the project details step first',
    complete_project_details_step_btn: 'Project details Projekt berechnen',
    can_not_complete_step: `Currently, you can't complete this step for the new calculator for CO₂, energy costs, and renovation costs. Development in progress`,
    budget_shortage_chart: {
      shortage_label: 'Shortage',
      budget_label: 'Own funds',
    },
    downloads: {
      title: 'Downloads',
      project_pdf: 'Project estimation',
      budget_not_available_tooltip: 'You must complete the Budget planning first',
      budget_pdf: 'Budget overview',
    },
    overview: {
      project_price: 'My project costs',
      budget: 'My own funds',
      monthly_affordable_repayments: 'My monthly affordable repayments',
      loan_amount: 'My loan amount for this project',
      monthly_rate: 'My monthly rate',
      budget_monthly_rate_loan: 'You must to complete {link} to get this value',
      budget_monthly_rate_loan_link_label: 'budget step',
    },
    getting_started: {
      step_cta_button: 'Proceed',
      project_details: {
        title: 'Dream home planning',
        description: 'TODO: Add description',
      },
      adjust_plan: {
        title: 'Adopt costs',
        description: 'TODO: Add description',
      },
      budget_planning: {
        title: 'Lifestyle budget planning',
        description: 'TODO: Add description',
      },
      adjust_budget_plan: {
        title: 'Adopt budget plan',
        description: 'TODO: Add description',
      },
      loan_calculator: {
        title: 'Plan financing',
        description: 'TODO: Add description',
        disabled_proceed_btn_tooltip: 'You must complete the Budget step first to have access to Financing',
      },
      loan: {
        title: 'Digital financing commitment',
        description: 'TODO: Add description',
      },
      request_personal_offer: {
        title: 'Request personal offer',
        description: 'TODO: Add description',
      },
    },
  },
  disclaimers: {
    guest_user: 'You are a guest. In order to save your data you need to register',
    registration:
      '<b>DISCLAIMER:</b>\n\n The values given are non-binding estimates, which were calculated on the basis of average market values in Austria and are used only as a guide. \n Please keep in mind that these values vary more or less from the actual actual costs depending on the market situation, negotiation situation and execution.\n HYPO NOE assumes no liability for the correctness of these values, nor for any malfunctions.',
    budget:
      '<b>DISCLAIMER:</b>\n\n HYPO NOE Landesbank für Niederösterreich und Wien AG (short: HYPO NOE) does not recommend a specific loan. However, based on your answers to some of the questions, HYPO NOE will provide you with information about financing so that you can make your own decision.\n This list is not a legal offer, but is only for internal processing of the loan request.',
    loan_calculator:
      '<b>DISCLAIMER:</b>\n\n This result was calculated based on your input, serves only as a guide and does not constitute a binding offer.\n For the calculation, the cheapest interest rate with mortgage collateral and very good creditworthiness of the borrower without taking into account expenses and incidental fees was used.\n HYPO NOE is ready to make you a non-binding offer in accordance with the Mortgage and Real Estate Loan Act (HIKrG) in the event of a specific interest.\n Please use the "Request a personal offer" option and one of our housing experts will contact you in this regard set.',
    sprengnetter_not_available: 'Unfortunately there is no Sprengnetter rating available for the address entered!',
    sprengnetter_estimation:
      'Note:\n\n A maximum of 10 property reviews are available to each user. Reviews you have already carried out: {requestsCount}',
    land_sprengnetter_not_available: 'Unfortunately there is no Sprengnetter rating available for the address entered!',
    land_sprengnetter_estimation:
      'Note:\n\n A maximum of 10 land area reviews are available to each user. Reviews you have already carried out: {requestsCount}',
    land_sprengnetter_estimation_loan_consultant: 'Reviews you have already carried out: {requestsCount} reviews',
  },
  legend: {
    totalLabel: 'Your price {value}',
    lower: 'Lower',
    avg: 'Average',
    upper: 'Upper',
  },
  IE_not_supported:
    'Unfortunately IE11 is not supported. It is recommended to view the website using the latest version of Google Chrome',
  debug_mode: {
    co2_values: {
      columns: {
        option_name: 'Option name',
        actual_scenario_input: 'Actual-Scenario Input Parameter',
        potential_scenario_input: 'Potential-Scenario Input Parameter',
        planned_scenario_input: 'Planned-Scenario Input Parameter',
        actual_scenario_output: 'Actual-Scenario Output Parameter',
        potential_scenario_output: 'Potential-Scenario Output Parameter',
        planned_scenario_output: 'Planned-Scenario Output Parameter',
      },
      form_values: {
        amount_of_hot_water: 'Amount of hot water',
        energy_standard: 'Energy standard',
        heated_living_area: 'Heated living area',
        heating_system: 'Heating system',
        hot_water_producer: 'Hot water producer',
        age_of_heating_system: 'How old is heating system',
        is_devices_younger_than_10_years: 'Is devices younger than 10 years',
        is_solar_heating_system: 'Is solar heating system',
        is_solar_power_system: 'Is solar power system',
        kind_of_house: 'Kind of house',
        number_of_people: 'Number of people',
        power_consumers: 'Power consumers',
        renovations: 'Renovations',
        solar_heating_system_type: 'Solar heating system type',
        solar_power_system_consuming_percentage: 'Solar power system consuming percentage',
        solar_power_system_size: 'Solar power system size',
        temperature: 'Temperature',
      },
      result: {
        specific_heating_demand: 'Specific heating demand',
        heating_factor_kea: 'Heating factor kea',
        heating_factor_ghg: 'Heating factor ghg',
        total_heating_demand: 'Total heating demand',
        primary_energy_for_heating: 'Primary energy for heating',
        heating_ghg_emission: 'Heating ghg emission',
        heating_ghg_equivalent_car: 'Heating ghg equivalent_car',
        heating_ghg_equivalent_plane: 'Heating ghg equivalent plane',
        heating_ghg_equivalent_trees: 'Heating ghg equivalent trees',
        total_heating_costs: 'Total heating costs',
        primary_energy_for_hot_water: 'Primary energy for hot water',
        hot_water_ghg_emission: 'Hot water ghg emission',
        hot_water_ghg_equivalent_car: 'Hot water ghg equivalent car',
        hot_water_ghg_equivalent_plane: 'Hot water ghg equivalent plane',
        hot_water_ghg_equivalent_trees: 'Hot water ghg equivalent trees',
        total_hot_water_costs: 'Total hot water costs',
        total_power_consumption_per_year: 'Total power consumption per year',
        total_power_production_per_year: 'Total power production per year',
        own_consumption: 'Yourself consumption',
        power_consumption_from_energy: 'Power consumption from energy',
        electrical_feed_in_to_grid: 'Electrical feed in to grid',
        ghg_savings_solar_power_system: 'Ghg savings solar power system',
        ghg_balance_of_power_consumption: 'Ghg balance of power consumption',
        electricity_ghg_equivalent_car: 'Electricity ghg equivalent car',
        electricity_ghg_equivalent_plane: 'Electricity ghg equivalent plane',
        electricity_ghg_equivalent_trees: 'Electricity ghg equivalent trees',
        solar_power_system_helps_to_save: 'Solar power system helps to save',
        total_electricity_costs: 'Total electricity costs',
        total_energy_balance_per_year: 'Total energy balance per year',
        energy_ghg_balance_per_year: 'Energy ghg balance per year',
        energy_ghg_equivalent_car: 'Energy ghg equivalent car',
        energy_ghg_equivalent_plane: 'Energy ghg equivalent plane',
        energy_ghg_equivalent_trees: 'Energy ghg equivalent trees',
        total_energy_costs: 'Total energy costs',
      },
    },
  },
  alt_text: {
    btn_select_with_icon: 'Image {btnLabel}',
    quick_calculator: {
      renovation_house: 'Image renovation of a single-family house (including CO₂ balance)',
      buying_house: 'Image single-family house (including renovation)',
      new_building: 'Image new building single-family house',
      new_apartment: 'Image new apartment',
      lifestyle_calculator: 'Image lifestyle calculator',
    },
    project_details_page: {
      new_apartment: {
        first_step: 'Image the first step new apartment project type',
        third_step: 'Image the third step new apartment project type',
        fourth_step: 'Image the fourth step new apartment project type',
        fifth_step: 'Image the fifth step new apartment project type',
      },
      new_building: {
        first_step: 'Image the first step new building project type',
        third_step: 'Image the third step new building project type',
        fourth_step: 'Image the fourth step new building project type',
        fifth_step: 'Image the fifth step new building project type',
        sixth_step: 'Image the sixth_step step new building project type',
      },
      buying_house: {
        first_step: 'Image the first step buying house project type',
        third_step: 'Image the third step buying house project type',
        fourth_step: 'Image the fourth step buying house project type',
        fifth_step: 'Image the fifth step buying house project type',
        sixth_step: 'Image the sixth_step step buying house project type',
      },
      renovation_house: {
        first_step: 'Image object data - house renovation',
        third_step: 'Image apartments - general information',
        fourth_step: 'Image CO2 optimization - wind turbines, solar plant',
        fifth_step: 'Interior graphics - woman sitting at computer',
        sixth_step: 'Image exterior - house with solar panels',
        seventh_step: 'Image apartments - general information',
      },
    },
    project_plan_page: {
      img: 'Image plan project costs - calculator',
    },
    my_projects: {
      delete_project: 'Icon delete project',
    },
    auth_pages: {
      login: 'Image login page',
      sign_up: 'Image sign-up page',
      forgot_password: 'Image forgot password page',
      reset_password: 'Image reset password page',
    },
    renovation_house: {
      energy_scale_house: 'Image Energy balance display for your home',
      co2_icon: 'Icon Co2 savings potential',
      euro_icon: 'Icon energy costs savings potential',
      lightning_icon: 'Icon energy consumption potential savings',
      plane_icon: 'Icon air travel',
      car_icon: 'Icon car kilometers',
      tree_icon: 'Icon trees',
      renovation_house_img: 'Image house renovation - old becomes new',
      number_of_people: {
        one_person: 'Image one person',
        two_people: 'Image two people',
        three_people: 'Image three people',
        four_people: 'Image four people',
        five_people: 'Image five people',
        six_people: 'Image six people',
      },
    },
    new_building: {
      number_of_floors: {
        one_floor: 'Image one-storey house',
        two_floors: 'Image two-storey house',
        three_floors: 'Image three-storey house',
      },
      equipment_house_types: {
        standard: 'Icon standard type of house equipment',
        premium: 'Icon premium type of house equipment',
        superior: 'Icon superior type of house equipment',
      },
    },
    invite_user_page_img: 'Image invite user',
    contact_us_img: 'Image contact us',
    budget_overview_img: 'Image budget overview',
    budget_edit_monthly_expenses_img: 'Image budget edit monthly expenses',
    budget_calculator: {
      first_step: 'Image Living situation - couple with stroller',
      second_step: 'Image Income - man with euro coin',
      third_step: 'Image Expenses - woman with purse',
      fourth_step: 'Image Lifestyle - woman with shopping bags',

      cars: {
        one_car: 'Icon one car',
        two_cars: 'Icon two cars',
        three_cars: 'Icon three cars',
        four_cars: 'Icon four or more cars',
      },
      kids_quantity: {
        one_child: 'Icon one child',
        two_children: 'Icon two children',
        three_children: 'Icon three children',
        four_children: 'Icon four or more children',
      },
    },
    icons: {
      info: 'Icon info',
      plus: 'Icon plus',
      minus: 'Icon minus',
      lightning: 'Icon lightning',
      has_none: 'Icon has none',
      close: 'Icon close',
      check_circle: 'Icon check circle',

      euro: 'Icon euro',
      wallet: 'Icon wallet',
      document: 'Icon document',
      dashboard: 'Icon dashboard',
      message: 'Icon message',
      subject: 'Icon subject',
      local_offer: 'Icon local offer',
      supervisor_account: 'Icon supervisor account',
      wrench: 'Icon wrench',
      arrow_right: 'Icon arrow right',
    },
    navbar: {
      logo: 'Logo',
      dropdown_user_icon: 'Icon user',
    },
  },
};

export const backendErrors = {
  access_denied: 'Access denied',
  something_went_wrong: 'Something went wrong',
  form_data_is_missing: 'Form data is missing',
  create_project_first: 'You cannot log in until you register and create a project',
  auth: {
    login_failed: 'Login failed. Please try again',
    email_exists: 'User with this email is already registered',
    verify_account: 'You have to verify your email before login.',
    password_is_required: 'Password is required',
    not_authorized: 'Not authorized',
    temporary_password_is_required: 'Your first login must have a temporary password',
    wrong_password: 'Wrong password',
    email_is_required: 'Email is required',
    account_has_no_password:
      'Your account does not have a password because you signed up with a social services (Google or Facebook)',
    link_has_expired: 'Link has expired',
  },
  user: {
    user_not_found: 'User not found',
    cannot_remove_yourself: 'You cannot remove yourself',
  },
  sprengnetter_estimation: {
    no_permissions: 'No permissions for get Sprengnetter estimation',
    requests_limit: 'You have already used the service limit',
  },
  project: {
    limit_of_projects: 'You cannot create more than 10 projects',
    last_project: 'You cannot delete the last project',
    not_found: 'Project not found',
    cannot_remove_project_payments_recorded: 'You cannot remove this project because payments have been recorded',
    has_already_owner: 'Project has already an owner',
  },
  budget: {
    no_budget_calculation: 'You have no budget calculation',
    need_to_transfer_project_with_budget:
      'As the financing step in the project is completed, you will also have to transfer budget calculations',
  },
  financing: {
    request_sent: 'Request has already been sent',
  },
  invitation: {
    email_exists: '@Such an email already exists in the system',
    cannot_resend_temp_password: `@You can't send a temporary password because the user has already changed it`,
    cannot_delete_invited_user: `@You can't delete a user account because he has changed the temporary password`,
  },
  image_uploading: {
    fetch_fail: 'Failed to fetch file',
    fetch_owner_fail: 'Failed to fetch file uploader',
    wrong_owner: 'No permissions to remove this file',
  },
  payments: {
    limit_of_payers: 'You cannot add more than 5 payers',
    failed_to_remove_payer:
      'You cannot remove this payer at this time. Please reassign payments when this payer is assigned.',
  },
  admin: {
    disable_2_fa: 'Disable 2FA before initiating it again.',
    otp_is_invalid: 'One-time password is not valid. Please try again',
    last_admin: 'You last admin',
  },
  pdf: {
    export_error: 'Something went wrong while exporting pdf',
  },
  co2Calculations: {
    co2_calculations: 'Unfortunately no CO₂ calculations were found, please try again later',
  },
  express_loan: {
    calculations_not_found: 'Calculations not found',
  },
};
