import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import TextArea from 'components/inputs/TextArea';
import Button from 'components/common/Button';
import ErrorMessage from 'components/common/ErrorMessage';
import { ArrowForward } from 'assets/icons';
import { useIntl } from 'react-intl';
import styles from './ContactForm.module.scss';

const initialValue = {
  message: '',
};

const contactShape = {
  message: yup.string().required('Message is required.'),
};

const contactParamsSchema = yup.object().shape(contactShape);

const ContactForm = ({ onSubmit }) => {
  const { formatMessage: t } = useIntl();
  return (
    <Formik initialValues={initialValue} validationSchema={contactParamsSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <>
          <div className={styles.container}>
            <Form>
              <Field name="message" component={TextArea} minRows={15} label={t({ id: 'contact.note' })} />
              {errors.form && <ErrorMessage message={errors.form} />}
              <div className={styles.buttonContainer}>
                <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                  {t({ id: 'contact.note' })}
                </Button>
              </div>
            </Form>
          </div>
        </>
      )}
    </Formik>
  );
};

export default ContactForm;
