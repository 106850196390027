import React, { useRef } from 'react';
import NumberInput from 'components/inputs/NumberInput';
import { Formik, Form, Field } from 'formik';
import { BudgetStep6 } from 'assets/images/lifestyle-calculator';
import * as yup from 'yup';
import Button from 'components/common/Button';
import map from 'lodash/map';
import { Row, Col } from 'react-flexbox-grid';
import ScrollToError from 'components/common/ScrollToError';
import { useIntl, FormattedMessage } from 'react-intl';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';

function getValidationSchema(initialValues = []) {
  return yup.object().shape({
    ...initialValues.reduce((result, key) => {
      const tmp = {
        [key]: yup
          .number()
          .required(<FormattedMessage id="errors.fill_field" />)
          .typeError(<FormattedMessage id="errors.fill_field" />),
      };
      return { ...result, ...tmp };
    }, {}),
  });
}

const BudgetCalculatorEditForm = ({ initialValues, onSubmit, onCancel }) => {
  const { formatMessage: t } = useIntl();
  const BudgetSchema = getValidationSchema(Object.keys(initialValues));
  const formikRef = useRef();

  return (
    <Row center="md" between="lg">
      <Col md={8} lg={6} xl={5}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={(...props) => onSubmit(...props, formikRef.current)}
          validationSchema={BudgetSchema}
        >
          {({ isSubmitting }) => (
            <>
              <div>
                <Form>
                  <ScrollToError />
                  {map(initialValues, (item, key) => (
                    <Field
                      key={key}
                      name={key}
                      component={NumberInput}
                      required
                      max={2000000}
                      label={`${t({ id: `budget_calculator.${key}` })}, €`}
                    />
                  ))}
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to={routePaths.lifestyleOverview}>
                      <Button disabled={isSubmitting} onClick={onCancel} startIcon={<ArrowBack />}>
                        {t({ id: 'app.back' })}
                      </Button>
                    </Link>
                    <Button
                      disabled={isSubmitting}
                      color="secondary"
                      loading={isSubmitting}
                      type="submit"
                      endIcon={<ArrowForward />}
                    >
                      {t({ id: 'app.save' })}
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep6 width="100%" height="100%" title={t({ id: 'alt_text.budget_edit_monthly_expenses_img' })} />
        </div>
      </Col>
    </Row>
  );
};

export default BudgetCalculatorEditForm;
