/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useRef } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import ScreenLink from 'components/common/ScreenLink';
import Button from 'components/common/Button';
import { scrollToNextMatchingSibling } from 'utils/form';
import { useAppContext } from 'contexts/AppContext';
import { useIntl } from 'react-intl';
import styles from './ButtonSelect.module.scss';

function getIconWithAltText({ altText, icon, t }) {
  return icon({ altText: t({ id: 'alt_text.btn_select_with_icon' }, { btnLabel: altText }) });
}

function getIcon({ icon, altText, label, t }) {
  if (typeof icon === 'function') {
    return getIconWithAltText({ icon, altText: altText || label, t });
  }
  return icon;
}

const SButton = ({ name, value, option, handleToggle, onBlur, theme, disabled }) => {
  const { formatMessage: t } = useIntl();
  const { topIcon, endIcon, startIcon, label, altText } = option;

  const themeToButtonColor = {
    primary: {
      selected: styles.selected,
      notSelected: styles.notSelected,
    },
    green: {
      selected: styles.greenSelected,
      notSelected: styles.greenNotSelected,
    },
  };

  const isSelected = React.useCallback(
    (o) => {
      return Array.isArray(value) ? value.includes(o.value) : o.value === value;
    },
    [value],
  );

  return (
    <Button
      name={name}
      key={option.value}
      onClick={(e) => handleToggle(option, e)}
      onBlur={onBlur}
      disabled={disabled}
      className={cn(
        styles.button,
        topIcon ? styles.btnContentInColumn : '',
        startIcon ? styles.btnWithStartIcon : '',
        isSelected(option) ? themeToButtonColor[theme].selected : themeToButtonColor[theme].notSelected,
      )}
      startIcon={getIcon({ icon: startIcon, t, label, altText })}
      endIcon={getIcon({ icon: endIcon, t, label, altText })}
      topIcon={getIcon({ icon: topIcon, t, label, altText })}
    >
      {label}
    </Button>
  );
};

const InlineSelect = ({
  label,
  onChange,
  onBlur,
  value,
  options,
  multi,
  nullable,
  error,
  description,
  moreDetails,
  inOneRow = true,
  skipScrollingToField,
  theme,
  classnames,
  disabled = false,
  name,
  largeLabel,
}) => {
  const fieldWrapperRef = useRef();

  const handleToggle = React.useCallback(
    async (option, e) => {
      async function triggerChange() {
        if (multi) {
          let result = null;
          if (Array.isArray(value)) {
            result = value;
            if (value.includes(option.value)) result = result.filter((item) => item !== option.value);
            else result = [...result, option.value];
          } else {
            result = [value];
          }
          await onChange(result);
        } else if (value === option.value) {
          if (nullable !== false) {
            await onChange(null);
          }
        } else {
          await onChange(option.value);
        }
      }
      e.persist();
      await triggerChange();
      e.target.blur();
      if (!multi && !skipScrollingToField) {
        scrollToNextMatchingSibling(fieldWrapperRef);
      }
    },
    [multi, value, onChange, nullable, skipScrollingToField],
  );

  const fieldWrapperClassname = useMemo(() => {
    let classes = 'field-wrapper';
    if (largeLabel) {
      classes += ' field-with-large-label';
    }
    if (!skipScrollingToField) {
      classes += ' scroll-to-field';
    }
    return cn(classes, classnames);
  }, [largeLabel, skipScrollingToField, classnames]);

  return (
    <div ref={fieldWrapperRef} className={fieldWrapperClassname}>
      <label className="label-when-focused-input" style={moreDetails && { pointerEvents: 'initial' }}>
        {label} {moreDetails && <ScreenLink linkData={moreDetails} />}
      </label>
      <div className={cn(styles.buttonsContainer, !inOneRow ? styles.multiLineButtons : {})}>
        {options.map((option) => (
          <SButton
            key={`${name}_${option.value}`}
            {...{ option, handleToggle, onBlur, name, value, theme, disabled }}
          />
        ))}
      </div>
      <div>{error ? <div className="error-text">{error}</div> : null}</div>
      {description ? (
        <div className={styles.note}>
          <small className="muted">{description}</small>
        </div>
      ) : null}
    </div>
  );
};

const emptyFunc = () => {};

function InlineSelectContainer({ form, field, sideEffectOnButtonClick = emptyFunc, ...rest }) {
  const { name } = field;
  const { errors, touched } = form;
  const { theme } = useAppContext();

  const onChange = React.useCallback(
    async (v) => {
      form.setFieldValue(name, v);
      await sideEffectOnButtonClick(name, v);
    },
    [form, name, sideEffectOnButtonClick],
  );

  const error = get(errors, name) && get(touched, name) ? get(errors, name) : null;

  return <InlineSelect {...{ ...rest, ...field, onChange, theme, error }} />;
}

export default InlineSelectContainer;
