import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import { inRange, last, get } from 'lodash';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { formatValue } from 'utils/helpers';
import Table from 'rc-table';
import cn from 'classnames';
import { ReactComponent as EnergyScaleHouseImg } from 'assets/images/energy-scale-house.svg';
import { Hidden } from 'components/common/Grid';
import useResizeObserver from '@react-hook/resize-observer';
import energyScaleClasses from './energy-scale-classes';
import s from './EnergyClassesAndIndicators.module.scss';

function getEnergyClass(specificHeatingDemand) {
  let energyClass;
  if (specificHeatingDemand < energyScaleClasses[0].max) {
    [energyClass] = energyScaleClasses;
  } else if (specificHeatingDemand > last(energyScaleClasses).min) {
    energyClass = last(energyScaleClasses);
  } else {
    energyClass = energyScaleClasses.find(({ max, min }) => inRange(specificHeatingDemand, min, max + 1));
  }
  return energyClass;
}

function EnergyScaleClass({ label, color, index }) {
  const width = useMemo(() => `${20 + index * index}%`, [index]);
  return (
    <div key={label} className={s.energyClass} style={{ width, backgroundColor: color }}>
      {label}
      <div className={s.triangle} style={{ borderColor: color }} />
    </div>
  );
}

function ActualEnergyScaleClass({ isActualEnergyClass, color, label }) {
  if (!isActualEnergyClass) return null;
  return (
    <div style={{ backgroundColor: color }} className={s.actualEnergyClass}>
      <div className={s.triangle} style={{ borderColor: color }} />
      {label ? <div className={s.label}>{label}</div> : null}
    </div>
  );
}

function getColumns({ t, costOverviewTab }) {
  const columns = [
    {
      title: '',
      key: 'energyClassName',
      dataIndex: 'energyClassName',
      render: EnergyScaleClass,
    },
    {
      title: costOverviewTab ? t({ id: 'renovation_house_wizard.house_energy_balance.before' }) : '',
      key: 'actualEnergyClass',
      dataIndex: 'actualEnergyClass',
      width: '25%',
      render: (props) => {
        return (
          <ActualEnergyScaleClass
            {...props}
            label={!costOverviewTab ? t({ id: 'renovation_house_wizard.house_energy_balance.your_house' }) : ''}
          />
        );
      },
    },
  ];
  const columnAfterRenovations = {
    title: t({ id: 'renovation_house_wizard.house_energy_balance.after' }),
    key: 'afterEnergyClass',
    dataIndex: 'afterEnergyClass',
    width: '25%',
    render: ActualEnergyScaleClass,
  };

  if (costOverviewTab) columns.push(columnAfterRenovations);

  return columns;
}

const useSize = (target) => {
  const [size, setSize] = useState();

  useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

const EnergyScaleHouse = ({ containerTableRef, t }) => {
  const size = useSize(containerTableRef);

  const height = get(size, 'height', 0);
  const width = get(size, 'width', 0);

  return (
    <Hidden xs sm>
      <EnergyScaleHouseImg
        title={t({ id: 'alt_text.renovation_house.energy_scale_house' })}
        style={{
          height: `${height}px`,
          minHeight: `${height}px`,
          maxHeight: `${height}px`,
          width,
          marginRight: '10px',
        }}
      />
    </Hidden>
  );
};

export const EnergyScaleClasses = ({ t, co2Calculations, costOverviewTab = false }) => {
  const containerTableRef = useRef();
  const { co2_emissions_before, co2_emissions_after } = co2Calculations;
  const specificHeatingDemandBefore = get(co2_emissions_before, 'result.specific_heating_demand', null);
  const specificHeatingDemandAfter = get(co2_emissions_after, 'result.specific_heating_demand', null);

  const actualEnergyClass = useMemo(() => getEnergyClass(specificHeatingDemandBefore), [specificHeatingDemandBefore]);
  const energyClassAfterRenovations = useMemo(() => getEnergyClass(specificHeatingDemandAfter), [
    specificHeatingDemandAfter,
  ]);

  const columns = useMemo(() => getColumns({ t, costOverviewTab }), [t, costOverviewTab]);

  const data = useMemo(
    () =>
      energyScaleClasses.map(({ label, color, index }) => ({
        key: label,
        energyClassName: { label, color, index },
        actualEnergyClass: { isActualEnergyClass: index === actualEnergyClass.index, color },
        afterEnergyClass: { isActualEnergyClass: index === energyClassAfterRenovations.index, color },
      })),
    [actualEnergyClass.index, energyClassAfterRenovations.index],
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%' }}>
      <div ref={containerTableRef} style={{ width: '100%', order: 1 }}>
        <Table
          className={cn(s.energyScaleTable, !costOverviewTab ? s.withoutGrid : '')}
          direction="ltr"
          columns={columns}
          data={data}
          emptyText="No data"
        />
      </div>
      {!costOverviewTab ? <EnergyScaleHouse {...{ containerTableRef, t }} /> : null}
    </div>
  );
};

function HouseEnergyIndicators({ t, nextStep, calculations }) {
  return (
    <div className={s.houseEnergyIndicators}>
      <h3 className={s.label}>{t({ id: 'renovation_house_wizard.house_energy_balance.label' })}</h3>
      <b className={s.heatingDemandValue}>
        {t(
          { id: 'renovation_house_wizard.house_energy_balance.specific_heating_demand' },
          { value: formatValue(calculations.specific_heating_demand) },
        )}
      </b>
      <div>
        <p>
          {t(
            { id: 'renovation_house_wizard.house_energy_balance.heating' },
            { value: formatValue(calculations.heating_ghg_emission) },
          )}
        </p>
        <p>
          {t(
            { id: 'renovation_house_wizard.house_energy_balance.hot_water' },
            { value: formatValue(calculations.hot_water_ghg_emission) },
          )}
        </p>
        <p>
          {t(
            { id: 'renovation_house_wizard.house_energy_balance.electricity' },
            { value: formatValue(calculations.ghg_balance_of_power_consumption) },
          )}
        </p>
      </div>
      <div className={s.btnWrapper}>
        <Button onClick={nextStep} color="secondary" endIcon={<ArrowForward />}>
          {t({ id: 'renovation_house_wizard.improve_house_btn_label' })}
        </Button>
      </div>
    </div>
  );
}

const EnergyClassesAndIndicators = ({ t, co2Calculations, nextStep }) => {
  const { co2_emissions_before } = co2Calculations;

  return (
    <Row className={s.houseEnergySection}>
      <Col md={12}>
        <Row top="md" between="md">
          <Col lg={8}>
            <EnergyScaleClasses {...{ co2Calculations, t }} />
          </Col>
          <Col lg={4}>
            <HouseEnergyIndicators {...{ t, nextStep, calculations: co2_emissions_before.result }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EnergyClassesAndIndicators;
