import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import Button from 'components/common/Button';
import styles from './ToggleButtons.module.scss';

const ToggleButtons = ({ isCalculated, onCalculatedClick, onQuotedClick, disabled }) => {
  const { formatMessage: __ } = useIntl();
  return (
    <div className={styles.toggleButtons}>
      <Button
        className={classnames(styles.toggleButton, isCalculated ? styles.activeButton : null)}
        onClick={async () => {
          await onCalculatedClick();
        }}
        disabled={disabled}
      >
        {__({ id: 'planning.calculated_button' })}
      </Button>
      <Button
        className={classnames(styles.toggleButton, !isCalculated ? styles.activeButton : null)}
        onClick={async () => {
          await onQuotedClick();
        }}
        disabled={disabled}
      >
        {__({ id: 'planning.quoted_button' })}
      </Button>
    </div>
  );
};

export default ToggleButtons;
