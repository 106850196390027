/* eslint-disable import/prefer-default-export */

import { ReactComponent as LoanBuyingImg } from 'assets/images/quick-calculator/buying-apartment.svg';
import { ReactComponent as LoanBuildImg } from 'assets/images/quick-calculator/building-house.svg';
import { ReactComponent as LoanRenovationImg } from 'assets/images/quick-calculator/renovation-house.svg';
import { ReactComponent as LoanRefinanceImg } from 'assets/images/lifestyle-calculator/budget-step-7.svg';
import { LOAN_BUILD, LOAN_BUYING, LOAN_RENOVATION, LOAN_REFINANCE } from 'constants/constants';

export const expressLoanProjectTypes = [
  {
    label: 'express_loan.project_types.loan_renovation',
    type: LOAN_RENOVATION,
    img: LoanRenovationImg,
  },
  {
    label: 'express_loan.project_types.loan_build',
    type: LOAN_BUILD,
    img: LoanBuildImg,
  },
  {
    label: 'express_loan.project_types.loan_buying',
    type: LOAN_BUYING,
    img: LoanBuyingImg,
  },
  {
    label: 'express_loan.project_types.loan_refinance',
    type: LOAN_REFINANCE,
    img: LoanRefinanceImg,
  },
];
