import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const PROJECT_PLAN_FRAGMENT = gql`
  fragment projectPlan on ProjectPlan {
    pdf
    price
    planningCategories {
      name
      label
      description
      group
      costs_type
      calculated_price
      user_price
      equipment_type
      subitems {
        name
        label
        description
        calculated_price
        user_price
        equipment_type
        has_equipment_type
      }
    }
  }
`;

export const PROJECT_PLAN_QUERY = gql`
  query projectPlan($projectId: ID!) {
    projectPlan(projectId: $projectId) {
      ...projectPlan
    }
    project(id: $projectId) {
      _id
      price
      personal_contribution_value
      type
    }
  }
  ${PROJECT_PLAN_FRAGMENT}
`;

const useProjectPlan = (projectId) => {
  const { data, loading, error } = useQuery(PROJECT_PLAN_QUERY, { variables: { projectId } });
  const projectPlan = get(data, 'projectPlan', null);
  const project = get(data, 'project', null);

  return [{ projectPlan, project }, loading, error];
};

export default useProjectPlan;
