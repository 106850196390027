import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';
import { PROJECT_QUERY } from './useProject';
import { PROJECT_PLAN_QUERY } from '../project-plan/useProjectPlan';

const UPDATE_PROJECT_NEW_BUILDING_MUTATION = gql`
  mutation updateProjectNewBuilding($projectId: ID!, $formData: UpdateProjectNewBuildingInput!) {
    updateProjectNewBuilding(projectId: $projectId, formData: $formData) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useUpdateProjectNewBuildingMutation = () => {
  const [updateProjectNewBuildingMutation] = useMutation(UPDATE_PROJECT_NEW_BUILDING_MUTATION);

  const mutate = useCallback(
    async (projectId, formData) => {
      try {
        const {
          data: { updateProjectNewBuilding: result },
        } = await updateProjectNewBuildingMutation({
          variables: { projectId, formData: { ...formData } },
          refetchQueries: [
            { query: PROJECT_PLAN_QUERY, variables: { projectId } },
            { query: PROJECT_QUERY, variables: { id: projectId } },
          ],
          awaitRefetchQueries: true,
        });

        return result;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        throw e;
      }
    },
    [updateProjectNewBuildingMutation],
  );
  return mutate;
};

export default useUpdateProjectNewBuildingMutation;
