import * as yup from 'yup';
import { createConditionalValidator } from 'utils/yup';
import { RENOVATION_HOUSE } from 'constants/constants';
import { getPartOfValidationSchema } from '../../helpers';

const validationSchema = yup.object().shape({
  ...getPartOfValidationSchema({ projectType: RENOVATION_HOUSE, groupName: 'energy' }),
  solar_power_size_kwp: createConditionalValidator('renovation_house_solar_power_system', 'number'),
  solar_power_equipment_type: createConditionalValidator('renovation_house_solar_power_system', 'string'),
  facade_insulation_type: createConditionalValidator('renovation_house_facade_insulation', 'string'),
  facade_insulation_area: createConditionalValidator('renovation_house_facade_insulation', 'number'),
  heating_system_for_renovation: createConditionalValidator('renovation_house_renewal_of_heating_system', 'string'),
  new_windows_number: createConditionalValidator('renovation_house_new_windows', 'number'),
  number_of_lift_and_slide_doors: createConditionalValidator('renovation_house_new_windows', 'number'),
  roof_renewal_type: createConditionalValidator('renovation_house_roof_renewal', 'string'),
  roof_renewal_area: createConditionalValidator('renovation_house_roof_renewal', 'number'),
  insulation_top_ceiling_area: createConditionalValidator('renovation_house_insulation_top_ceiling', 'number'),
  insulation_basement_ceiling_area: createConditionalValidator(
    'renovation_house_insulation_basement_ceiling',
    'number',
  ),
});

export default validationSchema;
