import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { COUNT_INVITATIONS_PER_PAGE } from 'constants/constants';
import { useParams } from 'react-router-dom';
import { GET_INVITATIONS_QUERY } from './useGetInvitationsByLoanConsultant';

const DELETE_USER_ACCOUNT = gql`
  mutation deleteUserAccount($userId: ID!) {
    deleteUserAccount(userId: $userId) {
      success
    }
  }
`;

const useDeleteUserAccount = () => {
  const [deleteUserAccountMutation, { loading }] = useMutation(DELETE_USER_ACCOUNT);
  const { formatMessage: t } = useIntl();
  const { page } = useParams();

  const deleteAccount = useCallback(
    async (userId) => {
      try {
        await deleteUserAccountMutation({
          variables: { userId },
          refetchQueries: [
            {
              query: GET_INVITATIONS_QUERY,
              variables: {
                limit: COUNT_INVITATIONS_PER_PAGE,
                offset: COUNT_INVITATIONS_PER_PAGE * (page - 1),
                filter: '',
              },
            },
          ],
          awaitRefetchQueries: true,
        });
        toast.success(t({ id: 'invitations.user_successfully_deleted' }));
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        toast.error(e.message);
      }
    },
    [deleteUserAccountMutation, page, t],
  );
  return [deleteAccount, loading];
};

export default useDeleteUserAccount;
