import React from 'react';
import { Field } from 'formik';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import ButtonSelect from 'components/inputs/ButtonSelect';
import Timeline from 'components/inputs/Timeline';
import { ReactComponent as SingleStandardHouse } from 'assets/icons/co2-calculator/kind-of-house/single.svg';
import { ReactComponent as SemiDetachedHouse } from 'assets/icons/co2-calculator/kind-of-house/semi-detached.svg';
import { ReactComponent as Bungalow } from 'assets/icons/co2-calculator/kind-of-house/bungalow.svg';
import { ReactComponent as TownHouse } from 'assets/icons/co2-calculator/kind-of-house/town-house.svg';
import { ReactComponent as OnePerson } from 'assets/icons/co2-calculator/peoples/one-person.svg';
import { ReactComponent as TwoPeople } from 'assets/icons/co2-calculator/peoples/two-people.svg';
import { ReactComponent as ThreePeople } from 'assets/icons/co2-calculator/peoples/three-people.svg';
import { ReactComponent as FourPeople } from 'assets/icons/co2-calculator/peoples/four-people.svg';
import { ReactComponent as FivePeople } from 'assets/icons/co2-calculator/peoples/five-people.svg';
import { ReactComponent as SixPeople } from 'assets/icons/co2-calculator/peoples/six-people.svg';
import { ReactComponent as NewWindows } from 'assets/icons/co2-calculator/renovations/new-windows.svg';
import { ReactComponent as InsulationTop } from 'assets/icons/co2-calculator/renovations/insulation-top-ceiling.svg';
import { ReactComponent as InsulationBasement } from 'assets/icons/co2-calculator/renovations/insulation-basement-ceiling.svg';
import { ReactComponent as InsulationFacade } from 'assets/icons/co2-calculator/renovations/insulation-facade.svg';
import { ReactComponent as Ventilation } from 'assets/icons/co2-calculator/renovations/ventilation.svg';
import s from './FirstSection.module.scss';

const FirstSection = ({ values, initialValues, t, isEditMode, goToThirdStep }) => (
  <>
    <Field
      name="heated_living_area"
      component={SliderWithTooltip}
      label={t({ id: 'renovation_house_wizard.heated_living_area' })}
      min={25}
      step={5}
      units="m²"
      max={initialValues?.house_area || 300}
    />
    {isEditMode ? (
      <div className={s.heatedLivingAreaAlert}>
        <small className="muted">
          {t({ id: 'renovation_house_wizard.heated_living_area_disclaimer' })}
          <button type="button" onClick={goToThirdStep} className="link">
            {t({ id: 'renovation_house_wizard.go_to_step_three_btn' })}
          </button>
        </small>
      </div>
    ) : null}
    <Field
      name="kind_of_house"
      component={ButtonSelect}
      inOneRow={false}
      label={t({ id: 'renovation_house_wizard.kind_of_house.name' })}
      options={[
        {
          topIcon: ({ altText }) => <SingleStandardHouse className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.kind_of_house.single_family_house' }),
          value: 'single_family_house',
        },
        {
          topIcon: ({ altText }) => <Bungalow className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.kind_of_house.bungalow_or_complex_floor_plan' }),
          value: 'bungalow_or_complex_floor_plan',
        },
        {
          topIcon: ({ altText }) => <TownHouse className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.kind_of_house.town_house' }),
          value: 'town_house',
        },
        {
          topIcon: ({ altText }) => <SemiDetachedHouse className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.kind_of_house.semi_detached_house' }),
          value: 'semi_detached_house',
        },
      ]}
    />
    <Field
      name="energy_standard"
      component={Timeline}
      description={t({ id: 'renovation_house_wizard.energy_standard.description' })}
      label={t({ id: 'renovation_house_wizard.energy_standard.name' })}
      options={[
        {
          label: t({ id: 'renovation_house_wizard.energy_standard.before_1960' }),
          value: 'before_1960',
        },
        {
          label: t({ id: 'renovation_house_wizard.energy_standard.between_1960_and_1975' }),
          value: 'between_1960_and_1975',
        },
        {
          label: t({ id: 'renovation_house_wizard.energy_standard.between_1976_and_1990' }),
          value: 'between_1976_and_1990',
        },
        {
          label: t({ id: 'renovation_house_wizard.energy_standard.after_1990' }),
          value: 'after_1990',
        },
        {
          label: t({ id: 'renovation_house_wizard.energy_standard.low_energy_house' }),
          value: 'low_energy_house',
        },
        {
          label: t({ id: 'renovation_house_wizard.energy_standard.passive_house' }),
          value: 'passive_house',
        },
      ]}
    />

    {!['low_energy_house', 'passive_house'].includes(values.energy_standard) ? (
      <Field
        label={t({ id: 'renovation_house_wizard.renovations.name' })}
        name="renovations"
        inOneRow={false}
        multi
        component={ButtonSelect}
        options={[
          {
            topIcon: ({ altText }) => <NewWindows className="fill" title={altText} />,
            label: t({ id: 'renovation_house_wizard.renovations.new_windows' }),
            value: 'new_windows',
          },
          {
            topIcon: ({ altText }) => <InsulationTop className="fill" title={altText} />,
            label: t({ id: 'renovation_house_wizard.renovations.insulation_top_ceiling' }),
            value: 'insulation_top_ceiling',
          },
          {
            topIcon: ({ altText }) => <InsulationBasement className="fill" title={altText} />,
            label: t({ id: 'renovation_house_wizard.renovations.insulation_basement_ceiling' }),
            value: 'insulation_basement_ceiling',
          },
          {
            topIcon: ({ altText }) => <InsulationFacade className="fill" title={altText} />,
            label: t({ id: 'renovation_house_wizard.renovations.insulation_facade' }),
            value: 'insulation_facade',
          },
          {
            topIcon: ({ altText }) => <Ventilation className="fill" title={altText} />,
            label: t({
              id: 'renovation_house_wizard.renovations.controlled_living_space_ventilation',
            }),
            value: 'controlled_living_space_ventilation',
          },
        ]}
      />
    ) : null}
    <Field
      name="number_of_people"
      component={ButtonSelect}
      inOneRow={false}
      options={[
        {
          topIcon: ({ altText }) => <OnePerson className="fill" title={altText} />,
          altText: t({ id: 'alt_text.renovation_house.number_of_people.one_person' }),
          value: 1,
        },
        {
          topIcon: ({ altText }) => <TwoPeople className="fill" title={altText} />,
          altText: t({ id: 'alt_text.renovation_house.number_of_people.two_people' }),
          value: 2,
        },
        {
          topIcon: ({ altText }) => <ThreePeople className="fill" title={altText} />,
          altText: t({ id: 'alt_text.renovation_house.number_of_people.three_people' }),
          value: 3,
        },
        {
          topIcon: ({ altText }) => <FourPeople className="fill" title={altText} />,
          altText: t({ id: 'alt_text.renovation_house.number_of_people.four_people' }),
          value: 4,
        },
        {
          topIcon: ({ altText }) => <FivePeople className="fill" title={altText} />,
          altText: t({ id: 'alt_text.renovation_house.number_of_people.five_people' }),
          value: 5,
        },
        {
          topIcon: ({ altText }) => <SixPeople className="fill" title={altText} />,
          altText: t({ id: 'alt_text.renovation_house.number_of_people.six_people' }),
          value: 6,
        },
      ]}
      label={t({ id: 'renovation_house_wizard.number_of_people' })}
    />
  </>
);

export default FirstSection;
