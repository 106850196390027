import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const useFilterValue = ({ changePage }) => {
  const [filter, setFilter] = useState('');
  const [debouncedFilterValue] = useDebounce(filter, 1000);
  const history = useHistory();

  const handleChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  useEffect(() => {
    if (filter && debouncedFilterValue === filter) changePage(1);
  }, [changePage, debouncedFilterValue, filter, history]);

  return { filter, handleChange, debouncedFilterValue };
};

export default useFilterValue;
