import LocationSearchInput from 'components/calculators/GoogleMap/LocationSearchInput';
import MapContainer from 'components/calculators/GoogleMap/MapContainer';
import ScrollToError from 'components/common/ScrollToError';
import ButtonSelect from 'components/inputs/ButtonSelect';
import Checkbox from 'components/inputs/Checkbox';
import NumberInput from 'components/inputs/NumberInput';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import TextInput from 'components/inputs/TextInput';
import { Field, Form } from 'formik';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { ReactComponent as NaturalGas } from 'assets/icons/co2-calculator/heating-system/natural-gas.svg';
import { ReactComponent as Electric } from 'assets/icons/co2-calculator/heating-system/electric.svg';
import { ReactComponent as DistrictHeatingStandard } from 'assets/icons/co2-calculator/heating-system/district-heating-standard.svg';
import { ReactComponent as Coal } from 'assets/icons/co2-calculator/heating-system/coal.svg';
import { Hidden, Visible } from 'components/common/Grid';
import SprengnetterEstimation from 'components/calculators/SprengnetterEstimationTab/SprengnetterEstimation';
import EstimationReminderModal from 'components/calculators/SprengnetterEstimationTab/EstimationReminderModal';
import { NEW_APARTMENT } from 'constants/constants';
import SwitchInput from 'components/inputs/Switch';

const ApartmentEstimationForm = ({
  values,
  calculations,
  requestsCount,
  loading,
  projectPrice,
  sideEffectOnSprengnetterRequest,
  hasAdditionalFeatures,
  isVisible,
  hideModal,
  isSubmitting,
  buttons,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Row between="lg" center="md">
      <Col md={12} lg={6}>
        <Form>
          <ScrollToError />
          <Field
            name="construction_year"
            component={NumberInput}
            label={t({ id: 'apartment_wizard.construction_year' })}
            thousandSeparator=""
            format="####"
          />
          <Field
            name="apartment_area"
            component={SliderWithTooltip}
            min={25}
            step={5}
            max={300}
            units="m²"
            label={t({ id: 'apartment_wizard.apartment_area' })}
          />
          <Field
            name="full_address"
            latLngName="coordinates"
            component={LocationSearchInput}
            label={t({ id: 'apartment_wizard.full_address' })}
            projectType="apartment"
          />
          {values.coordinates ? (
            <div className="my-4">
              <MapContainer position={values.coordinates} />
            </div>
          ) : null}
          <Field name="zip" component={TextInput} label={t({ id: 'project_wizard.zip_code' })} />

          <Field name="floor_number" component={NumberInput} label={t({ id: 'apartment_wizard.floor_number' })} />

          <Row>
            <Col md={6}>
              <Field name="terrace" label={t({ id: 'apartment_wizard.terrace' })} component={Checkbox} />
              {values?.terrace ? (
                <Field
                  name="terrace_area"
                  component={NumberInput}
                  max={10000}
                  label={t({ id: 'apartment_wizard.terrace_area' })}
                />
              ) : null}

              <Field name="garden" label={t({ id: 'apartment_wizard.garden' })} component={Checkbox} />
              {values?.garden ? (
                <Field
                  label={t({ id: 'apartment_wizard.garden_area' })}
                  name="garden_area"
                  component={NumberInput}
                  max={10000}
                />
              ) : null}

              <Field name="loggia" label={t({ id: 'apartment_wizard.loggia' })} component={Checkbox} />
              {values?.loggia ? (
                <Field
                  label={t({ id: 'apartment_wizard.loggia_area' })}
                  name="loggia_area"
                  component={NumberInput}
                  max={10000}
                />
              ) : null}
            </Col>
            <Col md={6}>
              <Field name="balcony" label={t({ id: 'apartment_wizard.balcony' })} component={Checkbox} />
              <Field name="elevator" label={t({ id: 'apartment_wizard.elevator' })} component={Checkbox} />
              <Field name="basement" label={t({ id: 'apartment_wizard.basement' })} component={Checkbox} />
              <Field name="is_rooftop" component={Checkbox} label={t({ id: 'apartment_wizard.is_rooftop' })} />
            </Col>
          </Row>

          <Field
            name="parking"
            component={ButtonSelect}
            inOneRow={false}
            options={[
              {
                label: t({ id: 'apartment_wizard.parking.none' }),
                value: 'none',
              },
              {
                label: t({ id: 'apartment_wizard.parking.outdoor_parking_space' }),
                value: 'outdoor_parking_space',
              },
              {
                label: t({ id: 'apartment_wizard.parking.garage' }),
                value: 'garage',
              },
              {
                label: t({ id: 'apartment_wizard.parking.underground_car_park' }),
                value: 'underground_car_park',
              },
            ]}
            label={t({ id: 'apartment_wizard.parking.name' })}
          />

          <Field
            name="condition_of_apartment"
            component={ButtonSelect}
            inOneRow={false}
            options={[
              {
                label: t({ id: 'apartment_wizard.not_renovated' }),
                value: 'not_renovated',
              },
              {
                label: t({ id: 'apartment_wizard.fully_renovated' }),
                value: 'fully_renovated',
              },
              {
                label: t({ id: 'apartment_wizard.partly_renovated' }),
                value: 'partly_renovated',
              },
              {
                label: t({ id: 'apartment_wizard.modernized' }),
                value: 'modernized',
              },
            ]}
            label={t({ id: 'apartment_wizard.condition_of_apartment' })}
          />

          <Field
            name="heating_system"
            component={ButtonSelect}
            inOneRow={false}
            options={[
              {
                topIcon: ({ altText }) => <DistrictHeatingStandard className="fill" title={altText} />,
                label: t({ id: 'apartment_wizard.heating_system.central_or_district' }),
                value: 'central_or_district',
              },
              {
                topIcon: ({ altText }) => <NaturalGas className="fill" title={altText} />,
                label: t({ id: 'apartment_wizard.heating_system.gas' }),
                value: 'gas',
              },
              {
                topIcon: ({ altText }) => <Electric className="fill" title={altText} />,
                label: t({ id: 'apartment_wizard.heating_system.electric' }),
                value: 'electric',
              },
              {
                topIcon: ({ altText }) => <Coal className="fill" title={altText} />,
                label: t({ id: 'apartment_wizard.heating_system.wood_coal' }),
                value: 'wood_coal',
              },
            ]}
            label={t({ id: 'apartment_wizard.heating_system.name' })}
          />

          <Visible sm xs md>
            <div className="my-4">
              <SprengnetterEstimation
                {...{ calculations, requestsCount, loading, projectPrice, values }}
                type={NEW_APARTMENT}
                sideEffectOnRequest={sideEffectOnSprengnetterRequest}
              />
            </div>
          </Visible>

          {!hasAdditionalFeatures ? (
            <>
              <h2 className="text-left">{t({ id: 'apartment_wizard.renovations_or_additional_investments' })}</h2>
              <Field
                name="additional_features"
                component={SwitchInput}
                label={t({ id: 'project_wizard.has_additional_features' })}
              />
            </>
          ) : null}

          <EstimationReminderModal {...{ isVisible, hideModal, isSubmitting, t }} />

          {buttons}
        </Form>
      </Col>
      <Col xs={12} md={12} lg={6}>
        <Hidden sm xs md>
          <div className="sticky-img-box">
            <SprengnetterEstimation
              {...{ calculations, requestsCount, loading, projectPrice, values }}
              type={NEW_APARTMENT}
              sideEffectOnRequest={sideEffectOnSprengnetterRequest}
            />
          </div>
        </Hidden>
      </Col>
    </Row>
  );
};

export default ApartmentEstimationForm;
