import RenovationHouseForm from 'components/calculators/RenovationHouseForm';
import React, { useCallback, useMemo } from 'react';
import { cloneDeep, omit, pick, uniq } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import useUpdateProjectRenovationHouseMutation from 'hooks/project-details/useUpdateProjectRenovationHouseMutation';
import useGetCO2Calculations from 'hooks/co2-calculator/useCO2Calculations';
import LoadingOverlay from 'components/common/LoadingOverlay';
import {
  initialValuesCO2Calculator,
  whitelistedProps,
  whitelistedPropsCO2Calculator,
} from './RenovationHouseCalculator.data';

const handleSpecificCategories = (_formData) => {
  const formData = cloneDeep(_formData);

  if (!formData.features.includes('other_investments')) {
    formData.other_investments_amount = null;
    formData.other_investments_comment = '';
  }

  if (formData.features.includes('renovation_house_new_windows')) {
    if (!Number.isFinite(formData.number_of_extra_large_windows)) {
      formData.number_of_extra_large_windows = 0;
    }
  }

  return formData;
};

const useSubmitRenovationHouseDetailsCalculator = ({ projectId }) => {
  const updateProjectRenovationHouseMutation = useUpdateProjectRenovationHouseMutation();

  const onSubmit = useCallback(
    async ({ values, formikActions, isEditMode, currentStep, nextStep, lastStep }) => {
      const { setSubmitting } = formikActions;
      const features = Object.entries(values.features).reduce((arr, [feature, isEnabled]) => {
        if (isEnabled) arr.push(feature);
        return arr;
      }, []);

      const formData = cloneDeep(omit(values, whitelistedPropsCO2Calculator));
      formData.features = uniq([...features]);

      const step = isEditMode ? null : currentStep + 1;
      formData.step = step;

      const _formData = handleSpecificCategories(formData);
      setSubmitting(true);
      try {
        await updateProjectRenovationHouseMutation(projectId, _formData);
        if (isEditMode && typeof lastStep === 'function') {
          lastStep();
        } else if (typeof nextStep === 'function') {
          nextStep();
        }
      } catch (err) {
        const error = err.raw?.networkError ? <FormattedMessage id="errors.no_server_response" /> : err.message;
        toast.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [projectId, updateProjectRenovationHouseMutation],
  );

  return onSubmit;
};

const getInitialValues = (project, co2Calculations) => {
  if (!project) return {};
  const { form_values, ...whitelisted } = pick(project, whitelistedProps);
  const co2EmissionsFormValues = pick(
    co2Calculations?.co2_emissions_before?.form_values,
    whitelistedPropsCO2Calculator,
  );

  const restFeatures = {};
  if (project.progress.step !== null) {
    restFeatures.reserves_for_unexpected_costs = true;
    restFeatures.renovation_house_energy_certificate = true;
  }
  restFeatures.other_investments = !!project.form_values?.other_investments_amount;

  const initialValues = {
    ...initialValuesCO2Calculator,
    ...whitelisted,
    ...form_values,
    ...(co2EmissionsFormValues ? { ...co2EmissionsFormValues } : {}),
    features: { ...project.features.reduce((acc, name) => ({ ...acc, [name]: true }), {}), ...restFeatures },
  };
  return initialValues;
};

const RenovationHouseCalculator = ({ project }) => {
  const onSubmit = useSubmitRenovationHouseDetailsCalculator({ projectId: project._id });
  const [co2Calculations, loading] = useGetCO2Calculations(project._id);
  const initialValues = useMemo(() => getInitialValues(project, co2Calculations), [co2Calculations, project]);

  if (loading) return <LoadingOverlay />;

  return (
    <RenovationHouseForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      isEditMode={project.progress.step === null}
      step={project.progress.step}
    />
  );
};

export default RenovationHouseCalculator;
