import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import Button from 'components/common/Button';
import { Link, useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import useForgotPassword from 'hooks/auth/useForgotPassword';
import TextInput from 'components/inputs/TextInput';
import { useIntl, FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ArrowForward, ArrowBack } from 'assets/icons';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { QuickCalculatorStep3 } from 'assets/icons/QuickCalculator';
import PageTitle from 'components/common/PageTitle';
import s from './ForgotPassword.module.scss';

const useForgotPasswordSubmitHandler = () => {
  const forgotPassword = useForgotPassword();
  const history = useHistory();

  const handleSubmit = React.useCallback(
    async ({ email }, { resetForm }) => {
      try {
        await forgotPassword({ email });
        history.goBack();
      } catch (error) {
        toast.error(error.message);
      } finally {
        resetForm();
      }
    },
    [forgotPassword, history],
  );

  return handleSubmit;
};

const forgotPasswordShape = {
  email: yup
    .string()
    .required(<FormattedMessage id="errors.email_required" />)
    .email(<FormattedMessage id="errors.email_invalid" />),
};

const forgotPassParamsSchema = yup.object().shape(forgotPasswordShape);

const initialValue = { email: '' };

const ForgotPassword = () => {
  const { formatMessage: t } = useIntl();
  const onSubmit = useForgotPasswordSubmitHandler();

  return (
    <Grid className={s.container}>
      <Row between="lg" center="md">
        <Col md={8} lg={6}>
          <Formik initialValues={initialValue} validationSchema={forgotPassParamsSchema} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <>
                <PageTitle auth>{t({ id: 'page_titles.forgot_password' })}</PageTitle>
                <Form>
                  <Field type="email" name="email" lowerCase component={TextInput} label={t({ id: 'auth.email' })} />
                  <div className={s.buttonsWrapper}>
                    <Link to={routePaths.login} className="link">
                      <Button startIcon={<ArrowBack />}>{t({ id: 'app.back' })}</Button>
                    </Link>
                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                      {t({ id: 'auth.forgot_password.reset_password' })}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Col>
        <Col xs={0} sm={0} md={0} lg={6} xl={5}>
          <QuickCalculatorStep3 width="100%" height="100%" title={t({ id: 'alt_text.auth_pages.forgot_password' })} />
        </Col>
      </Row>
    </Grid>
  );
};

export default ForgotPassword;
