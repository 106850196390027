import { useParams } from 'react-router-dom';
import React from 'react';
import Loading from 'components/common/Loading';
import CO2CalculatorResult from 'components/calculators/CO2CalculatorResult';
import useGetCO2Calculations from 'hooks/co2-calculator/useCO2Calculations';

const CO2StatusSecondStep = ({ nextStep, previousStep, currentStep }) => {
  const { id: projectId } = useParams();
  const [co2Calculations, loading] = useGetCO2Calculations(projectId);

  if (loading) {
    return <Loading size={50} />;
  }

  return <CO2CalculatorResult {...{ previousStep, nextStep, co2Calculations, currentStep }} />;
};

export default CO2StatusSecondStep;
