/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { backendErrors as enBackendErrors } from 'i18n/en';
import { backendErrors as deBackendErrors } from 'i18n/de';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { get } from 'lodash';
import { print } from 'graphql';
import { formatQueryByErrorPositions } from 'utils/helpers';
import { LOGOUT_MUTATION, afterLogout } from 'hooks/auth/useLogout';
import RefreshTokenService from 'services/RefreshTokenService';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import settings from '../config/settings';
import introspectionQueryResultData from './fragment-types.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const link = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      return RefreshTokenService.isTokenValidOrUndefined();
    },
    fetchAccessToken: () => {
      return RefreshTokenService.fetchNewAccessToken();
    },
    handleFetch: () => console.log('refreshed a token with apollo TokenRefreshLink'),
    handleResponse: () => (response) => {
      return response;
    },
    handleError: () => {
      window.setTimeout(() => {
        window.setTimeout(async () => {
          try {
            await client.mutate({
              mutation: LOGOUT_MUTATION,
            });
          } catch (e) {
            console.log('Error while logout', e);
          }
          afterLogout(client);
        });
      });
    },
  }),
  setContext((_, { headers }) => {
    return { headers };
  }),
  onError(({ graphQLErrors, networkError, response, operation }) => {
    const code = response?.errors[0]?.extensions?.code;
    if (code === 'FORBIDDEN') {
      window.location.replace(`${window.location.origin}/app/secure/logout`);
    }
    const logError = (error, { errorType }) => {
      console.log(error, errorType);
      const { message, locations, path, extensions, stack } = error;
      if (extensions?.exception) extensions.exception.logged = true;
      const lExtensions = JSON.stringify({
        ...extensions,
        ...(extensions?.exception && {
          exception: { ...extensions.exception, ...(extensions.exception?.stacktrace && { stacktrace: '(below)' }) },
        }),
      });
      const lStacktrace =
        get(extensions, 'exception.stacktrace', []).reduce(
          (p, c, index) => (p || '').concat(index !== 0 ? '\n' : '', c),
          '',
        ) ||
        stack ||
        ''; // array to string
      const lVariables = JSON.stringify(operation.variables);
      const lQuery = formatQueryByErrorPositions({ queryString: print(operation.query), errorPositions: locations });
      const lPath = JSON.stringify(path);
      const lLocations = JSON.stringify(locations);
      const lMessage = JSON.stringify(message);
      console.error(
        [
          `[${errorType}]:`,
          lMessage && `Message: ${lMessage}`,
          lLocations && `Location: ${lLocations}`,
          lPath && `Path: ${lPath}`,
          lQuery && `Query:\n${lQuery}`,
          lVariables && `Variables: ${lVariables}`,
          lExtensions && `Extensions: ${lExtensions}`,
          lStacktrace && `Stacktrace: ${lStacktrace}`,
        ]
          .filter(Boolean)
          .join('\n'),
        extensions,
      );
    };
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const doNotLogOnClient = get(error, 'extensions.exception.doNotLogOnClient');
        if (!doNotLogOnClient) logError(error, { errorType: 'GraphQL error' });
      });
    }

    if (!graphQLErrors && networkError) logError(networkError, { errorType: 'Network error' });
  }),
  onError(({ graphQLErrors }) => {
    const locale = window?.localStorage?.getItem('language') || settings.defaultLocale;
    const backendErrorsTranslations = { de: deBackendErrors, en: enBackendErrors };
    if (graphQLErrors) {
      const translateMessage = (error) => {
        const messageKey = `${locale}.${error.message}`;
        const translated = get(backendErrorsTranslations, messageKey, error.message);
        if (translated !== messageKey) {
          error.originalMessage = error.message;
          error.message = translated;
        }
      };
      graphQLErrors.forEach(translateMessage);
    }
  }),
  new HttpLink({
    uri: settings.graphqlServerUrl,
    credentials: 'include',
  }),
]);

const cacheIndexBlacklist = [];

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    fragmentMatcher,
    freezeResults: true,
    dataIdFromObject: ({ _id, __typename }) => {
      if (cacheIndexBlacklist.includes(__typename)) return null;
      return _id ? `${__typename}___${_id}` : null;
    },
  }),
  assumeImmutableResults: true,
});

export default client;
