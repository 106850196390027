import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import { Close } from 'assets/icons';
import { useIntl } from 'react-intl';
import modalStyles from './modalStyles';
import styles from './Modal.module.scss';
import PageTitle from './PageTitle';

ReactModal.setAppElement(document.getElementById('root'));

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const showModal = useCallback((data = {}) => {
    setModalProps(data);
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
    setModalProps({});
  }, []);

  return { showModal, hideModal, modalProps, isVisible };
};

const Modal = ({ children, isVisible, close, headerText, size = 'lg', modalStyle = {} }) => {
  const { formatMessage: t } = useIntl();
  return (
    <ReactModal
      isOpen={isVisible}
      onRequestClose={close}
      style={{ ...modalStyles[size], ...modalStyle }}
      bodyOpenClassName={styles.bodyOpen}
      className={{
        base: styles.modal,
        afterOpen: styles.modalAfterOpen,
        beforeClose: styles.modalBeforeClose,
      }}
      overlayClassName={{
        base: styles.overlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
      closeTimeoutMS={300}
    >
      <div className={styles.modalHeader}>
        <PageTitle>{headerText}</PageTitle>
        <button type="button" className={styles.modalCloseButton} onClick={close}>
          <Close title={t({ id: 'alt_text.icons.close' })} />
        </button>
      </div>
      <div className={styles.modalBody}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
