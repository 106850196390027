/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import Tooltip from 'components/common/Tooltip';
import { ArrowForward, Trash, Download } from 'assets/icons';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import IconButton from 'components/common/IconButton';
import { LOAN_CONSULTANT } from 'constants/constants';
import Button from 'components/common/Button';
import cn from 'classnames';
import { routePatterns } from 'router/route-paths';
import s from './ProjectList.module.scss';

const ProjectCard = ({
  _id,
  name,
  price,
  type,
  pdf,
  isLastProject,
  isActive,
  removeProject,
  onAdjustProject,
  onTransferClick,
  onCardClick,
}) => {
  const { formatMessage: t } = useIntl();
  const isLoanConsultant = useHasRequiredRole(LOAN_CONSULTANT);

  return (
    <Col md={6} lg={5} xl={4}>
      <div className={cn(s.projectCard, isActive ? s.activeCard : '')} onClick={(e) => onCardClick(e, _id)}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 className={s.projectName}>{name}</h3>
          <Tooltip overlay={<p>{t({ id: 'my_projects.tooltip' })}</p>} hide={!isLastProject}>
            <span>
              <IconButton
                className={isLastProject ? s.disabledBtn : {}}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isLastProject) removeProject(_id);
                }}
              >
                <Trash title={t({ id: 'alt_text.my_projects.delete_project' })} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        <div className={s.projectInfo}>
          <Row className={s.row}>
            <Col xs={12}>
              <span>{t({ id: `project_types.${type}` })}</span>
            </Col>
          </Row>
          <Row className={s.row}>
            <Col xs={5} md={5}>
              <span className={s.item}>
                <FormattedMessage id="my_projects.price" />:
              </span>
            </Col>
            <Col xs={7} md={7} style={{ textAlign: 'right' }}>
              <span className={s.currency}>{formatCurrency(price)}</span>
            </Col>
          </Row>
        </div>
        <hr className={s.divider} />
        <div className={s.buttonsWrapper}>
          <Link
            to={routePatterns.projectDetailsCalculator.stringify({ id: _id })}
            onClick={(e) => {
              e.stopPropagation();
              onAdjustProject(_id);
            }}
          >
            <Button color="secondary" endIcon={<ArrowForward />}>
              {t({ id: 'my_projects.adjust' })}
            </Button>
          </Link>
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            <Button color="secondary" endIcon={<Download />}>
              {t({ id: 'my_projects.pdf_download' })}
            </Button>
          </a>

          {isLoanConsultant ? (
            <Button
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                onTransferClick(_id, name);
              }}
              endIcon={<ExportIcon />}
            >
              {t({ id: 'my_projects.export' })}
            </Button>
          ) : null}
        </div>
      </div>
    </Col>
  );
};

export default ProjectCard;
