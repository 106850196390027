/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useCallback } from 'react';
import { localizeWizard } from 'utils/helpers';
import { connect, Field } from 'formik';
import { get, isNil } from 'lodash';
import { useIntl } from 'react-intl';
import MorphField from 'components/inputs';
import Checkbox from 'components/inputs/Checkbox';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import { BUYING_HOUSE, NEW_APARTMENT, NEW_BUILDING, RENOVATION_HOUSE } from 'constants/constants';
import apartmentFeaturesSchema from 'constants/wizards/apartment-features-schema';
import buyingHouseFeaturesSchema from 'constants/wizards/buying-house-features-schema';
import buildHouseFeaturesSchema from 'constants/wizards/build-house-features-schema';
import renovationHouseFeaturesSchema from 'constants/wizards/renovation-house-features';
import Collapsible from 'react-collapsible';

const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);

export const projectTypeToSchemaMap = {
  [NEW_APARTMENT]: apartmentFeaturesSchema,
  [NEW_BUILDING]: buildHouseFeaturesSchema,
  [BUYING_HOUSE]: buyingHouseFeaturesSchema,
  [RENOVATION_HOUSE]: renovationHouseFeaturesSchema,
};

function getDefaultValue(field = {}, values) {
  let defaultValue = !isNil(field.defaultValue) ? field.defaultValue : null;
  if (isNil(defaultValue) && field.default_value_formula) {
    defaultValue = eval(field.default_value_formula);
  }
  return defaultValue;
}

const FeatureFormFields = ({ isOpen, feature, t, values }) => (
  <Collapsible open={isOpen} transitionTime={800}>
    {feature.form.map((field) => {
      const defaultValue = getDefaultValue(field, values);
      const { units, ...localizedProps } = field;
      return (
        <MorphField
          key={field.name}
          {...localizeWizard(localizedProps, {
            formatter: (id) => {
              if (!id) return id;
              const localizedLabel = t({ id, defaultMessage: ' ' });
              if (!containsHTML(localizedLabel)) return localizedLabel;
              return <FormattedHTMLMessage id={id} defaultMessage=" " />;
            },
          })}
          {...(units ? { units } : {})}
          defaultValue={defaultValue}
        />
      );
    })}
  </Collapsible>
);

export default connect(({ icon, color, group, projectType, formik: { values } }) => {
  const schema = useMemo(() => projectTypeToSchemaMap[projectType] || [], [projectType]);
  const { formatMessage: t } = useIntl();

  return (
    <>
      {schema
        .filter(({ group: _group }) => _group === group)
        .map((feature) => {
          const { name, label, description } = feature;
          const [isOpen, setIsOpen] = useState(get(values, `features.${name}`));
          const handleToggle = useCallback((checked) => setIsOpen(checked), []);

          return (
            <React.Fragment key={name}>
              <Field
                name={`features.${name}`}
                component={Checkbox}
                label={t({ id: label })}
                icon={icon}
                color={color}
                description={description ? t({ id: description, defaultMessage: '  ' }) : undefined}
                sideEffectOnChange={handleToggle}
                skipScrollingOnChange
              />
              <FeatureFormFields {...{ isOpen, feature, t, values }} />
            </React.Fragment>
          );
        })}
    </>
  );
});
