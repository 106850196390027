import React from 'react';
import styles from './Heading.module.css';

const Heading = ({ label, description }) => (
  <div className={styles.heading}>
    <p>{label}</p>
    {description ? <p className={styles.description}>{description}</p> : null}
  </div>
);

export default Heading;
