import React, { useMemo } from 'react';
import { BudgetStep2 } from 'assets/images/lifestyle-calculator';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import { Field, Form, Formik } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import * as yup from 'yup';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import { defaults } from 'lodash';
import s from './LifestyleCalculatorForm.module.scss';

const validationSchema = yup.object().shape({
  monthly_net_salary: yup.string().required(__('errors.fill_field')),
  monthly_net_salary_partner: yup.mixed().when('alone_or_partner', {
    is: 'partner',
    then: yup.string().required(__('errors.fill_field')),
  }),
  other_revenues: yup.string().required(__('errors.fill_field')),
});

const LifestyleCalculatorSecondStep = ({
  initialValues,
  calculatorCompleted,
  previousStep,
  formValuesRef,
  onSubmit,
  onSubmitStep,
  nextStep,
  onBack,
  currentStep,
  myBudget,
  clickableTabStep,
}) => {
  const { formatMessage: t } = useIntl();
  const isEditMode = useMemo(() => calculatorCompleted || myBudget?.step >= clickableTabStep, [
    myBudget,
    clickableTabStep,
    calculatorCompleted,
  ]);

  return (
    <Row between="xl" center="md">
      <Col md={10} lg={8} xl={6}>
        <PageTitle>{t({ id: 'budget_calculator.second_step_title' })}</PageTitle>

        <Formik
          initialValues={defaults(formValuesRef.current, initialValues)}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (isEditMode) {
              await onSubmit(values, actions, { currentStep });
            } else {
              await onSubmitStep(values, actions);
            }
            nextStep();
          }}
        >
          {({ isSubmitting, values, errors, isValid }) => (
            <Form>
              <ScrollToError />
              <Field
                name="monthly_net_salary"
                label={t({ id: 'budget_calculator.second_step.monthly_net_salary' })}
                component={SliderWithTooltip}
                largeLabel
                description={t({ id: 'budget_calculator.second_step.monthly_net_salary_description' })}
                min={400}
                max={10000}
                defaultValue={2500}
                step={100}
                units="€"
              />

              {values.alone_or_partner === 'partner' ? (
                <Field
                  name="monthly_net_salary_partner"
                  label={t({ id: 'budget_calculator.second_step.monthly_net_salary_partner' })}
                  component={SliderWithTooltip}
                  largeLabel
                  description={t({ id: 'budget_calculator.second_step.monthly_net_salary_description' })}
                  min={400}
                  max={10000}
                  defaultValue={2500}
                  step={100}
                  units="€"
                />
              ) : null}

              <Field
                name="other_revenues"
                label={t({ id: 'budget_calculator.second_step.other_regular_revenues' })}
                component={SliderWithTooltip}
                description={t({ id: 'budget_calculator.second_step.other_regular_revenues_description' })}
                min={0}
                max={10000}
                defaultValue={0}
                step={100}
                units="€"
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                <Button
                  disabled={isSubmitting || !isValid}
                  onClick={() => onBack(values, previousStep)}
                  startIcon={<ArrowBack />}
                  color="outline"
                >
                  {t({ id: 'app.back' })}
                </Button>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                  color="secondary"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={10} lg={8} xl={6} first="xs" last="xl">
        <div className="sticky-img-box">
          <BudgetStep2 title={t({ id: 'alt_text.budget_calculator.second_step' })} width="100%" height="100%" />
        </div>
      </Col>
    </Row>
  );
};

export default LifestyleCalculatorSecondStep;
