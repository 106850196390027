import React, { useState, useCallback, createContext, useContext } from 'react';
import settings from '../config/settings';

const useSidebarState = () => {
  const [sidebarOpen, setSidebar] = useState(useSidebarState.defaults.sidebarOpen);
  const toggleSidebar = useCallback((v) => setSidebar(typeof v === 'boolean' ? v : (s) => !s), []);
  return { sidebarOpen, toggleSidebar };
};
useSidebarState.defaults = {
  sidebarOpen: false,
  toggleSidebar: () => {},
};

const themes = ['primary', 'green'];

const useTheme = () => {
  const [theme, setTheme] = useState(useTheme.defaults.theme);
  const changeTheme = useCallback((selectedTheme) => {
    if (themes.includes(selectedTheme)) setTheme(selectedTheme);
  }, []);
  return { theme, changeTheme };
};
useTheme.defaults = {
  theme: 'primary',
  changeTheme: () => {},
};

export const AppContext = createContext({
  locale: settings.defaultLocale,
  ...useSidebarState.defaults,
  ...useTheme.defaults,
});

export const AppContextProvider = ({ children, ...value }) => {
  const sidebarState = useSidebarState();
  const themeManagement = useTheme();
  return (
    <AppContext.Provider value={{ ...value, ...sidebarState, ...themeManagement }}>{children}</AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export const useAppContext = () => useContext(AppContext);
