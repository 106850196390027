import PageTitle from 'components/common/PageTitle';
import React, { useMemo } from 'react';
import { Formik, Field, Form } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import Button from 'components/common/Button';
import TextInput from 'components/inputs/TextInput';
import Select from 'components/inputs/Select';
import useGetLoanConsultants from 'hooks/loan-consultant/useGetLoanConsultants';
import useInviteUser from 'hooks/loan-consultant/useInviteUser';
import { ArrowForward } from 'assets/icons';
import { QuickCalculatorStep4 } from 'assets/icons/QuickCalculator';
import TextArea from 'components/inputs/TextArea';
import s from './InviteUser.module.scss';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(<FormattedMessage id="errors.fill_field" />),
  firstName: yup.string().required(<FormattedMessage id="errors.fill_field" />),
  lastName: yup.string().required(<FormattedMessage id="errors.fill_field" />),
  loan_consultant_id: yup
    .string()
    .nullable()
    .required(<FormattedMessage id="errors.choose_one" />),
});

const InviteUser = () => {
  const { formatMessage: t } = useIntl();
  const onSubmit = useInviteUser();
  const [loanConsultants] = useGetLoanConsultants();

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    loan_consultant_id: '',
    message: '',
  };

  const memoLoanConsultants = useMemo(
    () =>
      loanConsultants.map(({ _id, emails, profile: { firstName, lastName } }) => ({
        label: `${firstName} ${lastName} - ${emails[0].address}`,
        value: _id,
      })),
    [loanConsultants],
  );

  return (
    <Grid>
      <Row center="md" between="lg">
        <Col md={9} lg={12}>
          <PageTitle>{t({ id: 'page_titles.invite_user' })}</PageTitle>
        </Col>
      </Row>
      <Row center="md" between="lg">
        <Col md={9} lg={6}>
          <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <>
                <Form>
                  <Field
                    type="email"
                    name="email"
                    lowerCase
                    component={TextInput}
                    required
                    label={t({ id: 'auth.email' })}
                  />
                  <Field type="text" name="firstName" component={TextInput} label={t({ id: 'user.first_name' })} />
                  <Field type="text" name="lastName" component={TextInput} label={t({ id: 'user.last_name' })} />
                  <Field
                    name="loan_consultant_id"
                    component={Select}
                    required
                    label={t({ id: `invite_user.loan_consultant` })}
                    options={memoLoanConsultants}
                  />
                  <Field name="message" component={TextArea} label={t({ id: 'invite_user.message' })} minRows={7} />

                  <div className={s.btnWrapper}>
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      color="primary"
                      type="submit"
                      endIcon={<ArrowForward />}
                    >
                      {t({ id: 'invite_user.add_user_btn' })}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Col>
        <Col md={0} lg={6} xl={6} first="xs" last="md">
          <QuickCalculatorStep4 title={t({ id: 'alt_text.invite_user_page_img' })} />
        </Col>
      </Row>
    </Grid>
  );
};

export default InviteUser;
