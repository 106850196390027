import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { GET_PROJECTS_QUERY } from 'hooks/project/useProjects';
import { LAST_PROJECT_ID_QUERY } from 'hooks/project/useLastProjectId';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

export const TRANSFER_PROJECT_MUTATION = gql`
  mutation transferProject($projectId: ID!, $transferWithBudget: Boolean!, $input: ProfileInput!) {
    transferProject(projectId: $projectId, transferWithBudget: $transferWithBudget, input: $input) {
      success
    }
  }
`;

const useTransferProject = () => {
  const [transferProjectMutation] = useMutation(TRANSFER_PROJECT_MUTATION);
  const client = useApolloClient();
  const { formatMessage: t } = useIntl();

  const handleSubmit = useCallback(
    async (variables) => {
      try {
        await transferProjectMutation({
          variables,
          refetchQueries: [{ query: LAST_PROJECT_ID_QUERY }],
          awaitRefetchQueries: true,
        });
        const { projects } = client.readQuery({ query: GET_PROJECTS_QUERY });
        const filteredProjects = projects.filter(({ _id }) => _id !== variables.projectId);

        client.writeQuery({
          query: GET_PROJECTS_QUERY,
          data: { projects: filteredProjects },
        });
        toast.success(t({ id: 'my_projects.successfully_transferred' }));
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        throw e;
      }
    },
    [client, t, transferProjectMutation],
  );
  return handleSubmit;
};

export default useTransferProject;
