/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { changeLanguage } from 'utils/helpers';
import SidebarAwareContainer from './SidebarAwareContainer';
import styles from './Footer.module.scss';

const AppFooter = () => {
  return (
    <footer onClick={changeLanguage} className={styles.footer}>
      <div className="container-fluid">
        <SidebarAwareContainer>
          Copyright © {new Date().getFullYear()} HYPO NOE Landesbank für Niederösterreich und Wien AG
        </SidebarAwareContainer>
      </div>
    </footer>
  );
};

export default AppFooter;
