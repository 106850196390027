import PageTitle from 'components/common/PageTitle';
import NumberInput from 'components/inputs/NumberInput';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import cn from 'classnames';
import get from 'lodash/get';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, Plus } from 'assets/icons';
import { ReactComponent as DoneAll } from 'assets/icons/done-all.svg';
import { ReactComponent as Img } from 'assets/images/lifestyle-calculator/budget-step-7.svg';
import { useParams, useHistory } from 'react-router-dom';
import routePaths, { routePatterns } from 'router/route-paths';
import Alert from 'components/common/Alert';
import Modal, { useModal } from 'components/common/Modal';
import PersonalFinancingRequest from 'components/loan/PersonalFinancingRequest';
import { ReactComponent as HandShakeIcon } from 'assets/icons/handshake.svg';
import s from './LifestyleCalculatorFinalStep.module.scss';
import { MonthlyRateMessage } from '../BudgetSummary';

const fields = [
  { key: 'broker_commission', interest: 0.036 },
  { key: 'property_transfer_tax', interest: 0.035 },
  { key: 'land_register_fee', interest: 0.011 },
  { key: 'notary_fee', interest: 0.01 },
];

function getInitialValues(myBudget) {
  const { formData = {}, calculation = {} } = myBudget;
  const loanAmount = get(calculation, 'amount_of_loan.calculated_value', 0);
  return fields.reduce((acc, { key, interest }) => {
    const value = Number.isFinite(formData[key]) ? formData[key] : Math.round(loanAmount * interest);
    return { ...acc, [key]: value };
  }, {});
}

const PossibleProjectCosts = ({
  t,
  myBudget,
  onSubmit,
  initialValues: _initialValues,
  onBack,
  previousStep,
  calculatorCompleted,
  currentStep,
}) => {
  const { id: projectId } = useParams();
  const { showModal, hideModal, isVisible } = useModal();
  const history = useHistory();
  const { calculation, formData, loan_request } = myBudget;

  const initialValues = useMemo(() => ({ ..._initialValues, ...getInitialValues(myBudget) }), [
    myBudget,
    _initialValues,
  ]);

  const ownFundsPercentage = useMemo(
    () => Math.round((formData.own_funds * 100) / calculation.amount_of_loan.calculated_value),
    [calculation.amount_of_loan.calculated_value, formData.own_funds],
  );

  const computePossibleProjectCosts = useCallback(
    (values) => {
      const fundsAndBudget = get(calculation, 'funds_and_budget.calculated_value', 0);
      const { property_transfer_tax, land_register_fee, notary_fee, broker_commission } = values;
      let additionalCosts = land_register_fee + notary_fee;

      if (formData.project_type === 'buying') {
        additionalCosts += property_transfer_tax;
        if (formData.buying_with_agent) additionalCosts += broker_commission;
      }

      return formatCurrency(fundsAndBudget - additionalCosts);
    },
    [calculation, formData.buying_with_agent, formData.project_type],
  );

  const onSubmitFinalStep = useCallback(
    async (values, formikActions, callbackfn = () => {}) => {
      await onSubmit(values, formikActions, {
        currentStep: null,
        cacheStep: calculatorCompleted || projectId ? null : currentStep,
      });
      callbackfn();
    },
    [onSubmit, calculatorCompleted, projectId, currentStep],
  );

  return (
    <Formik {...{ initialValues }} onSubmit={onSubmitFinalStep}>
      {({ values, ...formik }) => (
        <Form>
          <PageTitle>{t({ id: 'budget_calculator.final_step_title' })}</PageTitle>

          {!projectId ? (
            <div className={s.backButtonWrapper}>
              <Button
                disabled={formik.isSubmitting}
                onClick={() => onBack(values, previousStep)}
                startIcon={<ArrowBack />}
                color="secondary"
              >
                {t({ id: 'app.back' })}
              </Button>
            </div>
          ) : null}

          {ownFundsPercentage < 20 ? (
            <Alert color="warning">
              {t({ id: 'budget_calculator.final_step.message_insufficient_own_funds' }, { ownFundsPercentage })}
            </Alert>
          ) : null}

          <table className={cn('table last-col-text-right', s.budgetAndFunds)}>
            <tbody>
              <tr>
                <td>{t({ id: 'budget_calculator.final_step.affordable_loan' })}</td>
                <td>{formatCurrency(calculation.amount_of_loan.calculated_value)}</td>
              </tr>
              <tr>
                <td>{t({ id: 'budget_calculator.final_step.own_funds' }, { ownFundsPercentage })}</td>
                <td>{formatCurrency(formData.own_funds)}</td>
              </tr>
              <tr>
                <td>{t({ id: 'budget_calculator.final_step.available_budget' })}</td>
                <td>{formatCurrency(calculation.funds_and_budget.calculated_value)}</td>
              </tr>
            </tbody>
          </table>

          <div className={s.fieldsWrapper}>
            <p className={s.additionalCostsLabel}>{t({ id: 'budget_calculator.final_step.additional_costs' })}</p>
            <div className={s.group}>
              {formData.project_type === 'buying' ? (
                <div className="d-flex-center-between">
                  <p className={s.label}>{t({ id: 'budget_calculator.final_step.property_transfer_tax' })}</p>
                  <p className={s.value}>{formatCurrency(values.property_transfer_tax)}</p>
                </div>
              ) : null}
              <div className="d-flex-center-between">
                <p className={s.label}>{t({ id: 'budget_calculator.final_step.land_register_fee' })}</p>
                <p className={s.value}>{formatCurrency(values.land_register_fee)}</p>
              </div>
            </div>
            {formData.project_type === 'buying' && formData.buying_with_agent ? (
              <Field
                name="broker_commission"
                type="number"
                component={NumberInput}
                label={t({ id: 'budget_calculator.final_step.broker_commission' })}
                textAlign="right"
                suffix=" €"
                validate={(value) => (!Number.isFinite(value) ? t({ id: 'errors.fill_field' }) : '')}
              />
            ) : null}
            <Field
              name="notary_fee"
              type="number"
              component={NumberInput}
              label={t({ id: 'budget_calculator.final_step.notary_fee' })}
              textAlign="right"
              suffix=" €"
              validate={(value) => (!Number.isFinite(value) ? t({ id: 'errors.fill_field' }) : '')}
            />
            <div className={s.possibleProjectCosts}>
              <p>{t({ id: 'budget_calculator.final_step.possible_project_costs' })}</p>
              <b>{computePossibleProjectCosts(values)}</b>
            </div>
          </div>

          <div className={s.buttonsWrapper}>
            {!projectId ? (
              <>
                <Button
                  disabled={formik.isSubmitting || !formik.isValid}
                  color="secondary"
                  endIcon={<Plus />}
                  onClick={async () => {
                    const callbackfn = () => history.push(routePaths.newProject);
                    await onSubmitFinalStep(values, formik, callbackfn);
                  }}
                >
                  {t({ id: 'budget_calculator.final_step.calculate_project_costs' })}
                </Button>
                <Button
                  color={!loan_request ? 'secondary' : 'success'}
                  endIcon={!loan_request ? <ArrowForward /> : <DoneAll />}
                  disabled={formik.isSubmitting || !formik.isValid}
                  type="submit"
                  onClick={async () => {
                    if (!loan_request) await onSubmitFinalStep(values, formik, showModal);
                    else showModal();
                  }}
                >
                  {t({ id: 'budget_calculator.final_step.request_loan_consulting' })}
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => onBack(values, previousStep)}
                  startIcon={<ArrowBack />}
                >
                  {t({ id: 'app.back' })}
                </Button>
                <Button
                  disabled={formik.isSubmitting || !formik.isValid}
                  loading={formik.isSubmitting}
                  color="secondary"
                  type="submit"
                  endIcon={<ArrowForward />}
                  onClick={async () => {
                    const callbackfn = () => {
                      history.push(routePatterns.loan.stringify({ id: projectId }));
                    };
                    await onSubmitFinalStep(values, formik, callbackfn);
                  }}
                >
                  {t({ id: 'budget.btn_to_financing' })}
                </Button>
              </>
            )}

            <Modal
              isVisible={isVisible}
              close={hideModal}
              headerText={t({ id: 'loan.personal_financing_request.title' })}
            >
              {!loan_request ? (
                <PersonalFinancingRequest buttonLabelId="budget_calculator.final_step.request_loan_consulting" />
              ) : (
                <div className="text-center">
                  <h1>{t({ id: 'budget_calculator.final_step.gratitude_title' })}</h1>
                  <h2>{t({ id: 'budget_calculator.final_step.gratitude_subtitle' })}</h2>
                  <div className={s.iconWrapper}>
                    <HandShakeIcon />
                  </div>
                  <Button onClick={hideModal} startIcon={<ArrowBack />}>
                    {t({ id: 'app.back' })}
                  </Button>
                </div>
              )}
            </Modal>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const LifestyleCalculatorFinalStep = ({ myBudget, goToStep, ...rest }) => {
  const { formatMessage: t } = useIntl();
  return (
    <Row between="xl" center="md">
      <Col md={10} lg={8} xl={6}>
        {myBudget.calculation.monthly_rate_loan.calculated_value > 0 ? (
          <PossibleProjectCosts {...{ myBudget, t, goToStep, ...rest }} />
        ) : (
          <>
            <MonthlyRateMessage {...{ myBudget }} />
            <Button onClick={() => goToStep(4)} startIcon={<ArrowBack />}>
              {t({ id: 'budget_calculator.final_step.back_to_lifestyle_step' })}
            </Button>
          </>
        )}
      </Col>
      <Col md={10} lg={8} xl={6} first="xs" last="xl">
        <div className="sticky-img-box">
          <Img title={t({ id: 'alt_text.budget_calculator.fourth_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default LifestyleCalculatorFinalStep;
