import React, { useCallback, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useAppContext } from 'contexts/AppContext';
import { scrollToNextMatchingSibling } from 'utils/form';
import s from './Timeline.module.scss';

const themeToClassNameMap = {
  primary: s.primaryTimeline,
  green: s.greenTimeline,
};

const Timeline = ({
  field,
  form: { setFieldValue, errors, touched },
  options = [],
  description,
  label,
  skipScrollingToField,
}) => {
  const { value, name } = field;
  const { theme } = useAppContext();
  const fieldWrapperRef = useRef();

  const handleChange = useCallback(
    (v) => {
      setFieldValue(name, v);
      scrollToNextMatchingSibling(fieldWrapperRef);
    },
    [name, setFieldValue],
  );

  const fieldWrapperClassnames = useMemo(() => {
    let classes = '';
    if (!skipScrollingToField) classes += 'scroll-to-field';
    return cn(s.fieldWrapper, themeToClassNameMap[theme], classes);
  }, [skipScrollingToField, theme]);

  return (
    <div className={fieldWrapperClassnames} ref={fieldWrapperRef}>
      <div className={s.timeline}>
        <div className={s.labels}>
          {options.map(({ label: optionLabel, value: v }, i) => {
            const coefficient = 85 / options.length;
            const left = `${(i + 1) * coefficient}%`;
            const marginLeft = `-${250 / options.length}px`;
            return (
              <button
                id={optionLabel}
                className={cn(s.optionLabel, value === v ? s.activeOptionLabel : '')}
                style={{ left, marginLeft }}
                type="button"
                key={v}
                onClick={() => handleChange(v)}
              >
                {optionLabel}
              </button>
            );
          })}
        </div>
        <div className={s.lineContainer} />
      </div>
      <label htmlFor={name} className={s.label} id={`label-${name}`}>
        {label}
      </label>
      {description ? (
        <div className={s.note}>
          <small className="muted">{description}</small>
        </div>
      ) : null}
      {errors[field.name] && touched[field.name] && <div className="error-text">{errors[field.name]}</div>}
    </div>
  );
};

export default Timeline;
