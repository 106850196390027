import React from 'react';
import { FormattedMessage } from 'react-intl';
import useScript from 'react-script-hook';

export default function CookieDeclaration() {
  useScript({
    src: 'https://consent.cookiebot.com/bb3f7527-0b83-456b-9462-39287c52897b/cd.js',
  });

  return (
    <>
      <h1>
        <FormattedMessage id="cookie_declaration.title" />
      </h1>
      <div>
        <div id="CookieDeclaration" />
      </div>
    </>
  );
}
