import React, { useState } from 'react';
import Tooltip from 'rc-tooltip';
import { Close } from 'assets/icons';
import s from './Tooltip.module.scss';

export default function CTooltip({ children, overlay, defaultVisible = false, placement = 'right', hide, ...props }) {
  const [isVisible, setIsVisible] = useState(defaultVisible);
  if (hide) return children;
  return (
    <Tooltip
      placement={placement}
      afterVisibleChange={(visible) => {
        setIsVisible(visible);
      }}
      mouseEnterDelay={0.5}
      visible={isVisible}
      defaultVisible={defaultVisible}
      trigger={['hover']}
      onVisibleChange={() => setIsVisible(!isVisible)}
      overlay={
        <div className={s.overlay}>
          <button
            type="button"
            className={s.closeBtn}
            onClick={(e) => {
              e.stopPropagation();
              setIsVisible(false);
            }}
          >
            <Close />
          </button>
          {overlay}
        </div>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
}
