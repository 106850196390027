import React, { useMemo } from 'react';
import Alert from 'components/common/Alert';
import { FormattedMessage } from 'react-intl';
import { yellowColor, successColor, grayDarkColor, whisperGrey } from 'styles/variables.scss';
import { Col, Row } from 'react-flexbox-grid';
import Tooltip from 'rc-tooltip';
import { ReactComponent as OutlineQuestionCircle } from 'assets/icons/outline-question-circle.svg';
import { formatCurrency } from 'utils/helpers';
import ReactSpeedometer from '@uplab/react-d3-speedometer';
import Button from 'components/common/Button';
import s from './SprengnetterEstimation.module.scss';

export const NoAvailableEstimation = ({ requestsCount, t }) => (
  <Alert color="warning">
    <h2 className="mt-0">
      <FormattedMessage id="sprengnetter_estimation.sprengnetter.title" />
    </h2>
    <div>{t({ id: 'sprengnetter_estimation.ran_out_of_requests' }, { requestsCount })}</div>
  </Alert>
);

export const EstimationNotAvailable = ({ t }) => (
  <Alert color="warning">
    <h2 className="mt-0">
      <FormattedMessage id="sprengnetter_estimation.sprengnetter.title" />
    </h2>
    <div>{t({ id: 'disclaimers.sprengnetter_not_available' })}</div>
  </Alert>
);

export const NoActualEstimationInfo = ({ t, isLoanConsultant, requestsCount, isSubmitting, getSprengnetterValues }) => (
  <div className="my-4">
    <Alert color="warning">
      <h2 className="mt-0">{t({ id: 'sprengnetter_estimation.sprengnetter.title' })}</h2>
      <FormattedMessage
        id={
          isLoanConsultant
            ? 'disclaimers.land_sprengnetter_estimation_loan_consultant'
            : 'disclaimers.sprengnetter_estimation'
        }
        values={{ requestsCount: requestsCount ?? 0 }}
      />
    </Alert>

    <div className={s.btnWrapper}>
      <Button loading={isSubmitting} disabled={isSubmitting} onClick={getSprengnetterValues}>
        {t({ id: 'sprengnetter_estimation.estimate_property_btn' })}
      </Button>
    </div>
  </div>
);

export const EstimationNotAvailableForGuest = ({ t, sprengnetterLand }) => (
  <Alert color="warning">
    <h2 className="mt-0">
      {t({ id: `sprengnetter_estimation.${sprengnetterLand ? 'sprengnetter_land' : 'sprengnetter'}.title` })}
    </h2>
    <div>
      {t({
        id: `sprengnetter_estimation.${
          sprengnetterLand ? 'sprengnetter_land' : 'sprengnetter'
        }.not_available_for_guest`,
      })}
    </div>
  </Alert>
);

export function calculateLowerAndUpperPrices(price) {
  return { lower: price * 0.9, avg: price, upper: price * 1.1 };
}

function getDataForChart(sprengnetter) {
  if (!sprengnetter?.market_value) return [];
  const { lower, upper } = calculateLowerAndUpperPrices(sprengnetter?.market_value);
  const startValue = lower - (upper - lower);
  const endValue = lower - startValue + upper;

  return [startValue, lower, upper, endValue];
}

export const EstimationInfo = ({ t, sprengnetter, projectPrice }) => {
  const [startValue, lower, upper, endValue] = useMemo(() => getDataForChart(sprengnetter), [sprengnetter]);
  // eslint-disable-next-line no-nested-ternary
  const price = projectPrice > endValue ? endValue : projectPrice < startValue ? startValue : projectPrice;

  return (
    <div className={s.sprengnetterResultWrapper}>
      <Row>
        <Col xs={12}>
          <h2 className="mt-0">
            <FormattedMessage id="sprengnetter_estimation.sprengnetter.title" />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <div className={s.sprengnetter}>
            <p className={s.subTitle}>
              <Tooltip overlay={<p>{t({ id: 'sprengnetter_estimation.tooltip_description' })}</p>} placement="top">
                <span style={{ cursor: 'pointer' }}>
                  {t({ id: 'sprengnetter_estimation.tooltip_label' })}
                  <OutlineQuestionCircle />
                </span>
              </Tooltip>
            </p>
            <b className={s.avg}>{formatCurrency(sprengnetter.market_value)}</b>
            <p>{t({ id: 'sprengnetter_estimation.estimated_range' })}</p>
            <span>
              <b className={s.lower}>{formatCurrency(lower)}</b> - <b className={s.upper}>{formatCurrency(upper)}</b>
            </span>
          </div>
        </Col>
        <Col xl={6} className={s.chartContainer}>
          <ReactSpeedometer
            value={parseInt(price, 10)}
            width={250}
            height={200}
            minValue={startValue}
            maxValue={endValue}
            valueFormat={(v) => formatCurrency(v)}
            needleColor={grayDarkColor}
            segmentColors={[successColor, whisperGrey, yellowColor]}
            segments={3}
            currentValueText={t({ id: 'legend.totalLabel' }, { value: formatCurrency(projectPrice) })}
            customSegmentStops={[startValue, lower, upper, endValue]}
            textColor={grayDarkColor}
            paddingVertical={5}
          />
        </Col>
      </Row>
    </div>
  );
};
