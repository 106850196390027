import React, { memo, useCallback } from 'react';
import get from 'lodash/get';
import PhoneInput from 'react-phone-number-input';
import { areEqualFields } from 'utils/form';
import s from './PhoneNumberInput.module.scss';
import 'react-phone-number-input/style.css';

const PhoneNumberInput = ({ field, form, label }) => {
  const { name } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  const handleChange = useCallback(
    (v) => {
      setFieldValue(name, v);
    },
    [name, setFieldValue],
  );

  const handleOnBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  return (
    <div className={s.fieldWrapper}>
      <label htmlFor={name} className={s.label} id={`label-${name}`}>
        {label}
      </label>
      <PhoneInput
        {...field}
        limitMaxLength
        smartCaret
        international
        countryCallingCodeEditable={false}
        autoComplete="none"
        defaultCountry="AT"
        onChange={handleChange}
        onBlur={handleOnBlur}
        className={s.numberInput}
      />
      {get(touched, name) && get(errors, name) ? <div className="error-text">{get(errors, name)}</div> : null}
    </div>
  );
};

export default memo(PhoneNumberInput, areEqualFields);
