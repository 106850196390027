/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import NumberInput from 'components/inputs/NumberInput';
import Select from 'components/inputs/Select';
import classnames from 'classnames';
import useUpdatePlanItem from 'hooks/project-plan/useUpdatePlanItem';
import FormikEffect from 'components/common/FormikEffect';
import isNil from 'lodash/isNil';
import ToggleButtons from './ToggleButtons';
import styles from './SubItem.module.scss';

const useUpdatePlamItemHandler = (delay = 0) => {
  const updatePlanItem = useUpdatePlanItem();

  const handleChange = useCallback(
    debounce(async (variables) => {
      try {
        await updatePlanItem(variables);
      } catch (error) {
        toast.error(error.message);
      }
    }, delay),
    [updatePlanItem],
  );

  return handleChange;
};

const SubItem = ({
  categoryName,
  calculated_price,
  description,
  label,
  equipment_type,
  name,
  user_price,
  has_equipment_type,
  initialValues,
}) => {
  const { formatMessage: __ } = useIntl();
  const [isCalculated, setCalculated] = useState(isNil(user_price));
  const handleChange = useUpdatePlamItemHandler(1000);
  const handleToggle = useUpdatePlamItemHandler();
  const params = useParams();

  return (
    <Formik initialValues={initialValues}>
      {({ setFieldValue, values }) => (
        <>
          <Form>
            <div className={classnames(styles.subItem, 'card')}>
              <div className={styles.header}>
                <h4 className={styles.title}>{__({ id: label })}</h4>
                <div className={styles.price}>
                  <b>{formatCurrency(!isNil(user_price) ? user_price : calculated_price)}</b>
                </div>
              </div>
              {description && (
                <div className={styles.description}>
                  <small className="muted">{__({ id: description, defaultMessage: ' ' })}</small>
                </div>
              )}
              {
                <ToggleButtons
                  isCalculated={isCalculated}
                  onCalculatedClick={async () => {
                    setCalculated(true);
                    setFieldValue(name, equipment_type);
                    await handleToggle({
                      projectId: params.id,
                      categoryName,
                      planItemName: name,
                      formData: { user_price: null, equipment_type },
                    });
                  }}
                  onQuotedClick={async () => {
                    setCalculated(false);
                    setFieldValue(name, calculated_price);
                    await handleToggle({
                      projectId: params.id,
                      categoryName,
                      planItemName: name,
                      formData: { user_price: calculated_price },
                    });
                  }}
                />
              }
              {isCalculated && has_equipment_type ? (
                <div>
                  <Field
                    name={name}
                    component={Select}
                    required
                    options={[
                      {
                        label: __({ id: 'equipment_types.standard' }),
                        value: 'standard',
                      },
                      {
                        label: __({ id: 'equipment_types.premium' }),
                        value: 'premium',
                      },
                      {
                        label: __({ id: 'equipment_types.superior' }),
                        value: 'superior',
                      },
                    ]}
                    label={__({ id: 'project_plan.equipment_type' })}
                  />
                  <FormikEffect
                    onEffect={async () => {
                      await handleChange({
                        projectId: params.id,
                        categoryName,
                        planItemName: name,
                        formData: { equipment_type: values[name] },
                      });
                    }}
                  />
                </div>
              ) : null}{' '}
              {!isCalculated ? (
                <div>
                  <Field
                    name={name}
                    min={0}
                    max={3000000}
                    component={NumberInput}
                    required
                    label={__({ id: 'planning.override_cost' })}
                  />
                  <FormikEffect
                    onEffect={async () => {
                      await handleChange({
                        projectId: params.id,
                        categoryName,
                        planItemName: name,
                        formData: { user_price: values[name] },
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default SubItem;
