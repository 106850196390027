import React from 'react';
import classnames from 'classnames';
import Button from 'components/common/Button';
import styles from './ToggleButtons.module.scss';

const ToggleButtons = ({
  isSelect,
  onSelect,
  onDeselect,
  disabled,
  selectComponent,
  deselectComponent,
  style,
  selectComponentIcon,
  deselectComponentIcon,
}) => {
  return (
    <div className={styles.toggleButtons} style={style}>
      <Button
        className={classnames(styles.toggleButton, isSelect ? styles.activeButton : null)}
        onClick={async () => {
          await onSelect();
        }}
        disabled={disabled}
        startIcon={selectComponentIcon}
      >
        {selectComponent}
      </Button>
      <Button
        className={classnames(styles.toggleButton, !isSelect ? styles.activeButton : null)}
        onClick={async () => {
          await onDeselect();
        }}
        disabled={disabled}
        startIcon={deselectComponentIcon}
      >
        {deselectComponent}
      </Button>
    </div>
  );
};

export default ToggleButtons;
