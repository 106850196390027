import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { formatGraphqlErrors } from 'utils/helpers';
import { PROJECT_PLAN_FRAGMENT, PROJECT_PLAN_QUERY } from './useProjectPlan';

const SET_PLAN_CATEGORY_QUOTE_MUTATION = gql`
  mutation setPlanCategoryQuote($projectId: ID!, $categoryName: String!, $value: Float) {
    setPlanCategoryQuote(projectId: $projectId, categoryName: $categoryName, value: $value) {
      ...projectPlan
    }
  }
  ${PROJECT_PLAN_FRAGMENT}
`;

const useSetPlanCategoryQuote = () => {
  const [setPlanCategoryQuoteMutation, { loading }] = useMutation(SET_PLAN_CATEGORY_QUOTE_MUTATION);

  const planCategoryQuote = useCallback(
    async ({ projectId, categoryName, value }) => {
      try {
        await setPlanCategoryQuoteMutation({
          variables: { projectId, categoryName, value },
          refetchQueries: [{ query: PROJECT_PLAN_QUERY, variables: { projectId } }],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [setPlanCategoryQuoteMutation],
  );
  return [planCategoryQuote, loading];
};

export default useSetPlanCategoryQuote;
