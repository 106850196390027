import { GUEST } from 'constants/constants';
import equal from 'fast-deep-equal/es6/react';
import useFormValuesRef from 'hooks/useFormValuesRef';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import React, { memo, useCallback } from 'react';
import { Grid } from 'react-flexbox-grid';
import StepWizard from 'react-step-wizard';
import CostOverview from '../CostOverviewTab';
import Nav from '../Nav';
import {
  ApartmentFirstStep,
  ApartmentSecondStep,
  ApartmentThirdStep,
  ApartmentFourthStep,
  ApartmentFifthStep,
} from './tabs';

const formValueMock = {
  name: 'My apartment',
  type: 'new_apartment',
  country: 'AT',
  zip: '1414',

  main_residence: true,
  cost_apartment: 350000,
  property_transfer_tax: 12250,
  land_register_fee: 3850,
  notary_fee: 3500,
  broker_commission: 12600,

  heating_system: 'gas',
  parking: 'garage',
  construction_year: 2000,
  floor_number: 2,
  is_rooftop: true,
  apartment_area: 90,
  condition_of_apartment: 'not_renovated',
  full_address: 'Brigittaplatz 58, Wien, Österreich',
  coordinates: {
    lat: -23.5270028,
    lng: -46.6655865,
  },
};

const tabs = [
  { label: 'project_details.tabs.apartment_info', component: ApartmentFirstStep },
  { label: 'project_details.tabs.sprengnetter_estimation', component: ApartmentSecondStep },
  { label: 'project_details.tabs.energy', component: ApartmentThirdStep },
  { label: 'project_details.tabs.indoor', component: ApartmentFourthStep },
  { label: 'project_details.tabs.other', component: ApartmentFifthStep },
  { label: 'project_details.tabs.cost_overview', component: CostOverview },
];

const ApartmentDetails = ({ onSubmit, initialValues, isEditMode, hasAdditionalFeatures, projectPrice, step }) => {
  const isGuest = useHasRequiredRole(GUEST);

  const formValuesRef = useFormValuesRef([], { formValueMock, initialValues });

  const onSubmitStep = useCallback(
    (values) => {
      formValuesRef.current = { ...formValuesRef.current, ...values };
    },
    [formValuesRef],
  );

  const onBack = useCallback(
    (values, callback) => {
      onSubmitStep(values);
      callback();
    },
    [onSubmitStep],
  );

  return (
    <Grid>
      <StepWizard initialStep={step} transitions={{}} isLazyMount nav={<Nav tabs={tabs} isEditMode={isEditMode} />}>
        {tabs.map(({ label, component: C }) => (
          <C
            key={label}
            {...{
              isGuest,
              onSubmitStep,
              formValuesRef,
              initialValues,
              onSubmit,
              isEditMode,
              hasAdditionalFeatures,
              projectPrice,
              onBack,
            }}
          />
        ))}
      </StepWizard>
    </Grid>
  );
};

export default memo(ApartmentDetails, equal);
