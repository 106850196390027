import React from 'react';
import { Formik, Form } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import Button from 'components/common/Button';
import { useIntl } from 'react-intl';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { ReactComponent as LightningIcon } from 'assets/icons/lightning.svg';
import { Col, Row } from 'react-flexbox-grid';
import { ReactComponent as Img } from 'assets/images/common/energy-house.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import ScrollToTop from 'components/common/ScrollToTop';
import { RENOVATION_HOUSE } from 'constants/constants';
import Features from '../../Features';
import s from '../../calculators.module.scss';
import validationSchema from '../yupSchemas/renovationHouseFourthStepSchema';

const RenovationHouseFourthStep = ({
  onSubmitStep,
  onSubmit,
  lastStep,
  isEditMode,
  nextStep,
  initialValues,
  previousStep,
  formValuesRef,
  onBack,
  currentStep,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Row between="lg" center="md">
      <ScrollToTop />
      <Col md={8} lg={6}>
        <Formik
          initialValues={isEditMode ? initialValues : formValuesRef?.current}
          onSubmit={async (values, formikActions) => {
            onSubmitStep(values);
            await onSubmit({ values, formikActions, currentStep, isEditMode, lastStep, nextStep });
          }}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <ScrollToError />
              <Features
                values={values}
                group="energy"
                icon={<LightningIcon title={t({ id: 'alt_text.icons.lightning' })} />}
                color="#59a659"
                projectType={RENOVATION_HOUSE}
              />

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} first="xs" last="lg">
        <div className={s.imgBox}>
          <Img title={t({ id: 'alt_text.project_details_page.renovation_house.fourth_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default RenovationHouseFourthStep;
