/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';
import s from './InvitationsTable.module.scss';

const FilterInvitationsInput = ({ value, onChange }) => {
  const { formatMessage: t } = useIntl();
  return (
    <div className="field-wrapper">
      <input
        value={value}
        type="text"
        className="default-input"
        id="filter"
        name="filter"
        maxLength="64"
        onChange={onChange}
      />
      <label
        className={value ? 'label-when-focused-input' : 'text-label'}
        htmlFor="filter"
        id="label-filter"
        style={{ paddingRight: '20px' }}
      >
        {t({ id: 'invitations.search_label' })}
      </label>
      <MagnifierIcon className={s.magnifierIcon} />
    </div>
  );
};

export default FilterInvitationsInput;
