import * as yup from 'yup';
import { createConditionalValidator } from 'utils/yup';
import { NEW_BUILDING } from 'constants/constants';
import { getPartOfValidationSchema } from '../../helpers';

const validationSchema = yup.object().shape({
  ...getPartOfValidationSchema({ projectType: NEW_BUILDING, groupName: 'indoor' }),
  renovation_walls_and_interior_doors_area: createConditionalValidator('renovation_walls_and_interior_doors', 'number'),
});

export default validationSchema;
