import React, { memo, useEffect, useCallback } from 'react';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { areEqualFields } from 'utils/form';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import s from './Counter.module.scss';

const Counter = ({
  field: { name, value },
  form: { setFieldValue, touched, errors, values },
  label,
  defaultValue,
  min = 0,
  max,
  description,
  largeLabel = false,
  callbackOnChange,
}) => {
  const { formatMessage: t } = useIntl();

  const handleOnChangeNumber = useCallback(
    (condition) => {
      let newValue = value;
      if (condition === 'increment' && newValue < max) newValue += 1;
      if (condition === 'decrement' && newValue > min) newValue -= 1;
      if (value < min) newValue = min;
      setFieldValue(name, newValue);
      if (typeof callbackOnChange === 'function') callbackOnChange(newValue, values, setFieldValue);
    },
    [value, max, min, setFieldValue, name, callbackOnChange, values],
  );

  useEffect(() => {
    if (!Number.isFinite(value)) {
      setFieldValue(name, defaultValue || min);
    }
    if (Number.isFinite(value) && value < min) setFieldValue(name, min);
  }, [name, value, defaultValue, setFieldValue, min]);

  return (
    <div className={cn('field-wrapper', largeLabel ? 'field-with-large-label' : '')}>
      <div className={s.counter}>
        <button
          onClick={() => handleOnChangeNumber('decrement')}
          type="button"
          className={cn(s.counterBtn, s.ripple)}
          aria-label="Minus-"
        >
          <Minus title={t({ id: 'alt_text.icons.minus' })} />
        </button>
        <span className={s.value}>{value}</span>
        <button
          onClick={() => handleOnChangeNumber('increment')}
          type="button"
          className={cn(s.counterBtn, s.ripple)}
          aria-label="Plus"
        >
          <Plus title={t({ id: 'alt_text.icons.plus' })} />
        </button>
      </div>
      <label htmlFor={name} className="label-when-focused-input">
        {label}
      </label>
      {description ? <div className={s.description}>{description}</div> : null}
      {touched[name] && errors[name] && <div className="error-text">{errors[name]}</div>}
    </div>
  );
};

export default memo(Counter, areEqualFields);
