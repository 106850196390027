import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { cloneDeep, get } from 'lodash';
import { MY_BUDGET_QUERY } from 'hooks/budget/useMyBudget';
import { toastErrorMessage } from 'utils/helpers';
import { FINANCING_QUERY } from './useFinancing';

const REQUEST_PERSONAL_OFFER_MUTATION = gql`
  mutation requestPersonalOffer($projectId: ID, $expressLoanId: ID, $formData: RequestPersonalOffer!) {
    requestPersonalOffer(projectId: $projectId, expressLoanId: $expressLoanId, formData: $formData) {
      success
    }
  }
`;

const useRequestPersonalOffer = () => {
  const { formatMessage, locale } = useIntl();
  const [requestPersonalOfferMutation, { loading, client }] = useMutation(REQUEST_PERSONAL_OFFER_MUTATION, {
    onCompleted: () => toast.success(formatMessage({ id: 'loan.request_sent' })),
    onError: toastErrorMessage,
  });

  const handleRequestPersonalOffer = useCallback(
    async ({ projectId, expressLoanId, formData }) => {
      const result = await requestPersonalOfferMutation({ variables: { projectId, expressLoanId, formData } });
      const loanRequest = get(result, 'data.requestPersonalOffer.success');

      if (expressLoanId) return;

      if (projectId) {
        const financingData = client.readQuery({ query: FINANCING_QUERY, variables: { id: projectId } });
        const newFinancingData = cloneDeep(financingData);
        newFinancingData.project.progress.loan_request = loanRequest;
        client.writeQuery({ query: FINANCING_QUERY, variables: { id: projectId }, data: { ...newFinancingData } });
      } else {
        const myBudgetData = await client.readQuery({ query: MY_BUDGET_QUERY, variables: { locale } });
        const data = cloneDeep(myBudgetData);
        data.myBudget.loan_request = loanRequest;
        client.writeQuery({ query: MY_BUDGET_QUERY, variables: { locale }, data });
      }
    },
    [client, locale, requestPersonalOfferMutation],
  );
  return [handleRequestPersonalOffer, loading];
};

export default useRequestPersonalOffer;
