import { __ } from 'utils/form';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  full_address: yup
    .string()
    .required(__('errors.full_address'))
    .typeError(__('errors.full_address')),
  zip: yup
    .string()
    .matches(/^[1-9]{1}[0-9]{3}$/, { message: __('errors.wrong_zip_code') })
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  land_area: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field'))
    .min(200, __('errors.values_within_range', { min: 200, max: 3000 }))
    .max(3000, __('errors.values_within_range', { min: 200, max: 3000 })),
  avg_property_price_sq_m: yup
    .mixed()
    .notRequired()
    .when('has_property', {
      is: false,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  estimated_property_price: yup
    .mixed()
    .notRequired()
    .when('has_property', {
      is: true,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
});

export default validationSchema;
