import React, { useMemo } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { formatCurrency } from 'utils/helpers';
import isNil from 'lodash/isNil';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { ReactComponent as HumanWithCalculatorGreen } from 'assets/images/human-with-calculator-green.svg';
import { ReactComponent as HumanWithCalculator } from 'assets/images/human-with-calculator.svg';
import { Header, SectionWrapper } from 'components/common/heap';
import { RENOVATION_HOUSE } from 'constants/constants';
import CO2StatusProjectPlanSection from 'components/calculators/CO2CalculatorResult/CO2StatusProjectPlanSection';
import { useIntl } from 'react-intl';
import Button from 'components/common/Button';
import { routePatterns } from 'router/route-paths';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import s from './ProjectPlanTables.module.scss';

const orderOfCategories = ['main', 'cost_drivers', 'additional_costs', 'options'];

const ProjectPlanTables = ({ project, projectPlan, goToStep }) => {
  const { formatMessage: t } = useIntl();

  const orderedCategories = useMemo(
    () =>
      orderOfCategories.reduce((res, groupName) => {
        res.push(...projectPlan.planningCategories.filter(({ group }) => group === groupName));
        return res;
      }, []),
    [projectPlan.planningCategories],
  );

  return (
    <Row>
      <Col xs={12}>
        <Row start="xs">
          <Col xl={6}>
            <Header className={cn(s.totalCosts, project.type === RENOVATION_HOUSE ? s.greenBackground : '')}>
              <h2>
                {t({ id: 'project_plan.total_costs' })}
                <span className="float-right">{formatCurrency(projectPlan.price)}</span>
              </h2>
            </Header>
            {project.personal_contribution_value ? (
              <div className={s.personalContribution}>
                <div>{t({ id: 'project_plan.personal_contribution' })}</div>
                <div>{formatCurrency(project.personal_contribution_value)}</div>
              </div>
            ) : null}
            <div>
              {orderedCategories.map((category) => {
                return category.calculated_price ? (
                  <SectionWrapper key={category.name}>
                    <table className="table last-col-text-right" style={{ wordBreak: 'break-word' }}>
                      <thead>
                        <tr>
                          <td>
                            <h4>{t({ id: category.label })}</h4>
                          </td>
                          <td>
                            <h4 className="text-primary">
                              {formatCurrency(
                                !isNil(category.user_price) ? category.user_price : category.calculated_price,
                              )}
                            </h4>
                          </td>
                        </tr>
                      </thead>
                      {category.subitems.length > 1 ? (
                        <tbody>
                          {category.subitems.map((item) => (
                            <tr key={item.name}>
                              <td className={s.td}>{t({ id: item.label })}</td>
                              <td>
                                {formatCurrency(!isNil(item.user_price) ? item.user_price : item.calculated_price)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : null}
                    </table>
                  </SectionWrapper>
                ) : null;
              })}
            </div>

            <div className={s.buttonsWrapper}>
              <Button onClick={() => goToStep(1)} startIcon={<ArrowBack />}>
                {t({ id: 'project_wizard.edit_values_btn' })}
              </Button>
              <Link to={routePatterns.lifestyleOverviewWithProject.stringify({ id: project._id })}>
                <Button endIcon={<ArrowForward />} color="secondary">
                  {t({ id: 'project_plan.btn_to_budget_calculation' })}
                </Button>
              </Link>
            </div>
          </Col>
          <Col md={0} lg={0} xl={6} width="100%" first="xs" last="xl">
            <div className="sticky-img-box">
              {project.type === RENOVATION_HOUSE ? (
                <HumanWithCalculatorGreen
                  width="100%"
                  height="100%"
                  title={t({ id: 'alt_text.project_plan_page.img' })}
                />
              ) : (
                <HumanWithCalculator width="100%" height="100%" title={t({ id: 'alt_text.project_plan_page.img' })} />
              )}
            </div>
          </Col>
        </Row>

        {project.type === RENOVATION_HOUSE ? <CO2StatusProjectPlanSection /> : null}
      </Col>
    </Row>
  );
};

export default ProjectPlanTables;
