import * as yup from 'yup';
import { createConditionalValidator } from 'utils/yup';
import { NEW_BUILDING } from 'constants/constants';
import { getPartOfValidationSchema } from '../../helpers';

const validationSchema = yup.object().shape({
  ...getPartOfValidationSchema({ projectType: NEW_BUILDING, groupName: 'energy' }),
  solar_power_size_kwp: createConditionalValidator('buying_house_solar_power_system', 'number'),
  solar_power_equipment_type: createConditionalValidator('buying_house_solar_power_system', 'string'),
});

export default validationSchema;
