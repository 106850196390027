import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { toastErrorMessage } from 'utils/helpers';
import { PROJECT_CALCULATIONS } from './project/useGetProjectCalculations';

const INJECT_SPRENGNETTER_VALUES = gql`
  mutation injectValues($projectId: ID!, $type: String!) {
    injectSprengnetterValues(projectId: $projectId, type: $type) {
      success
    }
  }
`;

export default function useInjectSprengnetterValues({ type, projectId }) {
  const [injectSprengnetterValues] = useMutation(INJECT_SPRENGNETTER_VALUES);

  const getSprengnetterValues = useCallback(async () => {
    try {
      await injectSprengnetterValues({
        variables: { projectId, type },
        refetchQueries: [{ query: PROJECT_CALCULATIONS, variables: { id: projectId } }],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      toastErrorMessage(error);
    }
  }, [injectSprengnetterValues, projectId, type]);

  return getSprengnetterValues;
}
