import React, { memo, useEffect, useLayoutEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { areEqualFields } from 'utils/form';
import { isNil, get } from 'lodash';
import useSize from 'hooks/useSize';
import s from './TextArea.module.scss';

const TextArea = ({ field, form: { touched, errors, setFieldValue }, description, minRows = 4, style, label }) => {
  const labelRef = useRef();
  const textRef = useRef();
  const fieldWrapperRef = useRef();
  const { value, name } = field;
  const textSize = useSize(textRef);
  const textHeight = get(textSize, 'height', 0);

  useEffect(() => {
    if (isNil(value)) setFieldValue(name, '');
  }, [name, setFieldValue, value]);

  useLayoutEffect(() => {
    if (labelRef?.current && textRef?.current) {
      const { current } = labelRef;
      if (!value) {
        current.style.top = `${textHeight || 0}px`;
      } else {
        current.style.top = `-40px`;
      }
    }
  }, [textHeight, value]);

  useLayoutEffect(() => {
    if (fieldWrapperRef?.current && textRef?.current) {
      const { current } = fieldWrapperRef;
      current.style.paddingTop = `${textHeight + 10}px`;
    }
  }, [textHeight]);

  return (
    <div className="field-wrapper" style={style} ref={fieldWrapperRef}>
      <div className={s.textareaWrapper}>
        <TextareaAutosize {...field} className={s.textArea} resize="none" minRows={minRows} />
        <label className={value ? 'label-when-focused-input' : s.textLabel} htmlFor={name} ref={labelRef}>
          <span ref={textRef}>{label}</span>
        </label>
      </div>
      {description ? (
        <div>
          <small className="muted">{description}</small>
        </div>
      ) : null}
      {errors[name] && touched[name] && <div className="error-text">{errors[name]}</div>}
    </div>
  );
};

export default memo(TextArea, areEqualFields);
