import React from 'react';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useParams } from 'react-router-dom';
import useGetFinancing from 'hooks/financing/useFinancing';
import { useIntl } from 'react-intl';
import Alert from 'components/common/Alert';
import WarningMessage from 'components/common/WarningMessage';
import routePaths, { routePatterns } from 'router/route-paths';
import LoanCalculator from 'pages/LoanCalculator';
import StartProjectPlanning from 'components/calculators/PropertyValuation/StartProjectPlanning';
import { PROPERTY_VALUATION } from 'constants/constants';
import { Col, Row } from 'react-flexbox-grid';
import { isNull } from 'lodash';
import Loan from './Loan';

export default () => {
  const { id: projectId } = useParams();
  const [data, loading] = useGetFinancing(projectId);
  const { formatMessage: t } = useIntl();

  if (loading) {
    return <LoadingOverlay />;
  }

  const { project, budget } = data;

  if (!project) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'app.project_not_found' })}
      </Alert>
    );
  }

  if (!budget || !isNull(budget.step)) {
    return (
      <Row center="xs">
        <Col md={8} lg={6}>
          <WarningMessage
            title={t({ id: 'page_titles.loan' })}
            message={t({ id: 'loan.complete_previous_step' })}
            btnLabel={t({ id: 'loan.complete_previous_step_btn' })}
            btnLink={routePaths.lifestyleOverview}
          />
        </Col>
      </Row>
    );
  }

  if (project.type === PROPERTY_VALUATION) return <StartProjectPlanning />;

  if (project.price === 0) {
    return (
      <Row center="xs">
        <Col md={8} lg={6}>
          <WarningMessage
            title={t({ id: 'page_titles.loan' })}
            message={t({ id: 'loan.project_price_zero' })}
            btnLabel={t({ id: 'loan.start_planning' })}
            btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
          />
        </Col>
      </Row>
    );
  }

  if (!project.loan) return <LoanCalculator project={project} />;

  if (project.loan.amount <= 0) {
    return (
      <Row center="xs">
        <Col md={8} lg={6}>
          <WarningMessage
            title={t({ id: 'page_titles.loan' })}
            message={t({ id: 'loan.no_need' })}
            btnLabel={t({ id: 'loan.edit' })}
            btnLink={routePatterns.loanCalculator.stringify({ id: projectId })}
          />
        </Col>
      </Row>
    );
  }

  const { monthly_rate_loan } = budget.calculation;

  return <Loan {...{ project, projectId, t, budget, monthlyRateLoan: monthly_rate_loan }} />;
};
