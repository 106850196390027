/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo, memo, useState } from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import useInjectSprengnetterValues from 'hooks/useInjectSprengnetterValues';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { BUYING_HOUSE, GUEST, LOAN_CONSULTANT, NEW_APARTMENT } from 'constants/constants';
import { useParams } from 'react-router-dom';
import Loading from 'components/common/Loading';
import { toast } from 'react-toastify';
import { pick } from 'lodash';
import equal from 'fast-deep-equal/es6/react';
import {
  EstimationInfo,
  EstimationNotAvailable,
  EstimationNotAvailableForGuest,
  NoActualEstimationInfo,
  NoAvailableEstimation,
} from './SprengnetterEstimation.components';

const whiteListApartmentValues = [
  'cost_apartment',
  'coordinates.lat',
  'coordinates.lng',
  'apartment_area',
  'construction_year',
  'floor_number',
  'elevator',
  'parking',
  'condition_of_apartment',
];

export const whiteListBuyingHouseValues = [
  'construction_year',
  'coordinates.lat',
  'coordinates.lng',
  'house_area',
  'land_area',
  'house_category',
  'basement_type',
  'parking',
  'cost_house',
  'house_type',
  'number_of_floors',
];

const whiteListFormValuesByProjectType = {
  [NEW_APARTMENT]: whiteListApartmentValues,
  [BUYING_HOUSE]: whiteListBuyingHouseValues,
};

const useInjectSprengnetterValuesHandler = ({ values, type, sideEffectOnRequest }) => {
  const { id: projectId } = useParams();
  const getSprengnetterValues = useInjectSprengnetterValues({ projectId, type });
  const { formatMessage: t } = useIntl();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleInject = useCallback(async () => {
    setSubmitting(true);
    try {
      const formData = pick(values, whiteListFormValuesByProjectType[type]);
      const isFormInvalid = Object.values(formData).some((value) => value === null || typeof value === 'undefined');
      if (isFormInvalid || !values.full_address) {
        toast.error(t({ id: 'errors.fill_all_fields' }));
      } else {
        await sideEffectOnRequest();
        await getSprengnetterValues();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  }, [getSprengnetterValues, sideEffectOnRequest, t, type, values]);

  return [handleInject, isSubmitting];
};

const SprengnetterEstimation = ({
  projectPrice,
  type,
  values,
  sideEffectOnRequest,
  calculations,
  loading,
  requestsCount,
}) => {
  const { formatMessage: t } = useIntl();
  const [getSprengnetterValues, isSubmitting] = useInjectSprengnetterValuesHandler({
    values,
    type,
    sideEffectOnRequest,
  });
  const isGuest = useHasRequiredRole(GUEST);

  const isLoanConsultant = useHasRequiredRole(LOAN_CONSULTANT);
  const isRequests = !isLoanConsultant ? requestsCount < 10 : true;
  const sprengnetter = get(calculations, 'sprengnetter', {});

  const isActualInfo = useMemo(() => {
    const formValues = pick(values, whiteListFormValuesByProjectType[type]);
    const sprengnetterFormValues = pick(
      calculations?.sprengnetter?.form_values,
      whiteListFormValuesByProjectType[type],
    );
    return equal(formValues, sprengnetterFormValues);
  }, [calculations, type, values]);

  if (isGuest) {
    return <EstimationNotAvailableForGuest t={t} />;
  }

  if (loading) {
    return <Loading size={50} />;
  }

  if (!isActualInfo) {
    return <NoActualEstimationInfo {...{ t, isLoanConsultant, requestsCount, isSubmitting, getSprengnetterValues }} />;
  }

  if (sprengnetter?.market_value) return <EstimationInfo {...{ t, projectPrice, sprengnetter }} />;
  if (sprengnetter?.error) return <EstimationNotAvailable t={t} />;
  if (!isRequests) return <NoAvailableEstimation {...{ t, requestsCount }} />;

  return <NoActualEstimationInfo {...{ t, isLoanConsultant, requestsCount, isSubmitting, getSprengnetterValues }} />;
};

export default memo(SprengnetterEstimation, equal);
