import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const RESEND_TEMP_PASSWORD = gql`
  mutation resendTempPassword($userId: ID!) {
    resendTempPassword(userId: $userId) {
      success
    }
  }
`;

const useResendTempPassword = () => {
  const [resendTempPasswordMutation, { loading }] = useMutation(RESEND_TEMP_PASSWORD);
  const { formatMessage: t } = useIntl();
  const resendTempPassword = useCallback(
    async (userId) => {
      try {
        await resendTempPasswordMutation({ variables: { userId } });
        toast.success(t({ id: 'invitations.successfully_sent' }));
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        toast.error(e.message);
      }
    },
    [resendTempPasswordMutation, t],
  );
  return [resendTempPassword, loading];
};

export default useResendTempPassword;
