import gql from 'graphql-tag';

export default gql`
  fragment newBuildingFormValues on NewBuildingFormValues {
    house_type
    land_area
    full_address
    coordinates {
      lat
      lng
    }
    has_property
    avg_property_price_sq_m
    bathrooms
    shower
    bathtub
    car_ports
    demolition_area
    floors
    garage_places
    heating_system_type
    living_space
    living_room_direction
    living_space_by_floor
    terrace_area
    solar_power_size_kwp

    other_investments_amount
    other_investments_comment

    carport_equipment_type
    garage_equipment_type
    solar_power_equipment_type
    personal_contribution
    sole_plate_or_basement
    sole_plate_or_basement_area
    interior_equipment_type
    winter_garden_equipment_type
    pool_equipment_type
    garden_landscaping_equipment_type
    fence_equipment_type
    tile_stove_equipment_type
    domestic_ventilation_equipment_type
    bus_system_equipment_type
  }
`;
