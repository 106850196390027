import React, { useMemo } from 'react';
import Pagination from 'rc-pagination';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import { useIntl } from 'react-intl';
import Table from 'rc-table';
import 'rc-pagination/assets/index.css';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { COUNT_INVITATIONS_PER_PAGE } from 'constants/constants';
import { navbarHeight } from 'styles/variables.scss';
import Modal, { useModal } from 'components/common/Modal';
import s from './InvitationsTable.module.scss';
import { invitationsTableColumns } from './columns';
import ProjectsTable from './ProjectsTable';

const InvitationsTable = ({ invitations, total, currentPage, changePage }) => {
  const { formatMessage: t } = useIntl();
  const { showModal, hideModal, modalProps, isVisible } = useModal();
  const localizedColumns = useMemo(
    () => invitationsTableColumns.map(({ title, ...rest }) => ({ ...rest, title: t({ id: title }) })),
    [t],
  );

  const data = useMemo(
    () =>
      invitations.map(({ client, projects }) => {
        const {
          _id,
          profile,
          createdAt,
          lastLoginAt,
          budgetPdf,
          numberOfProjects,
          temp_password_changed,
          loanConsultant,
          invitedBy,
        } = client;

        return {
          user_email: profile.email,
          name: `${profile.firstName} ${profile.lastName}`,
          date_of_invitation: format(new Date(createdAt), 'do MMMM yyyy HH:mm', { locale: de }),
          date_of_last_login:
            new Date(lastLoginAt) - new Date(createdAt) > 1000
              ? format(new Date(lastLoginAt), 'do MMMM yyyy HH:mm', { locale: de })
              : '-',
          temp_password_changed:
            typeof temp_password_changed === 'boolean' ? t({ id: temp_password_changed ? 'app.yes' : 'app.no' }) : '-',
          numberOfProjects,
          loan_consultant_email: loanConsultant.email,
          loan_consultant_name: `${loanConsultant.firstName} ${loanConsultant.lastName}`,
          inviter_email: invitedBy.email,
          inviter_name: `${invitedBy.firstName} ${invitedBy.lastName}`,
          projects: { projects, showModal },
          budgetPdf,
          resend_temp_password: { userId: _id, temp_password_changed },
          delete_user_account: { userId: _id, temp_password_changed, profile },
        };
      }),
    [invitations, showModal, t],
  );

  return (
    <>
      <Table
        rowKey={({ user_email }) => user_email}
        style={{ width: '100%' }}
        scroll={{ x: '100%' }}
        direction="ltr"
        columns={localizedColumns}
        data={data}
        emptyText={t({ id: 'invitations.no_invitations' })}
        sticky={{ offsetHeader: navbarHeight }}
      />
      <Modal close={hideModal} isVisible={isVisible} size="xl" headerText={t({ id: 'invitations.projects' })}>
        <ProjectsTable projects={modalProps?.projects} />
      </Modal>
      <div className={s.paginationWrapper}>
        <Pagination
          defaultPageSize={10}
          defaultCurrent={1}
          prevIcon={<ArrowBack className={s.arrow} />}
          nextIcon={<ArrowForward className={s.arrow} />}
          pageSize={COUNT_INVITATIONS_PER_PAGE}
          onChange={changePage}
          current={currentPage}
          total={total}
        />
      </div>
    </>
  );
};

export default InvitationsTable;
