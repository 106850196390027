import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { FastField, Field, Form, Formik } from 'formik';
import ErrorMessage from 'components/common/ErrorMessage';
import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import CDatePicker from 'components/inputs/DatePicker';
import Checkbox from 'components/inputs/Checkbox';
import TextArea from 'components/inputs/TextArea';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import useRequestPersonalOffer from 'hooks/financing/useRequestPersonalOffer';
import { Visible, Hidden } from 'components/common/Grid';
import { get } from 'lodash';
import useUser from 'hooks/user/useUser';
import TextInput from 'components/inputs/TextInput';
import Select from 'components/inputs/Select';
import { GUEST, lowerAustriaStores, viennaStores } from 'constants/constants';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import ScrollToError from 'components/common/ScrollToError';
import { __ } from 'utils/form';
import settings from 'config/settings';
import useSearchParams from 'hooks/useSearchParams';
import s from './PersonalFinancingRequest.module.scss';

const useRequestPersonalOfferHandler = (afterSubmit) => {
  const params = useParams();
  const searchParams = useSearchParams();
  const expressLoanId = searchParams.get('id');
  const [requestPersonalOffer] = useRequestPersonalOffer();

  const handleSubmit = useCallback(
    async (variables, { setSubmitting }) => {
      const {
        day,
        month,
        year,
        first_name,
        last_name,
        email,
        form_type,
        terms_of_use_consent,
        privacy_policy_consent,
        ...rest
      } = variables;

      const profile = { first_name, last_name, email };
      await requestPersonalOffer({
        projectId: params?.id,
        formData: { ...rest, profile },
        ...(expressLoanId ? { expressLoanId } : {}),
      });
      if (typeof afterSubmit === 'function') {
        afterSubmit();
      }
      setSubmitting(false);
    },
    [afterSubmit, expressLoanId, params?.id, requestPersonalOffer],
  );

  return handleSubmit;
};

const ReachabilityOnPhoneBefore = ({ t }) => (
  <>
    <span className={s.text}>{t({ id: 'loan.personal_financing_request.before' })}</span>
    <Field
      name="reachability_on_phone_before"
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      timeCaption="Time"
      timeFormat="HH:mm"
      dateFormat="HH:mm"
      component={CDatePicker}
      outerClassName={s.fieldWrapper}
      className={s.reachabilityInput}
    />
    <span className={s.text}>{t({ id: 'loan.personal_financing_request.oclock' })}</span>
  </>
);

const ReachabilityOnPhoneAfter = ({ t }) => (
  <>
    <span className={s.text}>{t({ id: 'loan.personal_financing_request.after' })}</span>
    <Field
      name="reachability_on_phone_after"
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      timeCaption="Time"
      timeFormat="HH:mm"
      dateFormat="HH:mm"
      component={CDatePicker}
      outerClassName={s.fieldWrapper}
      className={s.reachabilityInput}
    />
    <span className={s.text}>{t({ id: 'loan.personal_financing_request.oclock' })}</span>
  </>
);

const personalFinancingRequestShape = yup.object().shape({
  first_name: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  last_name: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  email: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  desired_branch: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  phone_number: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  terms_of_use_consent: yup
    .mixed()
    .notRequired()
    .when('form_type', {
      is: (formType) => formType === 'expressLoan',
      then: yup.bool().oneOf([true], <FormattedMessage id="auth.terms_of_use_required" />),
    }),
  additional_information: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
});

function getTime(hours, minutes) {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

function getInitialValues({ isGuest, me, formType }) {
  return {
    ...(formType === 'expressLoan' ? { form_type: formType, terms_of_use_consent: false } : {}),
    reachability_on_phone_before: getTime(9, 0),
    reachability_on_phone_after: getTime(16, 0),
    day: null,
    month: null,
    year: null,
    prefer_contact_by_email: null,
    prefer_contact_by_phone: null,
    phone_number: null,
    first_name: !isGuest ? get(me, 'profile.firstName', '') : '',
    last_name: !isGuest ? get(me, 'profile.lastName', '') : '',
    email: get(me, 'emails[0].address', ''),
    desired_branch: null,
  };
}

const PersonalFinancingRequest = ({ afterSubmit = () => {}, buttonLabelId, backButton, formType, inModal }) => {
  const { formatMessage: t } = useIntl();
  const isGuest = useHasRequiredRole(GUEST);

  const desiredBranchOptions = useMemo(
    () => [
      {
        label: t({ id: 'loan.personal_financing_request.desired_branch.vienna_label' }),
        options: viennaStores.map((storeName) => ({ label: storeName, value: storeName })),
      },
      {
        label: t({ id: 'loan.personal_financing_request.desired_branch.lower_austria_label' }),
        options: lowerAustriaStores.map((storeName) => ({ label: storeName, value: storeName })),
      },
    ],
    [t],
  );

  const onSubmit = useRequestPersonalOfferHandler(afterSubmit);
  const [me] = useUser();

  const initialValues = useMemo(() => getInitialValues({ isGuest, me, formType }), [isGuest, me, formType]);

  return (
    <Formik initialValues={initialValues} validationSchema={personalFinancingRequestShape} onSubmit={onSubmit}>
      {({ isSubmitting, errors, values }) => (
        <>
          <Form>
            <ScrollToError inModal={inModal} />
            <Field name="first_name" component={TextInput} label={t({ id: 'user.first_name' })} />
            <Field name="last_name" component={TextInput} label={t({ id: 'user.last_name' })} />
            <Field name="email" lowerCase component={TextInput} label={t({ id: 'user.email' })} type="email" />

            <Field
              name="desired_branch"
              component={Select}
              grouped
              isSearchable
              label={t({ id: 'loan.personal_financing_request.desired_branch.label' })}
              options={desiredBranchOptions}
            />

            <span className={s.checkboxesLabel}>{t({ id: 'loan.personal_financing_request.prefer_contact_by' })}</span>
            <div className={s.contactByCheckboxesWrapper}>
              <Field
                name="prefer_contact_by_email"
                label={t({ id: 'loan.personal_financing_request.prefer_contact_by_email' })}
                component={Checkbox}
                skipScrollingOnChange
              />
              <Field
                name="prefer_contact_by_phone"
                label={t({ id: 'loan.personal_financing_request.prefer_contact_by_phone' })}
                component={Checkbox}
                skipScrollingOnChange
              />
            </div>
            <Field
              name="phone_number"
              component={PhoneNumberInput}
              label={t({ id: 'loan.personal_financing_request.phone_number' })}
            />
            {values.phone_number ? (
              <>
                <span className={s.reachabilityOnPhoneLabel}>
                  {t({ id: 'loan.personal_financing_request.prefer_contact_by' })}
                </span>
                {/* desktop */}
                <Hidden xs sm>
                  <div className={s.inputsWrapper}>
                    <ReachabilityOnPhoneBefore t={t} />
                    <ReachabilityOnPhoneAfter t={t} />
                  </div>
                </Hidden>
                {/* mobile */}
                <Visible xs sm>
                  <div className={s.inputsWrapper}>
                    <div className={s.group}>
                      <ReachabilityOnPhoneBefore t={t} />
                    </div>
                    <div className={s.group}>
                      <ReachabilityOnPhoneAfter t={t} />
                    </div>
                  </div>
                </Visible>
              </>
            ) : null}

            {values.form_type === 'expressLoan' ? (
              <>
                <FastField
                  name="terms_of_use_consent"
                  component={Checkbox}
                  label={
                    <FormattedMessage
                      id="auth.terms_of_use"
                      values={{
                        link: (
                          <a
                            href={settings.termsOfUseUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                          >
                            {t({ id: 'auth.terms_of_use_link_label' })}
                          </a>
                        ),
                      }}
                    />
                  }
                />
                <p className="text-left">
                  <FormattedMessage
                    id="auth.privacy_policy"
                    values={{
                      data_processing_label: (
                        <a
                          href={settings.dataProcessingUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                        >
                          {t({ id: 'auth.data_processing_label' })}
                        </a>
                      ),
                      data_protection_label: (
                        <a
                          href={settings.dataProtectionUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                        >
                          {t({ id: 'auth.data_protection_label' })}
                        </a>
                      ),
                    }}
                  />
                </p>
              </>
            ) : null}

            <Field
              name="additional_information"
              component={TextArea}
              minRows={8}
              label={t({ id: 'loan.personal_financing_request.message' })}
              description={t({ id: 'loan.personal_financing_request.description' })}
            />
            {errors.form && <ErrorMessage message={errors.form} />}

            <div className={s.buttonsWrapper}>
              {backButton}
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
                color="secondary"
                className="ml-auto"
                endIcon={<ArrowForward />}
              >
                {t({ id: buttonLabelId })}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default PersonalFinancingRequest;
