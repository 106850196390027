import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { formatGraphqlErrors } from 'utils/helpers';
import { useIntl } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import { MY_BUDGET_QUERY } from 'hooks/budget/useMyBudget';

const CHANGE_BUDGET_STEP_MUTATION = gql`
  mutation changeBudgetStep($step: Int!) {
    changeBudgetStep(step: $step) {
      success
    }
  }
`;

const useChangeBudgetStep = () => {
  const [changeBudgetStepMutation, { client, loading }] = useMutation(CHANGE_BUDGET_STEP_MUTATION);
  const { locale } = useIntl();

  const handleChangeStep = useCallback(
    async (step) => {
      try {
        const { data } = await changeBudgetStepMutation({ variables: { step } });
        const { changeBudgetStep } = data;

        if (!changeBudgetStep.success) return;

        const budget = client.readQuery({ query: MY_BUDGET_QUERY, variables: { locale } });
        const clonedBudget = cloneDeep(budget);
        clonedBudget.myBudget.step = step;
        client.writeQuery({ query: MY_BUDGET_QUERY, variables: { locale }, data: clonedBudget });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [changeBudgetStepMutation, client, locale],
  );

  return [handleChangeStep, loading];
};

export default useChangeBudgetStep;
