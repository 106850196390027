import React, { useCallback, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import Button from 'components/common/Button';
import { routePatterns } from 'router/route-paths';
import { Link } from 'react-router-dom';
import { ArrowForward } from 'assets/icons';
import { animateScroll } from 'react-scroll';
import WarningMessage from 'components/common/WarningMessage';
import { useAppContext } from 'contexts/AppContext';
import { Col, Row } from 'react-flexbox-grid';
import s from './PlanForm.module.scss';
import Category from './Category';

const theme2ActiveTabClassName = {
  primary: s.primaryActiveCategoryTab,
  green: s.greenActiveCategoryTab,
};

const PlanForm = ({ categories, initialValues, projectId }) => {
  const { formatMessage: t } = useIntl();
  const { theme } = useAppContext();
  const [activeCategoryName, setActiveCategoryName] = useState(categories[0]?.name);
  let activeCategory = categories.find((c) => c.name === activeCategoryName);
  const cardRef = useRef();

  if (!activeCategory) {
    [activeCategory] = categories;
  }

  const onCategoryTabClick = useCallback((categoryName) => {
    if (cardRef.current) {
      const coordinates = cardRef.current.getBoundingClientRect();
      animateScroll.scrollTo(coordinates.top + window.pageYOffset - 300);
    }
    setActiveCategoryName(categoryName);
  }, []);

  if (!activeCategoryName) {
    return (
      <Row center="xs">
        <Col md={8} lg={6}>
          <WarningMessage
            title={t({ id: 'page_titles.plan' })}
            message={t({ id: 'project_plan.complete_previous_step' })}
            btnLabel={t({ id: 'project_plan.complete_previous_step_btn' })}
            btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <div className="card">
        <div className={s.categoryTabs}>
          {categories.map((category) => (
            <Button
              key={category.name}
              className={classnames(
                s.categoryTab,
                category.name === activeCategory.name && theme2ActiveTabClassName[theme],
              )}
              onClick={() => onCategoryTabClick(category.name)}
            >
              {t({ id: category.label })}
            </Button>
          ))}
        </div>
        <div className={s.planOverviewBtn}>
          <Link to={routePatterns.plan.stringify({ id: projectId })}>
            <Button endIcon={<ArrowForward />}>{t({ id: 'planning.plan_overview' })}</Button>
          </Link>
        </div>
      </div>
      {activeCategoryName && (
        <div className={s.container}>
          <Category key={activeCategory.name} {...{ initialValues, cardRef, ...activeCategory }} />
        </div>
      )}
    </>
  );
};

export default PlanForm;
