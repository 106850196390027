import { ReactComponent as VisibilityIcon } from 'assets/icons/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from 'assets/icons/visibility-off.svg';
import React, { useCallback, useState } from 'react';
import TextInput from 'components/inputs/TextInput';

const PasswordInput = ({ type, ...props }) => {
  const [showPassword, changePasswordVisibility] = useState(false);

  const handleToggle = useCallback(() => {
    changePasswordVisibility(!showPassword);
  }, [showPassword]);

  return (
    <TextInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      endIcon={
        showPassword ? (
          <VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={handleToggle} />
        ) : (
          <VisibilityIcon style={{ cursor: 'pointer' }} onClick={handleToggle} />
        )
      }
    />
  );
};

export default PasswordInput;
