import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const EXPRESS_LOAN_FORM_VALUES_FRAGMENT = gql`
  fragment expressLoanFormValuesFragment on ExpressLoanFormValues {
    construction_costs
    has_property
    estimated_property_price
    estimated_purchase_property_price
    renovation_costs
    purchase_price
    buying_with_agent
    monthly_net_income
    own_funds
    period
    interest_type
    fixed_interest_period
    outstanding_loan_amount
    penalty_payment_interest
  }
`;

export const EXPRESS_LOAN_FRAGMENT = gql`
  fragment expressLoanFragment on ExpressLoan {
    _id
    type
    form_values {
      ...expressLoanFormValuesFragment
    }
    total_project_costs
    loan_amount
    broker_commission
    notary_fee
    property_transfer_tax
    land_register_fee
    registration_fee
    execution_fee
    penalty_old_loan_payment
    expected_loan_rate
    individual_sk1_factor
    total_loan_amount
    monthly_credit_rate
  }
  ${EXPRESS_LOAN_FORM_VALUES_FRAGMENT}
`;

const EXPRESS_LOAN_QUERY = gql`
  query expressLoan($id: ID!) {
    expressLoan(id: $id) {
      ...expressLoanFragment
    }
  }
  ${EXPRESS_LOAN_FRAGMENT}
`;

const useExpressLoan = ({ id }) => {
  const { loading, error, data } = useQuery(EXPRESS_LOAN_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });
  return { loading, error, expressLoan: data?.expressLoan };
};

export default useExpressLoan;
