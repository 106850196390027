/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import React, { useCallback } from 'react';
import { connect } from 'formik';
import { ArrowBack, ArrowForward } from 'assets/icons';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { GUEST } from 'constants/constants';
import { useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import s from './EstimationReminderModal.module.scss';

const EstimationReminderModal = ({ isVisible, hideModal, isSubmitting, t, formik }) => {
  const { handleSubmit } = formik;
  const isGuest = useHasRequiredRole(GUEST);
  const history = useHistory();

  const onSubmit = useCallback(async () => {
    await handleSubmit();
    hideModal();
  }, [handleSubmit, hideModal]);

  const onSignUpClick = useCallback(async () => {
    await onSubmit();
    if (formik.isValid && !formik.isValidating) history.push(routePaths.guestSignUp);
  }, [formik, history, onSubmit]);

  return (
    <Modal
      size="sm"
      isVisible={isVisible}
      close={hideModal}
      headerText={t({ id: 'sprengnetter_estimation.modal_header_text' })}
    >
      <p>
        {isGuest
          ? t(
              { id: 'sprengnetter_estimation.reminder_for_guest' },
              {
                link_label: (
                  <button key="some_key" type="button" onClick={onSignUpClick} className={s.signUpLink}>
                    {t({ id: 'sprengnetter_estimation.reminder_for_guest_link_label' })}
                  </button>
                ),
              },
            )
          : t(
              { id: 'sprengnetter_estimation.reminder' },
              { buttonTitle: t({ id: 'sprengnetter_estimation.estimate_property_btn' }) },
            )}
      </p>
      <div className={s.buttonsWrapper}>
        <Button startIcon={<ArrowBack />} onClick={hideModal}>
          {t({ id: 'app.cancel' })}
        </Button>
        <Button
          endIcon={<ArrowForward />}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={onSubmit}
          type="submit"
        >
          {t({ id: 'app.proceed' })}
        </Button>
      </div>
    </Modal>
  );
};

export default connect(EstimationReminderModal);
