import React from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import s from './ExpressLoanSteps.module.scss';

const TaxesAndFeesTable = ({ expressLoan }) => {
  const { formatMessage: t } = useIntl();
  const {
    broker_commission,
    notary_fee,
    property_transfer_tax,
    land_register_fee,
    execution_fee,
    penalty_old_loan_payment,
    registration_fee,
  } = expressLoan;
  return (
    <table className="w-100">
      <tbody>
        {property_transfer_tax ? (
          <tr>
            <td>{t({ id: 'express_loan.table.property_transfer_tax' })}</td>
            <td>{formatCurrency(property_transfer_tax)}</td>
          </tr>
        ) : null}
        {land_register_fee ? (
          <tr>
            <td>{t({ id: 'express_loan.table.land_register_fee' })}</td>
            <td>{formatCurrency(land_register_fee)}</td>
          </tr>
        ) : null}
        {broker_commission ? (
          <tr>
            <td>{t({ id: 'express_loan.table.broker_commission' })}</td>
            <td>{formatCurrency(broker_commission)}</td>
          </tr>
        ) : null}
        {notary_fee ? (
          <tr>
            <td>{t({ id: 'express_loan.table.notary_fee' })}</td>
            <td>{formatCurrency(notary_fee)}</td>
          </tr>
        ) : null}
        {penalty_old_loan_payment ? (
          <tr>
            <td>{t({ id: 'express_loan.table.penalty_old_loan_payment' })}</td>
            <td>{formatCurrency(penalty_old_loan_payment)}</td>
          </tr>
        ) : null}
        <tr>
          <td className={s.minus}>{t({ id: 'express_loan.table.registration_fee' })}</td>
          <td>{formatCurrency(registration_fee)}</td>
        </tr>
        <tr>
          <td className={s.minus}>{t({ id: 'express_loan.table.execution_fee' })}</td>
          <td>{formatCurrency(execution_fee)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TaxesAndFeesTable;
