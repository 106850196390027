import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const LAST_PROJECT_ID_QUERY = gql`
  query lastProject {
    lastProject {
      _id
    }
  }
`;

const useLastProjectId = () => {
  const { data, loading } = useQuery(LAST_PROJECT_ID_QUERY);
  const lastProjectId = get(data, 'lastProject._id', null);

  return { lastProjectId, loading };
};

export default useLastProjectId;
