import { ArrowBack } from 'assets/icons';
import Button from 'components/common/Button';
import Modal, { useModal } from 'components/common/Modal';
import PersonalFinancingRequest from 'components/loan/PersonalFinancingRequest';
import useSearchParams from 'hooks/useSearchParams';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { ReactComponent as WaveImg } from 'assets/images/wave.svg';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import ExpressLoanPersonalOfferForm from './ExpressLoanPersonalOfferForm';
import s from './ExpressLoanSteps.module.scss';

const validFormTypes = ['advisory', 'offer'];

const BackButton = ({ onClick }) => {
  const { formatMessage: t } = useIntl();
  return (
    <Button startIcon={<ArrowBack />} color="outline" onClick={onClick}>
      {t({ id: 'app.back' })}
    </Button>
  );
};

const FinalModalContent = () => {
  const { formatMessage: t } = useIntl();
  return (
    <div className={s.finalModal}>
      <div className={s.imgWrapper}>
        <img src={require('assets/images/express_loan.png')?.default} alt="Express loan final" />
        <p className={s.title}>{t({ id: 'express_loan.final_modal.title' })}</p>
        <WaveImg className={s.wave} />
      </div>
      <p className={s.message}>{t({ id: 'express_loan.final_modal.message' })}</p>
      <Link to={routePaths.expressLoanQuickStart}>
        <Button startIcon={<ArrowBack />} color="outline" className="mt-4">
          {t({ id: 'express_loan.final_modal.backToHomeButton' })}
        </Button>
      </Link>
    </div>
  );
};

const ExpressLoanThirdStep = ({ previousStep, onBack }) => {
  const searchParams = useSearchParams();
  const { formatMessage: t } = useIntl();
  const formType = searchParams.get('formType');
  const { showModal, isVisible, hideModal } = useModal();

  const isValidFormType = useMemo(() => validFormTypes.includes(formType), [formType]);

  if (!isValidFormType) {
    previousStep();
    return null;
  }

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col xl={8}>
        <>
          {formType === 'advisory' ? (
            <PersonalFinancingRequest
              buttonLabelId="app.submit"
              backButton={<BackButton onClick={() => onBack(previousStep)} />}
              formType="expressLoan"
              afterSubmit={showModal}
            />
          ) : (
            <ExpressLoanPersonalOfferForm backButton={<BackButton onClick={() => onBack(previousStep)} />} />
          )}
        </>
        <Modal
          size="xl"
          isVisible={isVisible}
          close={hideModal}
          headerText={t({ id: 'express_loan.final_modal.header_title' })}
        >
          <FinalModalContent />
        </Modal>
      </Col>
    </Row>
  );
};

export default ExpressLoanThirdStep;
