import React from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from '../../utils/helpers';

const AffordableLoanInfo = ({ myBudget }) => {
  const { formatMessage: t } = useIntl();
  const { monthlyRateLoan, amountOfLoan } = myBudget;
  return (
    <table className="table last-col-text-right">
      <thead>
        <tr>
          <td colSpan="2">
            <h4>{t({ id: 'budget.titles.affordable_loan' })}</h4>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t({ id: 'budget.titles.monthly_repayments' })}</td>
          <td>{monthlyRateLoan > 0 ? formatCurrency(monthlyRateLoan) : 0}</td>
        </tr>
        <tr>
          <td>{t({ id: 'budget.titles.total_loan_amount' })}</td>
          <td>{amountOfLoan > 0 ? formatCurrency(amountOfLoan) : 0}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default AffordableLoanInfo;
