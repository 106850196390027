import React from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import useProjectPlan from 'hooks/project-plan/useProjectPlan';
import LoadingOverlay from 'components/common/LoadingOverlay';
import PlanForm from 'components/plan-form/PlanForm';
import isNil from 'lodash/isNil';
import Alert from 'components/common/Alert';
import { FormattedMessage } from 'react-intl';

function getInitialValuesFromProjectPlan(categories) {
  return categories.reduce((acc, { name, user_price, calculated_price, subitems }) => {
    acc[name] = !isNil(user_price) ? user_price : calculated_price;
    subitems.forEach(({ name: subItemName, equipment_type: subItemEquipmentType, user_price: subItemUserPrice }) => {
      acc[subItemName] = !isNil(subItemUserPrice) ? subItemUserPrice : subItemEquipmentType;
    });
    return acc;
  }, {});
}

const Plan = () => {
  const params = useParams();
  const [plan, loading] = useProjectPlan(params.id);
  const categories = get(plan, 'projectPlan.planningCategories', []);
  const initialValues = getInitialValuesFromProjectPlan(categories);

  const filteredCategories = categories.filter((c) => c.calculated_price);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (filteredCategories.length === 0) {
    return (
      <Alert color="warning" withContainer>
        <FormattedMessage id="planning_categories.no_planning_categories" />
      </Alert>
    );
  }

  return <PlanForm projectId={params.id} categories={filteredCategories} initialValues={initialValues} />;
};

export default Plan;
