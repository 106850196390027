import gql from 'graphql-tag';
import { CURRENT_USER_FRAGMENT } from 'hooks/user/useUser';
import useAuth from './useAuth';

export const SIGN_UP_MUTATION = gql`
  mutation createUser($email: String!, $plainPassword: String!, $profile: CreateUserProfileInput!) {
    createUser(email: $email, plainPassword: $plainPassword, profile: $profile) {
      id
      refreshToken
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useSignUp = () => {
  const [signUp] = useAuth(SIGN_UP_MUTATION);
  return signUp;
};

export default useSignUp;
