/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatCurrency } from '../../utils/helpers';

const NegativeMonthlyRateBudgetStep = ({ negativeMonthlyRateLoan }) => {
  return (
    <div className="shadow-box">
      <div>
        <p>
          <FormattedMessage id="budget_calculator.loan_impossible_description" />
        </p>
        <p>
          <FormattedMessage
            id="budget_calculator.missing_monthly_value"
            values={{ value: <b>{formatCurrency(Math.abs(negativeMonthlyRateLoan))}</b> }}
          />
        </p>
      </div>
    </div>
  );
};

export default NegativeMonthlyRateBudgetStep;
