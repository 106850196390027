import React, { useState } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import get from 'lodash/get';
import useUser from 'hooks/user/useUser';
import { User, Message, ArrowBarRight } from 'assets/icons/sidebar';
import Button from 'components/common/Button';
import { Link } from 'react-router-dom';
import 'rc-dropdown/assets/index.css';
import routePaths from 'router/route-paths';
import { useHandleDeleteMyAccount } from 'pages/Profile';
import { FormattedMessage, useIntl } from 'react-intl';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { GUEST } from 'constants/constants';
import s from './Dropdown.module.scss';

const DropdownMenu = ({ email, profile: { firstName, lastName }, onDeleteAccount, onLogout, setVisible }) => {
  const isGuest = useHasRequiredRole(GUEST);
  return (
    <Menu className={s.menu}>
      <MenuItem key="1" className={s.img}>
        <User width="100%" height="100%" />
      </MenuItem>
      <MenuItem key="2" className={s.name}>{`${firstName} ${lastName}`}</MenuItem>
      {!isGuest ? (
        <MenuItem key="3" className={s.email}>
          {email}
        </MenuItem>
      ) : null}
      <MenuItem key="4" className={s.btn} onClick={() => setVisible(false)}>
        <Link to={routePaths.contact}>
          <Button startIcon={<Message />}>
            <FormattedMessage id="page_titles.contact" />
          </Button>
        </Link>
      </MenuItem>
      <MenuItem key="5" className={s.logoutBtn}>
        <Link to={routePaths.logout}>
          <Button startIcon={<ArrowBarRight />} onClick={onLogout}>
            <FormattedMessage id="page_titles.logout" />
          </Button>
        </Link>
      </MenuItem>
      <hr className={s.divider} />
      <MenuItem key="6" className={s.btnDeleteAccount} onClick={() => setVisible(false)}>
        <Button onClick={onDeleteAccount}>
          <FormattedHTMLMessage id="profile.delete_my_account.title" />
        </Button>
      </MenuItem>
    </Menu>
  );
};

const DropdownContent = () => {
  const [me] = useUser();
  const profile = get(me, 'profile', {});
  const email = get(me, 'emails[0].address', null);
  const deleteMyAccount = useHandleDeleteMyAccount();
  const [visible, setVisible] = useState(false);
  const { formatMessage: t } = useIntl();

  return (
    <Dropdown
      trigger={['click']}
      overlay={<DropdownMenu {...{ email, profile, setVisible }} onDeleteAccount={deleteMyAccount} />}
      animation="slide-up"
      closeOnSelect={false}
      onVisibleChange={setVisible}
      visible={visible}
    >
      <div className={s.triggeredContent}>
        <User width="100%" height="100%" title={t({ id: 'alt_text.navbar.dropdown_user_icon' })} />
      </div>
    </Dropdown>
  );
};

export default DropdownContent;
