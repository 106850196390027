import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';
import { PROJECT_PLAN_QUERY } from '../project-plan/useProjectPlan';

const UPDATE_PROJECT_BUYING_HOUSE_MUTATION = gql`
  mutation updateProjectBuyingHouse($projectId: ID!, $formData: UpdateProjectBuyingHouseInput!) {
    updateProjectBuyingHouse(projectId: $projectId, formData: $formData) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useUpdateProjectBuyingHouseMutation = () => {
  const [updateProjectBuyingHouseMutation] = useMutation(UPDATE_PROJECT_BUYING_HOUSE_MUTATION);

  const mutate = useCallback(
    async (projectId, formData) => {
      try {
        const {
          data: { updateProjectBuyingHouse: result },
        } = await updateProjectBuyingHouseMutation({
          variables: { projectId, formData: { ...formData } },
          refetchQueries: [{ query: PROJECT_PLAN_QUERY, variables: { projectId } }],
          awaitRefetchQueries: true,
        });

        return result;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        throw e;
      }
    },
    [updateProjectBuyingHouseMutation],
  );
  return mutate;
};

export default useUpdateProjectBuyingHouseMutation;
