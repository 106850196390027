/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import { toast } from 'react-toastify';
import ContentLoader from 'components/common/ContentLoader';
import debounce from 'lodash/debounce';
import NumberInput from 'components/inputs/NumberInput';
import SubItem from 'components/plan-form/SubItem';
import FormikEffect from 'components/common/FormikEffect';
import useSetPlanCategoryQuote from 'hooks/project-plan/useSetPlanCategoryQuote';
import { Visible, Hidden } from 'components/common/Grid';
import isNil from 'lodash/isNil';
import ToggleButtons from './ToggleButtons';
import styles from './Category.module.scss';

const emptyFunc = () => {};
const useSetPlanCategoryQuoteHandler = (delay = 0, setLoading = emptyFunc) => {
  const [setPlanCategoryQuote, loading] = useSetPlanCategoryQuote();

  const handleChange = useCallback(
    async (variables) => {
      try {
        setLoading(true);
        await setPlanCategoryQuote(variables);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [setLoading, setPlanCategoryQuote],
  );
  const handleChangeDebounced = useCallback(debounce(handleChange, delay), [setPlanCategoryQuote]);
  return [delay === 0 ? handleChange : handleChangeDebounced, loading];
};

const Category = ({ label, description, cardRef, calculated_price, user_price, subitems, name, initialValues }) => {
  const { formatMessage: __ } = useIntl();
  const [isCalculated, setCalculated] = useState(isNil(user_price));
  const [isMutationExecuting, setMutationExecuting] = useState(false);
  const params = useParams();

  const [handleChange] = useSetPlanCategoryQuoteHandler(1000, setMutationExecuting);
  const [handleToggle, loading] = useSetPlanCategoryQuoteHandler();

  const handleBlur = useCallback(
    (setFieldValue, values) => {
      if (!Number.isFinite(values[name])) {
        setFieldValue(name, calculated_price);
      }
    },
    [calculated_price, name],
  );

  return (
    <div className={styles.category} ref={cardRef}>
      <Formik initialValues={initialValues}>
        {({ setFieldValue, values }) => (
          <Form>
            <div className="card">
              <div>
                <div>
                  <div className={styles.header}>
                    <h2 className={styles.title}>{__({ id: label })}</h2>
                    <h2 className={styles.price}>
                      {formatCurrency(!isNil(user_price) ? user_price : calculated_price)}
                    </h2>
                  </div>
                  {description ? (
                    <p>
                      <small className="muted">{__({ id: description, defaultMessage: ' ' })}</small>
                    </p>
                  ) : null}
                </div>
                <div>
                  <ToggleButtons
                    disabled={loading || isMutationExecuting}
                    isCalculated={isCalculated}
                    onCalculatedClick={async () => {
                      await handleToggle({
                        projectId: params.id,
                        categoryName: name,
                        value: null,
                      });
                      setCalculated(true);
                    }}
                    onQuotedClick={async () => {
                      setFieldValue(name, calculated_price);
                      await handleToggle({
                        projectId: params.id,
                        categoryName: name,
                        value: calculated_price,
                      });
                      setCalculated(false);
                    }}
                  />
                </div>
              </div>
              {!isCalculated && !loading && (
                <div>
                  <Field
                    name={name}
                    min={0}
                    max={3000000}
                    component={NumberInput}
                    onBlur={() => handleBlur(setFieldValue, values)}
                    required
                    label={__({ id: 'planning.override_cost' })}
                  />
                  <FormikEffect
                    onEffect={async () => {
                      setMutationExecuting(true);
                      await handleChange({
                        projectId: params.id,
                        categoryName: name,
                        value: values[name],
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {!isCalculated && loading && (
        <div style={{ overflow: 'hidden' }}>
          <Hidden xs>
            <ContentLoader type="primary" />
            <ContentLoader type="primary" />
          </Hidden>
          <Visible xs>
            <ContentLoader type="small" />
            <ContentLoader type="small" />
          </Visible>
        </div>
      )}
      {isCalculated && loading && (
        <div style={{ overflow: 'hidden' }}>
          <Hidden xs>
            <ContentLoader type="primary" />
          </Hidden>
          <Visible xs>
            <ContentLoader type="small" />
          </Visible>
        </div>
      )}
      {isCalculated && !loading && (
        <div>
          {subitems.map((subitem) => (
            <SubItem key={subitem.name} initialValues={initialValues} categoryName={name} {...subitem} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Category;
