import { __ } from 'utils/form';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  full_address: yup
    .string()
    .required(__('errors.full_address'))
    .typeError(__('errors.full_address')),
  zip: yup
    .string()
    .matches(/^[1-9]{1}[0-9]{3}$/, { message: __('errors.wrong_zip_code') })
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  construction_year: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field'))
    .min(1700, __('errors.values_within_range', { min: 1700, max: new Date().getFullYear() }))
    .max(new Date().getFullYear(), __('errors.values_within_range', { min: 1700, max: new Date().getFullYear() })),
  land_area: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field'))
    .min(200, __('errors.values_within_range', { min: 200, max: 3000 }))
    .max(3000, __('errors.values_within_range', { min: 200, max: 3000 })),
  house_area: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field'))
    .min(25, __('errors.values_within_range', { min: 25, max: 300 }))
    .max(300, __('errors.values_within_range', { min: 25, max: 300 })),
  house_category: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  parking: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  basement_type: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  heating_system: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

export default validationSchema;
