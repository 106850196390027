import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import { Visible } from 'components/common/Grid';
import { useModal } from 'components/common/Modal';
import ScrollToError from 'components/common/ScrollToError';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import { NEW_BUILDING } from 'constants/constants';
import { Field, Form, Formik } from 'formik';
import useFormValuesRef from 'hooks/useFormValuesRef';
import { pick } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import LocationSearchInput from '../GoogleMap/LocationSearchInput';
import MapContainer from '../GoogleMap/MapContainer';
import EstimationReminderModal from '../SprengnetterEstimationTab/EstimationReminderModal';
import SprengnetterLandEstimation from '../SprengnetterEstimationTab/SprengnetterLandEstimation';
import LandValuationTypeModal, { useChangeProjectTypeHandler } from './LandValuationTypeModal';

const validationSchema = yup.object().shape({
  full_address: yup
    .string()
    .required(<FormattedMessage id="errors.full_address" />)
    .typeError(<FormattedMessage id="errors.full_address" />),
  land_area: yup
    .number()
    .required(<FormattedMessage id="errors.fill_field" />)
    .typeError(<FormattedMessage id="errors.fill_field" />)
    .min(200, <FormattedMessage id="errors.values_within_range" values={{ min: 200, max: 3000 }} />)
    .max(3000, <FormattedMessage id="errors.values_within_range" values={{ min: 200, max: 3000 }} />),
});

const whiteListFormValues = [
  'form_values.land_area',
  'form_values.full_address',
  'form_values.coordinates.lat',
  'form_values.coordinates.lng',
];

const EstimationReminder = ({ estimationRequestCompleted, isSubmitting, t }) => {
  const { showModal, hideModal, isVisible } = useModal();

  return (
    <>
      <EstimationReminderModal {...{ isVisible, hideModal, isSubmitting, t }} />

      <div className="text-right">
        <Button
          {...(!estimationRequestCompleted
            ? { onClick: showModal, loading: false, disabled: false, type: 'button' }
            : { loading: isSubmitting, disabled: isSubmitting, type: 'submit' })}
          endIcon={<ArrowForward />}
        >
          {t({ id: 'app.submit_form_next' })}
        </Button>
      </div>
    </>
  );
};

const LandValuationForm = ({
  project,
  calculations,
  requestsCount,
  loading,
  onSubmit,
  isGuest,
  estimationRequestCompleted,
}) => {
  const { formatMessage: t } = useIntl();
  const { form_values } = pick(project, whiteListFormValues);
  const initialValues = { ...form_values };
  const formikRef = useRef();
  const { showModal, hideModal, isVisible } = useModal();
  const [onChangeProjectType] = useChangeProjectTypeHandler(project._id);

  const onSprengnetterRequest = useCallback(async () => {
    await onSubmit(formikRef.current.values, { setSubmitting: formikRef.current.setSubmitting }, false);
  }, [onSubmit]);

  const formValuesRef = useFormValuesRef([], { formValueMock: {}, initialValues });
  const onSubmitStep = useCallback(
    (values) => {
      formValuesRef.current = {
        ...formValuesRef.current,
        ...values,
      };
    },
    [formValuesRef],
  );

  const onSubmitFormModal = useCallback(
    async (args, actions) => {
      const { type, ...rest } = args;
      await onChangeProjectType({ type }, actions);
      await onSubmit(rest, actions);
      window.scrollTo(0, 0);
    },
    [onChangeProjectType, onSubmit],
  );

  return (
    <Formik
      {...{ initialValues: formValuesRef.current, validationSchema, innerRef: formikRef }}
      onSubmit={(args, { setSubmitting }) => {
        onSubmitStep(args);
        showModal();
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting }) => (
        <Row center="md" between="lg">
          <Col xs={12} md={9} lg={6}>
            <Form>
              <ScrollToError />
              <Field
                name="full_address"
                latLngName="coordinates"
                component={LocationSearchInput}
                label={t({ id: 'project_wizard.full_address' })}
                projectType={NEW_BUILDING}
              />
              {values.coordinates ? (
                <div className="my-4">
                  <MapContainer position={values.coordinates} />
                </div>
              ) : null}
              <Field
                name="land_area"
                component={SliderWithTooltip}
                min={200}
                step={50}
                max={3000}
                units="m²"
                label={t({ id: 'project_wizard.land_area' })}
              />

              <Visible xs sm md>
                <div className="my-4">
                  <SprengnetterLandEstimation
                    {...{
                      values,
                      requestsCount,
                      loading,
                      projectType: NEW_BUILDING,
                      calculations,
                      sideEffectOnRequest: onSprengnetterRequest,
                    }}
                  />
                </div>
              </Visible>
              <LandValuationTypeModal {...{ hideModal, isVisible, t, formValuesRef, onSubmit: onSubmitFormModal }} />
              <EstimationReminder {...{ isGuest, estimationRequestCompleted, isSubmitting, t }} />
            </Form>
          </Col>
          <Col xs={0} sm={0} md={0} lg={6}>
            <div className="my-4">
              <SprengnetterLandEstimation
                {...{
                  values,
                  requestsCount,
                  loading,
                  projectType: NEW_BUILDING,
                  calculations,
                  sideEffectOnRequest: onSprengnetterRequest,
                }}
              />
            </div>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default LandValuationForm;
