import React from 'react';
import { Form, Field } from 'formik';
import { Row, Col } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import ScrollToError from 'components/common/ScrollToError';
import NumberInput from 'components/inputs/NumberInput';
import { BUYING_HOUSE } from 'constants/constants';
import LocationSearchInput from 'components/calculators/GoogleMap/LocationSearchInput';
import MapContainer from 'components/calculators/GoogleMap/MapContainer';
import TextInput from 'components/inputs/TextInput';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import ButtonSelect from 'components/inputs/ButtonSelect';
import { ReactComponent as DistrictHeatingStandard } from 'assets/icons/co2-calculator/heating-system/district-heating-standard.svg';
import { ReactComponent as NaturalGas } from 'assets/icons/co2-calculator/heating-system/natural-gas.svg';
import { ReactComponent as Electric } from 'assets/icons/co2-calculator/heating-system/electric.svg';
import { ReactComponent as Oil } from 'assets/icons/co2-calculator/heating-system/oil.svg';
import { ReactComponent as Coal } from 'assets/icons/co2-calculator/heating-system/coal.svg';
import { ReactComponent as HeatingPump } from 'assets/icons/co2-calculator/heating-system/heating-pump.svg';
import { ReactComponent as WoodPellets } from 'assets/icons/co2-calculator/heating-system/wood-pellets.svg';
import { Visible } from 'components/common/Grid';
import Switch from 'components/inputs/Switch';
import EstimationReminderModal from 'components/calculators/SprengnetterEstimationTab/EstimationReminderModal';
import SprengnetterEstimation from 'components/calculators/SprengnetterEstimationTab/SprengnetterEstimation';
import SprengnetterLandEstimation from 'components/calculators/SprengnetterEstimationTab/SprengnetterLandEstimation';

const SprengnetterEstimationSection = ({
  projectPrice,
  values,
  calculations,
  requestsCount,
  loading,
  showLandValuation,
  sideEffectOnSprengnetterRequest,
}) => (
  <section>
    <div className="mb-4">
      <SprengnetterEstimation
        {...{ projectPrice, values, calculations, requestsCount, loading }}
        type={BUYING_HOUSE}
        sideEffectOnRequest={sideEffectOnSprengnetterRequest}
      />
    </div>
    {showLandValuation ? (
      <SprengnetterLandEstimation
        {...{ values, requestsCount, loading, calculations, projectType: BUYING_HOUSE }}
        sideEffectOnRequest={sideEffectOnSprengnetterRequest}
      />
    ) : null}
  </section>
);

const BuyingHouseEstimationForm = ({
  values,
  calculations,
  projectPrice,
  requestsCount,
  loading,
  isSubmitting,
  isVisible,
  hideModal,
  hasAdditionalFeatures,
  buttons,
  sideEffectOnSprengnetterRequest,
  showLandValuation = true,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Row between="lg" center="md">
      <Col md={9} lg={6}>
        <Form>
          <ScrollToError />
          <Field
            name="construction_year"
            component={NumberInput}
            label={t({ id: 'buying_house_wizard.construction_year' })}
            thousandSeparator=""
            format="####"
          />
          <Field
            name="full_address"
            latLngName="coordinates"
            projectType={BUYING_HOUSE}
            component={LocationSearchInput}
            label={t({ id: 'buying_house_wizard.full_address' })}
          />

          {values.coordinates ? (
            <div className="my-4">
              <MapContainer position={values.coordinates} />
            </div>
          ) : null}
          <Field name="zip" component={TextInput} label={t({ id: 'project_wizard.zip_code' })} />

          <Field
            name="land_area"
            component={SliderWithTooltip}
            min={200}
            step={50}
            max={3000}
            units="m²"
            label={t({ id: 'buying_house_wizard.land_area' })}
          />
          <Field
            name="house_area"
            component={SliderWithTooltip}
            step={5}
            min={25}
            max={300}
            units="m²"
            label={t({ id: 'buying_house_wizard.house_area' })}
          />
          <Field
            name="house_category"
            component={ButtonSelect}
            options={[
              {
                label: t({ id: 'buying_house_wizard.category.detached_house' }),
                value: 'detached_house',
              },
              {
                label: t({ id: 'buying_house_wizard.category.townhouse' }),
                value: 'townhouse',
              },
              {
                label: t({ id: 'buying_house_wizard.category.semi_detached_house' }),
                value: 'semi_detached_house',
              },
            ]}
            label={t({ id: 'buying_house_wizard.category.name' })}
          />
          <Field
            name="basement_type"
            component={ButtonSelect}
            options={[
              {
                label: t({ id: 'buying_house_wizard.basement.full_basement' }),
                value: 'full_basement',
              },
              {
                label: t({ id: 'buying_house_wizard.basement.partially_basement' }),
                value: 'partially_basement',
              },
              {
                label: t({ id: 'buying_house_wizard.basement.no_basement' }),
                value: 'no_basement',
              },
            ]}
            label={t({ id: 'buying_house_wizard.basement.name' })}
          />
          <Field
            name="parking"
            component={ButtonSelect}
            options={[
              {
                label: t({ id: 'buying_house_wizard.parking.garage' }),
                value: 'garage',
              },
              {
                label: t({ id: 'buying_house_wizard.parking.carport' }),
                value: 'carport',
              },
              {
                label: t({ id: 'buying_house_wizard.parking.none' }),
                value: 'none',
              },
              {
                label: t({ id: 'buying_house_wizard.parking.outdoor_parking_space' }),
                value: 'outdoor_parking_space',
              },
            ]}
            label={t({ id: 'buying_house_wizard.parking.name' })}
          />

          <Field
            name="heating_system"
            component={ButtonSelect}
            inOneRow={false}
            options={[
              {
                topIcon: ({ altText }) => <DistrictHeatingStandard className="fill" title={altText} />,
                label: t({ id: 'buying_house_wizard.heating_system.district' }),
                value: 'district',
              },
              {
                topIcon: ({ altText }) => <NaturalGas className="fill" title={altText} />,
                label: t({ id: 'buying_house_wizard.heating_system.gas' }),
                value: 'gas',
              },
              {
                topIcon: ({ altText }) => <Oil className="fill" title={altText} />,
                label: t({ id: 'buying_house_wizard.heating_system.oil' }),
                value: 'oil',
              },
              {
                topIcon: ({ altText }) => <WoodPellets className="fill" title={altText} />,
                label: t({ id: 'buying_house_wizard.heating_system.pellet' }),
                value: 'pellet',
              },
              {
                topIcon: ({ altText }) => <HeatingPump className="fill" title={altText} />,
                label: t({ id: 'buying_house_wizard.heating_system.heat_pump' }),
                value: 'heat_pump',
              },
              {
                topIcon: ({ altText }) => <Coal className="fill" title={altText} />,
                label: t({ id: 'buying_house_wizard.heating_system.wood_or_coal' }),
                value: 'wood_or_coal',
              },
              {
                topIcon: ({ altText }) => <Electric className="fill" title={altText} />,
                label: t({ id: 'buying_house_wizard.heating_system.electric' }),
                value: 'electric',
              },
            ]}
            label={t({ id: 'buying_house_wizard.heating_system.name' })}
          />
          <Visible xs sm md>
            <div className="my-4">
              <SprengnetterEstimationSection
                {...{
                  projectPrice,
                  values,
                  calculations,
                  requestsCount,
                  loading,
                  projectType: BUYING_HOUSE,
                  showLandValuation,
                  sideEffectOnSprengnetterRequest,
                }}
              />
            </div>
          </Visible>

          <EstimationReminderModal {...{ isVisible, hideModal, isSubmitting, t }} />

          {!hasAdditionalFeatures ? (
            <>
              <h2>{t({ id: 'buying_house_wizard.renovations_or_additional_investments' })}</h2>
              <Field
                name="additional_features"
                component={Switch}
                label={t({ id: 'project_wizard.has_additional_features' })}
              />
            </>
          ) : null}

          {buttons}
        </Form>
      </Col>
      <Col xs={0} sm={0} md={0} lg={6}>
        <div className="sticky-img-box">
          <SprengnetterEstimationSection
            {...{
              projectPrice,
              values,
              calculations,
              requestsCount,
              loading,
              projectType: BUYING_HOUSE,
              showLandValuation,
              sideEffectOnSprengnetterRequest,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default BuyingHouseEstimationForm;
