/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import BuyingHouseForm from 'components/calculators/BuyingHouseForm';
import buyingHouseFeaturesSchema from 'constants/wizards/buying-house-features-schema';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import useUpdateProjectBuyingHouseMutation from 'hooks/project-details/useUpdateProjectBuyingHouseMutation';
import { intersection, isEmpty } from 'lodash';

const defaultFeatures = ['cost_house', 'buying_house_taxes_and_fees'];

const handleSpecificCategories = (_formData) => {
  const formData = cloneDeep(_formData);
  delete formData.additional_features;
  delete formData.type;

  if (!formData.features.includes('other_investments')) {
    formData.other_investments_amount = null;
    formData.other_investments_comment = '';
  }

  if (formData.features.includes('buying_house_new_windows')) {
    if (!Number.isFinite(formData.number_of_extra_large_windows)) {
      formData.number_of_extra_large_windows = 0;
    }
  }

  return formData;
};

const useSubmitBuyingHouseDetailsCalculator = (projectId) => {
  const updateProjectBuyingHouseMutation = useUpdateProjectBuyingHouseMutation();
  const onSubmit = React.useCallback(
    async ({ values, formikActions = {}, changeStep = true, isEditMode, currentStep, nextStep, lastStep }) => {
      const { setSubmitting } = formikActions;
      setSubmitting(true);
      // eslint-disable-next-line no-param-reassign
      const features = Object.entries(values.features).reduce((arr, [feature, isEnabled]) => {
        if (isEnabled) arr.push(feature);
        return arr;
      }, []);

      const formData = cloneDeep(values);
      formData.features = uniq([...defaultFeatures, ...features]);

      const step = isEditMode ? null : changeStep ? currentStep + 1 : currentStep;
      formData.step = step;

      const _formData = handleSpecificCategories(formData);

      try {
        await updateProjectBuyingHouseMutation(projectId, _formData);
        if (changeStep && isEditMode && typeof lastStep === 'function') {
          lastStep();
        } else if (changeStep && typeof nextStep === 'function') {
          nextStep();
        }
        if (changeStep) window.scrollTo(0, 0);
      } catch (err) {
        const error = err.raw?.networkError ? <FormattedMessage id="errors.no_server_response" /> : err.message;
        toast.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [projectId, updateProjectBuyingHouseMutation],
  );

  return onSubmit;
};

const whitelistedProps = [
  'name',
  'zip',
  'country',
  'form_values.full_address',
  'form_values.coordinates.lat',
  'form_values.coordinates.lng',
  'form_values.number_of_floors',
  'form_values.house_type',
  'form_values.house_category',
  'form_values.renovation_outer_wall',
  'form_values.year_of_outer_wall_renovation',
  'form_values.roof_renewed',
  'form_values.year_of_roof_renewal',
  'form_values.condition_of_house',
  'form_values.number_of_toilets',
  'form_values.number_of_bathrooms',
  'form_values.year_of_house_renovation',
  'form_values.land_area',
  'form_values.house_area',
  'form_values.condition_of_house',
  'form_values.construction_year',
  'form_values.basement_type',
  'form_values.parking',
  'form_values.heating_system',
  'form_values.cost_house',
  'form_values.property_transfer_tax',
  'form_values.land_register_fee',
  'form_values.notary_fee',
  'form_values.broker_commission',
  'form_values.number_of_car_ports',
  'form_values.number_of_garage_places',
  'form_values.solar_power_size_kwp',
  'form_values.solar_power_equipment_type',

  'form_values.other_investments_amount',
  'form_values.other_investments_comment',

  ...buyingHouseFeaturesSchema.flatMap((feature) =>
    Array.isArray(feature.form) ? feature.form.map(({ name }) => `form_values.${name}`) : [],
  ),
];

const BuyingHouseDetailsCalculator = ({ project }) => {
  const onSubmit = useSubmitBuyingHouseDetailsCalculator(project._id);

  const initialValues = useMemo(() => {
    const restFeatures = {};
    if (project.progress.step !== null) {
      restFeatures.reserves_for_unexpected_costs = true;
      restFeatures.energy_certificate = true;
    }
    restFeatures.other_investments = !!project.form_values?.other_investments_amount;

    const { form_values, ...whitelisted } = pick(project, whitelistedProps);
    const features = { ...project.features.reduce((acc, name) => ({ ...acc, [name]: true }), {}), ...restFeatures };
    return { ...whitelisted, ...form_values, features };
  }, [project]);

  const renovationFeatures = buyingHouseFeaturesSchema.map((feature) => feature.name);
  const autoSelectedFeatures = ['reserves_for_unexpected_costs', 'energy_certificate'];
  const enabledFeatures = Object.keys(initialValues.features).filter(
    (key) => !autoSelectedFeatures.includes(key) && initialValues.features[key],
  );

  const hasAdditionalFeatures = !isEmpty(intersection(renovationFeatures, enabledFeatures));
  initialValues.additional_features = !hasAdditionalFeatures;

  return (
    <BuyingHouseForm
      {...{ onSubmit, initialValues, hasAdditionalFeatures }}
      projectPrice={project.price}
      isEditMode={project.progress.step === null}
      step={project.progress.step}
      projectType={project.type}
    />
  );
};

export default BuyingHouseDetailsCalculator;
