/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import { primaryColor } from 'styles/variables.scss';
import { useAppContext } from 'contexts/AppContext';
import Loading from './Loading';
import s from './IconButton.module.scss';

const buttonThemes = {
  primary: {
    outline: s.outline,
    primary: s.primary,
  },
  green: {
    outline: s.outlineSuccess,
    primary: s.primarySuccess,
  },
};

export default function IconButton({
  children,
  className,
  color = 'outline',
  disabled,
  loading,
  type = 'button',
  onClick,
  ...rest
}) {
  const { theme } = useAppContext();

  return (
    <button
      className={cn(s.iconButton, buttonThemes[theme][color], className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <div style={loading ? { visibility: 'hidden' } : {}} className={s.btnContent}>
        {children}
      </div>
      {loading ? (
        <div className={s.btnLoading}>
          <Loading size={25} color={primaryColor} />
        </div>
      ) : null}
    </button>
  );
}
