import React from 'react';
import classnames from 'classnames';

export default function ButtonLink({ onClick, children, className, ...rest }) {
  return (
    <button type="button" className={classnames('link', className)} onClick={onClick} {...rest}>
      {children}
    </button>
  );
}
