/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toastErrorMessage } from 'utils/helpers';
import useSearchParams from 'hooks/useSearchParams';

const CALCULATE_LOAN_MUTATION = gql`
  mutation calculateExpressLoan($id: ID, $formData: ExpressLoanInput!) {
    calculateExpressLoan(id: $id, formData: $formData) {
      _id
      type
    }
  }
`;

const useCalculateExpressLoan = () => {
  const [calculateExpressLoanMutation] = useMutation(CALCULATE_LOAN_MUTATION);
  const searchParams = useSearchParams();

  const calculateExpressLoan = useCallback(
    async ({ formData }) => {
      try {
        const res = await calculateExpressLoanMutation({ variables: { id: searchParams.get('id'), formData } });
        return res?.data?.calculateExpressLoan;
      } catch (error) {
        toastErrorMessage(error);
      }
    },
    [calculateExpressLoanMutation, searchParams],
  );

  return calculateExpressLoan;
};

export default useCalculateExpressLoan;
