import React from 'react';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import Button from 'components/common/Button';
import { useIntl, FormattedMessage } from 'react-intl';
import useResetPassword from 'hooks/auth/useResetPassword';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { toast } from 'react-toastify';
import routePaths from 'router/route-paths';
import { QuickCalculatorStep3 } from 'assets/icons/QuickCalculator';
import { ArrowForward } from 'assets/icons';
import PageTitle from 'components/common/PageTitle';
import PasswordInput from 'components/inputs/PasswordInput';

const resetPasswordShape = {
  password: yup
    .string()
    .required(<FormattedMessage id="errors.password_required" />)
    .min(8, <FormattedMessage id="errors.password_too_small" />),
  repeatedPassword: yup
    .string()
    .required(<FormattedMessage id="errors.passwords_must_match" />)
    .oneOf([yup.ref('password'), null], <FormattedMessage id="errors.passwords_must_match" />),
};

const useResetPasswordSubmitHandler = (token) => {
  const resetPassword = useResetPassword();
  const history = useHistory();

  const handleSubmit = React.useCallback(
    async ({ password }, { resetForm }) => {
      try {
        await resetPassword({ token, password });
        history.replace(routePaths.myProjects);
      } catch (error) {
        toast.error(error.message);
      } finally {
        resetForm();
      }
    },
    [history, resetPassword, token],
  );

  return handleSubmit;
};

const resetPasswordSchema = yup.object().shape(resetPasswordShape);

const initialValues = { password: '', repeatedPassword: '' };

const ResetPassword = () => {
  const { formatMessage: t } = useIntl();
  const params = useParams();

  const onSubmit = useResetPasswordSubmitHandler(params.token);

  return (
    <Grid className="mt-8">
      <Row between="lg" center="md">
        <Col xs={0} sm={0} md={0} lg={6} xl={5}>
          <QuickCalculatorStep3 width="100%" title={t({ id: 'alt_text.auth_pages.reset_password' })} />
        </Col>
        <Col md={9} lg={6} xl={5}>
          <Formik initialValues={initialValues} validationSchema={resetPasswordSchema} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <>
                <PageTitle auth>{t({ id: 'page_titles.reset_password' })}</PageTitle>
                <Form>
                  <Field
                    label={t({ id: 'auth.new_password' })}
                    type="password"
                    name="password"
                    component={PasswordInput}
                  />
                  <Field
                    label={t({ id: 'auth.repeat_new_password' })}
                    type="password"
                    name="repeatedPassword"
                    component={PasswordInput}
                  />
                  <div className="text-right mt-3">
                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                      {t({ id: 'auth.forgot_password.reset_password' })}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Grid>
  );
};

export default ResetPassword;
