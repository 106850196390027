import equal from 'fast-deep-equal/es6/react';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import Input from './Input';
import s from './SliderWithTooltip.module.scss';

const useTooltip = ({ value, min, max, tooltipWidth }) => {
  const stylesForTooltip = useMemo(() => {
    let marginLeft = '0px';
    let percentage = ((value - min) / (max - min)) * 100;

    if (percentage > 30 && percentage <= 70) {
      marginLeft = `-${tooltipWidth / 2}px`;
    } else if (percentage > 70) {
      marginLeft = `-${tooltipWidth}px`;
    } else {
      percentage *= 0.94;
    }

    const styles = { left: `${percentage}%`, marginLeft };
    return styles;
  }, [min, max, value, tooltipWidth]);

  const stylesForTriangle = useMemo(() => {
    const percentage = ((value - min) / (max - min)) * 100;
    let left = '10%';

    if (percentage > 30 && percentage <= 70) {
      left = '50%';
    } else if (percentage > 70) {
      left = '90%';
    }

    return { left };
  }, [value, min, max]);

  return { stylesForTriangle, stylesForTooltip };
};

const Tooltip = ({ sliderValue, min, max, numberInputValue, setNumberInputValue, ...rest }) => {
  const tooltipRef = useRef(null);
  const [tooltipWidth, setTooltipWidth] = useState(null);
  const { stylesForTooltip, stylesForTriangle } = useTooltip({ value: sliderValue, min, max, tooltipWidth });

  useEffect(() => {
    if (!tooltipWidth && tooltipRef?.current) {
      setTooltipWidth(tooltipRef?.current.clientWidth);
    }
  }, [tooltipWidth]);

  return (
    <div className={s.tooltip} style={stylesForTooltip} ref={tooltipRef}>
      <Input {...{ min, max, sliderValue, ...rest }} value={numberInputValue} setValue={setNumberInputValue} />
      <span className={s.triangle} style={stylesForTriangle} />
    </div>
  );
};

export default memo(Tooltip, equal);
