import gql from 'graphql-tag';

export default gql`
  fragment budgetCalculation on BudgetEstimation {
    calculation {
      kids_quantity {
        calculated_value
        user_input
      }
      monthly_income {
        calculated_value
        user_input
      }
      personal_financial_situation {
        calculated_value
        user_input
      }
      monthly_expenses {
        calculated_value
        user_input
      }
      monthly_expenses_partner {
        calculated_value
        user_input
      }
      monthly_expenses_kids {
        calculated_value
        user_input
      }
      costs_for_repair {
        calculated_value
        user_input
      }
      technology {
        calculated_value
        user_input
      }
      luxuries {
        calculated_value
        user_input
      }
      socializing {
        calculated_value
        user_input
      }
      hobbies {
        calculated_value
        user_input
      }
      taking_a_break {
        calculated_value
        user_input
      }
      new_car {
        calculated_value
        user_input
      }
      pension_and_insurances {
        calculated_value
        user_input
      }
      monthly_costs {
        calculated_value
        user_input
      }
      monthly_rate_loan {
        calculated_value
        user_input
      }
      amount_of_loan {
        calculated_value
        user_input
      }
      funds_and_budget {
        calculated_value
        user_input
      }
      total_monthly_income {
        calculated_value
        user_input
      }
    }
  }
`;
