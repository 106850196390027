import React, { useMemo } from 'react';
import { BudgetStep3 } from 'assets/images/lifestyle-calculator';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import ScrollToError from 'components/common/ScrollToError';
import { Field, Form, Formik } from 'formik';
import PageTitle from 'components/common/PageTitle';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import ButtonSelect from 'components/inputs/ButtonSelect';
import { ReactComponent as DiagonalLine } from 'assets/icons/diagonal-line.svg';
import { ReactComponent as OneCarIcon } from 'assets/icons/budget/1-car.svg';
import { ReactComponent as TwoCarsIcon } from 'assets/icons/budget/2-cars.svg';
import { ReactComponent as ThreeCarsIcon } from 'assets/icons/budget/3-cars.svg';
import { ReactComponent as FourCarsIcon } from 'assets/icons/budget/4-cars.svg';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import * as yup from 'yup';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import { defaults } from 'lodash';
import s from './LifestyleCalculatorForm.module.scss';

const validationSchema = yup.object().shape({
  cars: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  amount_of_alimony: yup.string().required(__('errors.fill_field')),
  monthly_loan: yup.string().required(__('errors.fill_field')),
});

const LifestyleCalculatorThirdStep = ({
  initialValues,
  onSubmit,
  formValuesRef,
  nextStep,
  onBack,
  previousStep,
  currentStep,
  clickableTabStep,
  calculatorCompleted,
  myBudget,
}) => {
  const { formatMessage: t } = useIntl();
  const isEditMode = useMemo(() => calculatorCompleted || myBudget?.step >= clickableTabStep, [
    myBudget,
    clickableTabStep,
    calculatorCompleted,
  ]);

  return (
    <Row between="xl" center="md">
      <Col md={10} lg={8} xl={6}>
        <PageTitle>{t({ id: 'budget_calculator.third_step_title' })}</PageTitle>

        <Formik
          initialValues={defaults(formValuesRef.current, initialValues)}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await onSubmit(values, actions, { currentStep: currentStep + 1 });
            nextStep();
          }}
        >
          {({ isSubmitting, values, errors, isValid }) => (
            <Form>
              <ScrollToError />
              <Field
                name="cars"
                label={t({ id: 'budget_calculator.how_many_cars' })}
                nullable={false}
                contentInColumn
                inOneRow={false}
                component={ButtonSelect}
                options={[
                  {
                    startIcon: ({ altText }) => <DiagonalLine className="stroke" title={altText} />,
                    altText: t({ id: 'alt_text.icons.has_none' }),
                    value: 0,
                    label: '',
                  },
                  {
                    startIcon: ({ altText }) => <OneCarIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.cars.one_car' }),
                    value: 1,
                    label: '1',
                  },
                  {
                    startIcon: ({ altText }) => <TwoCarsIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.cars.two_cars' }),
                    value: 2,
                    label: '2',
                  },
                  {
                    startIcon: ({ altText }) => <ThreeCarsIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.cars.three_cars' }),
                    value: 3,
                    label: '3',
                  },
                  {
                    startIcon: ({ altText }) => <FourCarsIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.cars.four_cars' }),
                    value: 4,
                    label: '4+',
                  },
                ]}
              />

              <Field
                name="amount_of_alimony"
                label={t({ id: 'budget_calculator.amount_of_alimony' })}
                component={SliderWithTooltip}
                min={0}
                max={5000}
                defaultValue={0}
                step={100}
                units="€"
              />

              <Field
                name="monthly_loan"
                largeLabel
                label={t({ id: 'budget_calculator.monthly_loan' })}
                component={SliderWithTooltip}
                min={0}
                max={2000}
                defaultValue={0}
                step={100}
                units="€"
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                <Button
                  disabled={isSubmitting || !isValid}
                  onClick={() => onBack(values, previousStep)}
                  startIcon={<ArrowBack />}
                  color="outline"
                >
                  {t({ id: 'app.back' })}
                </Button>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                  color="secondary"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={10} lg={8} xl={6} first="xs" last="xl">
        <div className="sticky-img-box">
          <BudgetStep3 title={t({ id: 'alt_text.budget_calculator.third_step' })} width="100%" height="100%" />
        </div>
      </Col>
    </Row>
  );
};

export default LifestyleCalculatorThirdStep;
