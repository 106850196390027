import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const GET_PROJECT_TYPES_QUERY = gql`
  query projectTypes($projectId: ID!) {
    projectTypes(projectId: $projectId) {
      _id
      type
      planned_project_type
    }
  }
`;

const useGetProjectTypes = (projectId) => {
  const { data, loading } = useQuery(GET_PROJECT_TYPES_QUERY, { variables: { projectId } });
  const type = get(data, 'projectTypes.type', null);
  const plannedProjectType = get(data, 'projectTypes.planned_project_type', null);
  return { type, plannedProjectType, loading };
};

export default useGetProjectTypes;
