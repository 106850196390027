/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import TextInput from 'components/inputs/TextInput';
import NumberInput from 'components/inputs/NumberInput';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import { Col, Row } from 'react-flexbox-grid';
import { ArrowForward } from 'assets/icons';
import { ReactComponent as Img } from 'assets/images/apartment/step-1.svg';
import { cloneDeep, debounce } from 'lodash';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import Button from 'components/common/Button';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import ScrollToError from 'components/common/ScrollToError';
import Switch from 'components/inputs/Switch';
import s from '../../calculators.module.scss';
import validationSchema from '../yupSchemas/apartmentFirstStepSchema';

const fields = [
  { key: 'property_transfer_tax', interest: 0.035 },
  { key: 'land_register_fee', interest: 0.011 },
  { key: 'notary_fee', interest: 0.01 },
  { key: 'broker_commission', interest: 0.036 },
];

const ApartmentFirstStep = ({ onSubmit, lastStep, currentStep, isEditMode, formValuesRef, onSubmitStep, nextStep }) => {
  const { formatMessage: t } = useIntl();
  const formikRef = useRef();

  const computeTotalApartmentCost = useCallback((values) => {
    const { property_transfer_tax, land_register_fee, notary_fee, broker_commission, cost_apartment } = values;
    return formatCurrency(
      property_transfer_tax + land_register_fee + notary_fee + broker_commission + cost_apartment || 0,
    );
  }, []);

  const recalculateFields = useCallback(
    debounce(
      () =>
        fields.forEach(({ key, interest }) => {
          if (formikRef.current) {
            const value = formikRef.current.values?.cost_apartment;
            formikRef.current.setFieldValue(key, Math.round(value * interest));
          }
        }),
      1000,
    ),
    [],
  );

  const onSubmitFormHandler = useCallback(
    async (values, formikActions) => {
      const newValues = cloneDeep(values);
      fields.forEach(({ key, interest }) => {
        newValues[key] = Number.isFinite(values[key]) ? values[key] : Math.round(values.cost_apartment * interest);
      });
      onSubmitStep(newValues);
      await onSubmit({ values: newValues, formikActions, currentStep, isEditMode, lastStep, nextStep });
    },
    [currentStep, isEditMode, lastStep, nextStep, onSubmit, onSubmitStep],
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formValuesRef?.current}
      onSubmit={onSubmitFormHandler}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => (
        <Row between="lg" center="md">
          <Col md={12} lg={6}>
            <Form>
              <ScrollToError />
              <Field name="name" component={TextInput} type="text" label={t({ id: 'project_wizard.project_name' })} />
              <Field
                name="apartment_area"
                component={SliderWithTooltip}
                min={25}
                step={5}
                max={300}
                units="m²"
                label={t({ id: 'apartment_wizard.apartment_area' })}
              />
              <Field
                name="cost_apartment"
                component={SliderWithTooltip}
                step={1000}
                min={20000}
                max={3000000}
                units="€"
                sideEffect={recalculateFields}
                label={t({ id: 'apartment_wizard.cost_apartment' })}
              />
              <p className="text-left">{t({ id: 'apartment_wizard.additional_costs' })}</p>
              <Field name="main_residence" component={Switch} label={t({ id: 'apartment_wizard.main_residence' })} />
              <Field
                name="property_transfer_tax"
                type="number"
                component={NumberInput}
                label={t({ id: 'apartment_wizard.property_transfer_tax' })}
                skipScrollingToField
              />
              <Field
                name="land_register_fee"
                type="number"
                component={NumberInput}
                label={t({ id: 'apartment_wizard.land_register_fee' })}
                skipScrollingToField
              />
              <Field
                name="notary_fee"
                type="number"
                component={NumberInput}
                label={t({ id: 'apartment_wizard.notary_fee' })}
                skipScrollingToField
              />
              <Field
                name="broker_commission"
                type="number"
                component={NumberInput}
                label={t({ id: 'apartment_wizard.broker_commission' })}
                skipScrollingToField
              />
              <p className={s.totalValue}>
                <span>{t({ id: 'apartment_wizard.total_cost_apartment' })}</span>
                <b>{computeTotalApartmentCost(values)}</b>
              </p>

              <div className={s.buttonsWrapper}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          </Col>
          <Col md={12} lg={6} last="lg" first="xs">
            <div className={s.imgBox}>
              <Img
                width="100%"
                height="100%"
                title={t({ id: 'alt_text.project_details_page.new_apartment.first_step' })}
              />
            </div>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default ApartmentFirstStep;
