import React, { useCallback } from 'react';
import { Grid } from 'react-flexbox-grid';
import StepWizard from 'react-step-wizard';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import { GUEST } from 'constants/constants';
import useFormValuesRef from 'hooks/useFormValuesRef';
import {
  BuyingHouseFirstStep,
  BuyingHouseThirdStep,
  BuyingHouseFourthStep,
  BuyingHouseFifthStep,
  BuyingHouseSixthStep,
  BuyingHouseSecondStep,
} from './tabs';
import Nav from '../Nav';
import CostOverview from '../CostOverviewTab';

const formValueMock = {
  name: 'My house',
  type: 'renovation_house',
  country: 'AT',
  zip: '1414',

  main_residence: true,
  cost_house: 350000,
  property_transfer_tax: 12250,
  land_register_fee: 3850,
  notary_fee: 3500,
  broker_commission: 12600,

  heating_system: 'gas',
  parking: 'garage',
  number_of_bathrooms: 3,
  number_of_toilets: 2,
  construction_year: 2000,
  basement_type: 'full_basement',
  number_of_floors: 2,
  house_type: 'solid_construction',
  house_category: 'detached_house',
  land_area: 200,
  house_area: 100,
  full_address: 'Brigittaplatz 58, Wien, Österreich',
  coordinates: {
    lat: -23.5270028,
    lng: -46.6655865,
  },
};

const tabs = [
  { label: 'project_details.tabs.house_info', component: BuyingHouseFirstStep },
  { label: 'project_details.tabs.sprengnetter_estimation', component: BuyingHouseSecondStep },
  { label: 'project_details.tabs.energy', component: BuyingHouseThirdStep },
  { label: 'project_details.tabs.indoor', component: BuyingHouseFourthStep },
  { label: 'project_details.tabs.outdoor', component: BuyingHouseFifthStep },
  { label: 'project_details.tabs.other', component: BuyingHouseSixthStep },
  { label: 'project_details.tabs.cost_overview', component: CostOverview },
];

export default ({ onSubmit, initialValues, isEditMode, step, hasAdditionalFeatures, projectPrice, projectType }) => {
  const isGuest = useHasRequiredRole(GUEST);
  const formValuesRef = useFormValuesRef([], { formValueMock, initialValues });

  const onSubmitStep = useCallback(
    (values) => {
      formValuesRef.current = { ...formValuesRef.current, ...values };
    },
    [formValuesRef],
  );

  const onBack = useCallback(
    (values, callback) => {
      onSubmitStep(values);
      callback();
    },
    [onSubmitStep],
  );

  return (
    <Grid>
      <StepWizard initialStep={step} transitions={{}} isLazyMount nav={<Nav tabs={tabs} isEditMode={isEditMode} />}>
        {tabs.map(({ label, component: C }) => (
          <C
            key={label}
            {...{
              projectType,
              isGuest,
              onSubmitStep,
              formValuesRef,
              initialValues,
              onSubmit,
              isEditMode,
              hasAdditionalFeatures,
              projectPrice,
              onBack,
            }}
          />
        ))}
      </StepWizard>
    </Grid>
  );
};
