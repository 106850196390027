import React from 'react';
import classnames from 'classnames';
import styles from './SidebarAwareContainer.module.scss';

export default function SidebarAwareContainer({ children, className, ...rest }) {
  return (
    <div className={classnames(styles.container, className)} {...rest}>
      {children}
    </div>
  );
}
