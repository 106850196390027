import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import { FastField, Form, Formik } from 'formik';
import useRequestExpressLoanPersonalOffer from 'hooks/financing/useRequestExpressLoanPersonalOffer';
import useSearchParams from 'hooks/useSearchParams';
import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { ReactComponent as Alone } from 'assets/icons/budget/alone.svg';
import { ReactComponent as Partner } from 'assets/icons/budget/partner.svg';
import { ReactComponent as DiagonalLine } from 'assets/icons/diagonal-line.svg';
import { ReactComponent as OneChildIcon } from 'assets/icons/budget/one-child.svg';
import { ReactComponent as TwoChildrenIcon } from 'assets/icons/budget/two-children.svg';
import { ReactComponent as ThreeChildrenIcon } from 'assets/icons/budget/three-children.svg';
import { ReactComponent as FourChildrenIcon } from 'assets/icons/budget/four-children.svg';
import { ReactComponent as OneCarIcon } from 'assets/icons/budget/1-car.svg';
import { ReactComponent as TwoCarsIcon } from 'assets/icons/budget/2-cars.svg';
import { ReactComponent as ThreeCarsIcon } from 'assets/icons/budget/3-cars.svg';
import { ReactComponent as FourCarsIcon } from 'assets/icons/budget/4-cars.svg';
import * as yup from 'yup';
import ButtonSelect from 'components/inputs/ButtonSelect';
import Toggler from 'components/inputs/Toggler';
import NumberInput from 'components/inputs/NumberInput';
import TextInput from 'components/inputs/TextInput';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import { __ } from 'utils/form';
import ScrollToError from 'components/common/ScrollToError';
import useExpressLoanPersonalOfferFormValues from 'hooks/financing/useExpressLoanPersonalOfferFormValues';
import { get } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import routePaths from 'router/route-paths';
import s from './ExpressLoanSteps.module.scss';

const validationSchema = yup.object().shape({
  alone_or_partner: yup
    .string()
    .required(__('errors.choose_one'))
    .typeError(__('errors.choose_one')),
  kids_quantity: yup
    .number()
    .required(__('errors.choose_one'))
    .typeError(__('errors.choose_one')),
  cars: yup
    .number()
    .required(__('errors.choose_one'))
    .typeError(__('errors.choose_one')),
  loan_exists: yup
    .boolean()
    .required(__('errors.choose_one'))
    .typeError(__('errors.choose_one')),
  existing_loan_rate: yup
    .mixed()
    .notRequired()
    .when('loan_exists', {
      is: true,
      then: yup
        .number()
        .required(__('errors.fill_field'))
        .typeError(__('errors.fill_field'))
        .min(10, __('errors.values_within_range', { min: 10, max: 5000 }))
        .max(5000, __('errors.values_within_range', { min: 10, max: 5000 })),
    }),
  full_address: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  zip: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  property_type: yup
    .string()
    .required(__('errors.choose_one'))
    .typeError(__('errors.choose_one')),
  land_area: yup.number().required(__('errors.fill_field')),
  living_area: yup.number().required(__('errors.fill_field')),
  construction_year: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  parking: yup
    .string()
    .required(__('errors.choose_one'))
    .typeError(__('errors.choose_one')),
});

const PersonalOfferForm = ({ backButton }) => {
  const { formatMessage: t } = useIntl();
  const searchParams = useSearchParams();
  const onRequestPersonalOffer = useRequestExpressLoanPersonalOffer();
  const { formValues } = useExpressLoanPersonalOfferFormValues({ id: searchParams.get('id') });
  const history = useHistory();
  const location = useLocation();

  const initialValues = useMemo(
    () => ({
      alone_or_partner: get(formValues, 'alone_or_partner', 'alone'),
      kids_quantity: get(formValues, 'kids_quantity', 1),
      cars: get(formValues, 'cars', null),
      loan_exists: get(formValues, 'loan_exists', false),
      existing_loan_rate: get(formValues, 'existing_loan_rate', 100),
      full_address: get(formValues, 'full_address', ''),
      zip: get(formValues, 'zip', null),
      property_type: get(formValues, 'property_type', null),
      land_area: get(formValues, 'land_area', 500),
      living_area: get(formValues, 'living_area', 130),
      construction_year: get(formValues, 'construction_year', null),
      parking: get(formValues, 'parking', null),
    }),
    [formValues],
  );

  const onSubmit = useCallback(
    async (values, helpers) => {
      await onRequestPersonalOffer(searchParams.get('id'), values);
      helpers.setSubmitting(false);
      history.push({
        pathname: routePaths.expressLoan,
        search: location.search.replace('offer', 'advisory'),
      });
      window.scrollTo(0, 0);
    },
    [history, location.search, onRequestPersonalOffer, searchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema, enableReinitialize: true }}>
      {({ isSubmitting, values }) => (
        <Form>
          <ScrollToError />
          <FastField
            name="alone_or_partner"
            label={t({ id: 'express_loan.personal_offer_form.alone_or_partner' })}
            component={ButtonSelect}
            nullable={false}
            largeLabel
            options={[
              {
                startIcon: <Alone className="stroke" />,
                value: 'alone',
                label: t({ id: 'express_loan.personal_offer_form.alone' }),
              },
              {
                startIcon: <Partner className="stroke" />,
                value: 'partner',
                label: t({ id: 'express_loan.personal_offer_form.partner' }),
              },
            ]}
          />

          <FastField
            name="kids_quantity"
            label={t({ id: 'express_loan.personal_offer_form.kids_quantity' })}
            component={ButtonSelect}
            inOneRow={false}
            nullable={false}
            options={[
              {
                startIcon: <DiagonalLine className="stroke" />,
                value: 0,
                label: '',
              },
              {
                startIcon: <OneChildIcon className="fill" />,
                value: 1,
                label: '1',
              },
              {
                startIcon: <TwoChildrenIcon className="fill" />,
                value: 2,
                label: '2',
              },
              {
                startIcon: <ThreeChildrenIcon className="fill" />,
                value: 3,
                label: '3',
              },
              {
                startIcon: <FourChildrenIcon className="fill" />,
                value: 4,
                label: '4+',
              },
            ]}
          />

          <FastField
            name="cars"
            label={t({ id: 'express_loan.personal_offer_form.cars' })}
            nullable={false}
            contentInColumn
            inOneRow={false}
            component={ButtonSelect}
            options={[
              {
                startIcon: <DiagonalLine className="stroke" />,
                value: 0,
                label: '',
              },
              {
                startIcon: <OneCarIcon className="fill" />,
                value: 1,
                label: '1',
              },
              {
                startIcon: <TwoCarsIcon className="fill" />,
                value: 2,
                label: '2',
              },
              {
                startIcon: <ThreeCarsIcon className="fill" />,
                value: 3,
                label: '3',
              },
              {
                startIcon: <FourCarsIcon className="fill" />,
                value: 4,
                label: '4+',
              },
            ]}
          />

          <FastField
            name="loan_exists"
            required
            component={Toggler}
            options={[
              { label: t({ id: 'yes' }), value: true },
              { label: t({ id: 'no' }), value: false },
            ]}
            label={t({ id: 'express_loan.personal_offer_form.loan_exists' })}
          />

          {values.loan_exists ? (
            <FastField
              name="existing_loan_rate"
              component={NumberInput}
              max={5000}
              label={t({ id: 'express_loan.personal_offer_form.existing_loan_rate' })}
            />
          ) : null}

          <FastField
            name="full_address"
            component={TextInput}
            label={t({ id: 'express_loan.personal_offer_form.full_address' })}
          />

          <FastField name="zip" component={TextInput} label={t({ id: 'express_loan.personal_offer_form.zip' })} />

          <FastField
            name="property_type"
            inOneRow={false}
            component={ButtonSelect}
            options={[
              {
                label: t({ id: 'express_loan.personal_offer_form.property_types.family_house' }),
                value: 'family_house',
              },
              {
                label: t({ id: 'express_loan.personal_offer_form.property_types.apartment' }),
                value: 'apartment',
              },
              {
                label: t({ id: 'express_loan.personal_offer_form.property_types.property' }),
                value: 'property',
              },
            ]}
            label={t({ id: 'express_loan.personal_offer_form.property_type' })}
          />

          {['property', 'family_house'].includes(values.property_type) ? (
            <FastField
              name="land_area"
              component={SliderWithTooltip}
              min={200}
              step={50}
              max={3000}
              defaultValue={500}
              units="m²"
              label={t({ id: 'express_loan.personal_offer_form.land_area' })}
            />
          ) : null}

          <FastField
            name="living_area"
            component={SliderWithTooltip}
            step={5}
            min={25}
            max={300}
            defaultValue={130}
            units="m²"
            label={t({ id: 'express_loan.personal_offer_form.living_area' })}
          />

          <FastField
            name="construction_year"
            component={NumberInput}
            label={t({ id: 'express_loan.personal_offer_form.construction_year' })}
            thousandSeparator=""
            format="####"
          />

          <FastField
            name="parking"
            component={ButtonSelect}
            inOneRow={false}
            options={[
              {
                label: t({ id: 'express_loan.personal_offer_form.parking_type.outdoor_parking_space' }),
                value: 'outdoor_parking_space',
              },
              {
                label: t({ id: 'express_loan.personal_offer_form.parking_type.garage' }),
                value: 'garage',
              },
              {
                label: t({ id: 'express_loan.personal_offer_form.parking_type.underground_car_park' }),
                value: 'underground_car_park',
              },
            ]}
            label={t({ id: 'express_loan.personal_offer_form.parking' })}
          />

          <div className={s.buttonsWrapper}>
            {backButton}
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              endIcon={<ArrowForward />}
              color="secondary"
            >
              {t({ id: 'app.submit_form_next' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const ExpressLoanPersonalOfferForm = ({ backButton }) => {
  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col xs={12}>
        <PersonalOfferForm {...{ backButton }} />
      </Col>
    </Row>
  );
};

export default ExpressLoanPersonalOfferForm;
