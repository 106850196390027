export default {
  isDevelopment: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',
  isStaging: process.env.REACT_APP_IS_STAGING ? JSON.parse(process.env.REACT_APP_IS_STAGING) : false,
  debug: process.env.REACT_APP_DEBUG ? JSON.parse(process.env.REACT_APP_DEBUG) : false,
  apiUrl: process.env.REACT_APP_API_URL,
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE,
  dataProtectionUrl: process.env.REACT_APP_DATA_PROTECTION_URL,
  dataProcessingUrl: process.env.REACT_APP_DATA_PROCESSING_URL,
  termsOfUseUrl: process.env.REACT_APP_TERMS_OF_USE_URL,
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL,
  dsn: process.env.REACT_APP_DSN,
  googleApiKey: process.env.REACT_APP_GOOGLE_CONSOLE_API_KEY,
  cookiebotDomainGroupId: process.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID,
  enuPartnerLink: process.env.REACT_APP_ENU_PARTNER_URL,
  applicationType: process.env.REACT_APP_APPLICATION_TYPE,
};
