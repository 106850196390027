/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useLogin from 'hooks/auth/useLogin';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Formik, Form, Field } from 'formik';
import PageTitle from 'components/common/PageTitle';
import { QuickCalculatorStep3 } from 'assets/icons/QuickCalculator';
import * as yup from 'yup';
import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import TextInput from 'components/inputs/TextInput';
import Checkbox from 'components/inputs/Checkbox';
import settings from 'config/settings';
import ErrorMessage from 'components/common/ErrorMessage';
import PasswordInput from 'components/inputs/PasswordInput';

const loginByInvitationParamsSchema = yup.object().shape({
  email: yup
    .string()
    .required(<FormattedMessage id="errors.email_required" />)
    .email(<FormattedMessage id="errors.email_invalid" />),
  tempPassword: yup.string().required(<FormattedMessage id="errors.password_required" />),
  plainPassword: yup
    .string()
    .required(<FormattedMessage id="errors.password_required" />)
    .min(8, <FormattedMessage id="errors.password_too_small" />),
  consent: yup.bool().oneOf([true], <FormattedMessage id="errors.consent_required" />),
});

export default function LoginByInvitation() {
  const login = useLogin();
  const handleSubmit = useAuthFormSubmit(login);
  const { formatMessage: t } = useIntl();

  const initialValues = {
    email: '',
    tempPassword: '',
    plainPassword: '',
    consent: false,
  };

  const onSubmit = useCallback(
    async (values, formikActions) => {
      await handleSubmit(values, formikActions);
    },
    [handleSubmit],
  );

  return (
    <Grid className="mt-8">
      <Row>
        <Col md={6}>
          <PageTitle auth>{t({ id: 'auth.login_by_invitation_page_title' })}</PageTitle>
        </Col>
      </Row>
      <Row between="xs">
        <Col md={5}>
          <Formik initialValues={initialValues} validationSchema={loginByInvitationParamsSchema} onSubmit={onSubmit}>
            {({ isSubmitting, errors }) => (
              <Form>
                <Field name="email" lowerCase component={TextInput} label={t({ id: 'auth.email' })} type="email" />
                <Field
                  name="tempPassword"
                  component={PasswordInput}
                  label={t({ id: 'auth.temp_password' })}
                  type="password"
                  required
                />
                <Field
                  name="plainPassword"
                  component={PasswordInput}
                  label={t({ id: 'auth.password' })}
                  type="password"
                />
                <Field
                  name="consent"
                  component={Checkbox}
                  label={
                    <FormattedMessage
                      id="auth.terms_of_use"
                      values={{
                        link: (
                          <a
                            href={settings.privacyPolicy}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                          >
                            {t({ id: 'auth.terms_of_use_link_label' })}
                          </a>
                        ),
                      }}
                    />
                  }
                />

                {errors.form && <ErrorMessage message={errors.form} />}

                <div className="text-right mt-3">
                  <Button type="submit" loading={isSubmitting} disabled={isSubmitting} endIcon={<ArrowForward />}>
                    <FormattedMessage id="auth.sign_in_submit" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
        <Col md={5} first="xs" last="md">
          <QuickCalculatorStep3 width="100%" height="100%" />
        </Col>
      </Row>
    </Grid>
  );
}
