/* eslint-disable import/prefer-default-export */
import React from 'react';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';

export const createEquipmentTypeValidator = (featureName) =>
  yup
    .mixed()
    .notRequired()
    .when(`features.${featureName}`, {
      is: true,
      then: yup
        .string()
        .required(<FormattedMessage id="errors.choose_one" />)
        .typeError(<FormattedMessage id="errors.choose_one" />),
    });

export const createConditionalValidator = (featureName, type = 'string') => {
  return yup
    .mixed()
    .notRequired()
    .when(`features.${featureName}`, {
      is: true,
      then:
        type === 'string'
          ? yup
              .string()
              .required(<FormattedMessage id="errors.fill_field" />)
              .typeError(<FormattedMessage id="errors.fill_field" />)
          : yup
              .number()
              .required(<FormattedMessage id="errors.fill_field" />)
              .typeError(<FormattedMessage id="errors.fill_field" />),
    });
};
