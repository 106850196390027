import React from 'react';
import { useParams } from 'react-router-dom';
import useProject from 'hooks/project-details/useProject';
import get from 'lodash/get';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { BUYING_HOUSE, NEW_APARTMENT, NEW_BUILDING, RENOVATION_HOUSE, PROPERTY_VALUATION } from 'constants/constants';
import Alert from 'components/common/Alert';
import { useIntl } from 'react-intl';
import { grabErrorMessage } from 'utils/helpers';
import HouseDetailsCalculator from './BuildHouseCalculator';
import ApartmentDetailsCalculator from './ApartmentDetailsCalculator';
import BuyingHouseDetailsCalculator from './BuyingHouseDetailsCalculator';
import RenovationHouseCalculator from './RenovationHouseCalculator';
import PropertyValuationCalculator from './PropertyValuationCalculator';

const projectTypeToComponentMap = {
  [NEW_BUILDING]: HouseDetailsCalculator,
  [NEW_APARTMENT]: ApartmentDetailsCalculator,
  [BUYING_HOUSE]: BuyingHouseDetailsCalculator,
  [RENOVATION_HOUSE]: RenovationHouseCalculator,
  [PROPERTY_VALUATION]: PropertyValuationCalculator,
};

const ProjectDetailsCalculator = ({ projectId, t }) => {
  const [project, loading, error] = useProject(projectId);
  const type = get(project, 'type', '');

  if (loading) return <LoadingOverlay />;

  const Calculator = projectTypeToComponentMap[type];

  if (error) {
    return (
      <Alert color="danger" withContainer>
        {grabErrorMessage(error)}
      </Alert>
    );
  }

  if (!project) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'app.project_not_found' })}
      </Alert>
    );
  }

  return <Calculator {...{ project }} />;
};

export default () => {
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();

  if (!projectId) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'app.project_not_found' })}
      </Alert>
    );
  }

  return <ProjectDetailsCalculator {...{ projectId, t }} />;
};
