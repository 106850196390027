import { ArrowBack, ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import { Formik } from 'formik';
import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { useParams } from 'react-router-dom';
import useGetProjectCalculations from 'hooks/project/useGetProjectCalculations';
import { useModal } from 'components/common/Modal';
import s from '../../calculators.module.scss';
import BuyingHouseEstimationForm from './BuyingHouseEstimationForm';
import validationSchema from '../yupSchemas/buyingHouseSecondStepSchema';

const Buttons = ({
  isEditMode,
  onBack,
  values,
  previousStep,
  calculations,
  requestsCount,
  showModal,
  isSubmitting,
}) => {
  const { formatMessage: t } = useIntl();
  const estimationRequestCompleted = useMemo(
    () =>
      (calculations?.sprengnetter?.request_completed && calculations?.sprengnetter_land?.request_completed) ||
      requestsCount >= 10,
    [calculations, requestsCount],
  );

  return (
    <div className={s.buttonsWrapper}>
      {!isEditMode ? (
        <Button disabled={isSubmitting} onClick={() => onBack(values, previousStep)} startIcon={<ArrowBack />}>
          {t({ id: 'app.back' })}
        </Button>
      ) : null}
      <Button
        {...(!estimationRequestCompleted ? { onClick: showModal, type: 'button' } : { type: 'submit' })}
        loading={isSubmitting}
        disabled={isSubmitting}
        endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
        className="ml-auto"
      >
        {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
      </Button>
    </div>
  );
};

const BuyingHouseSecondStep = ({
  currentStep,
  formValuesRef,
  isEditMode,
  lastStep,
  onSubmit,
  onSubmitStep,
  nextStep,
  previousStep,
  projectPrice,
  hasAdditionalFeatures,
  onBack,
}) => {
  const { id: projectId } = useParams();
  const { calculations, sprengnetterRequestsCount: requestsCount, loading } = useGetProjectCalculations(projectId);
  const { showModal, hideModal, isVisible } = useModal();
  const formikRef = useRef();

  const onSubmitFormHandler = useCallback(
    async (values, formikActions) => {
      const step = !isEditMode && values.additional_features ? currentStep : null;
      onSubmitStep(values);
      await onSubmit({
        values,
        formikActions,
        currentStep: step,
        isEditMode: !values.additional_features,
        lastStep,
        nextStep,
      });
    },
    [currentStep, isEditMode, lastStep, nextStep, onSubmit, onSubmitStep],
  );

  const sideEffectOnSprengnetterRequest = useCallback(async () => {
    await onSubmit({
      values: formikRef.current.values,
      currentStep,
      isEditMode,
      changeStep: false,
      formikActions: { setSubmitting: formikRef.current.setSubmitting },
    });
  }, [currentStep, isEditMode, onSubmit]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formValuesRef?.current}
      onSubmit={onSubmitFormHandler}
      validationSchema={validationSchema}
    >
      {(props) => (
        <BuyingHouseEstimationForm
          {...{
            calculations,
            projectPrice,
            requestsCount,
            loading,
            hideModal,
            isVisible,
            hasAdditionalFeatures,
            sideEffectOnSprengnetterRequest,
            buttons: (
              <Buttons {...{ isEditMode, onBack, previousStep, calculations, requestsCount, showModal, ...props }} />
            ),
            ...props,
          }}
        />
      )}
    </Formik>
  );
};

export default BuyingHouseSecondStep;
