/* eslint-disable prefer-template */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useCallback, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { areEqualFields } from 'utils/form';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import { spliceLabelOnMobile } from '../../utils/helpers';
import { SelectCustomStyles } from './customStyle';
import s from './Select.module.scss';

const Select = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { label, options = [], style, isSearchable = false, note, grouped } = props;
  const [inputValue, setInputValue] = useState();

  const handleInputChange = useCallback((v) => {
    setInputValue(v);
  }, []);

  // const handleBlur = useCallback(() => {
  //   setFieldTouched(field.name);
  // }, [field, setFieldTouched]);

  const handleChange = useCallback(
    (selectedOption) => {
      const selectedValue = get(selectedOption, 'value', null);
      setFieldValue(field.name, selectedValue);
    },
    [setFieldValue, field.name],
  );

  const optionValue = useMemo(() => {
    const defaultOption = field.defaultValue ? options.find((o) => o.value && o.value === field.defaultValue) : null;
    if (defaultOption) {
      handleChange(defaultOption);
      return defaultOption;
    }
    const _options = grouped ? flatten(options.map(({ options: o }) => o)) : options;
    const option = _options.find((o) => o.value === field.value);
    return option || null;
  }, [field.defaultValue, field.value, options, grouped, handleChange]);

  const classNameForLabel = useMemo(() => (field.value || inputValue ? 'label-when-focused-input' : s.label), [
    field.value,
    inputValue,
  ]);

  return (
    <div className="field-wrapper" style={style}>
      <ReactSelect
        inputValue={inputValue}
        onInputChange={handleInputChange}
        inputProps={{ autoComplete: 'off' }}
        inputId={field.name}
        value={optionValue}
        // onBlur={handleBlur}
        onChange={handleChange}
        name={field.name}
        options={options}
        className={touched[field.name] && errors[field.name] && 'error-input'}
        styles={SelectCustomStyles}
        placeholder=""
        components={{ IndicatorSeparator: () => null }}
        isSearchable={isSearchable}
        blurInputOnSelect
      />
      <label className={classNameForLabel} id={`label-${field.name}`} htmlFor={field.name}>
        {spliceLabelOnMobile(label)}
      </label>
      {note ? (
        <div className="text-left">
          <small className="muted">{note}</small>
        </div>
      ) : null}
      {touched[field.name] && errors[field.name] && <div className="error-text">{errors[field.name]}</div>}
    </div>
  );
};

export default memo(Select, (prevProps, nextProps) => areEqualFields(prevProps, nextProps, ['options']));
