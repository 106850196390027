import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Download, ExclamationTriangle } from 'assets/icons';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import Tooltip from 'components/common/Tooltip';
import Button from 'components/common/Button';
import s from './Downloads.module.scss';

const MY_BUDGET_PDF_QUERY = gql`
  query myBudgetPdf {
    myBudget {
      _id
      pdf
    }
  }
`;

export default function Downloads({ project }) {
  const { data: myBudgetQueryData } = useQuery(MY_BUDGET_PDF_QUERY);
  const budgetPdfUrl = myBudgetQueryData?.myBudget?.pdf;
  const { formatMessage: t } = useIntl();

  return (
    <div className={s.downloads}>
      <h3 className="mt-0">{t({ id: 'dashboard.downloads.title' })}</h3>
      <a href={project.pdf} target="_blank" rel="noopener noreferrer" className={cn('link', s.downloadButton)}>
        <Button startIcon={<Download />}>{t({ id: 'dashboard.downloads.project_pdf' })}</Button>
      </a>
      <Tooltip
        hide={budgetPdfUrl}
        placement="top"
        overlay={<p>{t({ id: 'dashboard.downloads.budget_not_available_tooltip' })}</p>}
      >
        <a
          href={budgetPdfUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={cn('link', s.downloadButton, !budgetPdfUrl && 'cursor-default')}
        >
          <Button startIcon={budgetPdfUrl ? <Download /> : <ExclamationTriangle />}>
            {t({ id: 'dashboard.downloads.budget_pdf' })}
          </Button>
        </a>
      </Tooltip>
    </div>
  );
}
