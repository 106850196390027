import gql from 'graphql-tag';
import { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const GET_LIST_OF_CONSULTANTS = gql`
  query {
    loanConsultants {
      _id
      emails {
        address
        verified
      }
      profile {
        firstName
        lastName
      }
    }
  }
`;

const useGetLoanConsultants = () => {
  const { data, loading } = useQuery(GET_LIST_OF_CONSULTANTS);
  const loanConsultants = useMemo(() => get(data, 'loanConsultants', []), [data]);
  return [loanConsultants, loading];
};

export default useGetLoanConsultants;
