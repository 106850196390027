import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { formatGraphqlErrors } from 'utils/helpers';

const COMPLETE_PROJECT_STEP = gql`
  mutation completeProjectStep($projectId: ID!, $step: Step!) {
    completeProjectStep(projectId: $projectId, step: $step) {
      success
    }
  }
`;

const useCompleteProjectStep = (projectId) => {
  const [completeProjectStepMutation] = useMutation(COMPLETE_PROJECT_STEP);

  const completeProjectStep = useCallback(
    async (step) => {
      try {
        await completeProjectStepMutation({ variables: { projectId, step } });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [completeProjectStepMutation, projectId],
  );

  return completeProjectStep;
};

export default useCompleteProjectStep;
