import Table from 'rc-table';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { projectsTableColumns } from './columns';

const ProjectsTable = ({ projects = [] }) => {
  const { formatMessage: t } = useIntl();
  const localizedColumns = useMemo(
    () => projectsTableColumns.map(({ title, ...rest }) => ({ ...rest, title: t({ id: title }) })),
    [t],
  );

  const data = useMemo(
    () =>
      projects.map(({ _id, name, type, progress, pdf, transferredBy }) => {
        const { project_details, plan_adjusted, budget_planning, loan_calculator, loan_request } = progress;
        return {
          _id,
          name,
          type: t({ id: `project_wizard.${type}` }),
          pdf,
          project_details: t({ id: project_details ? 'app.yes' : 'app.no' }),
          plan_adjusted: t({ id: plan_adjusted ? 'app.yes' : 'app.no' }),
          budget_planning: t({ id: budget_planning ? 'app.yes' : 'app.no' }),
          loan: t({ id: loan_calculator ? 'app.yes' : 'app.no' }),
          loan_offer: t({ id: loan_request ? 'app.yes' : 'app.no' }),
          transferred_by_email: transferredBy.email,
          transferred_by_name: `${transferredBy.firstName} ${transferredBy.lastName}`,
        };
      }),
    [projects, t],
  );

  return (
    <Table
      rowKey={({ _id }) => _id}
      style={{ width: '100%' }}
      scroll={{ x: '100%' }}
      direction="ltr"
      columns={localizedColumns}
      data={data}
      emptyText={t({ id: 'invitations.no_projects' })}
    />
  );
};

export default ProjectsTable;
