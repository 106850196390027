import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';

export const PROJECT_QUERY = gql`
  query project($id: ID!) {
    project(id: $id) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useProject = (projectId) => {
  const { loading, data, error } = useQuery(PROJECT_QUERY, {
    variables: { id: projectId },
    fetchPolicy: 'cache-and-network',
  });

  return [data?.project, loading, error];
};

export default useProject;
