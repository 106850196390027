import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { formatGraphqlErrors } from 'utils/helpers';

export const RESET_PASSWORD = gql`
  mutation resetPassword($newPassword: HashedPassword, $newPasswordPlain: String, $token: String!) {
    resetPassword(newPassword: $newPassword, newPasswordPlain: $newPasswordPlain, token: $token)
  }
`;

const useResetPassword = () => {
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD);

  const resetPassword = useCallback(
    async ({ password, token }) => {
      try {
        await resetPasswordMutation({ variables: { token, newPasswordPlain: password } });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [resetPasswordMutation],
  );

  return resetPassword;
};

export default useResetPassword;
