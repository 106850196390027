import React, { useCallback, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useIntl } from 'react-intl';
import { clamp, isNil } from 'lodash';
import s from './NumberInputSimple.module.scss';

const config = {
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  de: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};

const NumberInputSimple = (
  { name, label, defaultValue, disabled = false, max = 5000000, min, required = false, thousandSeparator },
  ref,
) => {
  const [value, setValue] = useState(defaultValue);
  const { locale } = useIntl();
  const [, forceRerender] = useState(false);

  const handleChange = useCallback(
    (values) => {
      let v;
      if (isNil(values.floatValue)) {
        v = null;
      } else {
        v = clamp(values.floatValue, min, max);
      }
      setValue(v);
      forceRerender((b) => !b);
    },
    [max, min],
  );

  return (
    <div className={s.fieldWrapper}>
      <NumberFormat
        allowNegative={false}
        allowLeadingZeros={false}
        allowEmptyFormatting={false}
        {...(!isNil(thousandSeparator) ? { thousandSeparator } : config[locale])}
        name={name}
        value={value}
        onValueChange={handleChange}
        autoComplete="off"
        id={name}
        ref={ref}
        onFocus={(e) => e.target.focus()}
        required={required}
        className={s.numberInput}
        disabled={disabled}
        type="text"
        max={max}
      />
      <label className="text-label" htmlFor={name} id={`label-${name}`}>
        {label}
      </label>
    </div>
  );
};

export default React.forwardRef(NumberInputSimple);
