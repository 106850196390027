import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import Button from 'components/common/Button';
import { pick } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { ArrowForward } from 'assets/icons';
import { useModal } from 'components/common/Modal';
import validationSchema from '../ApartmentDetails/yupSchemas/apartmentSecondStepSchema';
import ApartmentEstimationForm from '../ApartmentDetails/tabs/ApartmentEstimationForm';

const whiteListFormValues = [
  'zip',
  'country',
  'form_values.apartment_area',
  'form_values.balcony',
  'form_values.basement',
  'form_values.condition_of_apartment',
  'form_values.construction_year',
  'form_values.elevator',
  'form_values.floor_number',
  'form_values.full_address',
  'form_values.coordinates.lat',
  'form_values.coordinates.lng',
  'form_values.garden',
  'form_values.garden_area',
  'form_values.heating_system',
  'form_values.is_rooftop',
  'form_values.loggia',
  'form_values.loggia_area',
  'form_values.parking',
  'form_values.terrace',
  'form_values.terrace_area',
];

const Buttons = ({ showModal, estimationRequestCompleted, isSubmitting }) => {
  const { formatMessage: t } = useIntl();
  return (
    <div className="text-right">
      <Button
        {...(!estimationRequestCompleted
          ? { onClick: showModal, loading: false, disabled: false, type: 'button' }
          : { loading: isSubmitting, disabled: isSubmitting, type: 'submit' })}
        endIcon={<ArrowForward />}
      >
        {t({ id: 'property_valuation.plan_project_btn' })}
      </Button>
    </div>
  );
};

const ApartmentValuationForm = ({ project, onSubmit, isGuest, estimationRequestCompleted, ...props }) => {
  const { form_values, ...rest } = pick(project, whiteListFormValues);
  const initialValues = { ...form_values, ...rest };
  const { showModal, hideModal, isVisible } = useModal();
  const formikRef = useRef();

  const sideEffectOnSprengnetterRequest = useCallback(async () => {
    await onSubmit(formikRef.current.values, { setSubmitting: formikRef.current.setSubmitting }, false);
  }, [onSubmit]);

  return (
    <Formik {...{ initialValues, innerRef: formikRef, onSubmit, validationSchema }}>
      {(formikProps) => (
        <ApartmentEstimationForm
          {...{
            onSubmit,
            projectPrice: project.price,
            isEditMode: true,
            sideEffectOnSprengnetterRequest,
            hideModal,
            isVisible,
            hasAdditionalFeatures: true,
            buttons: <Buttons {...{ showModal, estimationRequestCompleted, ...formikProps }} />,
            ...formikProps,
            ...props,
          }}
        />
      )}
    </Formik>
  );
};

export default ApartmentValuationForm;
