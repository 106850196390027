import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import VerifyEmail from 'pages/VerifyEmail';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import QuickCalculator from 'pages/QuickCalculator';
import AuthLayout from 'components/layouts/AuthLayout';
import Login from 'pages/Login/Login';
import ScrollToTop from 'components/common/ScrollToTop';
import SignUp from 'pages/SignUp';
import CookieDeclaration from 'pages/CookieDeclaration';
import LoginByInvitation from 'pages/LoginByInvitation';
import ExpressLoanQuickStart from 'pages/ExpressLoan/ExpressLoanQuickStart';
import ExpressLoan from 'pages/ExpressLoan/ExpressLoan';
import settings from 'config/settings';
import { applicationTypes } from 'constants/constants';
import routePaths from './route-paths';

const expressLoanRoutes = [
  {
    path: routePaths.expressLoanQuickStart,
    component: ExpressLoanQuickStart,
  },
  {
    path: routePaths.expressLoan,
    component: ExpressLoan,
  },
];

const mainAppRoutes = [
  {
    path: routePaths.quickCalculator,
    component: QuickCalculator,
  },
  {
    path: routePaths.login,
    component: Login,
  },
  {
    path: routePaths.signUp,
    component: SignUp,
  },
  {
    path: routePaths.verifyEmail,
    component: VerifyEmail,
  },
  {
    path: routePaths.forgotPassword,
    component: ForgotPassword,
  },
  {
    path: routePaths.resetPassword,
    component: ResetPassword,
  },
  {
    path: routePaths.cookieDeclaration,
    component: CookieDeclaration,
  },
  {
    path: routePaths.loginByInvitation,
    component: LoginByInvitation,
  },
];

export const authRoutes = settings.applicationType === applicationTypes.expressLoan ? expressLoanRoutes : mainAppRoutes;

const AuthRouter = () => (
  <AuthLayout>
    <ScrollToTop />
    <Switch>
      {authRoutes.map(({ path, component, ...rest }) => (
        <Route key={path} path={path} component={component} exact {...rest} />
      ))}
      <Route render={() => <Redirect to={routePaths.quickCalculator} />} />
    </Switch>
  </AuthLayout>
);

export default AuthRouter;
