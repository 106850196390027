import * as yup from 'yup';
import { createConditionalValidator } from 'utils/yup';
import { BUYING_HOUSE } from 'constants/constants';
import { getPartOfValidationSchema } from '../../helpers';

const validationSchema = yup.object().shape({
  ...getPartOfValidationSchema({ projectType: BUYING_HOUSE, groupName: 'indoor' }),
  sanitary_renovation_area: createConditionalValidator('buying_house_sanitary_renovation', 'number'),
  new_flooring_area: createConditionalValidator('buying_house_new_flooring', 'number'),
  number_of_interior_doors_for_renovation: createConditionalValidator(
    'buying_house_renovation_walls_and_interior_doors',
    'number',
  ),
  renovation_walls_and_interior_doors_area: createConditionalValidator(
    'buying_house_renovation_walls_and_interior_doors',
    'number',
  ),
});

export default validationSchema;
