import React, { useCallback } from 'react';
import { Grid } from 'react-flexbox-grid';
import StepWizard from 'react-step-wizard';
import useFormValuesRef from 'hooks/useFormValuesRef';
import RenovationHouseCommonInfo from './tabs/RenovationHouseThirdStep';
import EnergyRenovations from './tabs/RenovationHouseFourthStep';
import IndoorRenovations from './tabs/RenovationHouseFifthStep';
import OutdoorRenovations from './tabs/RenovationHouseSixthStep';
import OtherInvestments from './tabs/RenovationHouseSeventhStep';
import CO2CalculatorForm from './tabs/co2-calculator-form/CO2CalculatorFormFirstStep';
import Nav from '../Nav';
import s from '../calculators.module.scss';
import CO2Status from './tabs/CO2StatusSecondStep';
import CostOverview from '../CostOverviewTab';

const formValueMock = {
  name: 'My house',
  country: 'AT',
  zip: '1414',

  heated_living_area: 220,
  kind_of_house: 'single_family_house',
  energy_standard: 'between_1960_and_1975',
  renovations: ['new_windows', 'insulation_top_ceiling', 'insulation_basement_ceiling', 'insulation_facade'],
  is_solar_heating_system: true,
  solar_heating_system_type: 'heating_and_hot_water',
  number_of_people: 4,
  heating_system: 'natural_gas',
  age_of_heating_system: 'between_10_and_20_years',
  temperature: 'less_than_21',
  hot_water_producer: 'electric_boiler',
  amount_of_hot_water: 'medium',
  is_devices_younger_than_10_years: true,
  power_consumers: ['sauna', 'outdoor_whirlpool'],
  is_solar_power_system: true,
  solar_power_system_size: 10,
  solar_power_system_consuming_percentage: 15,
};

const tabs = [
  { label: 'project_details.tabs.house_info', component: CO2CalculatorForm },
  { label: 'project_details.tabs.co2_status', component: CO2Status },
  { label: 'project_details.tabs.common', component: RenovationHouseCommonInfo },
  { label: 'project_details.tabs.energy', component: EnergyRenovations },
  { label: 'project_details.tabs.indoor', component: IndoorRenovations },
  { label: 'project_details.tabs.outdoor', component: OutdoorRenovations },
  { label: 'project_details.tabs.other', component: OtherInvestments },
  { label: 'project_details.tabs.cost_overview', component: CostOverview },
];

const RenovationHouseForm = ({ initialValues, onSubmit, isEditMode, step }) => {
  const formValuesRef = useFormValuesRef([], { formValueMock, initialValues });

  const onSubmitStep = useCallback(
    (values) => {
      formValuesRef.current = { ...formValuesRef.current, ...values };
    },
    [formValuesRef],
  );

  const onBack = useCallback(
    (values, callback) => {
      onSubmitStep(values);
      callback();
    },
    [onSubmitStep],
  );

  return (
    <Grid className={s.gridContainer}>
      <StepWizard initialStep={step} transitions={{}} isLazyMount nav={<Nav isEditMode={isEditMode} tabs={tabs} />}>
        {tabs.map(({ label, component: C }) => (
          <C key={label} {...{ onSubmitStep, onBack, formValuesRef, initialValues, onSubmit, isEditMode }} />
        ))}
      </StepWizard>
    </Grid>
  );
};

export default RenovationHouseForm;
