import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Visible } from 'components/common/Grid';
import { HamburgerIcon } from 'react-hamburger-icon';
import { useAppContext } from 'contexts/AppContext';
import settings from 'config/settings';
import { ReactComponent as Logo } from 'assets/images/hyponoe-logo.svg';
import routePaths from 'router/route-paths';
import { useIntl } from 'react-intl';
import styles from './Navbar.module.scss';

const Navbar = ({ fluid, children, hideHamburger, auth }) => {
  const { sidebarOpen, toggleSidebar } = useAppContext();
  const { formatMessage: t } = useIntl();

  return (
    <header className={styles.navbar}>
      <div className={classnames({ 'container-fluid': fluid, 'root-container': !fluid }, styles.navbarInner)}>
        <div className={styles.logoLinkContainer} style={auth && { textAlign: 'left' }}>
          <Link to={auth ? routePaths.default : routePaths.myProjects}>
            <Logo className={styles.logo} title={t({ id: 'alt_text.navbar.logo' })} />
          </Link>
          {settings.isStaging ? <span className={styles.environment}>staging</span> : null}
          {settings.isDevelopment ? <span className={styles.environment}>local</span> : null}
        </div>
        <div className={styles.navbarContent} style={auth && { padding: 0 }}>
          {children}
          {!hideHamburger ? (
            <Visible sm xs>
              <div className={styles.hamburger}>
                <HamburgerIcon open={sidebarOpen} onClick={() => toggleSidebar()} />
              </div>
            </Visible>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
