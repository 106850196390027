import { createEquipmentTypeValidator } from 'utils/yup';
import { projectTypeToSchemaMap } from './Features';

// eslint-disable-next-line import/prefer-default-export
export const getPartOfValidationSchema = ({ projectType, groupName }) => {
  const featuresSchema = projectTypeToSchemaMap[projectType] || [];
  return featuresSchema
    .filter(({ group }) => group === groupName)
    .reduce((schema, feature) => {
      const tmp = {};
      if (Array.isArray(feature.form)) {
        feature.form.forEach((field) => {
          if (field.name.includes('equipment_type')) {
            tmp[field.name] = createEquipmentTypeValidator(feature.name);
          }
        });
      }
      return { ...schema, ...tmp };
    }, {});
};
