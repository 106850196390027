import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency, formatValue } from 'utils/helpers';
import cn from 'classnames';
import pick from 'lodash/pick';
import { Small } from 'components/common/heap';
import { Close, Pencil } from 'assets/icons';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import IconButton from 'components/common/IconButton';
import NumberInputSimple from 'components/inputs/NumberInputSimple';
import s from '../CO2CalculatorResult.module.scss';

const HeatingResult = ({ co2Calculations, onSaveButtonClick }) => {
  const { formatMessage: t } = useIntl();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef();

  const whiteListValues = [
    'specific_heating_demand',
    'heating_factor_kea',
    'heating_factor_ghg',
    'total_heating_demand',
    'primary_energy_for_heating',
    'heating_ghg_emission',
    'heating_ghg_equivalent_car',
    'heating_ghg_equivalent_plane',
    'heating_ghg_equivalent_trees',
    'total_heating_costs',
  ];

  const heatingResultValues = Object.entries(pick(co2Calculations.co2_emissions_before.result, whiteListValues)).reduce(
    (acc, [key, value]) => {
      if (key === 'primary_energy_for_heating') {
        const tmpValue = Number.isFinite(value.user_input) ? value.user_input : value.calculated_value;
        const parsedValue = parseFloat(tmpValue).toFixed(3) * 1;
        return { ...acc, [key]: parsedValue };
      }
      return { ...acc, [key]: parseFloat(value).toFixed(3) * 1 };
    },
    {},
  );

  return (
    <table className={cn('table last-col-text-right', s.resultTable)}>
      <thead>
        <tr>
          <td colSpan="2">
            <h4>{t({ id: 'renovation_house_wizard.heating_result.table_title' })}</h4>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.specific_heating_demand' })}
            <Small>{t({ id: 'renovation_house_wizard.heating_result.specific_heating_demand_description' })}</Small>
          </td>
          <td>{formatValue(heatingResultValues.specific_heating_demand)} kWh/m²</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.heating_factor_kea' })}
            <Small>{t({ id: 'renovation_house_wizard.heating_result.heating_factor_kea_description' })}</Small>
          </td>
          <td>{formatValue(heatingResultValues.heating_factor_kea)} kWh/kWh</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.heating_factor_ghg' })}
            <Small>{t({ id: 'renovation_house_wizard.heating_result.heating_factor_ghg_description' })}</Small>
          </td>
          <td>{formatValue(heatingResultValues.heating_factor_ghg)} kg CO2/kWh</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.total_heating_demand' })}
            <Small>{t({ id: 'renovation_house_wizard.heating_result.total_heating_demand_description' })}</Small>
          </td>
          <td>{formatValue(heatingResultValues.total_heating_demand)} kWh/a</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.primary_energy_for_heating' })}
            <Small>{t({ id: 'renovation_house_wizard.heating_result.primary_energy_for_heating_description' })}</Small>
          </td>
          <td>
            {showInput ? (
              <NumberInputSimple
                name="primary_energy_for_heating"
                ref={inputRef}
                defaultValue={heatingResultValues.primary_energy_for_heating}
              />
            ) : (
              <span>{formatValue(heatingResultValues.primary_energy_for_heating)} kWh/a</span>
            )}
          </td>
          <td>
            {showInput ? (
              <div className={s.buttonsWrapper}>
                <IconButton onClick={() => setShowInput(false)}>
                  <Close />
                </IconButton>
                <IconButton
                  onClick={() =>
                    onSaveButtonClick({
                      callback: () => setShowInput(false),
                      fieldName: 'primary_energy_for_heating',
                      fieldValue: Number(inputRef.current.state.numAsString),
                    })
                  }
                >
                  <TickIcon />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={() => setShowInput(true)}>
                <Pencil />
              </IconButton>
            )}
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.heating_ghg_emission' })}
            <Small>{t({ id: 'renovation_house_wizard.heating_result.heating_ghg_emission_description' })}</Small>
          </td>
          <td>{formatValue(heatingResultValues.heating_ghg_emission)} kg/a</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.heating_ghg_equivalent_car' })}
            <Small>{t({ id: 'renovation_house_wizard.heating_result.heating_ghg_equivalent_car_description' })}</Small>
          </td>
          <td>{formatValue(heatingResultValues.heating_ghg_equivalent_car)} km</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.heating_ghg_equivalent_plane' })}
            <Small>
              {t({ id: 'renovation_house_wizard.heating_result.heating_ghg_equivalent_plane_description' })}
            </Small>
          </td>
          <td>
            {formatValue(heatingResultValues.heating_ghg_equivalent_plane)}
            <Small>{t({ id: 'renovation_house_wizard.number_of_economy_class_flights' })}</Small>
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.heating_ghg_equivalent_trees' })}
            <Small>
              {t({ id: 'renovation_house_wizard.heating_result.heating_ghg_equivalent_trees_description' })}
            </Small>
          </td>
          <td>
            {formatValue(heatingResultValues.heating_ghg_equivalent_trees)}
            <Small>{t({ id: 'renovation_house_wizard.number_stored_trees' })}</Small>
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.heating_result.heating_costs' })}
            <Small>{t({ id: 'renovation_house_wizard.heating_result.heating_costs_description' })}</Small>
          </td>
          <td>{formatCurrency(heatingResultValues.total_heating_costs)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default HeatingResult;
