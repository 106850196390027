import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency, formatValue } from 'utils/helpers';
import cn from 'classnames';
import pick from 'lodash/pick';
import { Small } from 'components/common/heap';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import NumberInputSimple from 'components/inputs/NumberInputSimple';
import IconButton from 'components/common/IconButton';
import { Close, Pencil } from 'assets/icons';
import s from '../CO2CalculatorResult.module.scss';

const ElectricityResult = ({ co2Calculations, onSaveButtonClick }) => {
  const { formatMessage: t } = useIntl();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef();

  const whiteListValues = [
    'total_power_consumption_per_year',
    'total_power_production_per_year',
    'own_consumption',
    'power_consumption_from_energy',
    'electrical_feed_in_to_grid',
    'ghg_savings_solar_power_system',
    'ghg_balance_of_power_consumption',
    'electricity_ghg_equivalent_car',
    'electricity_ghg_equivalent_plane',
    'electricity_ghg_equivalent_trees',
    'solar_power_system_helps_to_save',
    'total_electricity_costs',
  ];

  const electricityResultValues = Object.entries(
    pick(co2Calculations.co2_emissions_before.result, whiteListValues),
  ).reduce((acc, [key, value]) => {
    if (key === 'total_power_consumption_per_year') {
      const tmpValue = Number.isFinite(value.user_input) ? value.user_input : value.calculated_value;
      const parsedValue = parseFloat(tmpValue).toFixed(3) * 1;
      return { ...acc, [key]: parsedValue };
    }
    return { ...acc, [key]: parseFloat(value).toFixed(3) * 1 };
  }, {});

  return (
    <table className={cn('table last-col-text-right', s.resultTable)}>
      <thead>
        <tr>
          <td colSpan="2">
            <h4>{t({ id: 'renovation_house_wizard.electricity_result.table_title' })}</h4>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.electricity_result.total_power_consumption_per_year' })}
            <Small>
              {t({ id: 'renovation_house_wizard.electricity_result.total_power_consumption_per_year_description' })}
            </Small>
          </td>
          <td>
            {showInput ? (
              <NumberInputSimple
                name="primary_energy_for_heating"
                ref={inputRef}
                defaultValue={electricityResultValues.total_power_consumption_per_year}
              />
            ) : (
              <span>{formatValue(electricityResultValues.total_power_consumption_per_year)} kWh/a</span>
            )}
          </td>
          <td>
            {showInput ? (
              <div className={s.buttonsWrapper}>
                <IconButton onClick={() => setShowInput(false)}>
                  <Close />
                </IconButton>
                <IconButton
                  onClick={() =>
                    onSaveButtonClick({
                      callback: () => setShowInput(false),
                      fieldName: 'total_power_consumption_per_year',
                      fieldValue: Number(inputRef.current.state.numAsString),
                    })
                  }
                >
                  <TickIcon />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={() => setShowInput(true)}>
                <Pencil />
              </IconButton>
            )}
          </td>
        </tr>
        {electricityResultValues.total_power_production_per_year ? (
          <tr>
            <td>
              {t({ id: 'renovation_house_wizard.electricity_result.total_power_production_per_year' })}
              <Small>
                {t({ id: 'renovation_house_wizard.electricity_result.total_power_production_per_year_description' })}
              </Small>
            </td>
            <td>{formatValue(electricityResultValues.total_power_production_per_year)} kWh/a</td>
          </tr>
        ) : null}
        {electricityResultValues.own_consumption ? (
          <tr>
            <td>
              {t({ id: 'renovation_house_wizard.electricity_result.own_consumption' })}
              <Small>{t({ id: 'renovation_house_wizard.electricity_result.own_consumption' })}</Small>
            </td>
            <td>{formatValue(electricityResultValues.own_consumption)} kWh/a</td>
          </tr>
        ) : null}
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.electricity_result.power_consumption_from_energy' })}
            <Small>
              {t({ id: 'renovation_house_wizard.electricity_result.power_consumption_from_energy_description' })}
            </Small>
          </td>
          <td>{formatValue(electricityResultValues.power_consumption_from_energy)} kWh/a</td>
        </tr>
        {electricityResultValues.electrical_feed_in_to_grid ? (
          <tr>
            <td>
              {t({ id: 'renovation_house_wizard.electricity_result.electrical_feed_in_to_grid' })}
              <Small>
                {t({ id: 'renovation_house_wizard.electricity_result.electrical_feed_in_to_grid_description' })}
              </Small>
            </td>
            <td>{formatValue(electricityResultValues.electrical_feed_in_to_grid)} kWh/a</td>
          </tr>
        ) : null}
        {electricityResultValues.ghg_savings_solar_power_system ? (
          <tr>
            <td>
              {t({ id: 'renovation_house_wizard.electricity_result.ghg_savings_solar_power_system' })}
              <Small>
                {t({ id: 'renovation_house_wizard.electricity_result.ghg_savings_solar_power_system_description' })}
              </Small>
            </td>
            <td>{formatValue(electricityResultValues.ghg_savings_solar_power_system)} kg/a</td>
          </tr>
        ) : null}
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.electricity_result.ghg_balance_of_power_consumption' })}
            <Small>
              {t({ id: 'renovation_house_wizard.electricity_result.ghg_balance_of_power_consumption_description' })}
            </Small>
          </td>
          <td>{formatValue(electricityResultValues.ghg_balance_of_power_consumption)} kg/a</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.electricity_result.electricity_ghg_equivalent_car' })}
            <Small>
              {t({ id: 'renovation_house_wizard.electricity_result.electricity_ghg_equivalent_car_description' })}
            </Small>
          </td>
          <td>{formatValue(electricityResultValues.electricity_ghg_equivalent_car)} km</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.electricity_result.electricity_ghg_equivalent_plane' })}
            <Small>
              {t({ id: 'renovation_house_wizard.electricity_result.electricity_ghg_equivalent_plane_description' })}
            </Small>
          </td>
          <td>
            {formatValue(electricityResultValues.electricity_ghg_equivalent_plane)}
            <Small>{t({ id: 'renovation_house_wizard.number_of_economy_class_flights' })}</Small>
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.electricity_result.electricity_ghg_equivalent_trees' })}
            <Small>
              {t({ id: 'renovation_house_wizard.electricity_result.electricity_ghg_equivalent_trees_description' })}
            </Small>
          </td>
          <td>
            {formatValue(electricityResultValues.electricity_ghg_equivalent_trees)}
            <Small>{t({ id: 'renovation_house_wizard.number_stored_trees' })}</Small>
          </td>
        </tr>
        {electricityResultValues.solar_power_system_helps_to_save ? (
          <tr>
            <td>
              {t({ id: 'renovation_house_wizard.electricity_result.solar_power_system_helps_to_save' })}
              <Small>
                {t({ id: 'renovation_house_wizard.electricity_result.solar_power_system_helps_to_save_description' })}
              </Small>
            </td>
            <td>{formatCurrency(electricityResultValues.solar_power_system_helps_to_save)}</td>
          </tr>
        ) : null}
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.electricity_result.total_electricity_costs' })}
            <Small>{t({ id: 'renovation_house_wizard.electricity_result.total_electricity_costs_description' })}</Small>
          </td>
          <td>{formatCurrency(electricityResultValues.total_electricity_costs)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ElectricityResult;
