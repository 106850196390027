import { useAppContext } from 'contexts/AppContext';
import React, { useMemo } from 'react';
import ReactLoading from 'react-loading';
import themeVariables from '../../config/theme';

const themeToLoadingColor = {
  primary: themeVariables.primaryColor,
  green: themeVariables.successColor,
};

const Loading = ({ size, color }) => {
  const { theme } = useAppContext();
  const spinnerColor = useMemo(() => color || themeToLoadingColor[theme] || theme.primaryColor, [color, theme]);
  return <ReactLoading type="spin" color={spinnerColor} height={size} width={size} />;
};

export default Loading;
