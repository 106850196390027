import Alert from 'components/common/Alert';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useProjectBudgetQuery from 'hooks/budget/useProjectBudgetQuery';
import { get, isNull } from 'lodash';
import LifestyleCalculatorForm from 'components/budget/form';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { BUYING_HOUSE, NEW_APARTMENT, NEW_BUILDING, RENOVATION_HOUSE } from 'constants/constants';
import { getInitialLifestyleCalculatorValues } from './LifestyleCalculator';
import { LifestyleOverview } from './LifestyleOverview';

const projectType2LifestyleProjectType = {
  [NEW_BUILDING]: 'building',
  [RENOVATION_HOUSE]: 'renovation',
  [BUYING_HOUSE]: 'buying',
  [NEW_APARTMENT]: 'buying',
};

const LifestyleCalculatorWithProject = () => {
  const { id: projectId } = useParams();
  const { myBudget, project, loading } = useProjectBudgetQuery(projectId);
  const { formatMessage: t } = useIntl();

  const formValues = get(project, 'form_values', {});
  const { living_space, apartment_area, house_area } = formValues;

  const squareMeters = useMemo(() => [living_space, apartment_area, house_area, 0].find(Number), [
    apartment_area,
    house_area,
    living_space,
  ]);
  const initialValues = useMemo(
    () => ({
      ...getInitialLifestyleCalculatorValues(myBudget),
      project_type: projectType2LifestyleProjectType[project?.type],
      square_meters: squareMeters,
    }),
    [myBudget, squareMeters, project?.type],
  );
  const calculatorCompleted = useMemo(() => (!myBudget ? false : isNull(myBudget.step)), [myBudget]);
  const initialStep = useMemo(() => (!myBudget || isNull(myBudget.step) ? 1 : myBudget.step), [myBudget]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!project) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'errors.project_not_found' })}
      </Alert>
    );
  }

  const monthlyRate = get(myBudget, 'calculation.monthly_rate_loan.calculated_value', null);

  if (!myBudget || !isNull(myBudget.step)) {
    return <LifestyleCalculatorForm {...{ initialValues, myBudget, calculatorCompleted, initialStep }} />;
  }

  return <LifestyleOverview {...{ projectId, myBudget, monthlyRate, t }} />;
};

export default LifestyleCalculatorWithProject;
