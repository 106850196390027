// import clamp from 'lodash/clamp';
import React, { memo, useEffect, useRef, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import NumberFormat from 'react-number-format';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import cn from 'classnames';
import { spliceLabelOnMobile } from 'utils/helpers';
import clamp from 'lodash/clamp';
import { areEqualFields, onKeyDown } from 'utils/form';

const config = {
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  de: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};
const NumberInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  icon,
  onChange,
  sideEffectOnChange,
  defaultValue,
  className,
  textAlign = 'left',
  largeLabel = false,
  required,
  skipScrollingToField,
  ...props
}) => {
  const didMountRef = useRef(false);
  const { locale } = useIntl();
  const [, forceRerender] = React.useState(false);
  const { label, style, min, max = 5000000, disabled, description, thousandSeparator, ...rest } = props;
  const { value, name } = field;
  const fieldWrapperRef = useRef();

  const handleBlur = useCallback(() => {
    setFieldTouched(name);
  }, [setFieldTouched, name]);

  const handleChange = React.useCallback(
    (values) => {
      let v;
      if (isNil(values.floatValue)) {
        v = null;
      } else {
        v = clamp(values.floatValue, min, max);
      }
      setFieldValue(name, v);
      forceRerender((b) => !b);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange({ name, value: v });
    },
    [max, min, name, setFieldValue, sideEffectOnChange],
  );

  const getClassNameForInput =
    touched[field.name] && errors[field.name] ? 'error-input default-input' : 'default-input';

  useEffect(() => {
    if (didMountRef.current) return;
    if (!Number.isFinite(value) && Number.isFinite(defaultValue)) {
      setFieldValue(name, defaultValue);
    }
    didMountRef.current = true;
  }, [name, value, defaultValue, setFieldValue, min]);

  const fieldWrapperClassnames = useMemo(() => {
    let classes = 'field-wrapper';
    if (largeLabel) {
      classes += ' field-with-large-label';
    }
    if (!skipScrollingToField) {
      classes += ' scroll-to-field';
    }
    return cn(classes, className);
  }, [skipScrollingToField, largeLabel, className]);

  const handleKeyDown = useCallback(
    (e) => {
      if (Number.isFinite(value)) onKeyDown(e, fieldWrapperRef);
    },
    [value],
  );

  return (
    <div className={fieldWrapperClassnames} style={style} ref={fieldWrapperRef}>
      {icon && <div style={{ position: 'absolute' }}>{icon}</div>}
      <NumberFormat
        allowNegative={false}
        allowLeadingZeros={false}
        allowEmptyFormatting={false}
        {...(!isNil(thousandSeparator) ? { thousandSeparator } : config[locale])}
        name={name}
        value={value}
        onValueChange={onChange || handleChange}
        onBlur={handleBlur}
        autoComplete="off"
        id={name}
        className={cn(getClassNameForInput, `text-${textAlign}`, disabled && 'disabled-input')}
        disabled={disabled}
        style={icon && { paddingLeft: '50px' }}
        {...rest}
        type="text"
        onKeyDown={handleKeyDown}
      />
      <label
        htmlFor={name}
        className={!isNil(value) ? 'label-when-focused-input number-label' : 'number-label'}
        id={`label-${name}`}
      >
        {spliceLabelOnMobile(label)}
      </label>
      {description ? (
        <div className="text-left">
          <small className="muted">{description}</small>
        </div>
      ) : null}
      {get(touched, name) && get(errors, name) && <div className="error-text">{get(errors, name)}</div>}
    </div>
  );
};

export default memo(NumberInput, areEqualFields);
