import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Settings, Pencil } from 'assets/icons';
import { ReactComponent as CloudDownloadIcon } from 'assets/icons/cloud-download.svg';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import Tooltip from 'components/common/Tooltip';
import routePaths, { routePatterns } from 'router/route-paths';
import IconButton from 'components/common/IconButton';
import { useIntl } from 'react-intl';
import useMyBudget from 'hooks/budget/useMyBudget';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { isNull } from 'lodash';
import { ReactComponent as Img } from 'assets/images/lifestyle-calculator/budget-step-2.svg';
import BudgetSummary from 'components/budget/BudgetSummary';
import LifestyleCalculator from './LifestyleCalculator';
import s from './LifestyleOverview.module.scss';

export const LifestyleOverview = ({ myBudget }) => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <div className={s.header}>
            <PageTitle>{t({ id: 'page_titles.my_budget' })}</PageTitle>
            <div className={s.actionBar}>
              <Tooltip overlay={<p>{t({ id: 'budget.tooltip_download_button' })}</p>} placement="left">
                <div className="mr-2">
                  <a href={myBudget.pdf} target="_blank" rel="noopener noreferrer" className="link">
                    <IconButton>
                      <CloudDownloadIcon />
                    </IconButton>
                  </a>
                </div>
              </Tooltip>
              <Tooltip overlay={<p>{t({ id: 'budget.tooltip_edit_button' })}</p>} placement="left">
                <div className="mr-2">
                  <Link to={routePaths.lifestyleCalculatorEditValues}>
                    <IconButton>
                      <Pencil />
                    </IconButton>
                  </Link>
                </div>
              </Tooltip>
              <Tooltip overlay={<p>{t({ id: 'budget.tooltip_settings_button' })}</p>} placement="left">
                <div>
                  <Link
                    to={
                      projectId
                        ? routePatterns.lifestyleCalculatorWithProject.stringify({ id: projectId })
                        : routePaths.lifestyleCalculator
                    }
                  >
                    <IconButton>
                      <Settings />
                    </IconButton>
                  </Link>
                </div>
              </Tooltip>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={6}>
          <BudgetSummary {...{ myBudget }} />
        </Col>
        <Col md={0} lg={0} xl={6} first="xs" last="xl">
          <div className="sticky-img-box">
            <Img width="100%" height="100%" />
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

const LifestyleOverviewContainer = () => {
  const [myBudget, loading] = useMyBudget();

  if (loading) return <LoadingOverlay />;

  if (!myBudget || !isNull(myBudget.step)) return <LifestyleCalculator />;

  return <LifestyleOverview {...{ myBudget }} />;
};

export default LifestyleOverviewContainer;
