import { Header, SectionWrapper } from 'components/common/heap';
import React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import EnergyAndCO2Balance from './CalculationTables/EnergyAndCO2BalanceTable';
import AllCalculations from './CalculationTables/AllCalculations';
import EnergyClassesAndIndicatorsSection from './EnergyClassesAndIndicators';
import EnergyReductionAndGhgEquivalents from './EnergyReductionAndGhgEquivalents';

const CO2CalculatorResult = ({ co2Calculations, nextStep, previousStep }) => {
  const { formatMessage: t } = useIntl();

  return (
    <Grid>
      <Row between="md">
        <Col md={12}>
          <Header>
            <h3>{t({ id: 'renovation_house_wizard.house_energy_balance.header' })}</h3>
          </Header>

          <EnergyClassesAndIndicatorsSection {...{ t, co2Calculations, nextStep }} />

          <Header>
            <h3>{t({ id: 'renovation_house_wizard.potential_energy_balance.title' })}</h3>
            <small className="muted">{t({ id: 'renovation_house_wizard.potential_energy_balance.description' })}</small>
          </Header>

          <EnergyReductionAndGhgEquivalents {...{ t, co2Calculations, nextStep, previousStep }} />

          <SectionWrapper>
            <EnergyAndCO2Balance {...{ co2Calculations }} />
          </SectionWrapper>
          <AllCalculations {...{ co2Calculations }} />
        </Col>
      </Row>
    </Grid>
  );
};

export default CO2CalculatorResult;
