import { primaryColor } from 'styles/variables.scss';

const SelectCustomStyles = {
  container: (style) => ({
    ...style,
    outline: 'none',
    borderBottom: '1px solid #adadad',
  }),
  control: (style) => ({
    // none of react-select's styles are passed to <Control />
    ...style,
    border: 'none',
    outline: 'none',
    borderRadius: 0,
    backroundColor: 'red',

    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
    },
  }),
  dropdownIndicator: (style) => ({
    ...style,
    color: primaryColor,
  }),
  valueContainer: (style) => ({
    ...style,
    padding: 0,
    marginLeft: '-2px',
  }),
  singleValue: (style) => ({
    ...style,
    marginLeft: '2px',
  }),
  option: (style) => ({
    ...style,
    textAlign: 'left',
  }),
};

const featuresInputStyle = {
  display: 'inlineBlock',
  width: '90%',
};

export { featuresInputStyle, SelectCustomStyles };
