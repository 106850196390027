import React, { memo } from 'react';
import settings from 'config/settings';
import GoogleMapReact from 'google-map-react';
import { isEqual } from 'lodash';

const getMapOptions = (maps) => {
  return {
    streetViewControl: true,
    scaleControl: true,
    styles: [
      {
        featureType: 'poi.business',
        elementType: 'labels',
      },
    ],
    gestureHandling: 'greedy',
    disableDoubleClickZoom: true,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.TOP_LEFT,
      mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID, maps.MapTypeId.TERRAIN],
    },

    zoomControl: true,
    clickableIcons: false,
  };
};

const MapContainer = ({ position }) => {
  return (
    <div style={{ width: '100%', height: '370px', position: 'static' }}>
      <GoogleMapReact
        options={getMapOptions}
        bootstrapURLKeys={{ key: settings.googleApiKey }}
        defaultCenter={position}
        defaultZoom={15}
      />
    </div>
  );
};

export default memo(MapContainer, isEqual);
