import React from 'react';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookiebot from 'react-cookiebot';
import { AppContextProvider } from './contexts/AppContext';
import settings from './config/settings';
import i18nMessages from './i18n';
import RootRouter from './router/RootRouter';
import startup from './startup';
import apolloClient from './graphql/apollo';
import 'normalize.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import './styles/index.scss';

startup();

const locale = window?.localStorage?.getItem('language') || settings.defaultLocale;

const App = () => (
  <ApolloProvider client={apolloClient}>
    <IntlProvider locale={locale} messages={i18nMessages[locale]}>
      <AppContextProvider locale={locale}>
        <RootRouter />
        <Cookiebot domainGroupId={settings.cookiebotDomainGroupId} />
      </AppContextProvider>
    </IntlProvider>
    <ToastContainer />
  </ApolloProvider>
);

export default App;
