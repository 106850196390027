/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import ErrorMessage from 'components/common/ErrorMessage';
import Modal from 'components/common/Modal';
import Checkbox from 'components/inputs/Checkbox';
import TextArea from 'components/inputs/TextArea';
import TextInput from 'components/inputs/TextInput';
import { Field, Form, Formik } from 'formik';
import useTransferProject from 'hooks/loan-consultant/useTransferProject';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import s from './CustomerInfoModal.module.scss';

const useTransferProjectHandler = (projectId, hideModal) => {
  const transferProject = useTransferProject();

  const handleSubmit = useCallback(
    async ({ transferWithBudget, ...values }, { setSubmitting, setErrors }) => {
      try {
        await transferProject({ transferWithBudget, input: { ...values }, projectId });
        hideModal();
      } catch (error) {
        setErrors({ form: error.message });
      } finally {
        setSubmitting(false);
      }
    },
    [hideModal, projectId, transferProject],
  );
  return handleSubmit;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(<FormattedMessage id="errors.fill_field" />),
  firstName: yup.string().required(<FormattedMessage id="errors.fill_field" />),
  lastName: yup.string().required(<FormattedMessage id="errors.fill_field" />),
});

const CustomerInfoModal = ({ isVisible, hideModal, projectId, name: projectName }) => {
  const { formatMessage: t } = useIntl();
  const onSubmit = useTransferProjectHandler(projectId, hideModal);

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    message: '',
    transferWithBudget: false,
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        isVisible={isVisible}
        close={hideModal}
        headerText={t({ id: 'my_projects.transfer_project' }, { projectName })}
      >
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ isSubmitting, errors }) => (
            <>
              <Form>
                <Field
                  type="email"
                  name="email"
                  lowerCase
                  component={TextInput}
                  required
                  label={t({ id: 'auth.email' })}
                />
                <Field
                  type="text"
                  name="firstName"
                  component={TextInput}
                  required
                  label={t({ id: 'user.first_name' })}
                />
                <Field type="text" name="lastName" component={TextInput} required label={t({ id: 'user.last_name' })} />
                <Field
                  label={t({ id: 'invite_user.transfer_project_with_budget' })}
                  name="transferWithBudget"
                  component={Checkbox}
                  description={t({ id: 'invite_user.transfer_budget_description' })}
                />
                <Field name="message" component={TextArea} label={t({ id: 'invite_user.message' })} minRows={7} />

                {errors.form && <ErrorMessage message={errors.form} />}

                <div className={s.btnWrapper}>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    color="primary"
                    type="submit"
                    endIcon={<ArrowForward />}
                  >
                    {t({ id: 'my_projects.transfer_project_button' })}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default CustomerInfoModal;
