import { LOAN_BUILD, LOAN_BUYING, LOAN_RENOVATION } from 'constants/constants';
import { isUndefined } from 'lodash';
import { __ } from 'utils/form';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  renovation_costs: yup
    .mixed()
    .notRequired()
    .when('type', {
      is: (type) => type === LOAN_RENOVATION,
      then: yup
        .string()
        .required(__('errors.fill_field'))
        .typeError(__('errors.fill_field')),
    }),
  has_property: yup
    .mixed()
    .notRequired()
    .when('type', {
      is: (type) => type === LOAN_BUILD,
      then: yup
        .string()
        .required(__('errors.choose_one'))
        .typeError(__('errors.choose_one')),
    }),
  estimated_property_price: yup
    .mixed()
    .notRequired()
    .when('has_property', {
      is: (buildingPlot) => !isUndefined(buildingPlot) && buildingPlot,
      then: yup
        .string()
        .required(__('errors.fill_field'))
        .typeError(__('errors.fill_field')),
    }),
  construction_costs: yup
    .mixed()
    .notRequired()
    .when('type', {
      is: (type) => type === LOAN_BUILD,
      then: yup
        .string()
        .required(__('errors.fill_field'))
        .typeError(__('errors.fill_field')),
    }),
  purchase_price: yup
    .mixed()
    .notRequired()
    .when('type', {
      is: (type) => type === LOAN_BUYING,
      then: yup
        .string()
        .required(__('errors.fill_field'))
        .typeError(__('errors.fill_field')),
    }),
  buying_with_agent: yup
    .mixed()
    .notRequired()
    .when('type', {
      is: (type) => type === LOAN_BUYING,
      then: yup
        .string()
        .required(__('errors.choose_one'))
        .typeError(__('errors.choose_one')),
    }),
  period: yup
    .number()
    .required(__('errors.choose_one'))
    .typeError(__('errors.choose_one')),
  interest_type: yup
    .string()
    .required(__('errors.choose_one'))
    .typeError(__('errors.choose_one')),
  fixed_interest_period: yup
    .mixed()
    .notRequired()
    .when('interest_type', {
      is: (interestType) => interestType === 'fixed',
      then: yup
        .string()
        .required(__('errors.choose_one'))
        .typeError(__('errors.choose_one')),
    }),
});

export default validationSchema;
