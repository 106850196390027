/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useInjectSprengnetterValues from 'hooks/useInjectSprengnetterValues';
import Button from 'components/common/Button';
import { formatCurrency } from 'utils/helpers';
import { BUYING_HOUSE, GUEST, LAND_ESTIMATION, LOAN_CONSULTANT, NEW_BUILDING } from 'constants/constants';
import useHasRequiredRole from 'hooks/useHasRequiredRole';
import Alert from 'components/common/Alert';
import Loading from 'components/common/Loading';
import { get, pick } from 'lodash';
import equal from 'fast-deep-equal/es6/react';
import { toast } from 'react-toastify';
import s from './SprengnetterLandEstimation.module.scss';
import { whiteListBuyingHouseValues } from './SprengnetterEstimation';
import { EstimationNotAvailableForGuest } from './SprengnetterEstimation.components';

const EstimatedSprengnetterValues = ({ estimatedPropertyPrice, isActualData, landArea, showNote }) => {
  const { formatMessage: t } = useIntl();

  return (
    <div className="my-4">
      {estimatedPropertyPrice
        ? isActualData && (
            <div className={s.sprengnetter}>
              <h2 className="mt-0">{t({ id: 'sprengnetter_estimation.sprengnetter_land.title' })}</h2>
              <p>{t({ id: 'project_wizard.land_estimation' })}</p>
              <b>
                {formatCurrency(estimatedPropertyPrice)}{' '}
                {t(
                  { id: 'project_wizard.land_estimation_per_square_meter' },
                  { value: parseFloat(estimatedPropertyPrice / landArea).toFixed(2) },
                )}
              </b>
              {showNote ? <p className={s.note}>{t({ id: 'project_wizard.land_estimation_note' })}</p> : null}
            </div>
          )
        : null}
    </div>
  );
};

const whiteListLandEstimationValues = ['coordinates.lat', 'coordinates.lng', 'land_area'];

const whiteListFormValuesByProjectType = {
  [NEW_BUILDING]: whiteListLandEstimationValues,
  [BUYING_HOUSE]: whiteListBuyingHouseValues,
};

const useInjectSprengnetterValuesHandler = ({ projectId, type, values, sideEffectOnRequest }) => {
  const { formatMessage: t } = useIntl();
  const getSprengnetterValues = useInjectSprengnetterValues({ projectId, type: LAND_ESTIMATION });
  const [isSubmitting, setSubmitting] = useState(false);

  const handleInject = useCallback(async () => {
    setSubmitting(true);
    try {
      const formData = pick(values, whiteListFormValuesByProjectType[type]);
      const isFormInvalid = Object.values(formData).some((value) => value === null || typeof value === 'undefined');
      if (isFormInvalid || !values.full_address) {
        toast.error(t({ id: 'errors.fill_all_fields' }));
      } else {
        await sideEffectOnRequest();
        await getSprengnetterValues();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  }, [getSprengnetterValues, sideEffectOnRequest, t, type, values]);

  return [handleInject, isSubmitting];
};

const SprengnetterLandEstimation = ({
  values,
  projectType,
  requestsCount,
  loading,
  calculations,
  sideEffectOnRequest,
}) => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const isGuest = useHasRequiredRole(GUEST);

  const [getSprengnetterValues, isSubmitting] = useInjectSprengnetterValuesHandler({
    projectId,
    values,
    sideEffectOnRequest,
    type: projectType,
  });

  const sprengnetter = get(calculations, 'sprengnetter_land', {});
  const sprengnetterFromValues = get(calculations, 'sprengnetter_land.form_values', {});

  const isActualData = useMemo(
    () =>
      equal(pick(sprengnetterFromValues, whiteListLandEstimationValues), pick(values, whiteListLandEstimationValues)),
    [sprengnetterFromValues, values],
  );

  const isLoanConsultant = useHasRequiredRole(LOAN_CONSULTANT);
  const isAvailableRequests = !isLoanConsultant ? requestsCount < 10 : true;

  if (isGuest) {
    return <EstimationNotAvailableForGuest sprengnetterLand t={t} />;
  }

  if (loading) {
    return <Loading size={50} />;
  }

  if (isActualData && sprengnetter?.market_value) {
    return (
      <EstimatedSprengnetterValues
        isActualData={isActualData}
        showNote={values.has_property}
        estimatedPropertyPrice={sprengnetter?.market_value}
        landArea={values.land_area}
      />
    );
  }

  if (isActualData && sprengnetter?.error) {
    return (
      <Alert color="warning">
        <h2 className="mt-0">{t({ id: 'sprengnetter_estimation.sprengnetter_land.title' })}</h2>
        <div>{t({ id: 'disclaimers.land_sprengnetter_not_available' })}</div>
      </Alert>
    );
  }

  if (!isAvailableRequests) {
    return (
      <Alert color="warning">
        <h2 className="mt-0">{t({ id: 'sprengnetter_estimation.sprengnetter_land.title' })}</h2>
        <div>{t({ id: 'sprengnetter_estimation.ran_out_of_requests' }, { requestsCount })}</div>
      </Alert>
    );
  }

  return (
    <div className="my-4">
      <Alert color="warning">
        <h2 className="mt-0">{t({ id: 'sprengnetter_estimation.sprengnetter_land.title' })}</h2>
        <div>
          {t(
            {
              id: !isLoanConsultant
                ? 'disclaimers.land_sprengnetter_estimation'
                : 'disclaimers.land_sprengnetter_estimation_loan_consultant',
            },
            { requestsCount: requestsCount ?? 0 },
          )}
        </div>
      </Alert>
      <div className={s.btnWrapper}>
        <Button loading={isSubmitting} disabled={isSubmitting} onClick={getSprengnetterValues}>
          {t({ id: 'sprengnetter_estimation.estimate_property_btn' })}
        </Button>
      </div>
    </div>
  );
};

export default memo(SprengnetterLandEstimation, equal);
