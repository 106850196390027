import Button from 'components/common/Button';
import { FastField, Form, Formik, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ArrowBack, ArrowForward } from 'assets/icons';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import Slider from 'components/inputs/Slider';
import Toggler from 'components/inputs/Toggler';
import { defaults, pick } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { LOAN_BUILD, LOAN_BUYING, LOAN_REFINANCE, LOAN_RENOVATION } from 'constants/constants';
import useCalculateExpressLoan from 'hooks/financing/useCalculateExpressLoan';
import Loading from 'components/common/Loading';
import useSearchParams from 'hooks/useSearchParams';
import useExpressLoan from 'hooks/financing/useExpressLoan';
import ScrollToError from 'components/common/ScrollToError';
import { Col, Row } from 'react-flexbox-grid';
import Alert from 'components/common/Alert';
import { grabErrorMessage } from 'utils/helpers';
import { ReactComponent as BankingImg } from 'assets/images/banking.svg';
import s from './ExpressLoanSteps.module.scss';
import validationSchema from './yupSchemas/schemaFirstStep';

export const expressLoanFormValuesKeys = [
  'type',
  'renovation_costs',
  'has_property',
  'estimated_property_price',
  'estimated_purchase_property_price',
  'construction_costs',
  'purchase_price',
  'buying_with_agent',
  'monthly_net_income',
  'own_funds',
  'period',
  'interest_type',
  'fixed_interest_period',
  'outstanding_loan_amount',
  'penalty_payment_interest',
];

const RenovationFields = () => {
  const { formatMessage: t } = useIntl();
  return (
    <FastField
      name="renovation_costs"
      component={SliderWithTooltip}
      min={0}
      step={5000}
      max={2000000}
      units="€"
      label={t({ id: 'express_loan.form.renovation_costs' })}
    />
  );
};

const BuildFields = () => {
  const { formatMessage: t } = useIntl();
  const { values } = useFormikContext();
  return (
    <>
      <FastField
        name="has_property"
        required
        component={Toggler}
        options={[
          {
            label: t({ id: 'yes' }),
            value: true,
          },
          {
            label: t({ id: 'no' }),
            value: false,
          },
        ]}
        label={t({ id: 'express_loan.form.has_property' })}
      />
      {values.has_property ? (
        <FastField
          key="estimated_property_price"
          name="estimated_property_price"
          component={SliderWithTooltip}
          min={0}
          step={5000}
          max={2000000}
          units="€"
          label={t({ id: 'express_loan.form.estimated_property_price' })}
        />
      ) : (
        <FastField
          key="estimated_purchase_property_price"
          name="estimated_purchase_property_price"
          component={SliderWithTooltip}
          min={0}
          step={5000}
          max={2000000}
          units="€"
          label={t({ id: 'express_loan.form.estimated_purchase_property_price' })}
        />
      )}
      <FastField
        name="construction_costs"
        component={SliderWithTooltip}
        min={0}
        step={5000}
        max={2000000}
        units="€"
        label={t({ id: 'express_loan.form.construction_costs' })}
      />
    </>
  );
};

const PurchaseFields = () => {
  const { formatMessage: t } = useIntl();
  return (
    <>
      <FastField
        name="purchase_price"
        component={SliderWithTooltip}
        min={0}
        step={5000}
        max={2000000}
        units="€"
        label={t({ id: 'express_loan.form.purchase_price' })}
      />
      <FastField
        name="buying_with_agent"
        required
        component={Toggler}
        options={[
          {
            label: t({ id: 'yes' }),
            value: true,
          },
          {
            label: t({ id: 'no' }),
            value: false,
          },
        ]}
        label={t({ id: 'express_loan.form.estate_agent' })}
      />
    </>
  );
};

const RefinanceFields = () => {
  const { formatMessage: t } = useIntl();
  return (
    <>
      <FastField
        name="outstanding_loan_amount"
        component={SliderWithTooltip}
        min={0}
        step={5000}
        max={2000000}
        units="€"
        label={t({ id: 'express_loan.form.outstanding_loan_amount' })}
      />
      <FastField
        name="penalty_payment_interest"
        required
        component={Slider}
        min={0}
        max={2}
        step={0.5}
        units="%"
        label={t({ id: 'express_loan.form.penalty_payment_interest' })}
      />
    </>
  );
};

const ExpressLoanFirstStep = ({ initialValues, nextStep, onSubmitStep }) => {
  const { formatMessage: t } = useIntl();
  const calculateExpressLoan = useCalculateExpressLoan();
  const history = useHistory();

  const onSubmit = useCallback(
    async (values) => {
      const { estimated_property_price, has_property, estimated_purchase_property_price } = values;
      const estimatedPropertyPrice = has_property ? estimated_property_price : 0;
      const estimatedPurchasePrice = !has_property ? estimated_purchase_property_price : 0;
      const formData = {
        ...values,
        estimated_property_price: estimatedPropertyPrice,
        estimated_purchase_property_price: estimatedPurchasePrice,
      };
      const expressLoan = await calculateExpressLoan({ formData });
      onSubmitStep(values);
      nextStep();
      history.replace({
        pathname: routePaths.expressLoan,
        search: `?type=${expressLoan.type}&id=${expressLoan._id}`,
      });
    },
    [calculateExpressLoan, history, nextStep, onSubmitStep],
  );

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema, enableReinitialize: true }}>
      {({ isSubmitting, values }) => (
        <Form>
          <ScrollToError />
          {initialValues.type === LOAN_RENOVATION ? <RenovationFields /> : null}
          {initialValues.type === LOAN_BUILD ? <BuildFields /> : null}
          {initialValues.type === LOAN_BUYING ? <PurchaseFields /> : null}
          {initialValues.type === LOAN_REFINANCE ? <RefinanceFields /> : null}
          <FastField
            name="monthly_net_income"
            component={SliderWithTooltip}
            min={0}
            step={500}
            max={20000}
            units="€"
            largeLabel
            label={t({ id: 'express_loan.form.monthly_net_income_borrowers' })}
          />
          <FastField
            name="own_funds"
            component={SliderWithTooltip}
            min={0}
            step={5000}
            max={2000000}
            units="€"
            largeLabel
            label={t({ id: 'express_loan.form.own_funds' })}
          />
          <FastField
            name="period"
            required
            component={Slider}
            min={10}
            max={35}
            step={5}
            label={t({ id: 'express_loan.form.period' })}
          />
          <FastField
            name="interest_type"
            required
            component={Toggler}
            options={[
              {
                label: t({ id: 'express_loan.form.interest_types.variable' }),
                value: 'variable',
              },
              {
                label: t({ id: 'express_loan.form.interest_types.fixed' }),
                value: 'fixed',
              },
            ]}
            label={t({ id: 'loan.interest_terms' })}
          />
          {values.interest_type === 'fixed' && (
            <FastField
              name="fixed_interest_period"
              component={Slider}
              required
              min={10}
              max={20}
              step={5}
              label={t({ id: 'express_loan.form.fixed_interest_period' })}
            />
          )}
          <div className={s.buttonsWrapper}>
            <Link to={routePaths.expressLoanQuickStart}>
              <Button disabled={isSubmitting} startIcon={<ArrowBack />} color="outline">
                {t({ id: 'app.back' })}
              </Button>
            </Link>
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              endIcon={<ArrowForward />}
              color="secondary"
            >
              {t({ id: 'app.submit_form_next' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const initialValuesByType = {
  [LOAN_BUILD]: {
    has_property: true,
    estimated_property_price: 0,
    estimated_purchase_property_price: 0,
    construction_costs: 200000,
  },
  [LOAN_RENOVATION]: {
    renovation_costs: 200000,
  },
  [LOAN_BUYING]: {
    purchase_price: 200000,
    buying_with_agent: true,
  },
  [LOAN_REFINANCE]: {
    outstanding_loan_amount: 0,
    penalty_payment_interest: 0,
  },
};

export default ({ type, ...rest }) => {
  const searchParams = useSearchParams();
  const expressLoanId = searchParams.get('id');
  const { loading, error, expressLoan } = useExpressLoan({ id: expressLoanId });

  const defaultValues = useMemo(
    () => ({
      type,
      ...initialValuesByType[type],
      monthly_net_income: 2500,
      own_funds: 2500,
      period: 10,
      interest_type: 'variable',
      fixed_interest_period: null,
    }),
    [type],
  );

  const initialValues = useMemo(() => {
    const { form_values = {} } = expressLoan || {};
    const formValues = { ...form_values, type };
    return pick(defaults(formValues, defaultValues), expressLoanFormValuesKeys);
  }, [defaultValues, expressLoan, type]);

  if (loading) return <Loading size={50} />;

  if (error) {
    return <Alert color="danger">{grabErrorMessage(error)}</Alert>;
  }

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col md={10} lg={7}>
        <ExpressLoanFirstStep {...rest} {...{ initialValues }} />
      </Col>
      <Col md={10} lg={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BankingImg style={{ maxHeight: '250px' }} />
        </div>
      </Col>
    </Row>
  );
};
