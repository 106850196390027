import PageTitle from 'components/common/PageTitle';
import { pick } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { formatCurrency } from 'utils/helpers';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, SaveIcon } from 'assets/icons';
import useChangeBudgetStep from 'hooks/budget/useChangeBudgetStep';
import { ReactComponent as Img } from 'assets/images/lifestyle-calculator/budget-step-7.svg';
import s from './LifestyleCalculatorSIxthStep.module.scss';

const expenseKeys = [
  'socializing',
  'luxuries',
  'new_car',
  'taking_a_break',
  'hobbies',
  'technology',
  'costs_for_repair',
  'pension_and_insurances',
];

const LifestyleCalculatorSixthStep = ({ nextStep, previousStep, myBudget, calculatorCompleted, clickableTabStep }) => {
  const { formatMessage: t } = useIntl();
  const [changeBudgetStep, executing] = useChangeBudgetStep();
  const { calculation, formData } = myBudget;

  const isEditMode = useMemo(() => calculatorCompleted || myBudget?.step > clickableTabStep, [
    myBudget,
    clickableTabStep,
    calculatorCompleted,
  ]);

  const onSubmit = useCallback(
    async (step) => {
      await changeBudgetStep(step);
      window.scrollTo(0, 0);
      nextStep();
    },
    [changeBudgetStep, nextStep],
  );

  const monthlyExpensesValue = useMemo(() => {
    const monthlyExpenses = pick(calculation, [
      'monthly_expenses',
      'monthly_expenses_kids',
      'monthly_expenses_partner',
    ]);
    return Object.values(monthlyExpenses).reduce((sum, obj) => {
      const cv = Number.isFinite(obj.user_input) ? obj.user_input : obj.calculated_value;
      return sum + cv;
    }, 0);
  }, [calculation]);

  const expenses = useMemo(() => {
    return Object.entries(pick(myBudget.calculation, expenseKeys)).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Number.isFinite(value.user_input) ? value.user_input : value.calculated_value,
      }),
      {},
    );
  }, [myBudget]);

  return (
    <>
      <Row>
        <Col md={12}>
          <PageTitle>{t({ id: 'budget_calculator.sixth_step_title' })}</PageTitle>
        </Col>
      </Row>
      <Row between="xl" center="md">
        <Col md={10} lg={8} xl={6} className="text-left">
          <table className={cn('table last-col-text-right', s.monthlyExpensesTable)}>
            <tbody>
              <tr>
                <td>{t({ id: 'budget_calculator.sixth_step.monthly_income' })}</td>
                <td>{formatCurrency(formData.monthly_income)}</td>
              </tr>
              {formData.monthly_income_partner ? (
                <tr>
                  <td>{t({ id: 'budget_calculator.sixth_step.monthly_income_partner' })}</td>
                  <td>{formatCurrency(formData.monthly_income_partner)}</td>
                </tr>
              ) : null}
              {formData.other_revenues ? (
                <tr>
                  <td>{t({ id: 'budget_calculator.sixth_step.other_revenues' })}</td>
                  <td>{formatCurrency(formData.other_revenues)}</td>
                </tr>
              ) : null}
            </tbody>
            <tbody>
              <tr>
                <td>
                  <b>{t({ id: 'budget_calculator.sixth_step.total_monthly_income' })}</b>
                </td>
                <td>
                  <b>{formatCurrency(calculation.total_monthly_income.calculated_value)}</b>
                </td>
              </tr>
              <tr>
                <td>{t({ id: 'budget_calculator.sixth_step.monthly_expenses' })}</td>
                <td>{formatCurrency(monthlyExpensesValue)}</td>
              </tr>
              {Object.keys(expenses).map((key) => {
                if (!expenses[key]) return null;
                return (
                  <tr key={key}>
                    <td>{t({ id: `budget_calculator.${key}` })}</td>
                    <td>{formatCurrency(expenses[key])}</td>
                  </tr>
                );
              })}
              {formData.amount_of_alimony ? (
                <tr>
                  <td>{t({ id: 'budget_calculator.sixth_step.amount_of_alimony' })}</td>
                  <td>{formatCurrency(formData.amount_of_alimony)}</td>
                </tr>
              ) : null}
              {formData.monthly_loan ? (
                <tr>
                  <td>{t({ id: 'budget_calculator.sixth_step.existing_loan' })}</td>
                  <td>{formatCurrency(formData.monthly_loan)}</td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <b>{t({ id: 'budget_calculator.sixth_step.affordable_loan_repayments' })}</b>
                </td>
                <td>
                  <b>
                    {calculation.monthly_rate_loan.calculated_value > 0
                      ? formatCurrency(calculation.monthly_rate_loan.calculated_value)
                      : t({ id: 'app.none' })}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          <div className={s.buttonsWrapper}>
            <Button disabled={executing} onClick={previousStep} startIcon={<ArrowBack />} color="outline">
              {t({ id: 'app.back' })}
            </Button>
            <Button
              loading={executing}
              disabled={executing}
              onClick={() => onSubmit(7)}
              endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
              className="ml-auto"
              color="secondary"
            >
              {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
            </Button>
          </div>
        </Col>
        <Col md={10} lg={8} xl={6} first="xs" last="xl">
          <div className="sticky-img-box">
            <Img title={t({ id: 'alt_text.budget_calculator.fourth_step' })} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LifestyleCalculatorSixthStep;
