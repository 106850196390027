import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';

const UPDATE_CO2_CALCULATED_VALUES_MUTATION = gql`
  mutation updateCO2CalculatedValues($projectId: ID!, $input: CO2CalculatedValuesInput!) {
    updateCO2CalculatedValues(projectId: $projectId, input: $input) {
      success
    }
  }
`;

const useUpdateCO2CalculatedValues = () => {
  const [updateCO2CalculatedValuesMutation] = useMutation(UPDATE_CO2_CALCULATED_VALUES_MUTATION);

  const handleUpdate = useCallback(
    async (projectId, value) => {
      try {
        await updateCO2CalculatedValuesMutation({ variables: { projectId, input: { ...value } } });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        toast.error(e.message);
      }
    },
    [updateCO2CalculatedValuesMutation],
  );
  return handleUpdate;
};

export default useUpdateCO2CalculatedValues;
