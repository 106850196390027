import React from 'react';
import { useIntl } from 'react-intl';
import IconButton from 'components/common/IconButton';
import { Download, Pencil, Settings } from 'assets/icons';
import { Link } from 'react-router-dom';
import Tooltip from 'components/common/Tooltip';
import { routePatterns } from 'router/route-paths';
import s from './ProjectPlanTables.module.scss';

const ActionButtons = ({ projectId, pdf, onSettingsButtonClick = () => {} }) => {
  const { formatMessage: t } = useIntl();

  return (
    <div className={s.actionBar}>
      <Tooltip overlay={<p>{t({ id: 'planning_categories.tooltip_download_button' })}</p>} placement="top">
        <div>
          <a href={pdf} target="_blank" rel="noopener noreferrer" className="link">
            <IconButton color="primary">
              <Download />
            </IconButton>
          </a>
        </div>
      </Tooltip>
      <Tooltip overlay={<p>{t({ id: 'planning_categories.tooltip_edit_button' })}</p>} placement="top">
        <Link to={routePatterns.planForm.stringify({ id: projectId })}>
          <IconButton color="primary">
            <Pencil />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip overlay={<p>{t({ id: 'planning_categories.tooltip_settings_button' })}</p>} placement="top">
        <Link to={routePatterns.projectDetailsCalculator.stringify({ id: projectId })} onClick={onSettingsButtonClick}>
          <IconButton color="primary">
            <Settings />
          </IconButton>
        </Link>
      </Tooltip>
    </div>
  );
};

export default ActionButtons;
