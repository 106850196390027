import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency, formatValue } from 'utils/helpers';
import cn from 'classnames';
import pick from 'lodash/pick';
import { Small } from 'components/common/heap';
import IconButton from 'components/common/IconButton';
import { Close, Pencil } from 'assets/icons';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import NumberInputSimple from 'components/inputs/NumberInputSimple';
import s from '../CO2CalculatorResult.module.scss';

const HotWaterResult = ({ co2Calculations, onSaveButtonClick }) => {
  const { formatMessage: t } = useIntl();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef();

  const whiteListValues = [
    'primary_energy_for_hot_water',
    'hot_water_ghg_emission',
    'hot_water_ghg_equivalent_car',
    'hot_water_ghg_equivalent_plane',
    'hot_water_ghg_equivalent_trees',
    'total_hot_water_costs',
  ];

  const hotWaterResultValues = Object.entries(
    pick(co2Calculations.co2_emissions_before.result, whiteListValues),
  ).reduce((acc, [key, value]) => {
    if (key === 'primary_energy_for_hot_water') {
      const tmpValue = Number.isFinite(value.user_input) ? value.user_input : value.calculated_value;
      const parsedValue = parseFloat(tmpValue).toFixed(3) * 1;
      return { ...acc, [key]: parsedValue };
    }
    return { ...acc, [key]: parseFloat(value).toFixed(3) * 1 };
  }, {});

  return (
    <table className={cn('table last-col-text-right', s.resultTable)}>
      <thead>
        <tr>
          <td colSpan="2">
            <h4>{t({ id: 'renovation_house_wizard.hot_water_result.table_title' })}</h4>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.hot_water_result.primary_energy_for_hot_water' })}
            <Small>
              {t({ id: 'renovation_house_wizard.hot_water_result.primary_energy_for_hot_water_description' })}
            </Small>
          </td>
          <td>
            {showInput ? (
              <NumberInputSimple
                name="primary_energy_for_heating"
                ref={inputRef}
                defaultValue={hotWaterResultValues.primary_energy_for_hot_water}
              />
            ) : (
              <span>{formatValue(hotWaterResultValues.primary_energy_for_hot_water)}kWh/a</span>
            )}
          </td>
          <td>
            {showInput ? (
              <div className={s.buttonsWrapper}>
                <IconButton onClick={() => setShowInput(false)}>
                  <Close />
                </IconButton>
                <IconButton
                  onClick={() =>
                    onSaveButtonClick({
                      callback: () => setShowInput(false),
                      fieldName: 'primary_energy_for_hot_water',
                      fieldValue: Number(inputRef.current.state.numAsString),
                    })
                  }
                >
                  <TickIcon />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={() => setShowInput(true)}>
                <Pencil />
              </IconButton>
            )}
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.hot_water_result.hot_water_ghg_emission' })}
            <Small>{t({ id: 'renovation_house_wizard.hot_water_result.hot_water_ghg_emission_description' })}</Small>
          </td>
          <td>{formatValue(hotWaterResultValues.hot_water_ghg_emission)} kg/a</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.hot_water_result.hot_water_ghg_equivalent_car' })}
            <Small>
              {t({ id: 'renovation_house_wizard.hot_water_result.hot_water_ghg_equivalent_car_description' })}
            </Small>
          </td>
          <td>{formatValue(hotWaterResultValues.hot_water_ghg_equivalent_car)} km</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.hot_water_result.hot_water_ghg_equivalent_plane' })}
            <Small>
              {t({ id: 'renovation_house_wizard.hot_water_result.hot_water_ghg_equivalent_plane_description' })}
            </Small>
          </td>
          <td>
            {formatValue(hotWaterResultValues.hot_water_ghg_equivalent_plane)}
            <Small>{t({ id: 'renovation_house_wizard.number_of_economy_class_flights' })}</Small>
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.hot_water_result.hot_water_ghg_equivalent_trees' })}
            <Small>
              {t({ id: 'renovation_house_wizard.hot_water_result.hot_water_ghg_equivalent_trees_description' })}
            </Small>
          </td>
          <td>
            {formatValue(hotWaterResultValues.hot_water_ghg_equivalent_trees)}
            <Small>{t({ id: 'renovation_house_wizard.number_stored_trees' })}</Small>
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.hot_water_result.hot_water_costs' })}
            <Small>{t({ id: 'renovation_house_wizard.hot_water_result.hot_water_costs_description' })}</Small>
          </td>
          <td>{formatCurrency(hotWaterResultValues.total_hot_water_costs)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default HotWaterResult;
