import React, { useMemo, useCallback, useState, useRef } from 'react';
import { BudgetStep5 } from 'assets/images/lifestyle-calculator';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import { Field, Form, Formik } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import * as yup from 'yup';
import ButtonSelect from 'components/inputs/ButtonSelect';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import NumberInput from 'components/inputs/NumberInput';
import useCalculateMonthlyExpensesCategoryValue from 'hooks/budget/useCalculateMonthlyExpensesCategoryValue';
import { toast } from 'react-toastify';
import { defaults } from 'lodash';
import s from './LifestyleCalculatorForm.module.scss';

export const OPTIONS = [
  {
    label: 'budget_calculator.not_important_option_label',
    value: 'not_important',
  },
  {
    label: 'budget_calculator.important_option_label',
    value: 'important',
  },
  {
    label: 'budget_calculator.very_important_option_label',
    value: 'very_important',
  },
];

const validationSchema = yup.object().shape({
  socializing_number_value: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  luxuries_number_value: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  new_car_number_value: yup.mixed().when('cars', {
    is: (cars) => !!cars,
    then: yup
      .string()
      .required(__('errors.fill_field'))
      .typeError(__('errors.fill_field')),
  }),
  taking_a_break_number_value: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  hobbies_number_value: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  technology_number_value: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  pension_and_insurances_number_value: yup
    .string()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
});

const useMonthlyExpensesCategoryValueHandler = (formikRef) => {
  const calculateValue = useCalculateMonthlyExpensesCategoryValue();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = useCallback(
    async (name, value) => {
      setSubmitting(true);
      try {
        const category = await calculateValue({ categoryName: name, importanceValue: value });
        formikRef.current.setFieldValue(`${category.categoryName}_number_value`, category.categoryValue);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [calculateValue, formikRef],
  );
  return [handleChange, isSubmitting];
};

const CategoryGroup = ({ formikRef, inputName, inputLabel, buttonSelectName, buttonSelectDescription }) => {
  const { formatMessage: t } = useIntl();
  const [onImportanceValueChange, loading] = useMonthlyExpensesCategoryValueHandler(formikRef);
  const localizedOptions = useMemo(() => OPTIONS.map(({ label, ...rest }) => ({ label: t({ id: label }), ...rest })), [
    t,
  ]);

  const resetButtonSelection = useCallback(
    ({ name }) => {
      if (!formikRef?.current) return;
      const buttonSelectFieldName = name.split('_number_value')[0];
      formikRef.current.setFieldValue(buttonSelectFieldName, null);
    },
    [formikRef],
  );

  return (
    <>
      <Field
        name={inputName}
        label={inputLabel}
        max={5000}
        component={NumberInput}
        sideEffectOnChange={resetButtonSelection}
      />
      <Field
        name={buttonSelectName}
        description={buttonSelectDescription}
        classnames="pt-0"
        options={localizedOptions}
        component={ButtonSelect}
        nullable={false}
        disabled={loading}
        skipScrollingToField
        sideEffectOnButtonClick={onImportanceValueChange}
      />
    </>
  );
};

const LifestyleCalculatorFourthStep = ({
  initialValues,
  currentStep,
  calculatorCompleted,
  clickableTabStep,
  myBudget,
  onSubmit,
  onBack,
  nextStep,
  previousStep,
  formValuesRef,
}) => {
  const { formatMessage: t } = useIntl();
  const formikRef = useRef();
  const isEditMode = useMemo(() => calculatorCompleted || myBudget?.step >= clickableTabStep, [
    myBudget,
    clickableTabStep,
    calculatorCompleted,
  ]);

  return (
    <Row between="xl" center="md">
      <Col md={10} lg={8} xl={6}>
        <PageTitle>{t({ id: 'budget_calculator.fourth_step_title' })}</PageTitle>

        <Formik
          validationSchema={validationSchema}
          innerRef={formikRef}
          initialValues={defaults(formValuesRef.current, initialValues)}
          onSubmit={async (values, actions) => {
            await onSubmit(values, actions, { currentStep: currentStep + 1 });
            nextStep();
          }}
        >
          {({ isSubmitting, values, errors, isValid }) => (
            <Form>
              <ScrollToError />
              <CategoryGroup
                formikRef={formikRef}
                inputName="socializing_number_value"
                inputLabel={t({ id: 'budget_calculator.socializing' })}
                buttonSelectName="socializing"
                buttonSelectDescription={t({ id: 'budget_calculator.socializing_descriptions' })}
              />
              <CategoryGroup
                formikRef={formikRef}
                inputName="luxuries_number_value"
                inputLabel={t({ id: 'budget_calculator.luxuries' })}
                buttonSelectName="luxuries"
                buttonSelectDescription={t({ id: 'budget_calculator.luxuries_description' })}
              />

              {values.cars ? (
                <CategoryGroup
                  formikRef={formikRef}
                  inputName="new_car_number_value"
                  inputLabel={t({ id: 'budget_calculator.new_car' })}
                  buttonSelectName="new_car"
                  buttonSelectDescription={t({ id: 'budget_calculator.new_car_description' })}
                />
              ) : null}

              <CategoryGroup
                formikRef={formikRef}
                inputName="taking_a_break_number_value"
                inputLabel={t({ id: 'budget_calculator.taking_a_break' })}
                buttonSelectName="taking_a_break"
                buttonSelectDescription={t({ id: 'budget_calculator.taking_a_break_description' })}
              />

              <CategoryGroup
                formikRef={formikRef}
                inputName="hobbies_number_value"
                inputLabel={t({ id: 'budget_calculator.hobbies' })}
                buttonSelectName="hobbies"
                buttonSelectDescription={t({ id: 'budget_calculator.hobbies_description' })}
              />

              <CategoryGroup
                formikRef={formikRef}
                inputName="technology_number_value"
                inputLabel={t({ id: 'budget_calculator.technology' })}
                buttonSelectName="technology"
                buttonSelectDescription={t({ id: 'budget_calculator.technology_description' })}
              />

              <CategoryGroup
                formikRef={formikRef}
                inputName="pension_and_insurances_number_value"
                inputLabel={t({ id: 'budget_calculator.pension_and_insurances' })}
                buttonSelectName="pension_and_insurances"
                buttonSelectDescription=""
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                <Button
                  disabled={isSubmitting || !isValid}
                  onClick={() => onBack(values, previousStep)}
                  startIcon={<ArrowBack />}
                  color="outline"
                >
                  {t({ id: 'app.back' })}
                </Button>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                  color="secondary"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={10} lg={8} xl={6} first="xs" last="xl">
        <div className="sticky-img-box">
          <BudgetStep5 title={t({ id: 'alt_text.budget_calculator.fourth_step' })} width="100%" height="100%" />
        </div>
      </Col>
    </Row>
  );
};

export default LifestyleCalculatorFourthStep;
