import React, { useState, useMemo } from 'react';
import { BudgetStep1 } from 'assets/images/lifestyle-calculator';
import { ReactComponent as Alone } from 'assets/icons/budget/alone.svg';
import { ReactComponent as Partner } from 'assets/icons/budget/partner.svg';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { Field, Form, Formik } from 'formik';
import ShowMoreText from 'react-show-more-text';
import ButtonSelect from 'components/inputs/ButtonSelect';
import PageTitle from 'components/common/PageTitle';
import { ReactComponent as DiagonalLine } from 'assets/icons/diagonal-line.svg';
import { ReactComponent as OneChildIcon } from 'assets/icons/budget/one-child.svg';
import { ReactComponent as TwoChildrenIcon } from 'assets/icons/budget/two-children.svg';
import { ReactComponent as ThreeChildrenIcon } from 'assets/icons/budget/three-children.svg';
import { ReactComponent as FourChildrenIcon } from 'assets/icons/budget/four-children.svg';
import Button from 'components/common/Button';
import { ArrowForward } from 'assets/icons';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import * as yup from 'yup';
import ScrollToError from 'components/common/ScrollToError';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import Alert from 'components/common/Alert';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import { defaults } from 'lodash';
import s from './LifestyleCalculatorForm.module.scss';

const validationSchema = yup.object().shape({
  project_type: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  buying_with_agent: yup.mixed().when('project_type', {
    is: (projectType) => projectType === 'buying',
    then: yup
      .string()
      .required(__('errors.fill_field'))
      .typeError(__('errors.fill_field')),
  }),
  square_meters: yup
    .string()
    .nullable()
    .required(__('errors.fill_field')),
  own_funds: yup
    .string()
    .nullable()
    .required(__('errors.fill_field')),
  alone_or_partner: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  age: yup
    .string()
    .nullable()
    .required(__('errors.fill_field')),
  kids_quantity: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

const LifestyleCalculatorFirstStep = ({
  calculatorCompleted,
  onSubmitStep,
  initialValues,
  formValuesRef,
  onSubmit,
  nextStep,
  currentStep,
  clickableTabStep,
  myBudget,
}) => {
  const { formatMessage: t } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const isEditMode = useMemo(() => calculatorCompleted || myBudget?.step >= clickableTabStep, [
    myBudget,
    clickableTabStep,
    calculatorCompleted,
  ]);

  return (
    <Row between="xl" center="md">
      <Col md={10} lg={8} xl={6}>
        <PageTitle>{t({ id: 'budget_calculator.first_step_title' })}</PageTitle>
        <div className={s.showMoreText}>
          <ShowMoreText
            lines={2}
            more={t({ id: 'budget_calculator.first_step.show_more_text' })}
            less={t({ id: 'budget_calculator.first_step.show_less_text' })}
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-left"
          >
            {t({ id: 'budget_calculator.first_step.budget_calculator_description' })}
          </ShowMoreText>
        </div>

        <Formik
          initialValues={defaults(formValuesRef.current, initialValues)}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (isEditMode) {
              await onSubmit(values, actions, { currentStep });
            } else {
              await onSubmitStep(values, actions);
            }
            nextStep();
          }}
        >
          {({ isSubmitting, errors, values }) => (
            <Form>
              <ScrollToError />
              <Field
                name="project_type"
                label={t({ id: 'budget_calculator.first_step.project_type_finance.label' })}
                component={ButtonSelect}
                options={[
                  {
                    label: t({ id: 'budget_calculator.first_step.project_type_finance.renovation' }),
                    value: 'renovation',
                  },
                  {
                    label: t({ id: 'budget_calculator.first_step.project_type_finance.buying' }),
                    value: 'buying',
                  },
                  {
                    label: t({ id: 'budget_calculator.first_step.project_type_finance.building' }),
                    value: 'building',
                  },
                ]}
              />
              {values.project_type === 'buying' ? (
                <Field
                  name="buying_with_agent"
                  label={t({ id: 'budget_calculator.first_step.agents_help_when_buying.label' })}
                  component={ButtonSelect}
                  options={[
                    {
                      label: t({ id: 'budget_calculator.first_step.agents_help_when_buying.buy_with_agent' }),
                      value: true,
                    },
                    {
                      label: t({ id: 'budget_calculator.first_step.agents_help_when_buying.buy_without_agent' }),
                      value: false,
                    },
                  ]}
                />
              ) : null}

              <Field
                name="square_meters"
                label={t({ id: 'budget_calculator.first_step.square_meters' })}
                description={t({ id: 'budget_calculator.first_step.square_meters_description' })}
                component={SliderWithTooltip}
                largeLabel
                min={25}
                max={300}
                defaultValue={100}
                step={5}
                units="m²"
              />
              <Field
                name="own_funds"
                component={SliderWithTooltip}
                defaultValue={10000}
                max={500000}
                step={10000}
                label={`${t({ id: `budget_calculator.first_step.own_funds` })}, €`}
                description={t({ id: 'budget_calculator.first_step.own_funds_description' })}
                units="€"
              />

              <Field
                name="alone_or_partner"
                label={t({ id: 'budget_calculator.first_step.alone_or_partner' })}
                component={ButtonSelect}
                nullable={false}
                options={[
                  {
                    startIcon: ({ altText }) => <Alone className="stroke" title={altText} />,
                    value: 'alone',
                    label: t({ id: 'budget_calculator.first_step.alone_option_label' }),
                  },
                  {
                    startIcon: ({ altText }) => <Partner className="stroke" title={altText} />,
                    value: 'partner',
                    label: t({ id: 'budget_calculator.first_step.partner_option_label' }),
                  },
                ]}
              />

              <Field
                name="age"
                component={SliderWithTooltip}
                defaultValue={30}
                max={70}
                min={18}
                step={1}
                label={`${t({ id: `budget_calculator.first_step.age` })}`}
                description={t({ id: 'budget_calculator.first_step.age_description' })}
                units={t({ id: 'budget_calculator.first_step.slider_unit_years' })}
              />

              <Field
                name="kids_quantity"
                label={t({ id: 'budget_calculator.first_step.kids_quantity' })}
                component={ButtonSelect}
                description={t({ id: 'budget_calculator.first_step.kids_quantity_description' })}
                inOneRow={false}
                nullable={false}
                options={[
                  {
                    startIcon: ({ altText }) => <DiagonalLine className="stroke" title={altText} />,
                    altText: t({ id: 'alt_text.icons.has_none' }),
                    value: 0,
                    label: '',
                  },
                  {
                    startIcon: ({ altText }) => <OneChildIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.kids_quantity.one_child' }),
                    value: 1,
                    label: '1',
                  },
                  {
                    startIcon: ({ altText }) => <TwoChildrenIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.kids_quantity.two_children' }),
                    value: 2,
                    label: '2',
                  },
                  {
                    startIcon: ({ altText }) => <ThreeChildrenIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.kids_quantity.three_children' }),
                    value: 3,
                    label: '3',
                  },
                  {
                    startIcon: ({ altText }) => <FourChildrenIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.kids_quantity.four_children' }),
                    value: 4,
                    label: '4+',
                  },
                ]}
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <Alert color="warning">
                <FormattedHTMLMessage id="disclaimers.budget" />
              </Alert>

              <div className={s.buttonsWrapper}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                  color="secondary"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={10} lg={8} xl={6} first="xs" last="xl">
        <div className="sticky-img-box">
          <BudgetStep1 title={t({ id: 'alt_text.budget_calculator.first_step' })} width="100%" height="100%" />
        </div>
      </Col>
    </Row>
  );
};

export default LifestyleCalculatorFirstStep;
