import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { ReactComponent as ModalIcon } from 'assets/icons/modal.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as DeleteAccountIcon } from 'assets/icons/delete-account.svg';
import { ReactComponent as ResendPasswordIcon } from 'assets/icons/new-password.svg';
import IconButton from 'components/common/IconButton';
import useDeleteUserAccount from 'hooks/loan-consultant/useDeleteUserAccount';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import useResendTempPassword from 'hooks/loan-consultant/useResendTempPassword';

const useDeleteUserAccountHandler = () => {
  const [deleteAccount, loading] = useDeleteUserAccount();
  const { formatMessage: t } = useIntl();

  const handleRemove = useCallback(
    async ({ userId, profile }) => {
      try {
        confirmAlert({
          title: t({ id: 'invitations.delete_account.title' }),
          message: t(
            { id: 'invitations.delete_account.message' },
            { clientInfo: `${profile.firstName} ${profile.lastName} - ${profile.email}` },
          ),
          buttons: [
            {
              label: t({ id: 'invitations.delete_account.cancel' }),
            },
            {
              label: t({ id: 'invitations.delete_account.confirm' }),
              onClick: async () => {
                await deleteAccount(userId);
              },
            },
          ],
        });
      } catch (error) {
        toast.error(error.message);
      }
    },
    [t, deleteAccount],
  );
  return [handleRemove, loading];
};

const DeleteUserAccountButton = ({ userId, temp_password_changed, profile }) => {
  const [deleteUserAccount, submitting] = useDeleteUserAccountHandler();
  return !temp_password_changed ? (
    <div className="d-flex-center">
      <IconButton loading={submitting} disabled={submitting} onClick={() => deleteUserAccount({ userId, profile })}>
        <DeleteAccountIcon />
      </IconButton>
    </div>
  ) : null;
};

const ResendTemporaryPasswordButton = ({ userId, temp_password_changed }) => {
  const [resendTempPassword, loading] = useResendTempPassword();
  return !temp_password_changed ? (
    <div className="d-flex-center">
      <IconButton loading={loading} disabled={loading} onClick={() => resendTempPassword(userId)}>
        <ResendPasswordIcon />
      </IconButton>
    </div>
  ) : null;
};

export const invitationsTableColumns = [
  {
    title: 'invitations.email',
    dataIndex: 'user_email',
    key: 'user_email',
    width: isMobile ? 120 : 200,
    ellipsis: true,
    fixed: 'left',
  },
  {
    title: 'invitations.name',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: 'invitations.projects',
    dataIndex: 'projects',
    key: 'projects',
    width: 100,
    render: ({ showModal, projects }) => (
      <div className="d-flex-center">
        <IconButton onClick={() => showModal({ projects })}>
          <ModalIcon />
        </IconButton>
      </div>
    ),
  },
  {
    title: 'invitations.budget_pdf',
    dataIndex: 'budgetPdf',
    key: 'budgetPdf',
    width: 130,
    render: (budgetPdf) => {
      return budgetPdf ? (
        <div className="d-flex-center">
          <a href={budgetPdf} target="_blank" rel="noopener noreferrer">
            <IconButton>
              <PdfIcon />
            </IconButton>
          </a>
        </div>
      ) : null;
    },
  },
  {
    title: 'invitations.date_of_invitation',
    dataIndex: 'date_of_invitation',
    key: 'date_of_invitation',
    width: 130,
  },
  {
    title: 'invitations.date_of_last_login',
    dataIndex: 'date_of_last_login',
    key: 'date_of_last_login',
    width: 130,
  },
  {
    title: 'invitations.number_of_projects',
    dataIndex: 'numberOfProjects',
    key: 'numberOfProjects',
    width: 150,
  },
  {
    title: 'invitations.loan_consultant_email',
    dataIndex: 'loan_consultant_email',
    key: 'loan_consultant_email',
    width: 150,
  },
  {
    title: 'invitations.loan_consultant_name',
    dataIndex: 'loan_consultant_name',
    key: 'loan_consultant_name',
    width: 150,
  },
  {
    title: 'invitations.inviter_email',
    dataIndex: 'inviter_email',
    key: 'inviter_email',
    width: 150,
  },
  {
    title: 'invitations.inviter_name',
    dataIndex: 'inviter_name',
    key: 'inviter_name',
    width: 150,
  },
  {
    title: 'invitations.temp_password_changed',
    dataIndex: 'temp_password_changed',
    key: 'temp_password_changed',
    width: 100,
  },
  {
    title: 'invitations.resend_temp_password',
    dataIndex: 'resend_temp_password',
    key: 'resend_temp_password',
    width: 150,
    render: ResendTemporaryPasswordButton,
  },
  {
    title: 'invitations.delete_user_account',
    dataIndex: 'delete_user_account',
    key: 'delete_user_account',
    width: 100,
    render: DeleteUserAccountButton,
  },
];

export const projectsTableColumns = [
  {
    title: 'invitations.project.name',
    dataIndex: 'name',
    key: 'name',
    width: isMobile ? 120 : 200,
    fixed: 'left',
  },
  {
    title: 'invitations.project.type',
    dataIndex: 'type',
    key: 'type',
    width: 200,
  },
  {
    title: 'invitations.project.pdf',
    dataIndex: 'pdf',
    key: 'pdf',
    width: 100,
    render: (pdf) => (
      <div className="d-flex-center">
        <a href={pdf} target="_blank" rel="noopener noreferrer">
          <IconButton>
            <PdfIcon />
          </IconButton>
        </a>
      </div>
    ),
  },
  {
    title: 'invitations.project.project_details',
    dataIndex: 'project_details',
    key: 'project_details',
    width: 200,
  },
  {
    title: 'invitations.project.plan_adjusted',
    dataIndex: 'plan_adjusted',
    key: 'plan_adjusted',
    width: 200,
  },
  {
    title: 'invitations.project.budget_planning',
    dataIndex: 'budget_planning',
    key: 'budget_planning',
    width: 200,
  },
  {
    title: 'invitations.project.loan',
    dataIndex: 'loan',
    key: 'loan',
    width: 200,
  },
  {
    title: 'invitations.project.loan_offer',
    dataIndex: 'loan_offer',
    key: 'loan_offer',
    width: 200,
  },
  {
    title: 'invitations.project.transferred_by_email',
    dataIndex: 'transferred_by_email',
    key: 'transferred_by_email',
    width: 200,
  },
  {
    title: 'invitations.project.transferred_by_name',
    dataIndex: 'transferred_by_name',
    key: 'transferred_by_name',
    width: 200,
  },
];
