import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorMessage from 'components/common/ErrorMessage';
import Button from 'components/common/Button';
import Slider from 'components/inputs/Slider';
import { routePatterns } from 'router/route-paths';
import Toggler from 'components/inputs/Toggler';
import NumberInput from 'components/inputs/NumberInput';
import { ArrowForward } from 'assets/icons';
import { formatCurrency } from 'utils/helpers';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import Alert from 'components/common/Alert';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import s from './LoanCalculatorForm.module.scss';

const loanShape = {
  budget: yup
    .number()
    .required()
    .typeError(<FormattedMessage id="errors.fill_field" />)
    .max(2000000, <FormattedMessage id="errors.max_value" values={{ max: formatCurrency(2000000) }} />),
  period: yup
    .number()
    .required(<FormattedMessage id="errors.choose_one" />)
    .typeError(<FormattedMessage id="errors.choose_one" />),
  interest_type: yup
    .string()
    .required(<FormattedMessage id="errors.choose_one" />)
    .typeError(<FormattedMessage id="errors.choose_one" />),
  fixed_interest_period: yup
    .mixed()
    .notRequired()
    .when('interest_type', {
      is: (interestType) => interestType === 'fixed',
      then: yup
        .string()
        .required(<FormattedMessage id="errors.choose_one" />)
        .typeError(<FormattedMessage id="errors.choose_one" />),
    }),
};

const computeTotalOwnFunds = (budget = 0, estimatedPropertyPrice) => budget + estimatedPropertyPrice;

const loanParamsSchema = yup.object().shape(loanShape);

const LoanCalculatorForm = ({
  initialValues,
  onSubmit,
  projectName,
  projectId,
  onChangeBudget,
  onChangeEstimatedPropertyPrice,
}) => {
  const { formatMessage: t } = useIntl();
  return (
    <Formik initialValues={initialValues} validationSchema={loanParamsSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors, values }) => (
        <>
          <Form>
            <Field
              name="price"
              component={NumberInput}
              disabled
              label={t({ id: 'loan.price' })}
              description={
                <>
                  {t({ id: 'loan.project_link_description' })}{' '}
                  <Link to={routePatterns.plan.stringify({ id: projectId })} className="link">
                    {projectName}
                  </Link>
                </>
              }
            />
            <Field
              name="budget"
              component={SliderWithTooltip}
              min={0}
              step={1000}
              max={2000000}
              units="€"
              label={t({ id: 'loan.budget' })}
              sideEffectOnChange={onChangeBudget}
            />

            {initialValues.estimated_property_price ? (
              <>
                <Field
                  name="estimated_property_price"
                  component={NumberInput}
                  label={t({ id: 'loan.estimated_property_price' })}
                  sideEffectOnChange={onChangeEstimatedPropertyPrice}
                />
                <p className={s.totalLivingSpace}>
                  <span>
                    <FormattedMessage id="project_wizard.total_own_funds" />
                  </span>
                  <b>{formatCurrency(computeTotalOwnFunds(values?.budget, values.estimated_property_price))}</b>
                </p>
              </>
            ) : null}
            <Field
              name="period"
              required
              component={Slider}
              min={10}
              max={35}
              step={5}
              label={t({ id: 'loan.period' })}
            />
            <Field
              name="interest_type"
              required
              component={Toggler}
              options={[
                {
                  label: t({ id: 'loan.interest_type.variable' }),
                  value: 'variable',
                },
                {
                  label: t({ id: 'loan.interest_type.fixed' }),
                  value: 'fixed',
                },
              ]}
              label={t({ id: 'loan.interest_terms' })}
            />
            {values.interest_type === 'fixed' && (
              <Field
                name="fixed_interest_period"
                component={Slider}
                required
                min={10}
                max={20}
                step={5}
                label={t({ id: 'loan.fixed_interest_period' })}
              />
            )}
            {errors.form && <ErrorMessage message={errors.form} />}

            <Alert color="warning">
              <FormattedHTMLMessage id="disclaimers.loan_calculator" />
            </Alert>

            <div className={s.btnWrapper}>
              <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                {t({ id: 'app.submit_form_final' })}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default LoanCalculatorForm;
