import React from 'react';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { get } from 'lodash';
import { animateScroll } from 'react-scroll';
import { FormattedMessage } from 'react-intl';

export function __(id, values) {
  return <FormattedMessage {...{ id, values }} />;
}

export function serializeFormValues(fv) {
  const formValues = cloneDeep(fv);
  Object.entries(formValues).forEach(([key, value]) => {
    if (key.includes('.')) {
      set(formValues, key, value);
      delete formValues[key];
    }
    if (key.includes('--')) {
      const splitKey = key.split('--');
      const newFeatures = formValues.features.map((f) => {
        if (f.name === splitKey[0]) {
          const res = { name: splitKey[0], value: {} };
          set(res.value, `${splitKey[1]}--${splitKey[2]}`, value);
          delete formValues[key];
          return res;
        }
        return f;
      });
      set(formValues, 'features', newFeatures);
      delete formValues[key];
    }
  });
  return formValues;
}

export const getInitialValuesFromSteps = (steps) => {
  if (!steps && !Array.isArray(steps)) return {};
  const initialValues = {};
  const features = [];
  const getDefaultValues = (fields) => {
    fields.forEach((f) => {
      if (!f.name && !f.type === 'group') return;
      if (f.type === 'group') {
        getDefaultValues(f.components);
      } else if (f.type === 'text') {
        initialValues[f.name] = f.value || '';
      } else if (f.type === 'switch') {
        initialValues[f.name] = f.value || undefined;
      } else if (f.type === 'checkboxes_group') {
        initialValues.features = features;
        f.fields.forEach(({ autoselect, name, form }) => {
          if (autoselect) {
            features.push({ name, value: true });
          }
          form.forEach((formField) => {
            set(initialValues, `${name}--${formField.name}`, formField.defaultValue);
          });
        });
      } else if (f.defaultValue !== undefined) {
        initialValues[f.name] = f.defaultValue; // === undefined ? null : f.defaultValue;
      }
    });
  };
  steps.map((schema) => getDefaultValues(schema.fields));
  return initialValues;
};

export const getIndexElementFromArray = ({ array, searchedValue }) =>
  array.findIndex((element) => element === searchedValue);

export const areEqualFields = (prevProps, nextProps, additionalKeys = []) => {
  const keys = ['field', 'form.errors', 'form.touched', ...additionalKeys];
  const res = keys.every((key) => isEqual(get(prevProps, key), get(nextProps, key)));
  return res;
};

function scrollToElement($targetElement) {
  const coordinates = $targetElement.getBoundingClientRect();
  animateScroll.scrollTo(coordinates.top + window.pageYOffset - 300);
}

function focusInputElement($targetElement) {
  const $inputElement = $targetElement.querySelector('input[type="text"], input[type="number"]');
  if ($inputElement) $inputElement.focus();
}

function getMatchingSibling($elem, selector) {
  const $nextSibling = $elem.nextSibling;
  if (!$nextSibling) return null;
  if ($nextSibling.matches(selector)) return $nextSibling;

  const $deepMatchingElem = $nextSibling.querySelector(selector);
  if ($deepMatchingElem) return $deepMatchingElem;

  return getMatchingSibling($nextSibling, selector);
}

function getMatchingParent(currentElement, selector) {
  if (currentElement.parentElement.tagName === 'FORM') return null;
  const { parentElement } = currentElement;
  const $nextMatchingField = getMatchingSibling(parentElement, selector);
  return $nextMatchingField || getMatchingParent(parentElement, selector);
}

export function scrollToNextMatchingSibling(ref) {
  const $currentElement = ref.current;
  if (!$currentElement) return;

  const selector = 'div[class*="scroll-to-field"]';
  const $nextMatchingFieldWrapper =
    getMatchingSibling($currentElement, selector) || getMatchingParent($currentElement, selector);

  let $targetElement;
  if ($nextMatchingFieldWrapper) {
    $targetElement = $nextMatchingFieldWrapper;
  } else {
    $targetElement = document.querySelector('button[type="submit"]');
  }
  if (!$targetElement) return;

  scrollToElement($targetElement);
  focusInputElement($targetElement);
}

export const onKeyDown = (keyEvent, fieldWrapperRef) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
    scrollToNextMatchingSibling(fieldWrapperRef);
  }
};
