import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import routePaths, { routePatterns } from 'router/route-paths';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import useLastProjectId from 'hooks/project/useLastProjectId';
import { formatGraphqlErrors } from 'utils/helpers';
import { CURRENT_USER_FRAGMENT } from 'hooks/user/useUser';

export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useVerifyEmail = () => {
  const history = useHistory();
  const { formatMessage: __ } = useIntl();
  const { lastProjectId } = useLastProjectId();

  const [verifyEmail] = useMutation(VERIFY_EMAIL_MUTATION, {
    refetchQueries: ['me'],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const verifiedEmail = get(data, 'verifyEmail.emails[0].verified', false);
      if (verifiedEmail) {
        toast.success(__({ id: 'verify_account.email_verified' }));
        if (lastProjectId) {
          history.replace(routePatterns.dashboard.stringify({ id: lastProjectId }));
        } else {
          history.replace(routePaths.default);
        }
      }
    },
    onError: (e) => {
      history.replace(routePaths.login);
      toast.error(formatGraphqlErrors(e).message);
    },
  });

  const handleVerifyEmail = useCallback(
    async (token) => {
      try {
        const res = await verifyEmail({ variables: { token } });
        return res;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        toast.error(get(error, 'graphQLErrors[0].message'));
        e.raw = error;
        throw e;
      }
    },
    [verifyEmail],
  );

  return handleVerifyEmail;
};

export default useVerifyEmail;
