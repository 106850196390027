import React, { useMemo } from 'react';
import useMyBudget from 'hooks/budget/useMyBudget';
import pick from 'lodash/pick';
import map from 'lodash/map';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useHistory } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import useUpdateBudget from 'hooks/budget/useUpdateBudget';
import { toast } from 'react-toastify';
import routePaths from 'router/route-paths';
import BudgetCalculatorEditForm from 'components/budget/BudgetCalculatorEditForm';
import PageTitle from 'components/common/PageTitle';
import round from 'lodash/round';
import WarningMessage from 'components/common/WarningMessage';
import { get } from 'lodash';

const monthlyExpensesKeys = [
  'monthly_expenses',
  'monthly_expenses_partner',
  'monthly_expenses_kids',
  'costs_for_repair',
  'technology',
  'luxuries',
  'socializing',
  'hobbies',
  'taking_a_break',
  'new_car',
  'pension_and_insurances',
];

const useUpdateBudgetHandler = (myBudget) => {
  const updateBudget = useUpdateBudget();
  const history = useHistory();

  const handleSubmit = React.useCallback(
    async (variables, { setSubmitting }, formik) => {
      setSubmitting(true);
      try {
        const changedVariables = Object.keys(variables).filter(
          (key) => round(get(myBudget, `calculation[${key}].calculated_value`)) !== variables[key],
        );

        if (formik.dirty) await updateBudget(pick(variables, changedVariables));

        history.push(routePaths.lifestyleOverview);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [history, myBudget, updateBudget],
  );
  return handleSubmit;
};

function getInitialValues(lifestyleCalculations) {
  if (!lifestyleCalculations) return {};
  const allowedValues = pick(lifestyleCalculations.calculation, monthlyExpensesKeys);

  map(allowedValues, (item, key) => {
    if (item.calculated_value === 0 && !Number.isFinite(item.user_input)) {
      delete allowedValues[key];
    } else if (Number.isFinite(item.calculated_value)) {
      const value = Number.isFinite(item.user_input) ? item.user_input : item.calculated_value;
      allowedValues[key] = round(value);
    }
  });
  return allowedValues;
}

const LifestyleEditMonthlyExpensesValues = ({ lifestyleCalculations, t }) => {
  const onSubmit = useUpdateBudgetHandler(lifestyleCalculations);

  const initialValues = useMemo(() => getInitialValues(lifestyleCalculations), [lifestyleCalculations]);

  return (
    <Grid>
      <Row between="xs" center="md">
        <Col md={8} lg={12}>
          <PageTitle>{t({ id: 'page_titles.edit_budget' })}</PageTitle>
        </Col>
      </Row>
      <BudgetCalculatorEditForm {...{ onSubmit, initialValues }} />
    </Grid>
  );
};

export default () => {
  const [lifestyleCalculations, loading] = useMyBudget();
  const { formatMessage: t } = useIntl();

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!lifestyleCalculations) {
    return (
      <Row center="xs">
        <Col md={8} lg={6}>
          <WarningMessage
            title={t({ id: 'page_titles.edit_budget' })}
            message={t({ id: 'budget.edit.complete_budget_step' })}
            btnLabel={t({ id: 'budget.edit.complete_budget_step_btn' })}
            btnLink={routePaths.lifestyleOverview}
          />
        </Col>
      </Row>
    );
  }
  return <LifestyleEditMonthlyExpensesValues {...{ lifestyleCalculations, t }} />;
};
