import React, { useState, useCallback } from 'react';
import PageTitle from 'components/common/PageTitle';
import { Grid, Row, Col } from 'react-flexbox-grid';
import useSignUp from 'hooks/auth/useSignUp';
import useLogin from 'hooks/auth/useLogin';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import LoginForm from 'components/auth/LoginForm';
import { useIntl } from 'react-intl';
import { QuickCalculatorStep1 } from 'assets/icons/QuickCalculator';
import s from './SignUp.module.scss';

const SignUp = () => {
  const { formatMessage: t } = useIntl();
  const [isSignUp, setIsSignUp] = useState(true);
  const signUp = useSignUp();
  const login = useLogin();
  const auth = isSignUp ? signUp : login;

  const handleSubmit = useAuthFormSubmit(auth);

  const onSubmit = useCallback(
    async (values, formikActions) => {
      await handleSubmit(values, formikActions);
    },
    [handleSubmit],
  );

  return (
    <Grid>
      <Row center="md" between="lg">
        <Col md={8} lg={6} xl={5}>
          <PageTitle className={s.title} auth>
            {t({ id: isSignUp ? 'page_titles.sign_up' : 'page_titles.login' })}
          </PageTitle>

          <LoginForm isSignUp={isSignUp} setIsSignUp={setIsSignUp} onSubmit={onSubmit} />
        </Col>
        <Col md={0} lg={6} xl={5} className={s.secondCol} first="xs" last="md">
          <QuickCalculatorStep1 width="100%" height="100%" title={t({ id: 'alt_text.auth_pages.sign_up' })} />
        </Col>
      </Row>
    </Grid>
  );
};

export default SignUp;
