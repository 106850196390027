/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useRef, useCallback } from 'react';
import { RadioGroup, Radio } from 'react-radio-group';
import get from 'lodash/get';
import ScreenLink from 'components/common/ScreenLink';
import { areEqualFields, scrollToNextMatchingSibling } from 'utils/form';
import { useIntl } from 'react-intl';
import s from './InlineSelect.module.scss';

function RadioItem({ imageSrc, img: Img, label, description, value, name, t, index }) {
  return (
    <div className={s.radioItem} style={description && { alignSelf: 'flex-start' }}>
      <div className={s.wrapper}>
        <label className={s.imageLabel} htmlFor={`${name}.${index}`}>
          {/* added ?.default according to this issue https://github.com/facebook/create-react-app/issues/9992 */}
          {imageSrc ? (
            <img src={imageSrc?.default} alt={t({ id: 'alt_text.btn_select_with_icon' }, { btnLabel: label })} />
          ) : (
            <Img title={t({ id: 'alt_text.btn_select_with_icon' }, { btnLabel: label })} />
          )}
          <div className={s.container}>
            <div className={s.round}>
              <Radio type="radio" id={`${name}.${index}`} name={name} value={value} />
              <label htmlFor={`${name}.${index}`} />
            </div>
          </div>
        </label>
      </div>
      <div className="ml-4">
        <label className={s.optionLabel}>{label}</label>
        {description ? (
          <div className="text-left">
            <small className="muted">{description}</small>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const InlineSelect = ({ form, options, field, label, sideEffectOnChange, moreDetails, skipScrollingToField }) => {
  const { formatMessage: t } = useIntl();
  const { errors, touched } = form;
  const { name, value } = field;
  const fieldWrapperRef = useRef();

  const handleChange = useCallback(
    (v) => {
      form.setFieldValue(name, v);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange({ value: v, form });
      if (v) scrollToNextMatchingSibling(fieldWrapperRef);
    },
    [form, name, sideEffectOnChange],
  );

  return (
    <div ref={fieldWrapperRef} className={!skipScrollingToField ? 'scroll-to-field' : ''}>
      <label className={s.inlineSelectLabel}>
        {label} {moreDetails && <ScreenLink linkData={moreDetails} />}
      </label>
      <RadioGroup className={s.radioGroup} name={name} selectedValue={value} onChange={handleChange}>
        {options.map((option, index) => (
          <RadioItem key={option.value} {...{ ...option, name, t, index }} />
        ))}
      </RadioGroup>

      {get(errors, name) && get(touched, name) && <div className={s.errorSelect}>{get(errors, name)}</div>}
    </div>
  );
};

export default memo(InlineSelect, (prevProps, nextProps) => areEqualFields(prevProps, nextProps, ['options']));
