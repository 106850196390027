/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import { primaryColor } from 'styles/variables.scss';
import { useAppContext } from 'contexts/AppContext';
import Loading from './Loading';
import s from './Button.module.scss';

const colorToClassNameMap = {
  primary: {
    primary: s.primary,
    secondary: s.secondary,
    outline: s.outline,
    success: s.success,
    warning: s.warning,
  },
  green: {
    primary: s.outlineSuccess,
    secondary: s.success,
    outline: s.outline,
  },
};

export default function Button({
  children,
  className,
  color = 'primary',
  disabled,
  loading,
  type = 'button',
  onClick,
  startIcon,
  endIcon,
  topIcon,
  altText,
  ...rest
}) {
  const { theme } = useAppContext();

  return (
    <button
      className={cn(s.btn, topIcon && s.btnWithTopIcon, colorToClassNameMap[theme][color], className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <div
        className={cn(s.btnContent, (startIcon || endIcon) && s.btnContentWithIcon)}
        style={loading ? { visibility: 'hidden' } : {}}
      >
        {startIcon && <div>{startIcon}</div>}
        <div className={s.btnChildren}>{children}</div>
        {topIcon && <div className={s.topIcon}>{topIcon}</div>}
        {endIcon && <div>{endIcon}</div>}
      </div>
      {loading ? (
        <div className={s.btnLoading}>
          <Loading size={25} color={color === 'secondary' ? 'white' : primaryColor} />
        </div>
      ) : null}
    </button>
  );
}
