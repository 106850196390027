import { connect } from 'formik';
import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

const FormikEffect = ({ formik, onEffect }) => {
  const prevValuesRef = useRef(null);

  useEffect(() => {
    if (!isEqual(prevValuesRef.current, formik.values)) {
      if (prevValuesRef.current) {
        onEffect();
      }
      prevValuesRef.current = formik.values;
    }
  }, [formik.values, onEffect]);
  return null;
};
export default connect(FormikEffect);
